import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import { AnyObject } from 'yup';
import { ChangeEvent, ReactNode } from 'react';
import CardBox from '../containers/CardBox';
import TableListHeader from './TableListHeader';
import { Order } from '../../../model/table';
import TableFooter from './TableFooter';

type Props = {
	// id: string;
	children: ReactNode;
	orderBy?: string;
	order?: Order;
	headCells: AnyObject[];
	count?: number;
	rowsPerPage?: number;
	page?: number;
	onPageChange: (e: unknown, newPage: number) => void;
	onRowsPerPageChange: (e: ChangeEvent<HTMLInputElement>) => void;
	onHeaderClick?: (id: string, o: string) => void;
};

export default function TableList({
	headCells,
	orderBy,
	order = 'asc',
	count = 0,
	rowsPerPage = 10,
	page = 0,
	children,
	onPageChange,
	onRowsPerPageChange,
	onHeaderClick,
}: Props) {
	return (
		<CardBox className='pt-1' size='small'>
			<TableContainer className='overflow-visible'>
				<Table size='medium' stickyHeader>
					<TableListHeader headCells={headCells} orderBy={orderBy} order={order} onClick={onHeaderClick} />
					<TableBody>{children}</TableBody>
				</Table>
			</TableContainer>
			<TableFooter
				rowsPerPageOptions={[10, 20, 30, 50]}
				showFirstButton
				showLastButton
				count={count}
				rowsPerPage={rowsPerPage}
				page={page}
				onPageChange={onPageChange}
				onRowsPerPageChange={onRowsPerPageChange}
			/>
		</CardBox>
	);
}
