import { useQuery } from '@tanstack/react-query';
import React, { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { initSysCompanyConfigs } from '../../App';
import { queryKey } from '../../constants/queryKey';
import SysConfigStore from '../../store/common/SysConfigStore';

export function SysConfigSetter({ children }: { children: React.ReactNode }) {
	const { sysCompanyConfigs, setSysCompanyConfigs, setSysCompanyComponents } = SysConfigStore();
	const [config] = useCookies(['is-update_config_chache']);
	const updateConfigCache = config['is-update_config_chache'];

	const { data: res, refetch } = useQuery(queryKey.sysConfig, initSysCompanyConfigs, {
		cacheTime: 0,
	});

	useEffect(() => {
		if (res) {
			setSysCompanyConfigs(res.data);
			setSysCompanyComponents(res.data);
		}
	}, [res]);

	useEffect(() => {
		if (updateConfigCache || updateConfigCache === 'temp') {
			refetch();
		}
	}, [updateConfigCache]);

	return sysCompanyConfigs ? children : null;
}
