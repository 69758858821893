import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { throttle } from 'lodash';
import { ChangeEvent, MouseEvent } from 'react';
import TableFooter from '../ui/tables/TableFooter';
import UserChip from '../ui/chips/UserChip';
import ExternalUserChip from '../ui/chips/ExternalUserChip';
import CardBox from '../ui/containers/CardBox';
import TableSearchBar from '../ui/tables/TableSearchBar';
import InputSelect from '../ui/inputs/InputSelect';
import InputText from '../ui/inputs/InputText';
import SearchIcon from '../ui/icons/SearchIcon';
import TableAppHeader from '../ui/tables/app/TableAppHeader';
import { HeadCell } from '../../model/table';
import { useValidateForm } from '../../hooks/useValidateForm';
import { StatisticsTableRow } from '../../model/statistics';
import { useStatistics } from '../../context/StatisticsContext';

type Props = {
	listType?: 'checkbox' | 'radio';
	list: StatisticsTableRow[];
	headCells: readonly HeadCell<StatisticsTableRow>[];
	totalListCount?: number;
	onClick: (e: MouseEvent<HTMLTableRowElement>, row: StatisticsTableRow) => void;
};

const searchColumn: HeadCell<StatisticsTableRow>[] = [
	{
		id: 'type',
		width: 'w-[100px]',
		align: 'left',
		disablePadding: false,
		label: '구분',
		sort: false,
	},
	{
		id: 'title',
		width: 'w-4/12',
		align: 'left',
		disablePadding: false,
		label: '법무명',
		sort: false,
	},
	{
		id: 'counterparty',
		align: 'left',
		disablePadding: false,
		label: '상대방',
		sort: false,
	},
	{
		id: 'drafter',
		align: 'left',
		disablePadding: false,
		label: '등록인',
		sort: false,
	},
	{
		id: 'id',
		width: 'w-[100px]',
		align: 'left',
		disablePadding: false,
		label: 'ID',
		sort: false,
	},
];

export default function StatisticsTable({ listType, list, headCells, totalListCount, onClick }: Props) {
	const { searchParams, setSearchParams } = useStatistics();
	const { searchType, searchText, currentPage, rowsPerPage, orderColumn, order } = searchParams;

	const formikTableSearch = useValidateForm({
		validationSchema: {
			search_type: { initialValue: searchType, type: 'string' },
			search_text: { initialValue: searchText, type: 'string' },
		},
		onSubmit: values => {
			setSearchParams(prev => ({ ...prev, searchType: values.search_type, searchText: values.search_text }));
		},
	});

	const handleOrdering = (e: MouseEvent<unknown>, property: keyof StatisticsTableRow) => {
		const isAsc = orderColumn === property && order === 'asc';
		setSearchParams(prev => ({ ...prev, orderColumn: property, order: isAsc ? 'desc' : 'asc' }));
	};

	const handleClick = throttle(async (e: MouseEvent<HTMLTableRowElement>, row: StatisticsTableRow) => {
		onClick(e, row);
	}, 1000);

	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	// rows 양이 적을 경우 크기 조절
	const emptyRows = currentPage > 0 ? Math.max(0, rowsPerPage - list.length) : 0;

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar>
				<div className='flex items-center gap-2 w-full'>
					<div className='min-w-[150px] rounded-sm bg-white'>
						<InputSelect
							id='search_type'
							label='검색 옵션'
							placeholder='검색 옵션 선택'
							options={searchColumn.map(({ id, label }) => ({ name: label, value: id }))}
							formik={formikTableSearch}
							required
						/>
					</div>
					<div className='flex-1 rounded-sm bg-white'>
						<form onSubmit={formikTableSearch.handleSubmit}>
							<InputText
								id='search_text'
								icon={
									<SearchIcon
										classes={{ root: 'cursor-pointer' }}
										onClick={formikTableSearch.handleSubmit}
									/>
								}
								labelText={`${
									headCells.filter(
										cell => cell.id === formikTableSearch.values.table_search_select,
									)[0]?.label ?? ''
								} 검색`}
								formik={formikTableSearch}
							/>
						</form>
					</div>
				</div>
			</TableSearchBar>

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible'>
					<Table size='medium' stickyHeader>
						<TableAppHeader
							headCells={headCells}
							order={order}
							orderBy={orderColumn}
							onRequestSort={handleOrdering}
							rowCount={list.length}
						/>
						<TableBody>
							{list.length === 0 && (
								<TableRow>
									<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
										검색 결과가 없습니다.
									</TableCell>
								</TableRow>
							)}
							{list.length > 0 &&
								list.map((row, index) => {
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={e => handleClick(e, row)}
											role='checkbox'
											tabIndex={-1}
											key={row.id}
											sx={{ cursor: 'pointer' }}
										>
											{listType && (
												<TableCell padding='checkbox'>
													<Checkbox
														id={labelId}
														color='primary'
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
											)}
											<TableCell align='left' component='th' id={labelId} scope='row'>
												{row.type}
											</TableCell>
											<TableCell align='left'>{row.title}</TableCell>
											<TableCell align='left'>{row.status}</TableCell>
											<TableCell align='left'>
												<div className='flex flex-wrap items-center gap-1'>
													{row.counterparty.map(item => (
														<ExternalUserChip
															key={item.id}
															id={String(item.id)}
															user={item}
															size='small'
															disabled
														/>
													))}
												</div>
											</TableCell>
											<TableCell align='left'>
												<UserChip
													id={String(row.drafter.id)}
													user={row.drafter}
													size='small'
													disabled
												/>
											</TableCell>
											<TableCell align='left'>{row.view_code}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 73.03 * emptyRows,
									}}
								>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter
					// rowsPerPageOptions={[10, 20, 30, 50]}
					rowsPerPageOptions={[10]}
					showFirstButton
					showLastButton
					count={totalListCount || 0}
					rowsPerPage={rowsPerPage}
					page={currentPage}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</CardBox>
		</section>
	);
}
