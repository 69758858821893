import sysConfigStore from '../../store/common/SysConfigStore';
import LoginSendEmailPopup from './LoginSendEmailPopup';

export default function LoginSignUpButton() {
	const { isModuleEnabled } = sysConfigStore();

	if (isModuleEnabled('DISABLED_SIGN_UP_BTN')) {
		return null;
	}

	return <LoginSendEmailPopup text='Sign up' type='send_register' />;
}
