import { Chip, ChipOwnProps } from '@mui/material';
import { FormikValues } from 'formik';
import ClearIcon from '../icons/ClearIcon';
import { Tag } from '../../../model/tag';

type ChipOwnPropsOverrides = ChipOwnProps & {
	id: string | number;
	tag: Tag;
	formik?: FormikValues;
};

export default function LabelChip({ id, tag, disabled, formik, ...props }: ChipOwnPropsOverrides) {
	const { color, name, value } = tag;
	const handleDelete = () => {
		if (!formik) return;

		formik.setFieldValue(
			id,
			formik.values[id].filter((item: string) => item !== value),
		);
	};

	return (
		<Chip
			label={name}
			classes={{
				root: 'px-2 rounded-md',
				icon: 'ml-0',
				label: 'px-0',
				disabled: 'opacity-100',
			}}
			style={
				color
					? {
							backgroundColor: color,
					  }
					: undefined
			}
			size='small'
			deleteIcon={<ClearIcon className='m-0 -mt-0.5' />}
			onDelete={!disabled ? handleDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
			{...props}
		/>
	);
}
