import { Circle } from '@mui/icons-material';
import { DayCalendarSkeleton } from '@mui/x-date-pickers/DayCalendarSkeleton';
import { useQuery } from '@tanstack/react-query';
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import { getCalendarData, getCalendarScheduleDetail } from '../../../../../api/calendar';
import { calendarSubjectMap } from '../../../../../constants/calendar';
import { queryKey } from '../../../../../constants/queryKey';
import { useTheme } from '../../../../../hooks/useTheme';
import { useValidateForm } from '../../../../../hooks/useValidateForm';
import { ResScheduleData } from '../../../../../model/calendar';
import sysConfigStore from '../../../../../store/common/SysConfigStore';
import { dateFormat } from '../../../../../util/dateFormat';
import ButtonText from '../../../../ui/buttons/ButtonText';
import InputSelect from '../../../../ui/inputs/InputSelect';
import CalendarHighlightDate from './CalendarHighlightDate';
import CalendarItem from './CalendarItem';
import CalendarListSchedule from './CalendarListSchedule';
import PopupSettingSchedule from './PopupSettingSchedule';

const getCheckDict = (selectedOptions: string[]) => {
	return {
		check_con: selectedOptions.includes('check_con'),
		check_counsel: selectedOptions.includes('check_counsel'),
		check_lawsuit: selectedOptions.includes('check_lawsuit'),
		check_done_con: selectedOptions.includes('check_done_con'),
		check_my_schedule: selectedOptions.includes('check_my_schedule'),
	};
};

export default function CalendarDashboard() {
	const { isModuleEnabled, isComponentEnabled } = sysConfigStore();
	const [highlightedDays, setHighlightedDays] = useState([0]);
	const [selectedDate, setSelectedDate] = useState<Dayjs>(dateFormat());
	const [groupedSchedules, setGroupedSchedules] = useState<_.Dictionary<ResScheduleData[]>>();
	const { t } = useTranslation();
	const theme = useTheme();
	const selectOptions = [
		{ name: t('cmmn_label_contract_calendar'), value: 'check_con' },
		...(isModuleEnabled('APP_LAWSUIT')
			? [
					{
						name: isComponentEnabled('CMPNT164_CONTRACT_DETAIL_VIEW_CUSTOM_BF')
							? t('cmmn_label_litigation_deadline_calendar')
							: t('cmmn_label_litigation_calendar'),
						value: 'check_lawsuit',
					},
			  ]
			: []),
	];

	const fetchHighlightedDays = (date: Dayjs, scheduleData: ResScheduleData[]) => {
		if (scheduleData && scheduleData.length > 0) {
			const days = scheduleData
				.filter(({ start_time: startTime }) => dateFormat(startTime).month() === date.month())
				.map(({ start_time: startTime }) => Number(dateFormat(startTime).format('DD')));
			setHighlightedDays(days);
		} else {
			setHighlightedDays([]);
		}
	};

	const filterFormik = useValidateForm({
		validationSchema: {
			multi_calendar: {
				initialValue: selectOptions.map(option => option.value),
				type: 'array',
			},
		},
	});

	const scheduleQueryKey = [queryKey.scheduleList, selectedDate.format('YYYYMM'), filterFormik.values];
	const query = useQuery(
		scheduleQueryKey,
		() => getCalendarData(selectedDate, getCheckDict(filterFormik.values.multi_calendar)),
		{ staleTime: 60000 },
	);
	const { data: scheduleListData, isLoading } = query;

	const handleDateChange = (value: Dayjs | null) => {
		if (!value) return;
		setSelectedDate(value?.locale('ko'));
	};

	const todaySchedules = scheduleListData?.filter(({ start_time: startDate }) =>
		selectedDate.isSame(startDate, 'day'),
	);

	const dDaySchedules = scheduleListData?.filter(
		({ start_time: startDate, d_day_time: dDayDate }) =>
			selectedDate.isBefore(startDate) && selectedDate.isAfter(dDayDate),
	);

	const handleNavigate = (id: number) => {
		getCalendarScheduleDetail(id).then(({ app_link: link }) => {
			if (link) window.location.href = link;
		});
	};

	useEffect(() => {
		if (scheduleListData) {
			fetchHighlightedDays(selectedDate, scheduleListData);
			setGroupedSchedules(
				_(scheduleListData)
					.groupBy('cons_message')
					.mapValues(schedules => _.orderBy(schedules, ['start_time'], ['asc']))
					.value(),
			);
		}
	}, [scheduleListData]);

	return (
		<section className='flex flex-col gap-5'>
			<header className='flex justify-between gap-5'>
				<h3 className='text-2xl'>{t('cmmn_label_schedule')}</h3>
				<div className='flex gap-3'>
					<PopupSettingSchedule />
				</div>
			</header>
			<section>
				<div className='flex items-center gap-5'>
					<div className='flex-1'>
						<InputSelect
							multiple
							id='multi_calendar'
							size='small'
							type='checkbox'
							options={selectOptions}
							formik={filterFormik}
							allowEmptyCheck={false}
						/>
					</div>
				</div>
				<div className='flex flex-col gap-3'>
					<CalendarItem
						defaultValue={dateFormat()}
						value={selectedDate}
						onChange={handleDateChange}
						onMonthChange={date => setSelectedDate(date)}
						onYearChange={date => setSelectedDate(date)}
						renderLoading={() => <DayCalendarSkeleton />}
						slots={{
							day: CalendarHighlightDate,
						}}
						slotProps={{
							day: {
								highlightedDays,
							} as AnyObject,
						}}
					/>
					<section className='flex flex-col gap-3 overflow-x-hidden overflow-y-auto scrollbar max-h-screen pr-3'>
						<h3 className='font-m font-bold'>{selectedDate?.format('MM월 DD일 (ddd)')}</h3>
						<div className='rounded-md px-5 bg-zinc-100'>
							<div className='font-bold py-2'>{t('cmmn_label_today_schedule')}</div>
							<CalendarListSchedule
								selectedDate={selectedDate}
								isLoading={isLoading}
								schedules={todaySchedules}
							/>
						</div>
						<div className='font-bold py-2'>{t('cmmn_label_dday_notification')}</div>
						<CalendarListSchedule
							selectedDate={selectedDate}
							isLoading={isLoading}
							schedules={dDaySchedules}
						/>
						<div className='font-bold py-2'>{t('cmmn_label_monthly_schedule_summary')}</div>
						{groupedSchedules &&
							Object.keys(groupedSchedules).map(key => (
								<div key={key} className='flex flex-col gap-1'>
									<p className='flex items-center gap-2'>
										<Circle className='text-xs' style={{ color: theme.palette.primary.main }} />
										{calendarSubjectMap[key]}
									</p>
									{groupedSchedules[key].map(
										({ schedule_id: id, start_time: startTime, app_title: appTitle }) => (
											<div className='grid' key={id}>
												<ButtonText
													key={key}
													className='flex justify-start gap-2 px-0 w-full text-left'
													onClick={() => handleNavigate(id)}
												>
													<span className='text-sm font-bold text-neutral-500 truncate'>
														{dayjs(startTime).format('MM월 DD일 (ddd)')}
													</span>
													<span className='text-sm font-bold text-neutral-400 truncate'>
														{appTitle}
													</span>
												</ButtonText>
											</div>
										),
									)}
								</div>
							))}
						{!isLoading && groupedSchedules && !Object.keys(groupedSchedules!).length && (
							<p>{t('cmmn_label_no_scheduled_events_this_month')}</p>
						)}
					</section>
				</div>
			</section>
		</section>
	);
}
