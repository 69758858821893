import { SyntheticEvent, useCallback, useState } from 'react';
import Header from '../../components/search/header/Header';
import TabPanel from '../../components/search/TabPanel';
import TabsSearch from '../../components/search/TabsSearch';
import CardBox from '../../components/ui/containers/CardBox';
import StickyBox from '../../components/ui/containers/StickyBox';

export default function ListPage() {
	const [value, setValue] = useState(0);
	const handleChange = useCallback((_: SyntheticEvent, v: number) => setValue(v), []);

	return (
		<section className='flex flex-col gap-5 p-8 max-w-[1920px] mx-auto'>
			<Header />

			<section className='grid grid-cols-6 gap-6'>
				<section>
					<StickyBox position='top'>
						<CardBox className='col-span-1'>
							<TabsSearch value={value} onChange={handleChange} />
						</CardBox>
					</StickyBox>
				</section>

				<CardBox className='col-span-5 px-8 py-11'>
					<TabPanel value={value} />
				</CardBox>
			</section>
		</section>
	);
}
