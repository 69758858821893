import SysConfigStore from '../../store/common/SysConfigStore';
import DashboardDefaultPage from './DashboardPage';
import DashboardSKPage from './custom/sk/DashboardPage';

function Page() {
	const { isComponentEnabled } = SysConfigStore();
	// SK
	if (isComponentEnabled('CMPNT179_THEME_SK')) return <DashboardSKPage />;

	// Default
	return <DashboardDefaultPage />;
}

export default function RoutePage() {
	return <Page />;
}
