import { MouseEvent } from 'react';
import { LoadingButton } from '@mui/lab';

type Props = {
	text: string | React.ReactNode;
	loading?: boolean;
	disabled?: boolean;
	className?: string;
	type?: 'button' | 'submit' | 'reset';
	variant?: 'text' | 'outlined' | 'contained';
	size?: 'small' | 'medium' | 'large';
	color?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	onClick?: (e?: MouseEvent<HTMLButtonElement> | undefined) => void;
};

const getOverrideClasses = (className?: string) => {
	return { root: `${className}` };
};

export default function ButtonLoading({
	loading = false,
	className,
	text,
	type = 'button',
	color = 'inherit',
	size = 'medium',
	variant = 'text',
	disabled = false,
	onClick,
}: Props) {
	return (
		<LoadingButton
			loading={loading}
			classes={getOverrideClasses(className)}
			type={type}
			color={color}
			size={size}
			variant={variant}
			disabled={disabled}
			onClick={onClick}
		>
			{text}
		</LoadingButton>
	);
}
