import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { getHardDeleteList, reqHardDelete } from '../api/hardDelete';

export default function useHardDelete() {
	const keys = [queryKey.hardDelete, queryKey.list];
	const queryClient = useQueryClient();
	const getHardDelete = () => useQuery(keys, () => getHardDeleteList());
	const postHardDeleteFn = (list: readonly string[]) => reqHardDelete(list);

	const { mutate: postHardDelete } = useMutation(postHardDeleteFn, {
		// onSuccess: () => queryClient.invalidateQueries(keys),
		onSuccess: () => queryClient.refetchQueries([queryKey.hardDelete, queryKey.list]),
	});

	return { getHardDelete, postHardDelete };
}
