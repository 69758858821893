import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getPaperManageDetailList } from '../../../api/paperManage';
import { queryKey } from '../../../constants/queryKey';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { PaperManageDetailTableRow } from '../../../model/paperManage';
import { HeadCell } from '../../../model/table';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import PaperManageDetailTable from './PaperManageDetailTable';

type Props = {
	objId: number;
};

export default function PaperManageDetailList({ objId }: Props) {
	const { t } = useTranslation();
	const { data: paperManageDetailList, isLoading } = useQuery(
		[queryKey.paperManage, queryKey.detail, queryKey.list],
		() => getPaperManageDetailList(objId),
	);
	const formik = useValidateForm({
		validationSchema: {
			paper_manage_detail_table: {
				initialValue: [],
				type: 'array',
			},
		},
		onSubmit: values => {
			alert(JSON.stringify(values, null, 2));
		},
	});

	const headCells: HeadCell<PaperManageDetailTableRow>[] = [
		{
			id: 'no',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			label: t('cmmn_label_number'),
		},
		{
			id: 'app_type',
			width: 'w-[150px]',
			align: 'left',
			disablePadding: false,
			label: t('app_type'),
		},
		{
			id: 'title',
			width: 'w-4/12',
			align: 'left',
			disablePadding: false,
			label: t('label_title'),
		},
		{
			id: 'creator',
			align: 'left',
			disablePadding: false,
			label: t('MSG_ID_61'),
		},
		{
			id: 'department',
			align: 'left',
			disablePadding: false,
			label: t('cmmn_label_creator_dept_name'),
		},
		{
			id: 'req_paper_count',
			width: 'w-[150px]',
			align: 'left',
			disablePadding: false,
			label: t('req_paper_manage_count'),
		},
		{
			id: 'completed_date',
			width: 'w-[200px]',
			align: 'left',
			disablePadding: false,
			label: t('complete_paper_manage_date'),
		},
	];

	const selectedFormik = useValidateForm({
		validationSchema: {
			paper_manage_detail_table: {
				initialValue: [],
				type: 'array',
			},
		},
		onSubmit: values => {
			alert(JSON.stringify(values, null, 2));
		},
	});
	return (
		<section className='flex flex-col gap-10 p-10'>
			<article className='flex flex-col gap-5 pb-10 border-b border-b-zinc-400'>
				<h2 className='text-xl'>
					{t('all_complete_paper_manage_count')} {paperManageDetailList?.length}
				</h2>
				<div>
					{isLoading && !paperManageDetailList && <TableSkeleton colSpan={headCells.length + 1} />}
					{!isLoading && paperManageDetailList && (
						<PaperManageDetailTable
							id='paper_manage_detail_table'
							list={paperManageDetailList}
							orderBy='title'
							headCells={headCells}
							formik={formik}
							selectedFormik={selectedFormik}
						/>
					)}
				</div>
			</article>
		</section>
	);
}
