import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../../constants/common';
import { useUser } from '../../../../../context/UserContext';
import { MenuData } from '../../../../../model/header';
import SysConfigStore from '../../../../../store/common/SysConfigStore';
import SettingsIcon from '../../../../ui/icons/SettingsIcon';
import AdminSettingMenuDepth from './AdminSettingMenuDepth';

export default function AdminSettingMenu() {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const { isSealStampManageGroup, isSealStampStatisticGroup, isPaperManageGroup, isDigitalVertificateManageGroup } =
		useUser();

	const adminMenu = [];
	// 인감
	if (isSealStampManageGroup)
		adminMenu.push({
			link: `${DOMAIN_URL}/admin/admin_seal_stamp_list_view/`,
			title: labelText('seal_stamp'),
			display: isModuleEnabled('SEAL_STAMP'),
			isExternal: true,
		});
	// 인장통계
	if (isSealStampStatisticGroup)
		adminMenu.push({
			link: `${DOMAIN_URL}/admin/admin_stamp_statistic_view/`,
			title: t('cmmn_label_stamp_statistic').replace('{{인장}}', labelText('seal_stamp')),
			display: isComponentEnabled('CMPNT173_STAMP_STATISTIC_BF'),
			isExternal: true,
		});
	// 서류관리
	if (isPaperManageGroup)
		adminMenu.push({
			link: '/paperManage/list',
			title: t('paper_manage'),
			display: isComponentEnabled('CMPNT166_PAPER_MANAGE_BF'),
		});
	// // 전자인증서
	// if (isDigitalVertificateManageGroup)
	// 	adminMenu.push({
	// 		link: `${DOMAIN_URL}/digital_certificate/digital_certificate_list_view/`,
	// 		title: t('cmmn_label_digital_certificate'),
	// 		classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
	// 		display: isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF'),
	// 		isExternal: true,
	// 	});

	const menu: MenuData = {
		title: ``,
		icon: <SettingsIcon className='text-gray-400' />,
		classes: { button: 'min-w-0 p-0' },
		children: adminMenu,
	};
	const [isOpen, setIsopen] = useState(false);
	return <AdminSettingMenuDepth itemData={menu} isOpen={isOpen} setIsopen={setIsopen} />;
}
