import { Order } from "../table";

export type LegalTag = {
	id: number;
	name: string;
};

export type ExternalLaywer = {
	lawyer_id: number;
	lawyer_name: string;
	lawyer_email: string;
	lawyer_address?: string;
	lawyer_description?: string;
	lawfirm_id?: number;
	lawfirm_name?: string;
	lawfirm_code?: string;
	attachment_file_list?: string[];
	introduction_file?: string;
};

export type ExternalLaywerList = ExternalLaywer & {
	tag_name: string;
	cost_tot: number;
	eval_cnt: number;
	cost_avg: number;
	score_avg: string;
};

export type ReqCreateExternalLawyer = {
	name: string;
	law_firm_id: number;
	email: string;
	address?: string;
	description?: string;
	attachment_file_list?: FileList;
	introduction_file?: File;
};

export type ReqExternalLawyerDetail = {
	lawyer_id?: string;
	tag_id?: number;
	start_date?: string;
	end_date?: string;
	order_col?: string;
	order?: string;
};

export type evaluation = {
	id: number;
	task_name: string;
	task_id: number;
	task_type: string;
	tag_name_ids: number[];
	tag_colors: string[];
	subsidiary_name: string;
	evaluator_name: string;
	external_lawyer_name: string;
	external_lawyer_email: string;
	address: string;
	cost: number;
	avg_evaluation: number;
	evaluation_date: string;
	avg_list: number[];
}

export type ReqEvaluationList = {
	lawyer_id?: string;
	lawfirm_id?: string;
	tag_id?: number;
	start_date?: string;
	end_date?: string;
	order_col: string;
	order: Order;
}

export type ResEvaluationList = {
	extra_datas?: [];
	recordsTotal: number;
	recordsFiltered: number;
	data: evaluation[];
}

export type ReqExternalLawyerList = {
	lawfirm_id?: number;
	lawfirm_name?: string;
	lawyer_name?: string;
	tag_id?: number;
	currentPage?: number;
	rowsPerPage?: number;
	order_col?: string;
	order?: string;
};

export type ResExternalLawyerList = {
	external_lawyer_list: ExternalLaywerList[];
	cnt_external_lawyer: number;
};

export type ResExternalLawyerEvaluationList = {
	external_lawyer_list: ExternalLaywerList[];
	cnt_external_lawyer: number;
};

export type ResExternalLawyerDetail = {
	lawyer_name: string;
	address: string;
	email: string;
	description: string;
	total_cnt: number;
	total_cost: number;
	lawfirm_name: string;
	lawfirm_code: string;
	avg_evaluation: number;
	attachment_file_list?: string[];
};

export const EVALUATION_DEFAULT_ITEM = {
	speed: {
		detail: {
			'1': '검토의견, 서면의 작성, 회신, 제출 등을 적시에 완수한다.',
			'2': '추가적인 검토, 질의, 회의요청에 신속시 대처한다.',
		},
		text: '신속성',
		ordering_value: '0',
	},
	professionalism: {
		detail: {
			'1': '전문적 역량, 지식, 경험, 네트워크 등 문제해결에 충분한 전문성을 보유하고 있다',
			'2': '업무수행 결과물의 내용이 합리적이고 명확하며, 적절한 근거를 제시하고 있다',
		},
		text: '전문성',
		ordering_value: '1',
	},
	suitability: {
		detail: {
			'1': '업무수행 결과가 당 팀의 업무에 실질적인 도움이 되었다',
			'2': '회사의 현황, 배경을 충분히 이해하여 이를 업무수행에 반영한다.',
		},
		text: '적합성',
		ordering_value: '2',
	},
} as { [key: string]: any};
