import React, { createContext, useContext, useMemo, useState } from 'react';
import { User } from '../model/user';
import sysConfigStore from '../store/common/SysConfigStore';

const UserContext = createContext<
	{ loginUser: User; setLoginUser: React.Dispatch<React.SetStateAction<User>> } | undefined
>(undefined);

type Props = {
	children: React.ReactNode;
	user: User;
};

export function UserProvider({ children, user }: Props) {
	// const initialUser = JSON.parse(getCookie('user'));
	const [loginUser, setLoginUser] = useState<User>(user);

	// user와 setUser가 변경될 때만 새로운 객체가 생성되도록 하여 불필요한 리렌더링 감소
	const value = useMemo(() => ({ loginUser, setLoginUser }), [loginUser]);

	// value = useMemo로 생성한 객체
	// children = UserProvider 컴포넌트 하위 요소
	return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
}

/**
 * 현재 로그인 유저 정보와 관리자, 검토자등의 역할을 반환하는 훅
 *
 * 복합적인 역할이나 컨피그 조건에 따른 페이지, 메뉴, 버튼등의 노출 권한도 해당 훅에서 관리하도록 한다.
 * @example
 * const { isAdmin } = useUser()
 * if (isAdmin) ...
 */
export function useUser() {
	const context = useContext(UserContext);
	if (context === undefined) {
		throw new Error('useUser must be used within a UserProvider');
	}

	const { loginUser } = context;
	const { isModuleEnabled, isComponentEnabled } = sysConfigStore();

	const roleSet = new Set(loginUser.role || []);

	// 관리자
	const isAdmin = roleSet.has('ADMIN');
	// 검토자
	const isLawyer = roleSet.has('LAWYER');
	// 조회자
	const isViewer = roleSet.has('VIEWER');
	// 그룹관리자
	const isGroupAdmin = roleSet.has('GROUP_ADMIN');
	// 외부인 로그인이 아닌 유저
	const isDefault = roleSet.has('DEFAULT');

	// 관리자 or 검토자 or 조회자
	const hasAnyRole = isAdmin || isLawyer || isViewer;

	// s사 인감관리 그룹
	const isSealStampManageGroup = roleSet.has('SEAL_STAMP_MANAGE_GROUP');
	// s사 인장통계 그룹
	const isSealStampStatisticGroup = roleSet.has('SEAL_STAMP_STATISTIC_GROUP');
	// s사 서류관리 그룹
	const isPaperManageGroup = roleSet.has('PAPER_MANAGE_GROUP');
	// s사 전자인증서 관리 그룹
	const isDigitalVertificateManageGroup = roleSet.has('CMPNT163_DIGITAL_CERTIFICATE_BF');
	// s사 법무 팀장 그룹
	const isLawyerManageGroup = roleSet.has('LAWYER_MANAGE_GROUP');

	/**
	 * { name: allow condition }
	 * @example
	 * // 외부에서 사용시 컨디션 체크 방법
	 * const { permission } = useUser()
	 * if (permission.key) ...
	 */
	const permission = {
		// 자문 노출: 일부 고객사는 조회자는 자문 기능 사용하지 않길 원함
		DISPLAY_COUNSEL: isModuleEnabled('COUNSEL_MANAGE') && !(isViewer && isModuleEnabled('VIEWER_HIDE_COUNSEL')),
		// 소송 노출: 일부 고객사는 관리자만 소송 기능 사용 가능
		DISPLAY_LAWSUIT:
			isModuleEnabled('APP_LAWSUIT') && (hasAnyRole || !isModuleEnabled('DISABLED_LAWSUIT_APP_FOR_NORMAL_USER')),
		// 분쟁 노출: 일부 고객사는 관리자만 분쟁 기능 사용 가능
		DISPLAY_DISPUTE:
			isModuleEnabled('DIVIDE_LAWSUIT_OR_DISPUTE') &&
			(hasAnyRole || !isModuleEnabled('DISABLED_LAWSUIT_APP_FOR_NORMAL_USER')),
		// 통계 페이지 노출: 커스텀 권한 존재하는 고객사 존재
		DISPLAY_STATISTICS_PAGE: isAdmin || isLawyer || (isViewer && !isModuleEnabled('VIEWER_HIDE_COUNSEL')),
		// 대시보드 통계 노출: 일부 고객사는 대시보드 통계 노출되지 않길 원함
		get DISPLAY_DASHBOARD_STATISTICS() {
			return this.DISPLAY_STATISTICS_PAGE && !isModuleEnabled('DISABLED_DASHBOARD_STATISTICS');
		},
		// 전체 계열사 활성화
		ACTIVATE_SUBSIDIARY:
			(hasAnyRole && isModuleEnabled('SUBSIDIARY_MANAGE') && isModuleEnabled('ENABLED_SUBSIDIARY_MODULE_V28')) ||
			isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF'),
		// 대시보드 계열사 노출: 일부 고객사는 헤더에서 계열사를 선택하여 대시보드에 노출하지 않음
		DISPLAY_DASHBOARD_SUBSIDIARY:
			hasAnyRole &&
			isModuleEnabled('SUBSIDIARY_MANAGE') &&
			isModuleEnabled('ENABLED_SUBSIDIARY_MODULE_V28') &&
			!isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF'),
		// 대시보드 상태별 건수 노출
		DISPLAY_DASHBOARD_STATUS_COUNT: isComponentEnabled('CMPNT176_REACT_DASHBOARD_PROGRESS_COUNT'),
	};

	return {
		...context,
		isAdmin,
		isLawyer,
		isViewer,
		isGroupAdmin,
		isDefault,
		hasAnyRole,
		permission,
		isSealStampManageGroup,
		isSealStampStatisticGroup,
		isPaperManageGroup,
		isDigitalVertificateManageGroup,
		isLawyerManageGroup,
	};
}
