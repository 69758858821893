import { Pagination } from '@mui/material';
import { useCallback, useMemo, useState } from 'react';
import ButtonText from '../ui/buttons/ButtonText';
import Highlight from '../ui/highlights/Highlight';
import PopupCommonExternal from '../ui/popups/PopupCommonExternal';

export default function PopupPreviewFile() {
	const [open, setOpen] = useState(false);
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);
	const render = useMemo(() => {
		return (
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold text-left'>미리보기</h2>
				</div>
				<ul className='grid gap-5'>
					<li className='flex items-center gap-6'>
						<div className='flex gap-2 text-left'>
							<span>1.</span>
							<Highlight
								text='있어 정비사업조합을 "갑", 공급받는 자를 "을", A거래처"이라 칭하여 다음과 같이'
								pattern='거래처'
							/>
						</div>
						<ButtonText text='본문 미리보기' size='small' variant='outlined' />
					</li>

					<li className='flex items-center gap-6'>
						<div className='flex gap-2 text-left'>
							<span>2.</span>
							<Highlight
								text='있어 정비사업조합을 "갑", 공급받는 자를 "을", A거래처"이라 칭하여 다음과 같이 정비사업조합을'
								pattern='정비사업조합'
							/>
						</div>
						<ButtonText text='본문 미리보기' size='small' variant='outlined' />
					</li>
				</ul>
				<div className='grid justify-center'>
					<Pagination count={5} onChange={() => {}} />
				</div>
			</section>
		);
	}, []);
	return (
		<>
			<ButtonText text='미리보기' size='small' variant='outlined' onClick={handleOpen} />
			<PopupCommonExternal open={open} msg={render} onClose={handleClose} onCancel={handleClose} />
		</>
	);
}
