import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../../../constants/queryKey';
import { getCompanyInfoSummary } from '../../../api/login';
import { DOMAIN_URL } from '../../../constants/common';

type Props = {
	classes?: { root?: string; image?: string };
};

export default function LogoIcon({ classes = { root: undefined, image: undefined } }: Props) {
	const { root = 'relative w-[90px] h-[36px]', image = 'absolute left-0 top-0 object-contain h-[36px]' } = classes;
	const { data: company, isLoading } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, {
		staleTime: 60000,
	});

	return (
		<div className={root}>
			{!isLoading && <img className={image} src={`${DOMAIN_URL}/files/${company?.logoLink}`} alt='logo' />}
		</div>
	);
}
