import { sendPost } from './common';
import { BizNotification, NotificationCount } from '../model/api/bizNotification';

export async function getNotificationCount() {
	const res = await sendPost<NotificationCount>('/notification/count/');
	return res.data;
}

export async function getNotificationList() {
	const res = await sendPost<BizNotification[]>('/api/get_renewal_notification_list/');
	return res.data;
}
