/* eslint-disable @typescript-eslint/no-unused-vars */
import { Dayjs } from 'dayjs';
import {
	ReqCalCheckboxDict,
	ReqCreateScheduleData,
	ReqResCalendarConfigData,
	ReqScheduleData,
	ReqUpdateScheduleDetail,
	ResCreateScheduleData,
	ResIsSyncSnsLogin,
	ResScheduleData,
	ResScheduleDetailData,
} from '../model/calendar';

import { sendGet, sendPost } from './common';

export async function getCalendarConfigData() {
	// 캘린더 설정 조회
	const res = await sendPost<ReqResCalendarConfigData>('/buptle_calendar/get_calendar_schedule_config_data/');
	return res.data;
}

export async function getCalendarData(selectedDate: Dayjs | null, CalCheckboxDict: ReqCalCheckboxDict) {
	// 대시보드 캘린더 조회
	const formattedDate = selectedDate ? selectedDate.format('YYYY-MM-DD') : null;
	const params: ReqScheduleData = {
		cal_checkbox_dict: CalCheckboxDict,
		date_str: formattedDate,
	};
	const res = await sendPost<ResScheduleData[]>('/buptle_calendar/get_calendar_schedule_list/', {
		params: JSON.stringify(params),
	});
	return res.data;
}

export async function getCalendarScheduleDetail(scheduleId: number) {
	// 켈린더 일정 상세 조회
	const res = await sendPost<ResScheduleDetailData>('/buptle_calendar/get_calendar_schedule_detail/', {
		schedule_id: scheduleId,
	});
	return res.data;
}

export async function createCalendarScheduleManual(params: ReqCreateScheduleData) {
	// 수동 일정 생성
	const res = await sendPost<ResCreateScheduleData>('/buptle_calendar/create_calendar_schedule_manual/', params);
	return res.data;
}

export async function updateCalendarScheduleDetail(params: ReqUpdateScheduleDetail) {
	// 일정 상세 수정
	const res = await sendPost('/buptle_calendar/update_calendar_schedule_event/', params);
	return res.msg;
}

export async function deleteCalendarSchedule(scheduleId: number) {
	// 일정 삭제
	const res = await sendPost('/buptle_calendar/delete_calendar_schedule_event/', {
		schedule_id: scheduleId,
	});
	return res.msg;
}

export async function updateCalendarConfig(params: ReqResCalendarConfigData) {
	// 캘린더 설정 수정
	const res = await sendPost<ReqResCalendarConfigData>('/buptle_calendar/update_calendar_config/', {
		params: JSON.stringify(params),
	});
	return res.data;
}

export async function getIsSyncSnsCalendarLogin() {
	// SNS 연동 로그인 유무
	const res = await sendPost<ResIsSyncSnsLogin>('/buptle_calendar/is_sync_calendar_sns_login/', {});
	return res.data;
}

export async function reqSnsLogin(syncApp: string) {
	const res = await sendGet<string>('/buptle_calendar/req_sns_login/', { params: { sync_app: syncApp } });
	// // 현재 브라우저 창의 크기를 가져옵니다.
	// const width = window.innerWidth * 0.5; // 너비의 50%
	// const height = window.innerHeight * 0.5; // 높이의 50%
	//
	// // 팝업 창의 위치를 계산합니다 (화면 중앙에 위치시킵니다).
	// const left = window.innerWidth / 2 - width / 2;
	// const top = window.innerHeight / 2 - height / 2;
	//
	// // 팝업 창을 엽니다.
	// const popup = window.open(res.data, '_blank', `width=${width},height=${height},left=${left},top=${top}`); // 팝업 창에서 로그인 완료 신호를 기다립니다.
	// window.addEventListener(
	// 	'message',
	// 	event => {
	// 		alert('이벤트 리스터 프론트 코드');
	// 		if (event.data === 'loginSuccess') {
	// 			// 로그인 성공 신호를 받으면 팝업 창을 닫고 페이지를 리로드합니다.
	// 			if (popup) popup.close();
	// 			window.location.reload();
	// 		}
	// 	},
	// 	false,
	// );
	window.location.href = res.data;
}

export async function reqSnsSyncLogout(syncApp: string) {
	const res = await sendPost<string>('/buptle_calendar/req_sns_logout/', { sync_app: syncApp });
	return res.msg;
}
