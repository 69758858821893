import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { getNoticeList } from '../api/dashboard';

export function useNotice() {
	const queryClient = useQueryClient();
	const key = queryKey.notice;

	const getData = useQuery(key, getNoticeList);
	const refetch = () => queryClient.invalidateQueries(key);

	return { refetch, getData };
}
