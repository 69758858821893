import 'dayjs/locale/ko';
import dayjs, { ConfigType, Dayjs } from 'dayjs';
import _ from 'lodash';
import { DefaultOption } from '../components/ui/inputs/InputSelect';
/**
 * DDay 에 +, -, '' 표시
 */
const getMark = (v: number) => {
	if (v > 0) return '+';
	if (v === 0) return '-';
	return '';
};

/**
 * 날짜 포맷 방식 국가별 표기
 */
export const setDayLocale = (lang: 'ko' = 'ko') => {
	switch (lang) {
		case 'ko':
			dayjs.locale(lang);
			break;
		default:
	}
};

setDayLocale('ko');

/**
 * 공통 date format
 */
export const dateFormat = (date?: ConfigType): Dayjs => dayjs(date);

/**
 * 두 날짜의 일차 비교
 */
export const getDiffDate = (diff1: Dayjs, diff2: Dayjs) => Math.ceil(diff1.diff(diff2, 'd', true));

/**
 *
 * @param diff1
 * @param diff2
 * @returns D+1 D-1
 */
export const getDiffDateFormat = (diff1: Dayjs, diff2: Dayjs): string => {
	const diff = getDiffDate(diff1.startOf('day'), diff2.startOf('day'));
	return `D ${getMark(diff)} ${diff}`;
};

//
/**
 * DDay DefaultOption[] 생성
 * @param total 최대 DDay 값
 * @param type D + Day, D - Day 선택
 * @returns DefaultOption[]
 */
export const getDdayList = (total: number, type: 'plus' | 'minus'): DefaultOption[] => {
	const start = 1;
	const end = total + 1;

	return _.chain([])
		.range(end, start)
		.map(count => ({
			name: `${type === 'plus' ? 'D +' : 'D -'} ${count}`,
			value: type === 'plus' ? count : -count,
		}))
		.value();
};

export const getTodayFormatToHour = (date: string, type?: 'DATE' | 'DAY') => {
	const formatDate = dateFormat(date);
	const diffMinute = -formatDate.diff(dateFormat(), 'minute');
	if (diffMinute < 60) {
		return `${diffMinute}분 전`;
	}
	if (diffMinute < 60 * 24) {
		return `${Math.floor(diffMinute / 60)}시간 전`;
	}
	if (type === 'DAY') {
		return `${Math.floor(diffMinute / 60 / 24)}일 전`;
	}
	return formatDate.format('YY.MM.DD (ddd)');
};

export const getDetailedDifference = (date: Dayjs) => {
	let now = dayjs();

	// 일 차이 계산
	const day = now.diff(date, 'day');
	now = now.subtract(day, 'day');

	// 시간 차이 계산
	const hour = now.diff(date, 'hour');
	now = now.subtract(hour, 'hour');

	// 분 차이 계산
	const minute = now.diff(date, 'minute');

	return { day, hour, minute };
};

export const getStartDateOfWeek = (startDayOfWeek = 0) => {
	// startDayOfWeek: 0 = 일요일, 1 = 월요일, ...

	const today = dayjs();
	const currentDayOfWeek = today.day();
	let startDate = today;

	if (currentDayOfWeek >= startDayOfWeek) {
		startDate = today.subtract(currentDayOfWeek - startDayOfWeek, 'day');
	} else {
		startDate = today.subtract(currentDayOfWeek + 7 - startDayOfWeek, 'day');
	}

	return startDate;
};
