import { Plugin, PluginFunctions, createStore } from '@react-pdf-viewer/core';
import { useMemo } from 'react';

interface StoreProps {
	jumpToPage?(pageIndex: number): void;
}

export interface JumpToPagePlugin extends Plugin {
	jumpToPage(pageIndex: number): void;
}

/**
 * react-pdf-viewer custom plugin
 * PDF 특정 페이지로 이동
 */
export const jumpToPagePlugin = (): JumpToPagePlugin => {
	const store = useMemo(() => createStore<StoreProps>(), []);

	return {
		install: (pluginFunctions: PluginFunctions) => {
			store.update('jumpToPage', pluginFunctions.jumpToPage);
		},
		jumpToPage: (pageIndex: number) => {
			const fn = store.get('jumpToPage');
			if (fn) fn(pageIndex);
		},
	};
};
