import { isUndefined } from 'lodash';
import React from 'react';
import ButtonText from '../../ui/buttons/ButtonText';

type Props = {
	count?: number;
	label: string;
	onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
};
export default function StatusItemDashboard({ count, label, onClick }: Props) {
	return (
		<li className='max-w-full'>
			<ButtonText onClick={onClick} classes={{ root: 'p-0' }}>
				<div className='flex flex-col items-center'>
					<p
						className={`text-4xl text-right font-bold truncate ${isUndefined(count) && 'opacity-0'}`}
						title={`${count}`}
					>
						{count || 0}
					</p>
					<p className='text-sm text-right text-neutral-500'>{label}</p>
				</div>
			</ButtonText>
		</li>
	);
}
