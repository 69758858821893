import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function BoardIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.9994 2.92224C10.9715 2.92224 10.1075 3.6241 9.86059 4.57473H5.52852C4.5344 4.57473 3.72852 5.38062 3.72852 6.37473V19.2778C3.72852 20.2719 4.5344 21.0778 5.52852 21.0778H18.4704C19.4645 21.0778 20.2704 20.2719 20.2704 19.2778V6.37473C20.2704 5.38062 19.4645 4.57473 18.4704 4.57473H14.1382C13.8913 3.6241 13.0273 2.92224 11.9994 2.92224ZM11.9994 4.34153C11.7803 4.34153 11.582 4.4307 11.4388 4.57473H11.4432C11.2987 4.71795 11.2092 4.91659 11.2092 5.13613C11.2092 5.57263 11.563 5.92649 11.9995 5.92649C12.436 5.92649 12.7899 5.57263 12.7899 5.13613C12.7899 4.91659 12.7004 4.71795 12.5559 4.57473H12.56C12.4168 4.4307 12.2185 4.34153 11.9994 4.34153ZM6.41943 9.84536C6.41943 9.41457 6.76865 9.06536 7.19943 9.06536H16.7994C17.2302 9.06536 17.5794 9.41457 17.5794 9.84536C17.5794 10.2761 17.2302 10.6254 16.7994 10.6254H7.19943C6.76865 10.6254 6.41943 10.2761 6.41943 9.84536ZM6.41943 13.5867C6.41943 13.1559 6.76865 12.8067 7.19943 12.8067H16.7994C17.2302 12.8067 17.5794 13.1559 17.5794 13.5867C17.5794 14.0175 17.2302 14.3667 16.7994 14.3667H7.19943C6.76865 14.3667 6.41943 14.0175 6.41943 13.5867ZM6.41943 16.9112C6.41943 16.4804 6.76865 16.1312 7.19943 16.1312H13.1994C13.6302 16.1312 13.9794 16.4804 13.9794 16.9112C13.9794 17.342 13.6302 17.6912 13.1994 17.6912H7.19943C6.76865 17.6912 6.41943 17.342 6.41943 16.9112Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
