import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { getCompanyInfoSummary } from '../../api/login';
import { DOMAIN_URL, STATIC_DIR } from '../../constants/common';
import { queryKey } from '../../constants/queryKey';
import { Company } from '../../model/api/company';
import sysConfigStore from '../../store/common/SysConfigStore';

function changeFavicon(faviconLink?: Company['faviconLink']) {
	const link = document.createElement('link');
	const oldLink = document.getElementById('favicon');
	link.id = 'favicon';
	link.rel = 'shortcut icon';
	link.href = faviconLink ? `${DOMAIN_URL}/files/${faviconLink}` : `${STATIC_DIR}/favicon.ico`;
	if (oldLink) {
		document.head.removeChild(oldLink);
	}
	document.head.appendChild(link);
}

function changeTitle(text: string) {
	const title = document.createElement('title');
	title.innerHTML = text;
	document.head.appendChild(title);
}

// 앱이 먼저 렌더링이 되어도 상관없는 데이터만 세팅한다.
export default function GlobalDataSetter() {
	const { data: company } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, { staleTime: 60000 });
	const { sysCompanyConfigs } = sysConfigStore();

	useEffect(() => {
		const titleText = sysCompanyConfigs.TITLE_SYS_NAME || company?.name;
		if (titleText) {
			changeTitle(titleText);
		}
	}, [sysCompanyConfigs, company]);

	useEffect(() => {
		if (company) {
			const { faviconLink } = company;
			changeFavicon(faviconLink);
		}
	}, [company]);

	return null;
}
