import React from 'react';
import ButtonText from '../../../../ui/buttons/ButtonText';

type Props = {
	count?: number;
	label: string;
	onClick: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent> | undefined) => void;
};
export default function StatusItemDashboard({ count = 0, label, onClick }: Props) {
	return (
		<li className='max-w-full'>
			<ButtonText onClick={count ? onClick : () => {}} classes={{ root: 'p-0' }}>
				<div className='flex flex-col items-end'>
					<p className='text-sm text-right text-neutral-500'>{label}</p>
					<p
						className={`text-4xl text-right font-bold truncate ${!count && 'opacity-30'}`}
						title={`${count}`}
					>
						{count}
					</p>
				</div>
			</ButtonText>
		</li>
	);
}
