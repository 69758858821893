import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getCalendarConfigData, updateCalendarConfig } from '../../../../../api/calendar';
import { useAlert } from '../../../../../hooks/useAlert';
import { useValidateForm } from '../../../../../hooks/useValidateForm';
import { ReqResCalendarConfigData } from '../../../../../model/calendar';
import sysConfigStore from '../../../../../store/common/SysConfigStore';
import { getDdayList } from '../../../../../util/dateFormat';
import ButtonText from '../../../../ui/buttons/ButtonText';
import ScrollBox from '../../../../ui/containers/ScrollBox';
import SettingsIcon from '../../../../ui/icons/SettingsIcon';
import InputSelect from '../../../../ui/inputs/InputSelect';
import InputSwitch from '../../../../ui/inputs/InputSwitch';
import PopupDefault from '../../../../ui/popups/PopupDefault';

export default function PopupSettingSchedule() {
	const { isModuleEnabled, isComponentEnabled } = sysConfigStore();
	const [snackbar] = useAlert();
	const [open, setOpen] = useState(false);
	const isAutoRenewalEnabled = isComponentEnabled('CMPNT158_AUTO_RENEWAL_CONTRACT_BF');
	const { t } = useTranslation();

	const contractOptions = {
		id: 'cal_contract_alarm',
		label: t('cmmn_label_contract_calendar'),
		alarms: [
			{
				id: 'done_con_end_day',
				label: t('cmmn_label_contract_end_date'),
			},
			...(isAutoRenewalEnabled
				? [{ id: 'con_auto_renewal', label: t('cmmn_label_contract_renewal_decision_date') }]
				: []),
		],
	};

	const lawsuitOptions = {
		id: 'cal_lawsuit_alarm',
		label: t('cmmn_label_litigation_deadline_calendar'),
		alarms: [
			{
				id: 'lawsuit_argument',
				label: t('cmmn_label_negotiation_date'),
			},
			{
				id: 'lawsuit_sentence',
				label: t('cmmn_label_judgment_date'),
			},
			{
				id: 'lawsuit_mediation',
				label: t('cmmn_label_adjustment_date'),
			},
		],
	};

	const dDayOptions = [contractOptions, ...(isModuleEnabled('APP_LAWSUIT') ? [lawsuitOptions] : [])];

	const handleTogglePopup = () => setOpen(prev => !prev);

	const formik = useValidateForm({
		validationSchema: {
			cal_contract_alarm: { initialValue: true, type: 'boolean' },
			cal_counsel_alarm: { initialValue: true, type: 'boolean' },
			cal_lawsuit_alarm: { initialValue: false, type: 'boolean' },
			cal_end_contract_alarm: { initialValue: false, type: 'boolean' },
			cal_my_schedule_alarm: { initialValue: false, type: 'boolean' },
			con_review: { initialValue: 1, type: 'number' },
			counsel_review: { initialValue: 1, type: 'number' },
			done_con_end_day: { initialValue: -1, type: 'number' },
			done_con_payment: { initialValue: -1, type: 'number' },
			lawsuit_argument: { initialValue: -1, type: 'number' },
			lawsuit_sentence: { initialValue: -1, type: 'number' },
			lawsuit_mediation: { initialValue: -1, type: 'number' },
			...(isAutoRenewalEnabled ? { con_auto_renewal: { initialValue: -1, type: 'number' } } : {}),
		},

		onSubmit: values => {
			const calendarConfigData: ReqResCalendarConfigData = {
				alarm_email_config: {
					cal_contract_alarm: values.cal_contract_alarm,
					cal_counsel_alarm: values.cal_counsel_alarm,
					cal_lawsuit_alarm: values.cal_lawsuit_alarm,
					cal_end_contract_alarm: values.cal_end_contract_alarm,
					cal_my_schedule_alarm: values.cal_my_schedule_alarm,
				},
				d_day_config: {
					con_review: values.con_review,
					counsel_review: values.counsel_review,
					done_con_end_day: values.done_con_end_day,
					done_con_payment: values.done_con_payment,
					lawsuit_argument: values.lawsuit_argument,
					lawsuit_sentence: values.lawsuit_sentence,
					lawsuit_mediation: values.lawsuit_mediation,
				},
			};

			if (isAutoRenewalEnabled) {
				calendarConfigData.d_day_config.con_auto_renewal = values.con_auto_renewal;
			}

			updateCalendarConfig(calendarConfigData).then(res => {
				if (res) {
					const { alarm_email_config: alarmEmailConfig, d_day_config: dDayConfig } = res;
					formik.setValues({ ...alarmEmailConfig, ...dDayConfig });
				}
			});

			handleTogglePopup();
		},
	});

	const handleOpenPopup = () => {
		getCalendarConfigData().then(({ alarm_email_config: alarmEmailConfig, d_day_config: dDayConfig }) => {
			formik.setValues({ ...alarmEmailConfig, ...dDayConfig });
		});

		handleTogglePopup();
	};

	return (
		<>
			<ButtonText className='min-w-0' size='small' onClick={handleOpenPopup}>
				<SettingsIcon className='text-gray-400' />
			</ButtonText>
			<PopupDefault open={open} size='medium' onClose={handleTogglePopup}>
				<section className='flex flex-col gap-5'>
					<div>
						<h2 className='text-2xl font-bold'>{t('cmmn_label_legal_calendar_settings')}</h2>
					</div>
					<ScrollBox className='max-h-[70vh] xl:max-h-[77vh]'>
						<section>
							<ul className='flex flex-col gap-10'>
								<li className='flex flex-col pb-10 border-b border-neutral-300 lg:flex-row lg:px-8'>
									<div className='basis-1/4'>
										<h3 className='font-bold text-neutral-600'>
											{t('cmmn_label_receive_email_alerts')}
										</h3>
									</div>
									<div className='basis-3/4 flex flex-col gap-3'>
										<p className='text-sm text-neutral-500'>
											{t('cmmn_label_dday_notification_first_occurrence')}
										</p>
										<div className='grid grid-cols-2'>
											{dDayOptions.map(option => (
												<div key={option.id}>
													<InputSwitch
														id={option.id}
														label={option.label}
														labelPlacement='start'
														formik={formik}
													/>
												</div>
											))}
										</div>
									</div>
								</li>

								<li className='flex flex-col pb-10 border-b border-neutral-300 lg:flex-row lg:px-8'>
									<div className='basis-1/4'>
										<h3 className='font-bold text-neutral-600'>
											{t('cmmn_label_dday_notification_settings')}
										</h3>
									</div>
									<div className='basis-3/4 flex flex-col gap-3'>
										<p className='text-sm text-neutral-500'>
											{t('cmmn_label_notifications_and_receive_calendar_schedule_notifications')}
										</p>
										{dDayOptions.map(option =>
											option.alarms.map((alarm, index) => (
												<div key={alarm.id} className='grid grid-cols-3 items-center'>
													<div>
														{!index && <p className='text-black/60'>{option.label}</p>}
													</div>
													<div>
														<p className='text-sm text-black/60'>{alarm.label}</p>
													</div>
													<div>
														<InputSelect
															id={alarm.id}
															label={t('cmmn_label_dday_notification')}
															options={
																isAutoRenewalEnabled
																	? getDdayList(60, 'minus')
																	: getDdayList(30, 'minus')
															}
															formik={formik}
															required
														/>
													</div>
												</div>
											)),
										)}
									</div>
								</li>
							</ul>
						</section>
					</ScrollBox>
					<div className='flex justify-center gap-5'>
						<ButtonText text={t('label_cancellation')} variant='contained' onClick={handleTogglePopup} />
						<ButtonText
							text={t('label_confirm')}
							variant='contained'
							color='primary'
							onClick={() => {
								snackbar(t('cmmn_label_calendar_settings_updated'), 'success');
								formik.handleSubmit();
							}}
						/>
					</div>
				</section>
			</PopupDefault>
		</>
	);
}
