import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DateCalendarProps } from '@mui/x-date-pickers/DateCalendar/DateCalendar.types';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Dayjs } from 'dayjs';

type DateCalendarPropsOverrides = DateCalendarProps<Dayjs>;
export default function CalendarItem({ ...props }: DateCalendarPropsOverrides) {
	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<DateCalendar {...props} />
		</LocalizationProvider>
	);
}
