type Props = {
	className?: string;
	children?: React.ReactNode;
	index: string | number;
	value: string | number;
};

export default function TabPanel({ className, children, value, index, ...other }: Props) {
	return (
		<article
			className={className ?? ''}
			role='tabpanel'
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</article>
	);
}
