import { SyntheticEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import ChartIcon from '../../components/ui/icons/ChartIcon';
import DashboardIcon from '../../components/ui/icons/DashboardIcon';
import TabSnb from '../../components/ui/tabs/TabSnb';
import SysConfigStore from '../../store/common/SysConfigStore';

export default function RoutePage() {
	const { isModuleEnabled, labelText  } = SysConfigStore();
	const { t } = useTranslation();
	const [value, setValue] = useState(0);
	const { pathname } = useLocation();

	const tabs = [
		{
			id: 0,
			icon: <DashboardIcon />,
			label: '대시보드',
			isVisible: true,
		},
		{
			id: 1,
			icon: <ChartIcon />,
			label: '차트',
			disabled: true,
			isVisible: true,
		},
		{
			id: 2,
			query: 'NEW-REVIEW',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 검토 건수' : '계약 신규 검토 건수',
			isVisible: true,
		},
		{
			id: 3,
			query: 'NEW-REVIEW-STATUS-RATE',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 상태별 건수' : '계약 상태별 건수',
			isVisible: true,
		},
		{
			id: 4,
			query: 'NEW-REVIEWED-COUNT',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 검토 처리 건수 추이' : '계약 검토 처리 건수 추이',
			isVisible: true,
		},
		{
			id: 5,
			query: 'NEW-REVIEW-PERIOD-COUNT',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 발생 건수 추이' : '계약 신규 발생 건수 추이',
			isVisible: true,
		},
		{
			id: 6,
			query: 'NEW-REVIEW-MANAGERS',
			depth: 2,
			label: isModuleEnabled('COUNSEL_MANAGE') ? '신규 계약/자문 검토자 배정 현황' : '신규 계약 검토자 배정 현황',
			isVisible: true,
		},
		{
			id: 7,
			query: 'NEW-REVIEW-CONTRACT-COUNT-BY-CATEGORY',
			depth: 2,
			label: '계약 카테고리별 신규 현황',
			isVisible: true,
		},
	];

	if (isModuleEnabled('COUNSEL_MANAGE')) {
		tabs.push({
			id: tabs.length,
			query: 'NEW-REVIEW-COUNSEL-COUNT-BY-CATEGORY',
			depth: 2,
			label: '자문 카테고리별 신규 현황',
			isVisible: isModuleEnabled('COUNSEL_MANAGE'),
		});
	}

	if (isModuleEnabled('APP_LAWSUIT')) {
		tabs.push({
			id: tabs.length,
			query: 'NEW-LAWSUIT-MANAGE-COUNT',
			depth: 2,
			label: '신규소송 담당자 배정 현황',
			isVisible: isModuleEnabled('APP_LAWSUIT'),
		});
		tabs.push({
			id: tabs.length,
			query: 'NEW-LAWSUIT-MANAGE-WITH-IN-AND-OUT',
			depth: 2,
			label: `${labelText('cmmn_label_lawsuit_external_delegation')}`,
			isVisible: isModuleEnabled('APP_LAWSUIT'),
		});
	}

	const navigate = useNavigate();
	const handleChange = (_: SyntheticEvent, v: number) => {
		// console.log(tabs);
		setValue(v);
		if (v === 0) {
			navigate('/statistics');
		} else {
			const { query } = tabs.filter(tab => tab.id === v)[0];
			navigate(`/statistics/detail/${query}`, { state: { query } });
		}
	};

	useEffect(() => {
		const filterTab = tabs.filter(({ query }) => query && pathname.split('/').at(-1) === query);
		if (filterTab.length > 0) {
			const { query, id } = tabs.filter(tab => tab.id === filterTab[0].id)[0];
			setValue(id);
			navigate(`/statistics/detail/${query}`, { state: { query } });
		} else {
			setValue(0);
			navigate('/statistics/');
		}
	}, [value]);

	return (
		<section className='flex flex-col'>
			<div className='grid grid-cols-1 lg:grid-cols-12'>
				<div className='col-span-2 bg-slate-100'>
					<header className='py-14'>
						<h3 className='text-3xl text-center font-bold'>통계</h3>
					</header>

					<TabSnb value={value} onChange={handleChange} tabs={tabs} />
				</div>
				<div className='col-span-10'>
					<Outlet />
				</div>
			</div>
		</section>
	);
}
