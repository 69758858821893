import tailwindColors from 'tailwindcss/colors';
import { DefaultColors } from 'tailwindcss/types/generated/colors';

type Colors = Omit<DefaultColors, 'lightBlue' | 'warmGray' | 'trueGray' | 'coolGray' | 'blueGray'> & {
	orange: {
		'550': '#F47725';
	};
	amber: {
		'350': '#F5C172';
	};
	emerald: {
		'350': '#75C7AB';
	};
	rose: {
		'350': '#F59A9A';
	};
};

/**
 * tailwind color + 추가된 custom color
 * tailwind 는 custom 된 color 를 js 에서 전달 받을수 없다 ㅠㅠ
 */
export const colors: Colors = {
	inherit: tailwindColors.inherit,
	current: tailwindColors.current,
	transparent: tailwindColors.transparent,
	black: tailwindColors.black,
	white: tailwindColors.white,
	slate: {
		...tailwindColors.slate,
	},
	gray: {
		...tailwindColors.gray,
	},
	zinc: {
		...tailwindColors.zinc,
	},
	neutral: {
		...tailwindColors.neutral,
	},
	stone: {
		...tailwindColors.stone,
	},
	red: {
		...tailwindColors.red,
	},
	orange: {
		...tailwindColors.orange,
		'550': '#F47725',
	},
	amber: {
		...tailwindColors.amber,
		'350': '#F5C172',
	},
	yellow: {
		...tailwindColors.yellow,
	},
	lime: {
		...tailwindColors.lime,
	},
	green: {
		...tailwindColors.green,
	},
	emerald: {
		...tailwindColors.emerald,
		'350': '#75C7AB',
	},
	teal: {
		...tailwindColors.teal,
	},
	cyan: {
		...tailwindColors.cyan,
	},
	sky: {
		...tailwindColors.sky,
	},
	blue: {
		...tailwindColors.blue,
	},
	indigo: {
		...tailwindColors.indigo,
	},
	violet: {
		...tailwindColors.violet,
	},
	purple: {
		...tailwindColors.purple,
	},
	fuchsia: {
		...tailwindColors.fuchsia,
	},
	pink: {
		...tailwindColors.pink,
	},
	rose: {
		...tailwindColors.rose,
		'350': '#F59A9A',
	},
} as const;
