import React, { useState } from 'react';
import ButtonLoading from '../buttons/ButtonLoading';
import { ModalSize } from './PopupDefault';
import PopupInternalState from './PopupInternalState';

type ColorProps = {
	confirm?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	cancel?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

export type PopupCommonInternalProps = {
	openTrigger: React.ReactElement;
	msg: string;
	icon?: React.ReactNode;
	size?: ModalSize;
	autoClose?: number;
	externalClickClose?: boolean;
	confirmClose?: boolean;
	onConfirm?: boolean | (() => void);
	onCancel?: boolean | (() => void);
	color?: ColorProps;
};

const defaultColor: ColorProps = {
	confirm: 'primary',
	cancel: 'inherit',
};

export default function PopupCommonInternal({
	openTrigger,
	msg,
	icon,
	size,
	autoClose,
	externalClickClose = true,
	confirmClose = false,
	onConfirm = false,
	onCancel = false,
	color: providedColor,
}: PopupCommonInternalProps) {
	const [closeSwitch, setCloseSwitch] = useState(false);
	const [loading, setLoading] = useState(false);

	const handleButtonClick = async (action: typeof onConfirm, isCloseTrigger: boolean) => {
		if (typeof action === 'function') {
			setLoading(true);
			await action();
			setLoading(false);
		}

		if (isCloseTrigger) {
			setCloseSwitch(!closeSwitch);
		}
	};

	const color = {
		...defaultColor,
		...providedColor,
	};

	const getButton = (
		text: typeof msg,
		customColor: ColorProps['confirm' | 'cancel'],
		action: typeof onConfirm,
		buttonClose: boolean,
	) => (
		<ButtonLoading
			loading={loading}
			text={text}
			variant='contained'
			color={customColor}
			onClick={() => handleButtonClick(action, buttonClose)}
		/>
	);

	return (
		<PopupInternalState
			openTrigger={openTrigger}
			closeTrigger={closeSwitch}
			size={size}
			externalClickClose={externalClickClose}
			autoClose={autoClose}
		>
			<div className='flex flex-col gap-5'>
				{icon && <div className='text-center'>{icon}</div>}
				<div className='text-center'>{msg}</div>
				<div className='flex justify-center space-x-6'>
					{onConfirm && getButton('확인', color.confirm, onConfirm, confirmClose)}
					{onCancel && getButton('취소', color.cancel, onCancel, true)}
				</div>
			</div>
		</PopupInternalState>
	);
}
