import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { getPaperManageDetail, updatePaperManageDetail } from '../api/paperManage';
import { PaperManage } from '../model/paperManage';
import { usePaperManageList } from '../context/PaperManageContext';

export default function usePaperManage() {
	const keys = [queryKey.paperManage, queryKey.detail];
	const queryClient = useQueryClient();
	const getPaperManageDetailHook = (objId: number) => useQuery(keys, () => getPaperManageDetail(objId));
	const postPaperManageDetailFn = (obj: PaperManage) => updatePaperManageDetail(obj);

	const { mutate: postPaperManageDetail } = useMutation(postPaperManageDetailFn, {
		onSuccess: () => queryClient.invalidateQueries(keys),
	});

	const { searchParams } = usePaperManageList();
	const listKeys = [queryKey.paperManage, queryKey.list, searchParams];
	const resetList = () => queryClient.invalidateQueries(listKeys);

	return { getPaperManageDetailHook, postPaperManageDetail, resetList, listKeys };
}
