import { useTheme } from '@mui/material';
import { debounce } from 'lodash';
import { useEffect, useState } from 'react';
import { noticeDetailUrl } from '../../../../constants/adeleUrl';
import { useNotice } from '../../../../hooks/useNotice';
import { navigateToAdele } from '../../../../util/commonUtils';
import CampaignIcon from '../../../ui/icons/CampaignIcon';
import Slide from '../../../ui/slides/Slide';
import SysConfigStore from '../../../../store/common/SysConfigStore';

export default function MenuNoticeList() {
	const theme = useTheme();
	const {
		getData: { data },
	} = useNotice();

	const { isComponentEnabled } = SysConfigStore();
	const [show, setShow] = useState(false);
	const handleResize = debounce(() => {
		handleResize.cancel();
		const mediaWidth = window.innerWidth;
		setShow(mediaWidth >= 1536);
	}, 1000);

	useEffect(() => {
		handleResize();
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, [data]);

	const detailUri = isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF')
		? '/manage/notice-board-detail-view/'
		: noticeDetailUrl;

	const handleNavigate = (noticeId: number) => {
		sessionStorage.setItem(
			detailUri,
			JSON.stringify({
				notice_id: noticeId,
			}),
		);

		navigateToAdele(detailUri);
	};

	return (
		show &&
		data &&
		data.length > 0 && (
			<div className='flex items-center w-64 h-6 gap-2 px-4 rounded-2xl bg-black/20'>
				<CampaignIcon style={{ color: theme.palette.primary.main }} />
				<Slide direction='vertical' loop={data.length === 1 && false}>
					{data.map(({ id, notice_title: title }) => (
						<button
							key={id}
							type='button'
							onClick={() => handleNavigate(id)}
							className='w-full truncate text-m font-normal text-white text-left cursor-pointer'
						>
							{title}
						</button>
					))}
				</Slide>
			</div>
		)
	);
}
