import _ from 'lodash';
import { useMemo } from 'react';

/**
 * @property text 원본 문자열
 * @property pattern 속한 문자열을 <mark> 태그로 트리구조 변경
 */
interface IProps {
	text: string;
	pattern: string;
}

export default function Highlight({ text, pattern }: IProps) {
	if (!pattern) return text;

	const parts = useMemo(() => text.split(new RegExp(`(${_.escapeRegExp(pattern)})`, 'gi')), [text, pattern]);
	return parts.map(part =>
		part.toLowerCase() === pattern.toLowerCase() ? <mark key={_.uniqueId(part)}>{part}</mark> : part,
	);
}
