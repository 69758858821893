import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Dayjs } from 'dayjs';

export default function CalendarHighlightDate(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
	const { highlightedDays = [], day, outsideCurrentMonth, selected, ...other } = props;

	return (
		<Badge
			key={day.toString()}
			classes={{
				badge: `top-3/4 right-1/2 min-w-0 w-1.5 h-1.5 p-0 ${selected ? 'bg-white' : 'bg-red-600'}`,
			}}
			overlap='circular'
			badgeContent={!outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0 ? '' : undefined}
		>
			<PickersDay {...other} selected={selected} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}
