import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { getUserNoticeBoards } from '../api/notice';

export function useUserNoticeBoard() {
	const queryClient = useQueryClient();
	const key = queryKey.noticeBoardList;

	const getUserNoticeBoardFn = () => getUserNoticeBoards();

	const getUserNoticeBoardList = useQuery(key, () => getUserNoticeBoardFn());

	const refetchUserNoticeBoard = () => queryClient.invalidateQueries(key);
	return { refetchUserNoticeBoard, getUserNoticeBoardList };
}
