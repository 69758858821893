import Tab, { TabOwnProps } from '@mui/material/Tab';

type TabOwnPropsOverrides = TabOwnProps & { selected?: boolean };
export default function TabButtonDashboard({ ...props }: TabOwnPropsOverrides) {
	const { selected } = props;
	return (
		<Tab
			classes={{
				root: `flex-row justify-start gap-2 pl-8 ${!selected ? 'text-white' : ''} font-bold`,
				selected: `${selected ? 'text-orange-550' : 'text-white'} bg-black/20`,
				iconWrapper: `${selected ? 'text-orange-550' : 'text-orange-300'} mb-0`,
			}}
			{...props}
		/>
	);
}
