import { PaletteOptions } from '@mui/material';
import colors from 'tailwindcss/colors';

export const getPalette = (): PaletteOptions => {
	return {
		primary: {
			main: colors.blue[600],
		},
		secondary: {
			main: colors.blue[300],
		},
		tertiary: {
			main: colors.blue[600],
		},
		error: {
			main: colors.red[500],
		},
		success: {
			main: colors.green[600],
		},
		info: {
			main: colors.sky[500],
		},
		warning: {
			main: colors.orange[400],
		},
		body: {
			main: colors.slate[200],
		},
	};
};
