import { useNavigate } from 'react-router-dom';
import ButtonText from '../ui/buttons/ButtonText';
// import PopoverInternalState from '../ui/popovers/PopoverInternalState';
import PopupCommonInternal from '../ui/popups/PopupCommonInternal';
import { doaBackdo } from '../../api/login';

export default function DoaUserOption() {
	const navigate = useNavigate();

	return (
		<PopupCommonInternal
			openTrigger={<ButtonText className='px-3 py-5 text-sm text-gray-950'>내 계정으로 전환</ButtonText>}
			msg='내 계정으로 돌아가시겠습니까?'
			confirmClose={false}
			onConfirm={() => {
				doaBackdo({ navigate });
			}}
			onCancel={() => false}
		/>
	);
}
