import { SnackbarProvider } from 'notistack';
import React from 'react';
import { CookiesProvider } from 'react-cookie';
import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import App from './App';
import { PaperManageProvider } from './context/PaperManageContext';
import { StatisticsProvider } from './context/StatisticsContext';
import './i18n/i18n';
import './index.css';
import HardDelete from './pages/admin/HardDeletePage';
import ErrorPage from './pages/common/ErrorPage';
import GlobalDataSetter from './pages/common/GlobalDataSetter';
import LoginPage from './pages/common/LoginPage';
import LogoutPage from './pages/common/LogoutPage';
import ProtectedRoute from './pages/common/ProtectedRoute';
import QueryClientSetter from './pages/common/QueryClientSetter';
import RedirectHandler from './pages/common/RedirectHandler';
import { SysConfigSetter } from './pages/common/SysConfigSetter';
import RouteDashboardPage from './pages/dashboard/RoutePage';
import PaperManageDetail from './pages/main/paperManage/PaperManageDetail';
import PaperManageList from './pages/main/paperManage/PaperManageList';
import ListSearchPage from './pages/search/ListPage';
import SignProtectedRoute from './pages/sign/ProtectedRoute';
import DashboardStatisticsPage from './pages/statistics/DashboardPage';
import DetailStatisticsPage from './pages/statistics/DetailPage';
import RouteStatisticsPage from './pages/statistics/RoutePage';
import AccodionPage from './pages/ui/AccodionPage';
import ButtonPage from './pages/ui/ButtonPage';
import ChartPage from './pages/ui/ChartPage';
import ColorPage from './pages/ui/ColorPage';
import ContainerPage from './pages/ui/ContainerPage';
import FontSizePage from './pages/ui/FontSizePage';
import FormPage from './pages/ui/FormPage';
import IconPage from './pages/ui/IconPage';
import PaginationPage from './pages/ui/PaginationPage';
import PDFPage from './pages/ui/PDFPage';
import PopupPage from './pages/ui/PopupPage';
import UiRoutePage from './pages/ui/RoutePage';
import SlidePage from './pages/ui/SlidePage';
import TablePage from './pages/ui/TablePage';
import TabPage from './pages/ui/TabPage';
import reportWebVitals from './reportWebVitals';

const uiRoute =
	process.env.NODE_ENV === 'production'
		? {}
		: {
				path: '/ui',
				element: <UiRoutePage />,
				children: [
					{
						path: '/ui/font',
						element: <FontSizePage />,
					},
					{
						path: '/ui/color',
						element: <ColorPage />,
					},
					{
						path: '/ui/icon',
						element: <IconPage />,
					},
					{
						path: '/ui/button',
						element: <ButtonPage />,
					},
					{
						path: '/ui/container',
						element: <ContainerPage />,
					},
					{
						path: '/ui/tab',
						element: <TabPage />,
					},
					{
						path: '/ui/accodion',
						element: <AccodionPage />,
					},
					{
						path: '/ui/form',
						element: <FormPage />,
					},
					{
						path: '/ui/table',
						element: <TablePage />,
					},
					{
						path: '/ui/pagination',
						element: <PaginationPage />,
					},
					{
						path: '/ui/popup',
						element: <PopupPage />,
					},
					{
						path: '/ui/chart',
						element: <ChartPage />,
					},
					{
						path: '/ui/pdf',
						element: <PDFPage />,
					},
					{
						path: '/ui/slide',
						element: <SlidePage />,
					},
				],
		  };

export const router = createBrowserRouter([
	{
		path: '/',
		element: (
			<ProtectedRoute>
				<App />
			</ProtectedRoute>
		),
		errorElement: <ErrorPage />,
		children: [
			uiRoute,
			{
				index: true,
				path: '/',
				element: <RouteDashboardPage />,
			},
			{
				path: '/get_dashboard_view',
				element: <RedirectHandler />,
			},
			{
				path: '/manage/main',
				element: <RedirectHandler />,
			},
			{
				path: '/statistics',
				element: (
					<StatisticsProvider>
						<RouteStatisticsPage />
					</StatisticsProvider>
				),
				children: [
					{
						index: true,
						path: '/statistics',
						element: <DashboardStatisticsPage />,
					},
					{
						path: '/statistics/detail/:query',
						element: <DetailStatisticsPage />,
					},
				],
			},
			{
				path: '/sign/contract-seal/:contractId',
				element: <SignProtectedRoute />,
			},
			{
				path: '/paperManage/list',
				element: (
					<PaperManageProvider>
						<PaperManageList />
					</PaperManageProvider>
				),
			},
			{
				path: '/paperManage/detail',
				element: (
					<PaperManageProvider>
						<PaperManageDetail />
					</PaperManageProvider>
				),
			},
			{
				path: '/hardDelete',
				element: <HardDelete />,
			},
			{
				path: '/search',
				element: <ListSearchPage />,
			},
		],
	},
	{
		path: '/login',
		element: <LoginPage />,
		errorElement: <ErrorPage />,
	},
	{
		path: '/logout',
		element: <LogoutPage />,
		errorElement: <ErrorPage />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<QueryClientSetter>
			<SysConfigSetter>
				<SnackbarProvider maxSnack={5}>
					<CookiesProvider>
						<GlobalDataSetter />
						<RouterProvider router={router} />
					</CookiesProvider>
				</SnackbarProvider>
			</SysConfigSetter>
		</QueryClientSetter>
	</React.StrictMode>,
);
reportWebVitals();
