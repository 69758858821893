import { useQuery, useQueryClient } from '@tanstack/react-query';
import { queryKey } from '../constants/queryKey';
import { getConcurrentJob } from '../api/login';

export function useConcurrentJob() {
	const queryClient = useQueryClient();

	const getConcurrentJobListFn = () => getConcurrentJob();

	const getConcurrentJobList = useQuery([queryKey.concurrentJob], () =>
        getConcurrentJobListFn(),
	);

	const refetchConcurrentJobList = () => queryClient.invalidateQueries(queryKey.concurrentJob);

	return { refetchConcurrentJobList, getConcurrentJobList };
}
