import { colors } from '../util/colors';
import { getDomainURL } from '../util/commonUtils';

export const STATIC_DIR = process.env.NODE_ENV === 'production' ? '/static/adele_react' : '';

export const DOMAIN_URL = getDomainURL();

export const STATISTICS_CHART_COLOR = {
	CONTRACT: colors.sky[600],
	COUNSEL: colors.teal[500],
	LAWSUIT_INNER_LAWYER: colors.yellow[400],
	LAWSUIT_OUTER_LAWYER: colors.yellow[200],
	LAWSUIT_ETC: colors.yellow[100],
	LEGAL_REVIEW_IN_PROGRESS: colors.blue[600],
	LEGAL_REVIEW_COMPLETE: colors.blue[200],
	CONTRACT_ETC: colors.green[600],
};

/**
 * SK Custom Color
 */
export const STATISTICS_CHART_SK_COLOR = {
	CONTRACT: colors.rose[350],
	CONTRACT_ETC: colors.amber[350],
	COUNSEL: colors.emerald[350],
	LAWSUIT_INNER_LAWYER: colors.orange[300],
	LAWSUIT_OUTER_LAWYER: colors.orange[200],
	LAWSUIT_ETC: colors.orange[100],
	LEGAL_REVIEW_IN_PROGRESS: colors.blue[600],
	LEGAL_REVIEW_COMPLETE: colors.blue[200],
};

export const DASHBOARD_PROGRESS_GROUP_COLOR_MAP = {
	DRAFT: colors.neutral[300],
	REVIEW: colors.blue[200],
	SIGN_AND_SEAL: colors.blue[600],
	APPROVAL: colors.blue[600],
	COMPLETE: colors.teal[500],
};
