import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function AddCounselIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2 4.78182C2 3.79753 2.79775 3 3.78182 3H15.0667C16.0508 3 16.8485 3.79753 16.8485 4.78182V10.5247C16.7071 10.5139 16.5643 10.5084 16.4201 10.5084C13.3646 10.5084 10.8876 12.9853 10.8876 16.0408C10.8876 16.2446 10.8986 16.4459 10.9201 16.644L9.6343 17.9297C9.51829 18.0457 9.3303 18.0457 9.21429 17.9297L6.75718 15.4728H3.78182C2.79775 15.4728 2 14.6752 2 13.6909V4.78182ZM5.29785 10.1807C5.95393 10.1807 6.48573 9.64877 6.48573 8.99277C6.48573 8.33677 5.95393 7.80489 5.29785 7.80489C4.64177 7.80489 4.10997 8.33677 4.10997 8.99277C4.10997 9.64877 4.64177 10.1807 5.29785 10.1807ZM9.42426 10.1807C10.0803 10.1807 10.6121 9.64877 10.6121 8.99277C10.6121 8.33677 10.0803 7.80489 9.42426 7.80489C8.76818 7.80489 8.23638 8.33677 8.23638 8.99277C8.23638 9.64877 8.76818 10.1807 9.42426 10.1807ZM14.7388 8.99277C14.7388 9.64877 14.207 10.1807 13.551 10.1807C12.8949 10.1807 12.3631 9.64877 12.3631 8.99277C12.3631 8.33677 12.8949 7.80489 13.551 7.80489C14.207 7.80489 14.7388 8.33677 14.7388 8.99277Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M11.4607 16.0405C11.4607 13.3029 13.6825 11.0811 16.4202 11.0811C19.1578 11.0811 21.3796 13.3029 21.3796 16.0405C21.3796 18.7782 19.1578 21 16.4202 21C13.6825 21 11.4607 18.7782 11.4607 16.0405ZM16.9161 16.5365H18.8999V15.5446H16.9161V13.5608H15.9242V15.5446H13.9404V16.5365H15.9242V18.5203H16.9161V16.5365Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
