import { Epic, EpicTableSearchParams } from '../model/epic';
import { sendPostPageNavigation } from './common';

export async function getEpicList(searchParams: EpicTableSearchParams) {
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;
	const params = {
		returnType: 'json',
		curr_page: currentPage + 1,
		search_mode: searchType,
		search_text: searchText,
		number_per_page: rowsPerPage,
		isReact: true,
		// order_col: orderColumn,
		// order,
	};
	const res = await sendPostPageNavigation<Epic[]>('/epic/get_epic_list/', params);

	return res;
}
