import Tabs from '@mui/material/Tabs';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatus } from '../../../api/dashboard';
import { queryKey } from '../../../constants/queryKey';
import { useSubsidiary } from '../../../context/SubsidiaryContext';
import { useUser } from '../../../context/UserContext';
import { DashboardWorkStatus } from '../../../model/api/dashboard';
import SysConfigStore from '../../../store/common/SysConfigStore';
import DisputeIcon from '../../ui/icons/DisputeIcon';
import StampIcon from '../../ui/icons/StampIcon';
import ContractIcon from '../../ui/icons/apps/ContractIcon';
import CounselIcon from '../../ui/icons/apps/CounselIcon';
import DocIcon from '../../ui/icons/apps/DocIcon';
import LawsuitIcon from '../../ui/icons/apps/LawsuitIcon';
import ListDashboard from './ListDashboard';
import TabButtonDashboard from './TabButtonDashboard';

interface TabPanelProps {
	children?: React.ReactNode;
	index: number;
	value: number;
	className?: string;
}

function TabPanel(props: TabPanelProps) {
	const { children, value, index, className = '', ...other } = props;

	return (
		<div
			role='tabpanel'
			hidden={value !== index}
			id={`vertical-tabpanel-${index}`}
			aria-labelledby={`vertical-tab-${index}`}
			className={className}
			{...other}
		>
			{value === index && children}
		</div>
	);
}

function a11yProps(index: number) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

export default function TabsDashboard() {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { permission } = useUser();
	const [tab, setTab] = React.useState(0);
	const { id: subsidiaryId } = useSubsidiary();
	const { t } = useTranslation();

	const tabProps: { label: string; icon?: JSX.Element; type: string }[] = [
		{ label: '계약', icon: <ContractIcon />, type: 'CONTRACT' },
	];

	const optionalTabs: [{ label: string; icon?: JSX.Element; type: string }, boolean][] = [
		[
			{ label: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')), icon: <DocIcon />, type: 'CONTRACT_ETC' },
			isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
		],
		[{ label: '인감', icon: <StampIcon />, type: 'STAMP' }, isModuleEnabled('CONTRACT_STAMP_MANAGE')],
		[{ label: '자문', icon: <CounselIcon />, type: 'COUNSEL' }, permission.DISPLAY_COUNSEL],
		[{ label: '소송', icon: <LawsuitIcon />, type: 'LAWSUIT' }, permission.DISPLAY_LAWSUIT],
		[
			{ label: t('label_contract_sign_seal_process'), icon: <DocIcon />, type: 'CONTRACT_SIGN_SEAL_PROCESS' },
			isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF'),
		],
		[{ label: '분쟁', icon: <DisputeIcon />, type: 'DISPUTE' }, permission.DISPLAY_DISPUTE],
		[{ label: '수발신문서', icon: <DocIcon />, type: 'DOC_RECEIVED' }, isModuleEnabled('DOC_RECEIVED')],
	];

	optionalTabs
		.filter(item => item[1])
		.forEach(item => {
			tabProps.push(item[0]);
		});

	if (tabProps.length > 1) {
		tabProps.unshift({ label: '전체', type: 'ALL' });
	}

	const { data: workStatusList, isLoading } = useQuery(
		[queryKey.dashboard, queryKey.main, subsidiaryId, tab],
		() => getMainWorkStatus(tabProps[tab].type as 'ALL' | DashboardWorkStatus['history_type'], subsidiaryId),
		{ staleTime: 30000 },
	);

	const handleChange = (event: React.SyntheticEvent, newValue: number) => {
		setTab(newValue);
	};

	return (
		<section className='flex'>
			<article className='basis-1/5'>
				<div className='p-8'>
					<h3 className='text-2xl font-bold'>업무현황</h3>
				</div>
				<Tabs
					classes={{
						indicator: 'right-auto left-0 w-2 bg-blue-300',
					}}
					orientation='vertical'
					variant='scrollable'
					value={tab}
					onChange={handleChange}
					aria-label='메인 대시보드 탭'
				>
					{tabProps.map((prop, index) => (
						<TabButtonDashboard key={prop.label} {...prop} {...a11yProps(index)} />
					))}
				</Tabs>
			</article>

			<article className='basis-4/5 py-4 pr-4 h-screen overflow-y-auto scrollbar'>
				{!isLoading &&
					Array.from({ length: tabProps.length }, (_, index) => index).map(index => (
						<TabPanel key={index} value={tab} index={index} className='h-full'>
							{workStatusList && workStatusList.length > 0 && (
								<div className='rounded-2xl bg-white'>
									<ListDashboard data={workStatusList} />
								</div>
							)}
							{(!workStatusList || workStatusList.length === 0) && (
								<div className='flex items-center justify-center h-full rounded-2xl bg-white'>
									<p className='text-neutral-400'>조회결과가 없습니다.</p>
								</div>
							)}
						</TabPanel>
					))}
			</article>
		</section>
	);
}
