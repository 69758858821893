import { IconButton, Tooltip } from '@mui/material';
import InfoIcon from '../ui/icons/InfoIcon';

type Props = {
	msg: string;
	placement?:
		| 'bottom-end'
		| 'bottom-start'
		| 'bottom'
		| 'left-end'
		| 'left-start'
		| 'left'
		| 'right-end'
		| 'right-start'
		| 'right'
		| 'top-end'
		| 'top-start'
		| 'top';

	// InfoIcon 필요하면 추가
};

export default function InfoTooltip({ msg, placement = 'top-start' }: Props) {
	return (
		<Tooltip title={msg} placement={placement}>
			<IconButton>
				<InfoIcon color='secondary' fontSize='small' />
			</IconButton>
		</Tooltip>
	);
}
