import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function StampIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M11.5781 3.5155C11.5289 3.52605 11.3812 3.5577 11.244 3.58935C10.1257 3.84959 9.23247 4.67954 8.87728 5.78027C8.66628 6.43789 8.67331 6.71572 8.97575 8.82223C9.23247 10.6228 9.24654 10.6755 9.44347 10.8232C9.54194 10.8971 9.55953 10.8971 12.0001 10.8971C14.4407 10.8971 14.4583 10.8971 14.5568 10.8232C14.7537 10.6755 14.7678 10.6228 15.0245 8.82223C15.3269 6.71572 15.334 6.43789 15.123 5.78027C14.7783 4.70415 13.9062 3.88476 12.809 3.5999C12.5206 3.52253 11.7997 3.4733 11.5781 3.5155Z'
				fill='#0891B2'
				className='fill-inherit'
			/>
			<path
				d='M9.92516 13.2322V14.4947L9.79504 14.5158C9.72119 14.5299 8.80333 14.6389 7.75183 14.7655C6.70033 14.8921 5.76488 15.0117 5.67697 15.0328C4.9912 15.1981 4.36875 15.7924 4.14719 16.4887C4.08037 16.7032 4.06982 16.8122 4.06982 17.3573C4.06982 18.0396 4.08037 18.0712 4.31248 18.2435L4.40743 18.3174H12H19.5926L19.6876 18.2435C19.9197 18.0712 19.9302 18.0396 19.9302 17.3573C19.9302 16.8122 19.9197 16.7032 19.8529 16.4887C19.6313 15.7889 18.9877 15.1805 18.3161 15.0293C18.2176 15.0081 17.2927 14.8886 16.2588 14.7655C15.2213 14.6389 14.307 14.5299 14.2261 14.5193L14.0749 14.4982V13.2322V11.9697H12H9.92516V13.2322Z'
				fill='#0891B2'
				className='fill-inherit'
			/>
			<path
				d='M4.68506 19.826C4.68506 20.1601 4.70264 20.3149 4.75539 20.4872C4.89606 20.9408 5.24422 21.289 5.69787 21.4297C5.91943 21.5 6.04603 21.5 11.9998 21.5C17.9572 21.5 18.0802 21.5 18.3018 21.4297C18.5937 21.3417 18.8821 21.1307 19.0438 20.8951C19.2513 20.5927 19.2865 20.452 19.3076 19.8893L19.3216 19.39H12.0034H4.68506V19.826Z'
				fill='#0891B2'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
