import { Tabs, TabsOwnProps } from '@mui/material';

type TabsOwnPropsOverrides = TabsOwnProps;

export default function TabsCommon({ ...props }: TabsOwnPropsOverrides) {
	const { orientation = 'horizontal', variant = 'scrollable', onChange, value, children } = props;
	return (
		<Tabs
			orientation={orientation}
			variant={variant}
			scrollButtons={false}
			value={value}
			onChange={onChange}
			{...props}
		>
			{children}
		</Tabs>
	);
}
