import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function DocIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M12.6938 5.80354C12.6938 8.52618 12.6997 8.70614 12.8041 8.90932C12.868 9.03703 13.0073 9.17636 13.1467 9.25763L13.2654 9.32294C13.3398 9.36387 13.4234 9.38517 13.5084 9.38482L16.2582 9.37374L17.9408 9.36356C18.3848 9.36087 18.6053 8.82397 18.2913 8.51001L15.9157 6.13443L13.5474 3.7661C13.2324 3.45112 12.6938 3.6742 12.6938 4.11966V5.80354Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M6.6807 2.91539L6.6785 2.91522L6.23366 2.91557C5.51274 2.97326 4.9355 3.54051 4.8623 4.25695V4.5656C4.86723 4.61379 4.87443 4.6613 4.88381 4.70803H4.8623V12.0138V19.2904H4.89014C4.87521 19.3648 4.86578 19.4412 4.8623 19.5192V19.6552C4.89754 20.4451 5.54359 21.0762 6.33957 21.0876H6.58971C6.62241 21.0871 6.65486 21.0856 6.68701 21.0831V21.0876H11.9906H17.287V21.0835C17.3124 21.0855 17.338 21.0868 17.3637 21.0876H17.655C18.4641 21.0651 19.1132 20.4022 19.1132 19.5876C19.1132 19.486 19.1031 19.3868 19.0839 19.2909H19.1225L19.131 15.7668L19.1359 12.7132C19.1377 11.6073 18.2417 10.71 17.1359 10.71H15.3414H13.1339V10.6799C13.038 10.6991 12.9388 10.7092 12.8372 10.7092C12.0456 10.7092 11.3971 10.0962 11.3407 9.31891V8.89179C11.3409 8.88881 11.3411 8.88582 11.3414 8.88284H11.3407V6.7092V4.91312C11.3407 3.80793 10.4443 2.91224 9.3391 2.91312L6.6807 2.91522V2.91539ZM15.2399 13.9593C15.5988 13.9593 15.8898 13.6683 15.8898 13.3094C15.8898 12.9505 15.5988 12.6596 15.2399 12.6596H11.9906H8.74121C8.3823 12.6596 8.09134 12.9505 8.09134 13.3094C8.09134 13.6683 8.3823 13.9593 8.74121 13.9593H11.9906H15.2399ZM15.2399 17.2086C15.5988 17.2086 15.8898 16.9177 15.8898 16.5588C15.8898 16.1999 15.5988 15.9089 15.2399 15.9089H11.9906H8.74121C8.3823 15.9089 8.09134 16.1999 8.09134 16.5588C8.09134 16.9177 8.3823 17.2086 8.74121 17.2086H11.9906H15.2399Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
