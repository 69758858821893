import { useTranslation } from 'react-i18next';
import HardDeleteList from '../../components/admin/HardDeleteList';
import TableSkeleton from '../../components/ui/tables/TableSkeleton';
import useHardDelete from '../../hooks/useHardDelete';
import { useValidateForm } from '../../hooks/useValidateForm';
import { HardDelete } from '../../model/hardDelete';
import { HeadCell } from '../../model/table';

export default function HardDeletePage() {
	const { t } = useTranslation();
	const { getHardDelete } = useHardDelete();
	const { data, isLoading } = getHardDelete();

	const formikId = 'selectedHardDeleteList';
	const selectedFormik = useValidateForm({
		validationSchema: {
			[formikId]: { initialValue: [], type: 'array' },
		},
	});

	const headCells: HeadCell<HardDelete>[] = [
		{
			id: 'no',
			align: 'left',
			disablePadding: true,
			label: t('cmmn_label_number_no'),
		},
		{
			id: 'isdeleted',
			align: 'left',
			disablePadding: false,
			label: t('cmmn_label_activation'),
		},
		{
			id: 'company',
			align: 'left',
			disablePadding: false,
			label: t('label_company_name'),
		},
		{
			id: 'app_type',
			align: 'left',
			disablePadding: false,
			label: t('legal_app_type'),
		},
		{
			id: 'epic',
			align: 'left',
			disablePadding: false,
			label: t('MSG_ID_701'),
		},
		{
			id: 'title',
			align: 'left',
			disablePadding: false,
			label: t('app_name'),
		},
		{
			id: 'tags_info',
			align: 'left',
			disablePadding: false,
			label: t('cmmn_tags_legal_extended_type'),
		},
		{
			id: 'opposite',
			align: 'left',
			disablePadding: false,
			label: t('cmmn_label_opponent'),
		},
		{
			id: 'lawsuit_case_num',
			align: 'left',
			disablePadding: false,
			label: t('MSG_ID_217'),
		},
		{
			id: 'dept_info',
			align: 'left',
			disablePadding: false,
			label: t('department'),
		},
		{
			id: 'creator',
			align: 'left',
			disablePadding: false,
			label: t('MSG_ID_61'),
		},
		{
			id: 'create_time',
			align: 'left',
			disablePadding: false,
			label: t('register_date'),
		},
		{
			id: 'goToPage',
			align: 'left',
			disablePadding: false,
			label: t('MSG_ID_285'),
		},
	];

	return (
		<section className='flex flex-col gap-6 px-6 py-14'>
			<header>
				<h2 className='text-2xl'>{t('hard_delete')}</h2>
			</header>
			{isLoading && !data && <TableSkeleton colSpan={headCells.length + 1} />}
			{!isLoading && data && (
				<HardDeleteList formikId={formikId} list={data} headCells={headCells} selectedFormik={selectedFormik} />
			)}
		</section>
	);
}
