import { MouseEvent } from 'react';
import ButtonText from '../../../../ui/buttons/ButtonText';
import CardBox from '../../../../ui/containers/CardBox';
import AddIcon from '../../../../ui/icons/AddIcon';
import StatusItemDashboard from './StatusItemDashboard';

type Item = {
	count?: number;
	label: string;
	onClick: (e?: MouseEvent<HTMLButtonElement> | undefined) => void;
	display?: boolean;
};

type PlusBtn = {
	display?: boolean;
	classes?: string;
	text?: string;
	plusIcon: boolean;
	onClick?: (e?: MouseEvent<HTMLButtonElement> | undefined) => void;
};

type Props = {
	className?: string;
	title: string;
	items: Item[];
	plusBtnList?: PlusBtn[];
	children?: React.ReactNode;
};

export default function StatusCardDashboard({ title, items, className, plusBtnList, children }: Props) {
	return (
		<CardBox className={`flex flex-col gap-2 p-6 ${className}`}>
			<h3 className='text-xl font-bold'>{title}</h3>
			<div className='flex items-center justify-between'>
				{plusBtnList && (
					<div
						className='buttons-container'
						style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}
					>
						{plusBtnList
							.filter(({ display = true }) => display)
							.map((btn, index) => (
								<ButtonText
									key={btn.text}
									size='small'
									onClick={btn.onClick}
									variant='outlined'
									color='secondary'
									classes={{ root: btn.classes }}
								>
									{btn.plusIcon && <AddIcon fontSize='small' />}
									{btn.text}
								</ButtonText>
							))}
					</div>
				)}
				{children}
				<ul className='flex flex-wrap justify-end gap-x-14 flex-auto'>
					{items
						.filter(({ display = true }) => display)
						.map(({ label, count, onClick }) => (
							<StatusItemDashboard key={label} count={count} label={label} onClick={onClick} />
						))}
				</ul>
			</div>
		</CardBox>
	);
}
