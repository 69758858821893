import { MouseEvent } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import { HeadCell, Order } from '../../model/table';
import { SignList } from '../../model/list';

type Props = {
	headCells: readonly HeadCell<SignList>[];
	onRequestSort: (event: MouseEvent<unknown>, property: keyof SignList) => void;
	order: Order;
	orderBy: string;
};

export default function TableSignHeader({ order, orderBy, onRequestSort, headCells }: Props) {
	const handleClick = (headCell: HeadCell<SignList>) => (e: MouseEvent<unknown>) => {
		if (headCell.sort) onRequestSort(e, headCell.id);
	};

	return (
		<TableHead>
			<TableRow>
				{headCells.map(headCell => (
					<TableCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell?.id ? order : false}
						classes={{ head: `${headCell.width || ''}` }}
					>
						<TableSortLabel
							active={headCell.sort && orderBy === headCell?.id}
							hideSortIcon={!headCell.sort}
							classes={{
								root: `${!headCell.sort && 'cursor-default hover:text-inherit'}`,
							}}
							direction={orderBy === headCell?.id ? order : 'asc'}
							onClick={handleClick(headCell)}
						>
							{headCell?.label}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
