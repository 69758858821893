import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

export default function RedirectHandler() {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	// URL 끝에 있는 슬래시 제거
	const normalizedPath = pathname.replace(/\/$/, '');

	// 메인화면 대시보드로 리다이렉트 하는 URL 리스트
	const indexPathList = ['/get_dashboard_view', '/manage/main'];

	useEffect(() => {
		if (indexPathList.includes(normalizedPath)) {
			navigate('/');
		}
	}, [normalizedPath]);

	return null;
}
