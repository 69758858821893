import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../model/icon';

export default function DocIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M5.99371 4.65235C5.99371 4.3577 6.23257 4.11883 6.52723 4.11883H15.761V2.625H6.52723C5.40755 2.625 4.49988 3.53267 4.49988 4.65235V15.537H5.99371V4.65235Z'
				fill='#A3A3A3'
				className='fill-inherit'
			/>
			<path
				d='M8.5181 5.96175H15.9937L19.9722 9.94016V19.7714C19.9722 20.2428 19.5899 20.625 19.1185 20.625H8.5181C8.04669 20.625 7.66448 20.2428 7.66448 19.7714V6.81536C7.66448 6.34396 8.04669 5.96175 8.5181 5.96175ZM16.6992 12.4398H10.9372V14.147H16.6992V12.4398ZM10.9372 15.863H16.6992V17.5702H10.9372V15.863Z'
				fill='#A3A3A3'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
