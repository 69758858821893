import { ToolbarProps, ToolbarSlot } from '@react-pdf-viewer/default-layout';
import { ReactElement } from 'react';

export default function DefaultToolbar(Toolbar: (props: ToolbarProps) => ReactElement) {
	return (
		<Toolbar>
			{(slots: ToolbarSlot) => {
				const {
					ShowSearchPopover,
					CurrentPageInput,
					NumberOfPages,
					Download,
					EnterFullScreen,
					GoToNextPage,
					GoToPreviousPage,
					Print,
					Zoom,
					ZoomIn,
					ZoomOut,
				} = slots;
				return (
					<div className='flex items-center justify-between w-full p-2'>
						<div className='flex items-center gap-2'>
							<ShowSearchPopover />
							<GoToPreviousPage />
							<CurrentPageInput />
							<span> of </span>
							<NumberOfPages />
							<GoToNextPage />
						</div>
						<div className='flex items-center'>
							<ZoomOut />
							<Zoom />
							<ZoomIn />
						</div>
						<div className='flex items-center'>
							<EnterFullScreen />
							<Download />
							<Print />
						</div>
					</div>
				);
			}}
		</Toolbar>
	);
}
