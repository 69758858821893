import React from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../../../../hooks/useAlert';
import { DashboardWorkStatus } from '../../../../../model/api/dashboard';
import { getLegalLinkMap, navigateToAdele } from '../../../../../util/commonUtils';
import { getTodayFormatToHour } from '../../../../../util/dateFormat';
import ButtonText from '../../../../ui/buttons/ButtonText';
import StateChip from '../../../../ui/chips/StateChip';
import DisputeIcon from '../../../../ui/icons/DisputeIcon';
import ContractIcon from '../../../../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../../../../ui/icons/apps/custom/sk/CounselIcon';
import DocIcon from '../../../../ui/icons/apps/custom/sk/DocIcon';
import LawsuitIcon from '../../../../ui/icons/apps/custom/sk/LawsuitIcon';
import ReviewIcon from '../../../../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../../../../ui/icons/apps/custom/sk/SignIcon';
import ListItemActionDashboard from './ListItemActionDashboard';
import ListItemRolesDashboard from './ListItemRolesDashboard';
import ClaimIcon from '../../../../ui/icons/apps/custom/sk/ClaimIcon';
import SysConfigStore from "../../../../../store/common/SysConfigStore";

const getIcon = (type: DashboardWorkStatus['history_type']) => {
	let icon: React.ReactNode;
	switch (type) {
		case 'CONTRACT':
			icon = <ContractIcon className='text-neutral-300' size='large' />;
			break;
		case 'CONTRACT_ETC':
			icon = <ReviewIcon className='text-neutral-300' size='large' />;
			break;
		case 'COUNSEL':
			icon = <CounselIcon className='text-neutral-300' size='large' />;
			break;
		case 'LAWSUIT':
			icon = <LawsuitIcon className='text-neutral-300' size='large' />;
			break;
		case 'STAMP':
			icon = <SignIcon className='text-neutral-300' size='large' />;
			break;
		case 'CONTRACT_SIGN_SEAL_PROCESS':
			icon = <SignIcon className='text-neutral-300' size='large' />;
			break;
		case 'DOC_RECEIVED':
			icon = <DocIcon className='text-neutral-300' size='large' />;
			break;
		case 'CLAIM':
			icon = <ClaimIcon className='text-neutral-300' size='large' />;
			break;
		case 'DISPUTE':
			icon = <DisputeIcon className='text-neutral-300' size='large' />;
			break;
		default:
	}
	return icon;
};

const getTypeName = (type: DashboardWorkStatus['history_type']) => {
	const { t } = useTranslation();
	const { labelText } = SysConfigStore();
	let result: string = '';
	switch (type) {
		case 'CONTRACT':
			result = '계약';
			break;
		case 'COUNSEL':
			result = '자문';
			break;
		case 'LAWSUIT':
			result = t('MSG_ID_1203');
			break;
		case 'DOC_RECEIVED':
			result = '수발신문서';
			break;
		case 'STAMP':
			result = '인감';
			break;
		case 'DISPUTE':
			result = '분쟁';
			break;
		case 'CLAIM':
			result = t('MSG_ID_681');
			break;
		case 'CONTRACT_ETC':
			result = t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract'));
			break;
		case 'CONTRACT_SIGN_SEAL_PROCESS':
			result = t('label_contract_sign_seal_process');
			break;
		default:
	}
	return result;
};

type Props = {
	item: DashboardWorkStatus;
};

export default function ListItemDashboard({ item }: Props) {
	const [snackbar] = useAlert();
	const {
		history_type: workType,
		action_type: actionType,
		action_text: actionText,
		action_user: actionUser,
		create_time: createAt,
		title,
		comment,
		status,
		roles,
	} = item;

	const isComment =
		(workType === 'CONTRACT' && actionType === '6') || (workType === 'COUNSEL' && actionType === 'COMMENT') || (workType === 'CLAIM' && actionType === '30');

	const handleNavigate = async (id: number, type: DashboardWorkStatus['history_type']) => {
		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			navigateToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};

	const actionTextConfig = {
		CONTRACT: (
			<span>
				<strong>{actionUser}</strong>님 {actionText}
			</span>
		),
		COUNSEL: (
			<span>
				<strong>{actionUser}</strong>님 {actionText}
			</span>
		),
		LAWSUIT: <strong>[{actionText}]</strong>,
		DOC_RECEIVED: <strong>[{actionText}]</strong>,
		CONTRACT_ETC: (
			<span>
				<strong>{actionUser}</strong>님 {actionText}
			</span>
		),
		CONTRACT_SIGN_SEAL_PROCESS: (
			<span>
				<strong>{actionUser}</strong>님 {actionText}
			</span>
		),
	};

	return (
		<li className='overflow-hidden rounded-2xl bg-white'>
			<ButtonText className='w-full px-8 py-4' onClick={() => handleNavigate(item.related_id, item.history_type)}>
				<div className='flex justify-between w-full gap-10'>
					<div className='self-center flex flex-col items-center gap-2 w-10'>
						{getIcon(workType)}
						<p className='text-sm text-neutral-600'>{getTypeName(workType)}</p>
					</div>
					<div className='flex-1 text-left'>
						<div className='flex items-center gap-6'>
							<p className='font-bold'>{title}</p>
							<StateChip text={status} />
						</div>
						{!isComment && (
							<div className='flex flex-col gap-1 px-6 pt-2'>
								<p className='text-neutral-600'>
									<ListItemActionDashboard item={item} />
								</p>
								<p className='text-neutral-600'>{workType === 'LAWSUIT' ? comment : ''}</p>
							</div>
						)}
						{isComment && (
							<div className='flex flex-col gap-1 px-6 pt-2'>
								<p className='text-neutral-600'>
									<strong>{actionUser}</strong>님 {actionText}
								</p>
							</div>
						)}
					</div>
					<div className='flex flex-col flex-wrap items-end gap-1'>
						<p className='text-sm text-neutral-500 pr-1'>{getTodayFormatToHour(createAt)}</p>
						<div className='flex flex-col items-end'>
							<ListItemRolesDashboard roles={roles} />
						</div>
					</div>
				</div>
			</ButtonText>
		</li>
	);
}
