/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SealStampTableSearchParams } from '../model/sealStamp';
import { useUser } from './UserContext';

interface SealStampContext {
	searchParams: SealStampTableSearchParams;
	setSearchParams: React.Dispatch<React.SetStateAction<SealStampTableSearchParams>>;
}

const SealStampContext = createContext<SealStampContext | undefined>(undefined);

export const SealStampProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	const { isAdmin, isLawyer, isViewer } = useUser();
	const { state } = useLocation();

	// if (!(isAdmin || isLawyer || isViewer)) {
	// 	return <Navigate to='/' replace />;
	// }

	const [searchParams, setSearchParams] = useState<SealStampTableSearchParams>({
		currentPage: 0,
		searchType: 'title',
		searchText: '',
		rowsPerPage: 10,
		// orderColumn: -1,
		// order: 'desc',
	});

	const { searchType, searchText, rowsPerPage } = searchParams;

	useEffect(() => {
		setSearchParams(prev => ({ ...prev, currentPage: 0 }));
	}, [searchType, searchText, rowsPerPage, state]);

	return (
		<SealStampContext.Provider value={{ searchParams, setSearchParams }}>{children}</SealStampContext.Provider>
	);
};

export function useSealStampList() {
	const context = useContext(SealStampContext);
	if (context === undefined) {
		throw new Error('SealStamp must be used within a SealStampProvider');
	}

	return context;
}
