import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { t } from 'i18next';
import { DOMAIN_URL } from '../constants/common';
import { BizPagination, BizResponse } from '../model/api/common';
import { Tag } from '../model/tag';
import { Department, User } from '../model/user';
import { getCookie } from '../util/commonUtils';
import { TaskProcessStatusResponse } from '../model/tasks';

axios.defaults.withCredentials = true;

// Request interceptor: 요청을 보내기 전에 처리하는 로직
axios.interceptors.request.use(config => {
	const updatedConfig = { ...config };
	updatedConfig.headers = updatedConfig.headers || {};

	updatedConfig.headers['X-REQUESTED-WITH'] = 'XMLHTTPREQUEST';

	// // 헤더에 jwt 토큰 포함
	// const jwtToken = localStorage.getItem('jwt');
	// if (jwtToken) {
	// 	updatedConfig.headers.Authorization = `Bearer ${jwtToken}`;
	// }

	if (config.method && (config.method.toLowerCase() === 'get' || config.method.toLowerCase() === 'post')) {
		const lastUpdateCacheConfig = getCookie('last-update-cache-config');

		if (lastUpdateCacheConfig) {
			updatedConfig.headers['LAST-UPDATE-CACHE-CONFIG'] = lastUpdateCacheConfig;
		} else {
			// LAST-UPDATE-CACHE-CONFIG가 쿠키에 없다면 임시 값으로 설정
			document.cookie = 'last-update-cache-config=temp; path=/';
			updatedConfig.headers['LAST-UPDATE-CACHE-CONFIG'] = 'temp';
		}
	}

	// method가 GET이 아닌 경우, 헤더에 CSRF 토큰 포함
	if (config.method && config.method.toLowerCase() !== 'get') {
		const csrfToken = getCookie('csrftoken');
		if (csrfToken) {
			updatedConfig.headers['X-CSRFToken'] = csrfToken;
		} else {
			// CSRF 토큰이 쿠키에 없다면 임시 값으로 설정
			document.cookie = 'csrftoken=temp; path=/';
			updatedConfig.headers['X-CSRFToken'] = 'temp';
		}
	}

	// console.log(updatedConfig.headers);

	return updatedConfig;
});

// Response interceptor: 응답을 받은 후 처리하는 로직
axios.interceptors.response.use(
	response => {
		const { data } = response.data;

		// 세션 타임아웃시 로그인 페이지로 리다이렉트 시킨다.
		if (data && data?.s_timeout === '1') {
			window.location.href = '/login';
			return response;
		}

		return response;
	},
	(error: AxiosError<{ msg?: string }>) => {
		if (error.response) {
			// 서버가 에러 응답을 반환한 경우
			console.error(`Server returned error ${error.response.status}: ${error.response.data}`);
		} else if (error.request) {
			// 서버로의 요청이 이루어졌지만 응답을 받지 못한 경우
			console.error('Server did not respond to request:', error.request);
		} else {
			// 요청 설정 중에 오류가 발생한 경우
			console.error('Error setting up request:', error.message);
		}

		if (error?.response?.data.msg) {
			error.message = error.response.data.msg;
		}

		return Promise.reject(error);
	},
);

// 서버 Api에서 예외 발생 시 내려오는 상태값들 체크
function isApiFailedOrRejected(axiosData: BizResponse<unknown>) {
	const failed = !axiosData.success && !axiosData.status && !axiosData.rejected && axiosData.data === 'NODATA';
	const rejected = axiosData.success && axiosData.status && axiosData.data === 'REJECTED';

	return failed || rejected;
}

/**
 * 서버에서 데이터를 검색할 때 사용하는 GET 요청 함수입니다.
 *
 * @param {string} url - 요청 URL 경로
 * @param {AxiosRequestConfig} [config] - 옵션(헤더 설정 등)
 * @returns {Promise<BizResponse<T>>} 응답 데이터
 *
 * @example
 * sendGet<UserProfile>('/users/profile').then(data => console.log(data));
 */
export async function sendGet<T>(url: string, config?: AxiosRequestConfig): Promise<BizResponse<T>> {
	const res: AxiosResponse<BizResponse<T>> = await axios.get(`${DOMAIN_URL}${url}`, config);

	if (isApiFailedOrRejected(res.data)) {
		throw new Error(res.data.msg || '');
	}

	return res.data;
}

/**
 * 서버에 데이터를 생성하거나 전송할 때 사용하는 POST 요청 함수입니다.
 *
 * @param {string} url - 요청 URL 경로
 * @param {object | FormData} [params] - 요청에 함께 보낼 데이터
 * @param {AxiosRequestConfig} [config] - 옵션(헤더 설정 등)
 * @returns {Promise<BizResponse<T>>} 응답 데이터
 *
 * @example
 * sendPost('/posts', { title: 'New Post', content: 'This is content.' });
 */
export async function sendPost<T>(
	url: string,
	params?: { [key: string]: unknown } | FormData,
	config?: AxiosRequestConfig,
): Promise<BizResponse<T>> {
	// 장고 서버는 x-www-form-urlencoded 형식을 사용하므로, 매개변수들을 URLSearchParams 형태로 변환한다.
	const formParams = new URLSearchParams();

	Object.entries(params || {}).forEach(([key, value]) => {
		formParams.append(key, value as string);
	});

	// multipart/form-data 형식으로 보내야 하는 경우 FormData 객체를 사용한다.
	const reqData = params instanceof FormData ? params : formParams.toString();

	const res: AxiosResponse<BizResponse<T>> = await axios.post(`${DOMAIN_URL}${url}`, reqData, {
		...config,
	});

	if (typeof res.headers['is-update_config_chache'] !== 'undefined') {
		document.cookie = `is-update_config_chache=${res.headers['is-update_config_chache']}; path=/`;
	} else {
		document.cookie = `is-update_config_chache=temp; path=/`;
	}

	if (res.headers['is-update_config_chache'] === 'false' && res.headers['last-update-cache-config'] !== 'temp') {
		// alert(response.headers['Is-Update_config_chache']);
		// const res2 = axios.post(`${getDomainURL()}/get_sysconfig_list_by_company`, {}, { ...config });
		// console.log(res2);
		document.cookie = `last-update-cache-config=${res.headers['last-update-cache-config']}; path=/`;
		// axios
		// 	.post(`${getDomainURL()}/get_sysconfig_list_by_company/`, formParams.toString(), {
		// 		...config,
		// 	})
		// 	.then(res2 => {
		// 		if (res2.data.status) {
		// 			Object.entries(res2.data.data).forEach(([key, value]) => {
		// 				sysConfigs[key] = value;
		// 			});
		// 			console.log(res2);
		// 			console.log(res2.headers['last-update-cache-config']);
		// 			console.log(res2.headers['is-update_config_chache']);
		// 			console.log(typeof res2.headers['is-update_config_chache']);
		// 			if (res2.headers['last-update-cache-config'] !== 'temp') {
		// 				if (res2.headers['is-update_config_chache'] === 'false') {
		// 				} else {
		// 					document.cookie = `is_last-update-config_chache=${res2.headers['is_last-update-config_chache']}; path=/`;
		// 				}
		// 			}
		// 			console.log(sysConfigs);
		// 		}
		// 	});
	}

	// console.log('###########');
	// console.log(res);
	// console.log('###########');

	if (isApiFailedOrRejected(res.data)) {
		throw new Error(res.data.msg || '');
	}

	return res.data; // json타입의 data가 아니라, axios 의 data를 리턴함
}

/**
 * 서버에 multipart/form-data 데이터를 전송할 때 사용하는 POST 함수
 *
 * @param {string} url - 요청 URL 경로
 * @param {object} [params] - 요청에 함께 보낼 데이터, Array일 경우 key[]로 들어감.
 * @param {AxiosRequestConfig} [config] - 옵션(헤더 설정 등)
 * @returns {Promise<BizResponse<T>>} 응답 데이터
 *
 * @example
 */
export async function sendUploadPost<T>(
	url: string,
	params?: { [key: string]: number | string | Blob | Array<string | number | Blob> },
	formDataParam?: FormData,
	config?: AxiosRequestConfig,
): Promise<BizResponse<T>> {
	if (!formDataParam) {
		const formData = new FormData();

		Object.entries(params || {}).forEach(([key, value]) => {
			if (Array.isArray(value)) {
				value.forEach((arrayValue, arrayIndex) => {
					arrayValue = typeof arrayValue === 'number' ? arrayValue.toString() : arrayValue;
					formData.append(`${key}[]`, arrayValue);
				});
			} else {
				value = typeof value === 'number' ? value.toString() : value;
				formData.append(key, value);
			}
		});
		formDataParam = formData;
	}

	const res: AxiosResponse<BizResponse<T>> = await axios.post(`${DOMAIN_URL}${url}`, formDataParam, {
		headers: {
			'Content-Type': 'multipart/form-data',
		},
		...config,
	});

	if (typeof res.headers['is-update_config_chache'] !== 'undefined') {
		document.cookie = `is-update_config_chache=${res.headers['is-update_config_chache']}; path=/`;
	} else {
		document.cookie = `is-update_config_chache=temp; path=/`;
	}

	if (res.headers['is-update_config_chache'] === 'false' && res.headers['last-update-cache-config'] !== 'temp') {
		document.cookie = `last-update-cache-config=${res.headers['last-update-cache-config']}; path=/`;
	}

	if (isApiFailedOrRejected(res.data)) {
		throw new Error(res.data.msg || '');
	}

	return res.data; // json타입의 data가 아니라, axios 의 data를 리턴함
}

/**
 * 서버에 데이터를 생성하거나 전송할 때 사용하는 POST 요청 함수입니다.(axios 응답 래핑)
 *
 * @param {string} url - 요청 URL 경로
 * @param {object | FormData} [params] - 요청에 함께 보낼 데이터
 * @param {AxiosRequestConfig} [config] - 옵션(헤더 설정 등)
 * @returns {Promise<AxiosResponse<BizResponse<T>>>} 응답 데이터
 *
 * @example
 * axiosPost('/posts', { title: 'New Post', content: 'This is content.' });
 */
export async function axiosPost<T>(
	url: string,
	params?: { [key: string]: unknown },
	config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> {
	// 장고 서버는 x-www-form-urlencoded 형식을 사용하므로, 매개변수들을 URLSearchParams 형태로 변환한다.
	const formParams = new URLSearchParams();
	Object.entries(params || {}).forEach(([key, value]) => {
		formParams.append(key, value as string);
	});

	const res = await axios.post(`${DOMAIN_URL}${url}`, formParams.toString(), { ...config });
	return res;
}

/**
 * 서버에 데이터를 생성하거나 전송할 때 사용하는 페이지 네비게이션 POST 요청 함수입니다.
 *
 *
 * @param {string} url - 요청 URL 경로
 * @param {object | FormData} [params] - 요청에 함께 보낼 데이터
 * @param {AxiosRequestConfig} [config] - 옵션(헤더 설정 등)
 * @returns {Promise<CatResponse<T>>} 응답 데이터
 *
 * @example
 * sendPost('/posts', { title: 'New Post', content: 'This is content.' });
 */
export async function sendPostPageNavigation<T>(
	url: string,
	params?: { [key: string]: unknown },
	config?: AxiosRequestConfig,
): Promise<BizPagination<T>> {
	// 장고 서버는 x-www-form-urlencoded 형식을 사용하므로, 매개변수들을 URLSearchParams 형태로 변환한다.
	// 백엔드 페이지 네비게이션은 curr_page, order_col, number_per_page, order가 항상 있어야한다.
	if (params && typeof params.curr_page === 'undefined') {
		params.curr_page = 1;
	}
	if (params && typeof params.number_per_page === 'undefined') {
		params.number_per_page = 10;
	}
	if (params && typeof params.order_col === 'undefined') {
		params.order_col = -1;
	}

	if (params && typeof params.order === 'undefined') {
		params.order = 'desc';
	}

	const res = await axiosPost<BizPagination<T>>(url, params, config);
	return res.data;
}

export async function initSysCompanyConfigs() {
	const res = await sendPost<Record<string, string>>('/get_sysconfig_list_by_company/');
	return res;
}

/**
 * 리액트용 부서 리스트
 */
export async function getDeptList() {
	const res = await sendPost<Department[]>('/api/list-all-dept/', {});
	return res.data;
}

/**
 * 리액트용 유저 리스트
 */
export async function getUserList() {
	const res = await sendPost<User[]>('/api/list-all-user/', {});
	return res.data;
}

/**
 * 리액트용 라벨
 */
export async function getTagList() {
	const res = await sendPost<Tag[]>('/api/list-all-tag/', {});
	return res.data;
}

/**
 * 리액트용 파일 다운로드
 */
export async function sendGetWithFileDownload<T>(
	url: string,
	fileId: number,
	fileName: string,
	config?: AxiosRequestConfig,
): Promise<void> {
	try {
		// 서버에 파일 다운로드 요청을 보냅니다.
		const response = await axios.get(`${DOMAIN_URL}${url}`, {
			...config,
			responseType: 'blob', // 서버에서 파일로 응답을 받도록 설정
			params: {
				file_id: fileId, // 파일 ID를 쿼리 매개변수로 전달
			},
		});

		if (response.status === 200) {
			const blob = response.data;
			const downloadUrl = window.URL.createObjectURL(blob);
			const a = document.createElement('a');
			a.href = downloadUrl;
			a.download = fileName;
			document.body.appendChild(a);
			a.click();
			window.URL.revokeObjectURL(downloadUrl);
		} else {
			throw new Error(t('fail_download_file'));
		}
	} catch (error) {
		console.error(t('fail_download_file'), error);
		throw error;
	}
}

export async function sendPostGetMyTaskProcessStatus(taskId: number) {
	const res = await sendPost<TaskProcessStatusResponse>('/get_my_task_process_status/', {
		export_task_id: taskId,
	});
	return res.data;
}

export async function downloadExcelExportFile(taskId: number, fileName: string) {
	await sendGetWithFileDownload(`/get_my_excel/`, taskId, fileName);
}

export async function ExportExcelFileDownloadWithTask(taskId: number) {
	const checkAndInitiateDownload = () => {
		try {
			sendPostGetMyTaskProcessStatus(taskId).then(res => {
				const downloadEnabled = res.download_enabled;
				if (downloadEnabled) {
					downloadExcelExportFile(taskId, res.name).catch(error => {
						throw new Error(t('fail_download_file'));
					});
				} else {
					setTimeout(checkAndInitiateDownload, 1000);
				}
			});
		} catch (error) {
			console.error('Error checking task status: ', error);
			throw error;
		}
	};
	setTimeout(checkAndInitiateDownload, 300);
}
