import { Viewer as PDF, ViewerProps, Worker } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { useEffect, useMemo } from 'react';
import DefaultToolbar from './DefaultToolbar';

import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import { DOMAIN_URL } from '../../../constants/common';
import { jumpToPagePlugin } from '../../../store/pdf/jumpToPagePlugin';

type ViewerPropsOverrides = ViewerProps & {
	goToPageIndex?: number | null;
};

export default function Viewer({ goToPageIndex = null, ...props }: ViewerPropsOverrides) {
	const excludeTabsPlugins = useMemo(() => ['Attachment', 'Bookmark'], []);

	const layoutPluginInstance = defaultLayoutPlugin({
		renderToolbar: DefaultToolbar,
		sidebarTabs: defaultTabs => defaultTabs.filter(({ title }) => !excludeTabsPlugins.includes(title)),
	});

	const jumpToPagePluginInstance = jumpToPagePlugin();
	const { jumpToPage } = jumpToPagePluginInstance;

	useEffect(() => {
		if (goToPageIndex == null) return;
		jumpToPage(goToPageIndex);
	}, [goToPageIndex]);

	return (
		<section className='h-[125vh]'>
			<Worker
				workerUrl={
					process.env.NODE_ENV === 'development'
						? '/js/pdf.worker.js'
						: `${DOMAIN_URL}/static/adele/scripts/pdf.worker.js`
				}
			>
				<PDF plugins={[layoutPluginInstance, jumpToPagePluginInstance]} {...props} />
			</Worker>
		</section>
	);
}
