import { Table, TableContainer, TableCell, TableBody, Skeleton, TableRow } from '@mui/material';
import _ from 'lodash';
import CardBox from '../containers/CardBox';
import TableFooter from './TableFooter';

type Props = {
	isHeader?: boolean;
	colSpan: number;
};
export default function TableSkeleton({ isHeader = true, colSpan }: Props) {
	return (
		<section className='flex flex-col gap-3'>
			{isHeader && (
				<header className='flex w-full gap-5'>
					<div className='basis-6/12'>
						<Skeleton height={50} variant='rounded' animation='wave' />
					</div>
					<div className='basis-6/12'>
						<Skeleton height={50} variant='rounded' animation='wave' />
					</div>
				</header>
			)}

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible'>
					<Table size='medium'>
						<TableBody>
							{_.chain([])
								.range(10)
								.map(item => (
									<TableRow key={item}>
										<TableCell colSpan={colSpan} classes={{ root: 'text-center' }}>
											<Skeleton variant='rectangular' animation='wave' />
										</TableCell>
									</TableRow>
								))
								.value()}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter count={0} onPageChange={() => {}} page={0} rowsPerPage={10} />
			</CardBox>
		</section>
	);
}
