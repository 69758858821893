import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { ReactNode } from 'react';

type Props = {
	children: ReactNode;
};
export default function SignProvider({ children }: Props) {
	return <DndProvider backend={HTML5Backend}>{children}</DndProvider>;
}
