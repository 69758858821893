import { MouseEvent, ChangeEvent } from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableSortLabel from '@mui/material/TableSortLabel';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import { visuallyHidden } from '@mui/utils';
import { PaperManageDetailTableRow } from '../../../model/paperManage';
import { HeadCell, Order } from '../../../model/table';

type Props = {
	headCells: readonly HeadCell<PaperManageDetailTableRow>[];
	listType?: 'checkbox' | 'radio';
	numSelected: number;
	onRequestSort: (event: MouseEvent<unknown>, property: keyof PaperManageDetailTableRow) => void;
	onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
	order: Order;
	orderBy: string;
	rowCount: number;
};

export default function PaperManageDetailListHeader({
	listType,
	onSelectAllClick,
	order,
	orderBy,
	numSelected,
	rowCount,
	onRequestSort,
	headCells,
}: Props) {
	const createSortHandler = (property: keyof PaperManageDetailTableRow) => (e: MouseEvent<unknown>) => {
		onRequestSort(e, property);
	};

	return (
		<TableHead>
			<TableRow>
				{listType === 'checkbox' && (
					<TableCell padding='checkbox'>
						<Checkbox
							color='primary'
							indeterminate={numSelected > 0 && numSelected < rowCount}
							checked={rowCount > 0 && numSelected === rowCount}
							onChange={onSelectAllClick}
							inputProps={{
								'aria-label': 'select all',
							}}
						/>
					</TableCell>
				)}
				{headCells.map(headCell => (
					<TableCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell?.id ? order : false}
						classes={{ head: `${headCell.width || ''}` }}
					>
						<TableSortLabel
							active={orderBy === headCell?.id}
							direction={orderBy === headCell?.id ? order : 'asc'}
							onClick={createSortHandler(headCell?.id)}
						>
							{headCell?.label}
							{orderBy === headCell?.id ? (
								<Box component='span' sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
