import { useNavigate } from 'react-router-dom';
import { doaBackdo } from '../../../../api/login';
import ButtonText from '../../../ui/buttons/ButtonText';
import PopupCommonInternal from '../../../ui/popups/PopupCommonInternal';

export default function DoaUserOption() {
	const navigate = useNavigate();

	return (
		<PopupCommonInternal
			openTrigger={
				<ButtonText className='px-7 py-4 text-base text-gray-950 font-bold'>내 계정으로 전환</ButtonText>
			}
			msg='내 계정으로 돌아가시겠습니까?'
			confirmClose={false}
			onConfirm={() => {
				doaBackdo({ navigate });
			}}
			onCancel={() => false}
		/>
	);
}
