import { ChangeEvent } from 'react';
import { Checkbox, TableCell, TableHead, TableRow } from '@mui/material';
import { HardDelete } from '../../model/hardDelete';
import { HeadCell } from '../../model/table';

type Props = {
	headCells: HeadCell<HardDelete>[];
	numSelected: number;
	onSelectAllClick: (event: ChangeEvent<HTMLInputElement>) => void;
	rowCount: number;
};

export default function HardDeleteHeader({ onSelectAllClick, numSelected, headCells, rowCount }: Props) {
	return (
		<TableHead>
			<TableRow>
				<TableCell padding='checkbox'>
					<Checkbox
						color='primary'
						indeterminate={numSelected > 0 && numSelected < rowCount}
						checked={rowCount > 0 && numSelected === rowCount}
						onChange={onSelectAllClick}
						inputProps={{
							'aria-label': 'select all',
						}}
					/>
				</TableCell>
				{headCells.map(headCell => (
					<TableCell
						key={headCell?.id}
						align={headCell?.align || 'left'}
						padding={headCell?.disablePadding ? 'none' : 'normal'}
					>
						{headCell?.label}
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}
