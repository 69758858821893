import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { getNotificationCount, getNotificationList } from '../../../api/notification';
import { queryKey } from '../../../constants/queryKey';
import { BizNotification } from '../../../model/api/bizNotification';
import { getTodayFormatToHour } from '../../../util/dateFormat';
import ButtonText from '../../ui/buttons/ButtonText';

export default function AlarmDisplayDashboard() {
	const [notifyGroup, setNotifyGroup] = useState<_.Dictionary<BizNotification[]>>();
	useQuery(queryKey.notificationCount, getNotificationCount);
	const { data } = useQuery(queryKey.notification, getNotificationList);

	useEffect(() => {
		if (data) {
			const result = _.groupBy(data, notification => notification.action);
			setNotifyGroup(result);
		}
	}, [data]);

	const handleNavigate = (link: string) => {
		window.location.href = `http://${link}`;
	};

	return (
		<ul className='overflow-y-auto scrollbar flex flex-col max-h-[70vh] pb-10'>
			{notifyGroup &&
				Object.keys(notifyGroup).map(key => (
					<li key={key} className='pt-10'>
						<h3 className='px-6 font-bold'>
							{key} <span className='text-blue-600'>{notifyGroup[key].length}</span>
						</h3>
						<div>
							<ul>
								{notifyGroup[key].map(notification => (
									<li key={notification.id} className='border-b border-neutral-300'>
										<ButtonText
											className='w-full p-6 rounded-none'
											onClick={() => handleNavigate(notification.link)}
										>
											<div className='flex flex-col gap-1 w-full'>
												<p className='text-left text-neutral-600'>{notification.description}</p>
												<p className='text-left text-sm text-neutral-500'>
													{getTodayFormatToHour(notification.create_time)}
												</p>
												<p className='text-left text-sm text-neutral-400 font-bold'>
													<span className='text-cyan-600'>{notification.app_code_name}</span>{' '}
													{notification.title}
												</p>
											</div>
										</ButtonText>
									</li>
								))}
							</ul>
						</div>
					</li>
				))}
		</ul>
	);
}
