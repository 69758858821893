import { InputLabel } from '@mui/material';
import { useState } from 'react';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { dateFormat } from '../../../util/dateFormat';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';
import InputCheckbox from '../../ui/inputs/InputCheckbox';
import InputDate from '../../ui/inputs/InputDate';
import InputRadio from '../../ui/inputs/InputRadio';
import InputText from '../../ui/inputs/InputText';
import PopupBack from './PopupBack';

export default function Header() {
	const [open, setOpen] = useState(false);
	const formik = useValidateForm({
		validationSchema: {
			search: { initialValue: '', type: 'string' },
			sorting: { initialValue: 'accuracy', type: 'string' },
			startDate: { initialValue: dateFormat().format('YYYY.MM.DD'), type: 'string' },
			endDate: { initialValue: dateFormat().format('YYYY.MM.DD'), type: 'string' },
			isAll: { initialValue: false, type: 'boolean' },
			isTitle: { initialValue: false, type: 'boolean' },
			isDescription: { initialValue: false, type: 'boolean' },
			isFile: { initialValue: false, type: 'boolean' },
		},
	});

	const handleClick = async () => {
		setOpen(false);
	};

	return (
		<header className='grid grid-cols-6 gap-6'>
			<section className='col-span-1'>
				<PopupBack
					open={open}
					setOpen={setOpen}
					msg={<p>검색 결과가 초기화 됩니다.</p>}
					onConfirm={handleClick}
				/>
				<h2 className='text-xl font-bold mt-2'>A 거래처</h2>
			</section>
			<section className='col-span-5 grid gap-5'>
				<CardBox className='grid grid-cols-5 gap-8 p-8'>
					<article className='col-span-2 flex items-center gap-3'>
						<InputLabel className='shrink-0'>기간</InputLabel>
						<InputDate id='startDate' label='시작' size='small' format='YYYY.MM.DD' formik={formik} />
						<span>-</span>
						<InputDate id='endDate' label='종료' size='small' format='YYYY.MM.DD' formik={formik} />
						<ButtonText text='적용' variant='outlined' />
					</article>

					<article className='col-span-3 flex items-center gap-3'>
						<InputText id='search' size='small' formik={formik} />
						<ButtonText className='shrink-0' text='결과 내 재 검색' variant='outlined' />
					</article>
					<article className='col-span-5'>
						<article className='flex items-center gap-2'>
							<InputCheckbox id='isAll' label='전체' formik={formik} />
							<InputCheckbox id='isTitle' label='제목' formik={formik} />
							<InputCheckbox id='isDescription' label='내용' formik={formik} />
							<InputCheckbox id='isFile' label='첨부파일' formik={formik} />
							<InputRadio
								id='accuracy'
								name='sorting'
								value='accuracy'
								label='정확도순'
								required
								formik={formik}
							/>
							<InputRadio
								id='recent'
								name='sorting'
								value='recent'
								label='최신순'
								required
								formik={formik}
							/>
						</article>
					</article>
				</CardBox>
				<div className='flex items-center gap-2'>
					<p className='text-xl font-bold'>“A거래처”</p>
					<p className='text-xl'>제목 검색결과 7건</p>
				</div>
			</section>
		</header>
	);
}
