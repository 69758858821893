import React from 'react';
import PopupDefault, { ModalSize } from './PopupDefault';
import ButtonLoading from '../buttons/ButtonLoading';

type ColorProps = {
	confirm?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
	cancel?: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
};

export type PopupCommonExternalProps = {
	open: boolean;
	msg: string | React.ReactNode;
	icon?: React.ReactNode;
	size?: ModalSize;
	externalClickClose?: boolean;
	confirmClose?: boolean;
	onClose?: () => void;
	onConfirm?: boolean | (() => void);
	onCancel?: boolean | (() => void);
	color?: ColorProps;
	loading?: boolean;
};

const defaultColor: ColorProps = {
	confirm: 'primary',
	cancel: 'inherit',
};

export default function PopupCommonExternal({
	open,
	loading,
	msg,
	icon,
	size,
	externalClickClose = true,
	confirmClose = false,
	onClose,
	onConfirm,
	onCancel,
	color: providedColor,
}: PopupCommonExternalProps) {
	const handleButtonClick = async (action: typeof onConfirm, isCloseTrigger: boolean) => {
		if (typeof action === 'function') action();

		if (isCloseTrigger && onClose) onClose();
	};

	const color = {
		...defaultColor,
		...providedColor,
	};

	const getButton = (
		text: typeof msg,
		customColor: ColorProps['confirm' | 'cancel'],
		action: typeof onConfirm,
		buttonClose: boolean,
	) => (
		<ButtonLoading
			loading={loading}
			text={text}
			variant='contained'
			color={customColor}
			onClick={() => handleButtonClick(action, buttonClose)}
		/>
	);

	return (
		<PopupDefault open={open} size={size} onClose={onClose} externalClickClose={externalClickClose}>
			<div className='flex flex-col gap-5'>
				{icon && <div className='text-center'>{icon}</div>}
				<div className='text-center'>{msg}</div>
				<div className='flex justify-center space-x-6'>
					{onCancel && getButton('취소', color.cancel, onCancel, true)}
					{onConfirm && getButton('확인', color.confirm, onConfirm, confirmClose)}
				</div>
			</div>
		</PopupDefault>
	);
}
