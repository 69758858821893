/* eslint-disable import/no-unresolved */
// eslint-disable-next-line import/no-unresolved
import { uniqueId } from 'lodash';
import { Children, ReactNode } from 'react';
import 'swiper/css';
import { Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { SwiperOptions } from 'swiper/types';

type SwiperOptionsOverrides = SwiperOptions & { classes?: { root?: string; slide?: string }; children: ReactNode };

export default function Slide({ ...Props }: SwiperOptionsOverrides) {
	const {
		loop = true,
		speed = 1000,
		touchRatio = 0.4,
		spaceBetween = 30,
		autoplay = {
			delay: 2000,
			pauseOnMouseEnter: true,
		},
		classes = { root: undefined, slide: undefined },
		children,
	} = Props;
	const { root = 'w-full h-full', slide = 'flex items-center' } = classes;

	return (
		<Swiper
			className={root}
			modules={[Autoplay]}
			loop={loop}
			speed={speed}
			touchRatio={touchRatio}
			spaceBetween={spaceBetween}
			autoplay={autoplay}
			{...Props}
		>
			{Children.map(children, child => (
				<SwiperSlide key={uniqueId('swiper-slide')} className={slide}>
					{child}
				</SwiperSlide>
			))}
		</Swiper>
	);
}
