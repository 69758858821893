import ButtonFront from '../ui/buttons/ButtonFront';
import FileChip from '../ui/chips/FileChip';
import LabelChip from '../ui/chips/LabelChip';
import Highlight from '../ui/highlights/Highlight';
import ContractIcon from '../ui/icons/apps/custom/sk/ContractIcon';
import ItemSearchField from './ItemSearchField';
import PopupPreviewComment from './PopupPreviewComment';
import PopupPreviewFile from './PopupPreviewFile';

interface IProps {
	value: number;
}

export default function TabPanel({ value }: IProps) {
	return (
		<section>
			<ul className='[&>li]:pt-12 [&>li:first-of-type]:pt-0'>
				<li className='grid grid-cols-10 pb-6 border-b'>
					<p className='font-bold'>계약 {value}</p>
					<ul className='col-span-9 grid gap-12 [&>li]:pb-12 [&>li:last-of-type]:pb-0 [&>li]:border-b [&>li:last-child]:border-none'>
						<li className='grid gap-6'>
							<section>
								<div className='flex items-center gap-2'>
									<ContractIcon className='text-neutral-500' />
									<p className='text-lg font-bold'>
										<Highlight text='계약서 계약명' pattern='계약' />
									</p>
								</div>
							</section>
							<section className='grid grid-cols-4 gap-6'>
								<ItemSearchField title='관리회사'>
									<p>
										<Highlight text='계약 회사명' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='법무분야'>
									<div className='flex flex-wrap items-center gap-1'>
										<LabelChip
											id='label-1'
											disabled
											tag={{
												id: 1,
												name: <Highlight text='계약 회사 라벨A' pattern='계약' />,
												value: '라벨명1',
												color: 'rgb(254,166,109)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 1,
											}}
										/>
										<LabelChip
											id='label-2'
											disabled
											tag={{
												id: 2,
												name: <Highlight text='계약 회사 라벨B' pattern='계약' />,
												value: '라벨명2',
												color: 'rgb(245,193,114)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 2,
											}}
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField title='카테고리'>
									<p>
										<Highlight text='계약 회사 카테고리' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='외부 변호사'>
									<p>
										<Highlight text='홍길동 / hong@buptle.com' pattern='hong' />
									</p>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='사실관계' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='요청사항' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='계약서'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='진행상황톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='진행상황 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='검토부서 전용 톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='검토부서 전용 톡 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField
									className='col-span-4 items-start'
									title='스마트이메일'
									onClick={() => {}}
								>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='스마트이메일 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>
							</section>
						</li>
						<li className='grid gap-6'>
							<section>
								<div className='flex items-center gap-2'>
									<ContractIcon className='text-neutral-500' />
									<p className='text-lg font-bold'>
										<Highlight text='계약서 계약명' pattern='계약' />
									</p>
								</div>
							</section>
							<section className='grid grid-cols-4 gap-6'>
								<ItemSearchField title='관리회사'>
									<p>
										<Highlight text='계약 회사명' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='법무분야'>
									<div className='flex flex-wrap items-center gap-1'>
										<LabelChip
											id='label-1'
											disabled
											tag={{
												id: 1,
												name: <Highlight text='계약 회사 라벨A' pattern='계약' />,
												value: '라벨명1',
												color: 'rgb(254,166,109)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 1,
											}}
										/>
										<LabelChip
											id='label-2'
											disabled
											tag={{
												id: 2,
												name: <Highlight text='계약 회사 라벨B' pattern='계약' />,
												value: '라벨명2',
												color: 'rgb(245,193,114)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 2,
											}}
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField title='카테고리'>
									<p>
										<Highlight text='계약 회사 카테고리' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='외부 변호사'>
									<p>
										<Highlight text='홍길동 / hong@buptle.com' pattern='hong' />
									</p>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='사실관계' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='요청사항' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='계약서'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='진행상황톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='진행상황 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='검토부서 전용 톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='검토부서 전용 톡 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField
									className='col-span-4 items-start'
									title='스마트이메일'
									onClick={() => {}}
								>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='스마트이메일 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>
							</section>
						</li>
					</ul>
					<div className='col-span-10 flex justify-end pt-12'>
						<ButtonFront text='더보기' />
					</div>
				</li>
				<li className='grid grid-cols-10 pb-6'>
					<p className='font-bold'>자문</p>
					<ul className='col-span-9 grid gap-12 [&>li]:pb-12 [&>li:last-of-type]:pb-0 [&>li]:border-b [&>li:last-child]:border-none'>
						<li className='grid gap-6'>
							<section>
								<div className='flex items-center gap-2'>
									<ContractIcon className='text-neutral-500' />
									<p className='text-lg font-bold'>
										<Highlight text='계약서 계약명' pattern='계약' />
									</p>
								</div>
							</section>
							<section className='grid grid-cols-4 gap-6'>
								<ItemSearchField title='관리회사'>
									<p>
										<Highlight text='계약 회사명' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='법무분야'>
									<div className='flex flex-wrap items-center gap-1'>
										<LabelChip
											id='label-1'
											disabled
											tag={{
												id: 1,
												name: <Highlight text='계약 회사 라벨A' pattern='계약' />,
												value: '라벨명1',
												color: 'rgb(254,166,109)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 1,
											}}
										/>
										<LabelChip
											id='label-2'
											disabled
											tag={{
												id: 2,
												name: <Highlight text='계약 회사 라벨B' pattern='계약' />,
												value: '라벨명2',
												color: 'rgb(245,193,114)',
												type: 'public',
												extended_type: 'DEFAULT',
												creator_id: 2,
											}}
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField title='카테고리'>
									<p>
										<Highlight text='계약 회사 카테고리' pattern='계약' />
									</p>
								</ItemSearchField>

								<ItemSearchField title='외부 변호사'>
									<p>
										<Highlight text='홍길동 / hong@buptle.com' pattern='hong' />
									</p>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='사실관계' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='요청사항' onClick={() => {}}>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='계약서'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 A' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='진행상황톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='진행상황 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip text={<Highlight text='계약서 B' pattern='계약' />} disabled />
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-start' title='검토부서 전용 톡'>
									<ul className='flex-1 grid grid-cols-2 gap-8'>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
										<li className='flex items-start gap-2 px-4 py-3 rounded-lg bg-zinc-100'>
											<div className='flex-1'>
												<Highlight
													text='A거래처 의 9퍼센트 참가한 정책부터 심으라 알다. 수 및 동원하고 임박한 정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다정권이다'
													pattern='거래처'
												/>
											</div>
											<PopupPreviewComment />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='검토부서 전용 톡 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>

								<ItemSearchField
									className='col-span-4 items-start'
									title='스마트이메일'
									onClick={() => {}}
								>
									<div className='flex-1'>
										<Highlight
											text='전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고 어렵다 금융이 특히 고려하다. A거래처 전망으로 끼치자 재무는 않을 광고 정책인데 단체가, 기자와 확정의 대법관은 찾으느냐. 서서히 기인한 찬연하여 출신이 당초에, 살아간 잘 대일이 하다. 활용하다 아파트로 없고..'
											pattern='거래처'
										/>
									</div>
								</ItemSearchField>

								<ItemSearchField className='col-span-4 items-center' title='스마트이메일 첨부파일'>
									<ul className='flex-1 flex flex-wrap items-center gap-2'>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 AAAAAA' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
										<li className='flex gap-2'>
											<FileChip
												text={<Highlight text='계약서 BBBBB' pattern='계약' />}
												disabled
											/>
											<PopupPreviewFile />
										</li>
									</ul>
								</ItemSearchField>
							</section>
						</li>
					</ul>
					<div className='col-span-10 flex justify-end pt-12'>
						<ButtonFront text='더보기' />
					</div>
				</li>
			</ul>
		</section>
	);
}
