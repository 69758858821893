import { Checkbox, Chip, Table, TableBody, TableCell, TableContainer, TableRow } from '@mui/material';
import dayjs from 'dayjs';
import { FormikValues } from 'formik';
import _, { uniqueId } from 'lodash';
import { ChangeEvent, MouseEvent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAlert } from '../../hooks/useAlert';
import useHardDelete from '../../hooks/useHardDelete';
import { useValidateForm } from '../../hooks/useValidateForm';
import { LegalType } from '../../model/common';
import { Epic } from '../../model/epic';
import { HardDelete } from '../../model/hardDelete';
import { HeadCell } from '../../model/table';
import { Tag } from '../../model/tag';
import { Department } from '../../model/user';
import SysConfigStore from '../../store/common/SysConfigStore';
import { getLegalLinkMap, openToAdele } from '../../util/commonUtils';
import { dateFormat } from '../../util/dateFormat';
import SubsidiarySelector from '../common/SubsidiarySelector';
import PopupEpic from '../epic/PopupEpic';
import PopupDepts from '../main/department/PopupDepts';
import ButtonLoading from '../ui/buttons/ButtonLoading';
import ButtonText from '../ui/buttons/ButtonText';
import LabelChip from '../ui/chips/LabelChip';
import CardBox from '../ui/containers/CardBox';
import ResetIcon from '../ui/icons/ResetIcon';
import SearchIcon from '../ui/icons/SearchIcon';
import InputDate from '../ui/inputs/InputDate';
import InputSelect from '../ui/inputs/InputSelect';
import InputText from '../ui/inputs/InputText';
import PopupHandleBtns from '../ui/popups/PopupHandleBtns';
import TableSearchBar from '../ui/tables/TableSearchBar';
import HardDeleteHeader from './HardDeleteHeader';
import InputTags from './InputTags';

type Props = {
	formikId: string;
	list: HardDelete[];
	headCells: HeadCell<HardDelete>[];
	selectedFormik: FormikValues;
};

export default function HardDeleteList({ formikId, list, headCells, selectedFormik }: Props) {
	const { t } = useTranslation();
	const [selected, setSelected] = useState<readonly string[]>([]);
	const [snackbar] = useAlert();
	const { postHardDelete } = useHardDelete();
	const [delMsg, setDelMsg] = useState('');
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [isPopupBtnLoading, setIsPopupBtnLoading] = useState(false);
	const [isShowPopupCloseBtn, setIsShowPopupCloseBtn] = useState(false);
	const { labelText } = SysConfigStore();

	useEffect(() => {
		setDelMsg(
			`${t('hard_delete_alert_msg_1').replace('{{selected_length}}', String(selected.length))}\n${t(
				'hard_delete_alert_msg_2',
			)}\n${t('hard_delete_alert_msg_3')} `,
		);
	}, [selected]);

	const isdeletedSelectOptions = [
		{ name: t('cmmn_label_activation'), value: 'false' },
		{ name: t('cmmn_label_disable'), value: 'true' },
	];

	const appTypeSelectOptions = [
		{ name: t('MSG_ID_5'), value: 'CONTRACT' },
		{ name: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')), value: 'CONTRACT_ETC' },
		{ name: t('counsel'), value: 'COUNSEL' },
		{ name: t('MSG_dispute_and_case'), value: 'LAWSUIT' },
	];

	const formikTableSearch = useValidateForm({
		validationSchema: {
			table_name_input: { initialValue: '', type: 'string' },
			table_dept_input: { initialValue: '', type: 'string' },
			isdeleted_hard_delete: { initialValue: isdeletedSelectOptions.map(option => option.value), type: 'array' },
			subsidiary_hard_delete: { initialValue: -1, type: 'number' },
			app_type_hard_delete: { initialValue: '', type: 'string' },
			title_hard_delete: { initialValue: '', type: 'string' },
			search_date_start_hard_delete: { initialValue: dateFormat().format('YYYY/MM/DD'), type: 'string' },
			search_date_end_hard_delete: { initialValue: dateFormat().format('YYYY/MM/DD'), type: 'string' },
			dept_hard_delete: { initialValue: [], type: 'array' },
			epic_hard_delete: { initialValue: [], type: 'array' },
			tags_hard_delete: { initialValue: [], type: 'array' },
		},
	});

	const handleClick = (e: MouseEvent<unknown>, id: string) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) newSelected = _.concat(selected, id);
		else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.html_id)),
		);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	// 현재 page 와 filter 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		let resultArr = _.chain(list).sortBy('create_time').reverse();

		if (formikTableSearch.values.isdeleted_hard_delete.length === 1)
			resultArr = resultArr.filter(
				item => String(item.isdeleted).indexOf(formikTableSearch.values.isdeleted_hard_delete) !== -1,
			);
		if (formikTableSearch.values.subsidiary_hard_delete !== -1)
			resultArr = resultArr.filter(item => item?.company?.id === formikTableSearch.values.subsidiary_hard_delete);
		if (formikTableSearch.values.app_type_hard_delete)
			resultArr = resultArr.filter(item => item.legal_type === formikTableSearch.values.app_type_hard_delete);
		if (formikTableSearch.values.title_hard_delete)
			resultArr = resultArr.filter(item => item.title.indexOf(formikTableSearch.values.title_hard_delete) !== -1);
		if (formikTableSearch.values.search_date_start_hard_delete)
			resultArr = resultArr.filter(item =>
				dayjs(formikTableSearch.values.search_date_start_hard_delete)
					.subtract(1, 'day')
					.isBefore(dayjs(item.create_time)),
			);
		if (formikTableSearch.values.search_date_end_hard_delete)
			resultArr = resultArr.filter(item =>
				dayjs(formikTableSearch.values.search_date_end_hard_delete)
					.add(1, 'day')
					.isAfter(dayjs(item.create_time)),
			);
		if (formikTableSearch.values.dept_hard_delete.length)
			resultArr = resultArr.filter(item =>
				formikTableSearch.values.dept_hard_delete
					.map((dept: Department) => dept.id)
					.includes(item?.dept_info?.id),
			);
		if (formikTableSearch.values.epic_hard_delete.length)
			resultArr = resultArr.filter(item =>
				formikTableSearch.values.epic_hard_delete.map((epic: Epic) => epic.id).includes(item?.epic?.id),
			);
		if (formikTableSearch.values.tags_hard_delete.length)
			resultArr = resultArr.filter(item => {
				const itemIds = item?.tags_info?.map(tag => tag.id);
				if (itemIds) {
					return itemIds.some(itemId => formikTableSearch.values.tags_hard_delete.includes(itemId));
				}
				return false;
			});

		return {
			count: resultArr.value().length,
			rows: resultArr.value(),
		};
	}, [
		list,
		formikTableSearch.values.table_name_input,
		formikTableSearch.values.table_dept_input,
		formikTableSearch.values.isdeleted_hard_delete,
		formikTableSearch.values.subsidiary_hard_delete,
		formikTableSearch.values.app_type_hard_delete,
		formikTableSearch.values.title_hard_delete,
		formikTableSearch.values.search_date_start_hard_delete,
		formikTableSearch.values.search_date_end_hard_delete,
		formikTableSearch.values.dept_hard_delete,
		formikTableSearch.values.epic_hard_delete,
		formikTableSearch.values.tags_hard_delete,
	]);

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = visibleRows.rows.map(n => n.html_id);
			setSelected(newSelected);
			selectedFormik.setFieldValue(formikId, list);
			return;
		}
		setSelected([]);
		selectedFormik.setFieldValue(formikId, []);
	};

	const handleNavigate = async (id: number, type: LegalType) => {
		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			openToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};

	const openTrigger = (
		<ButtonLoading loading={false} text={t('hard_delete_action_btn')} color='error' variant='contained' />
	);
	const handleConfirm = () => {
		setIsPopupBtnLoading(true);
		setDelMsg(t('hard_delete_ing_msg'));
		postHardDelete(selected, {
			onSuccess: () => {
				setIsPopupBtnLoading(false);
				setIsPopupOpen(false);
				setSelected([]);
			},
			onError: () => {
				setDelMsg(t('hard_delete_error_msg'));
				setIsPopupBtnLoading(false);
				setIsShowPopupCloseBtn(true);
			},
		});
	};
	const popupCloseEvent = () => {
		setDelMsg(
			`${t('hard_delete_alert_msg_1').replace('{{selected_length}}', String(selected.length))}\n${t(
				'hard_delete_alert_msg_2',
			)}\n${t('hard_delete_alert_msg_3')} `,
		);
		setIsPopupOpen(false);
		setIsShowPopupCloseBtn(false);
	};

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar numSelected={selected.length}>
				<div className='flex items-start gap-5 w-full'>
					<div className='basis-4/6 grid grid-cols-4 gap-5'>
						<InputSelect
							multiple
							id='isdeleted_hard_delete'
							options={isdeletedSelectOptions}
							formik={formikTableSearch}
							allowEmptyCheck={false}
							placeholder={t('is_enable')}
							label={t('is_enable')}
						/>

						<SubsidiarySelector formikId='subsidiary_hard_delete' formik={formikTableSearch} />

						<InputSelect
							id='app_type_hard_delete'
							options={appTypeSelectOptions}
							formik={formikTableSearch}
							placeholder={t('legal_app_type')}
							label={t('legal_app_type')}
						/>

						<PopupEpic id='epic_hard_delete' formik={formikTableSearch} />

						<InputTags
							id='tags_hard_delete'
							label={t('cmmn_tags_legal_extended_type')}
							formik={formikTableSearch}
							extendedType='LEGAL_FIELD'
						/>

						<PopupDepts id='dept_hard_delete' formik={formikTableSearch} />

						<div className='col-span-2 flex items-center gap-2'>
							<InputDate
								id='search_date_start_hard_delete'
								label={t('search_start_day')}
								format='YYYY.MM.DD (ddd)'
								formik={formikTableSearch}
								shouldDisableDate={date =>
									date > dayjs(formikTableSearch.values.search_date_end_hard_delete)
								}
							/>
							<span>~</span>
							<InputDate
								id='search_date_end_hard_delete'
								label={t('search_end_day')}
								format='YYYY.MM.DD (ddd)'
								formik={formikTableSearch}
								shouldDisableDate={date =>
									date < dayjs(formikTableSearch.values.search_date_start_hard_delete)
								}
							/>
						</div>
					</div>
					<div className='basis-2/6 flex items-center gap-5'>
						<div className='flex-auto'>
							<InputText
								id='title_hard_delete'
								icon={<SearchIcon />}
								labelText={t('app_name')}
								formik={formikTableSearch}
							/>
						</div>
						<div>
							<ButtonText
								text={t('reset_search')}
								color='warning'
								variant='contained'
								onClick={() => {
									formikTableSearch.resetForm();
								}}
							>
								<ResetIcon />
							</ButtonText>
						</div>
					</div>
				</div>
			</TableSearchBar>

			<div className='flex items-center justify-end'>
				{selected.length > 0 && (
					<PopupHandleBtns
						openTrigger={openTrigger}
						isOpen={isPopupOpen}
						isLoading={isPopupBtnLoading}
						onClick={() => setIsPopupOpen(true)}
						onClose={() => setIsPopupOpen(false)}
						onConfirm={handleConfirm}
						msg={delMsg}
						icon={t('hard_delete_icon_msg')}
						externalClickClose={false}
						isShowCloseBtn={isShowPopupCloseBtn}
						popupCloseEvent={popupCloseEvent}
						size='xsmall'
					/>
				)}
			</div>

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible' style={{ minHeight: (headCells.length + 1) * 53 }}>
					<Table size='medium' stickyHeader>
						<HardDeleteHeader
							headCells={headCells}
							numSelected={selected.length}
							onSelectAllClick={handleSelectAllClick}
							rowCount={visibleRows.rows.length}
						/>
						<TableBody>
							{visibleRows.rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
										{t('label_no_search_results_exist')}
									</TableCell>
								</TableRow>
							)}
							{visibleRows.rows.length > 0 ? (
								visibleRows.rows.map((row, index) => {
									const isItemSelected = isSelected(row.html_id);
									const labelId = `enhanced-table-checkbox-${index}`;
									const compList = row.comp_info ? row.comp_info?.map(comp => comp.title) : [];
									const prsnList = row.prsn_info ? row.prsn_info?.map(prsn => prsn.title) : [];
									const opposite = [...compList, ...prsnList];

									return (
										<TableRow
											hover
											onClick={event => handleClick(event, row.html_id)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={uniqueId(`${row.html_id}-`)}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											<TableCell padding='checkbox'>
												<Checkbox
													id={labelId}
													color='primary'
													checked={isItemSelected}
													inputProps={{
														'aria-labelledby': labelId,
													}}
												/>
											</TableCell>
											<TableCell padding='none'>{index + 1}</TableCell>
											<TableCell align='left'>{row.isdeleted ? '비활성' : '활성'}</TableCell>
											<TableCell align='left'>{row.company?.name}</TableCell>
											<TableCell align='left'>{row.app_type}</TableCell>
											<TableCell align='left'>{row.epic?.name}</TableCell>
											<TableCell align='left'>{row.title}</TableCell>
											<TableCell align='left'>
												<div className='flex flex-wrap items-center gap-1'>
													{row.tags_info?.map((tag: Tag) => (
														<LabelChip key={tag.id} id={tag.id} tag={tag} disabled />
													))}
												</div>
											</TableCell>
											<TableCell align='left'>
												<div className='flex flex-wrap items-center gap-1'>
													{opposite.map(item => (
														<Chip key={item} label={item} />
													))}
												</div>
											</TableCell>
											<TableCell align='left'>{row.lawsuit_case_num}</TableCell>
											<TableCell align='left'>{row.dept_info?.name}</TableCell>
											<TableCell align='left'>{row.creator}</TableCell>
											<TableCell align='left'>{row.create_time}</TableCell>
											<TableCell align='left'>
												{!row.isdeleted && (
													<ButtonText
														classes={{
															root: 'min-w-0',
														}}
														text={t('go_to_app_detail')}
														size='small'
														variant='outlined'
														onClick={e => {
															e?.stopPropagation();
															const appId = row.html_id.split('|')[1];
															handleNavigate(Number(appId), row.legal_type);
														}}
													/>
												)}
											</TableCell>
										</TableRow>
									);
								})
							) : (
								<TableRow
									style={{
										height: 53 * (headCells.length + 1),
									}}
								>
									<TableCell colSpan={headCells.length + 1} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
			</CardBox>
		</section>
	);
}
