import Checkbox from '@mui/material/Checkbox';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import { FormikValues } from 'formik';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { HeadCell, Order } from '../../../model/table';
import { Department } from '../../../model/user';
import SysConfigStore from '../../../store/common/SysConfigStore';
import SubsidiarySelector from '../../common/SubsidiarySelector';
import CardBox from '../../ui/containers/CardBox';
import SearchIcon from '../../ui/icons/SearchIcon';
import InputText from '../../ui/inputs/InputText';
import TableFooter from '../../ui/tables/TableFooter';
import TableSearchBar from '../../ui/tables/TableSearchBar';
import TableDeptHeader from './TableDeptHeader';

type Props = {
	id: string;
	listType?: 'checkbox' | 'radio';
	list: Department[];
	orderBy: keyof Department;
	headCells: readonly HeadCell<Department>[];
	formik: FormikValues;
	selectedFormik: FormikValues;
};

export default function TableDeptList({
	id: formikId,
	listType,
	list,
	orderBy: ob = 'name',
	headCells,
	formik,
	selectedFormik,
}: Props) {
	const { t } = useTranslation();
	const { isComponentEnabled } = SysConfigStore();
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState(ob);
	const [selected, setSelected] = useState<readonly number[]>(
		formik.values[formikId].map((item: Department) => item.id),
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const formikTableSearch = useValidateForm({
		validationSchema: {
			table_dept_name_input: { initialValue: '', type: 'string' },
			table_dept_no_input: { initialValue: '', type: 'string' },
			table_subsidiary_select: { initialValue: -1, type: 'number' },
		},
	});

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof Department) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			const newSelected = list.map(n => n.id);
			setSelected(newSelected);
			selectedFormik.setFieldValue(formikId, list);
			return;
		}
		setSelected([]);
		selectedFormik.setFieldValue(formikId, []);
	};

	const handleClick = (e: MouseEvent<unknown>, id: number) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) newSelected = _.concat(selected, id);
		else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// 검색하면 page, rowsPerPage 초기화
	const handleBeforeDebounceChange = () => {
		setRowsPerPage(10);
		setPage(0);
	};

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	// rows 양이 적을 경우 크기 조절
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	// 현재 page 와 filter 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		let resultArr = order === 'asc' ? _.chain(list).sortBy(orderBy) : _.chain(list).sortBy(orderBy).reverse();

		if (formikTableSearch.values.table_dept_name_input)
			resultArr = resultArr.filter(
				item => item.name.indexOf(formikTableSearch.values.table_dept_name_input) !== -1,
			);
		if (formikTableSearch.values.table_dept_no_input)
			resultArr = resultArr.filter(
				item => item?.department_no?.indexOf(formikTableSearch.values.table_dept_no_input) !== -1,
			);

		return {
			count: resultArr.value().length,
			rows: resultArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).value(),
		};
	}, [
		order,
		orderBy,
		page,
		rowsPerPage,
		formikTableSearch.values.table_dept_name_input,
		formikTableSearch.values.table_dept_no_input,
		formikTableSearch.values.table_subsidiary_select,
	]);

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar numSelected={selected.length}>
				{isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF') && (
					<div className='flex items-center gap-2 w-full'>
						<div className='rounded-sm bg-white'>
							<SubsidiarySelector formikId='table_subsidiary_select' formik={formikTableSearch} />
						</div>
					</div>
				)}
				<div className='flex items-center gap-2 w-full'>
					<div className='basis-1/2 rounded-sm bg-white'>
						<InputText
							id='table_dept_name_input'
							icon={<SearchIcon />}
							labelText={t('label_department')}
							// delay={100}
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
					<div className='basis-1/2 rounded-sm bg-white'>
						<InputText
							id='table_dept_no_input'
							icon={<SearchIcon />}
							labelText={t('cmmn_label_department_number')}
							// delay={100}
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
				</div>
			</TableSearchBar>

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible' style={{ minHeight: (rowsPerPage + 1) * 53 }}>
					<Table size='medium' stickyHeader>
						<TableDeptHeader
							listType='checkbox'
							headCells={headCells}
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={list.length}
						/>
						<TableBody>
							{visibleRows.rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={5} classes={{ root: 'text-center' }}>
										{t('label_no_search_results_exist')}
									</TableCell>
								</TableRow>
							)}
							{visibleRows.rows.length > 0 &&
								visibleRows.rows.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={event => handleClick(event, row.id)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{listType && (
												<TableCell padding='checkbox'>
													<Checkbox
														id={labelId}
														color='primary'
														checked={isItemSelected}
														inputProps={{
															'aria-labelledby': labelId,
														}}
													/>
												</TableCell>
											)}
											<TableCell component='th' id={labelId} scope='row' padding='none'>
												{row.name}
											</TableCell>
											{isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF') && (
												<TableCell align='left'>{row.subsidiaryName}</TableCell>
											)}
											<TableCell align='left'>{row.department_no}</TableCell>
											<TableCell align='left'>{row.group_id}</TableCell>
											<TableCell align='left'>{row.group_name}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter
					rowsPerPageOptions={[10, 20, 30, 50]}
					showFirstButton
					showLastButton
					count={visibleRows.count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</CardBox>
		</section>
	);
}
