import { debounce } from 'lodash';
import { useCallback, useEffect, useRef, useState } from 'react';

type Props = {
	children: React.ReactNode;
	className?: string;
};

export default function ScrollBox({ children, className }: Props) {
	const ref = useRef(null);
	const [isResize, setResize] = useState(false);
	const [maxHeight, setMaxHeight] = useState('75vh');
	const debounceFn = debounce(() => setResize(prev => !prev), 1000);

	const handleResize = useCallback(() => {
		debounceFn.cancel();
		debounceFn();
	}, [ref]);

	useEffect(() => {
		if (ref.current) {
			const rect = (ref.current as HTMLElement).getBoundingClientRect();
			setMaxHeight(`${window.innerHeight - rect.y - 100}px`);
			window.addEventListener('resize', handleResize);
		}
		return () => window.removeEventListener('resize', handleResize);
	}, [ref, isResize]);
	return (
		<section
			ref={ref}
			className={`overflow-y-auto overflow-x-hidden scrollbar py-2 pr-2 ${className}`}
			style={{ maxHeight }}
		>
			{children}
		</section>
	);
}
