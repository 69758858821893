import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../model/icon';

export default function LawsuitIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M6.67969 9.17475L6.4512 8.95746C5.94628 8.47713 5.14759 8.49648 4.66718 9.00144L4.12358 9.57326C3.64329 10.0782 3.66318 10.8779 4.16809 11.3582L8.16773 15.1657C8.67264 15.646 9.47145 15.6266 9.95174 15.1208L10.4953 14.549C10.9756 14.044 10.9557 13.2452 10.4508 12.7649L10.2223 12.5467L10.7081 12.0356L13.6696 14.8551L13.2958 15.2475L17.7812 19.5177C18.2861 19.998 19.0848 19.9778 19.5651 19.4728L20.6523 18.3292C21.1327 17.8242 21.1128 17.0254 20.6079 16.5442L16.1225 12.2749L15.7488 12.6681L12.7875 9.8495L13.2733 9.33838L13.5019 9.55567C14.0068 10.0369 14.8055 10.0166 15.2858 9.51168L15.8294 8.93986C16.3098 8.4349 16.2898 7.63523 15.785 7.1549L11.7852 3.34745C11.2803 2.86712 10.4816 2.88736 10.0012 3.39232L9.45771 3.96414C8.97731 4.4691 8.9972 5.26789 9.50211 5.7491L9.73072 5.96639L6.67969 9.17475Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
			<path
				d='M5.70108 16.7237C4.20931 16.7237 3 17.9333 3 19.4262V21H15.605V19.4262C15.605 17.9333 14.3957 16.7237 12.904 16.7237H5.70108Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
