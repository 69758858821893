import { Toolbar } from '@mui/material';

type Props = {
	numSelected?: number;
	children: React.ReactNode;
};

export default function TableSearchBar({ numSelected = 0, children }: Props) {
	return (
		<Toolbar classes={{ root: 'flex-col px-0 min-h-0' }}>
			{children}
			{numSelected > 0 && (
				<div className='z-10 absolute bottom-0 translate-y-[120%] w-full'>
					<p>{numSelected} 선택</p>
				</div>
			)}
		</Toolbar>
	);
}
