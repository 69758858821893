import { ReactElement, useState, MouseEvent, useEffect, cloneElement } from 'react';
import PopoverDefault, { PopoverDefaultProps } from './PopoverDefault';

type Props = Omit<PopoverDefaultProps, 'open' | 'anchorEl' | 'children'> & {
	children: React.ReactNode;
	openTrigger: ReactElement;
	closeTrigger?: boolean;
	autoClose?: number;
};

export default function PopoverInternalState({ children, openTrigger, closeTrigger, autoClose, ...props }: Props) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);

	const handleOpen = (e: MouseEvent<HTMLElement>) => {
		setAnchorEl(e.currentTarget);

		if (autoClose) {
			setTimeout(() => {
				setAnchorEl(null);
			}, autoClose);
		}
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	useEffect(() => {
		if (closeTrigger) handleClose();
	}, [closeTrigger]);

	const openComponent = cloneElement(openTrigger, {
		onClick: handleOpen,
	});

	return (
		<>
			{openComponent}
			<PopoverDefault open={open} anchorEl={anchorEl} onClose={handleClose} {...props}>
				{children}
			</PopoverDefault>
		</>
	);
}
