import _ from 'lodash';
import { DashboardWorkStatus } from '../../../../../model/api/dashboard';
import { ContractRole, CounselRole } from '../../../../../model/common';
import { getContractRoleString, getCounselRoleString } from '../../../../../util/commonUtils';
import StateChip from '../../../../ui/chips/StateChip';

type Props = {
	roles: DashboardWorkStatus['roles'];
};

// HS사 대시보드 역할 표시를 위한 컴포넌트
// 컨피그 미적용 시 서버에서 값을 내려주지 않기때문에 값이 없을때 null 반환 처리로 충분하다.
export default function ListItemRolesDashboard({ roles }: Props) {
	if (!roles) {
		return null;
	}

	const rolesToString = roles.map(role => {
		// 계약 classification - 숫자
		// 자문 role - 문자열
		if (_.isNumber(role)) {
			return getContractRoleString(role as ContractRole);
		}
		return getCounselRoleString(role as CounselRole);
	});

	return rolesToString.map(role => {
		return role ? (
			<div key={role} className='block'>
				<StateChip key={role} classes={{ root: 'text-xs font-normal text-neutral-600 px-1' }} text={role} />
			</div>
		) : null;
	});
}
