import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { PaperManageTableRow } from '../../../model/paperManage';
import TableListCell from '../../ui/tables/TableListCell';
import TableListRow from '../../ui/tables/TableListRow';
import { User } from '../../../model/user';

type Props = {
	item: PaperManageTableRow;
	index: number;
};

export default function ListItemPaperManage({ item, index }: Props) {
	const { t } = useTranslation();
	const { id, company, paperType, title, userIds, paperCount, sealStamp, viewCode, isActive, createTime } = item;
	const navigate = useNavigate();

	return (
		<TableListRow sx={{ cursor: 'pointer' }} onClick={() => navigate(`/paperManage/detail`, { state: { id } })}>
			<TableListCell>
				<div>{index + 1}</div>
			</TableListCell>
			<TableListCell>
				<div>{company}</div>
			</TableListCell>
			<TableListCell>
				<div>{paperType}</div>
			</TableListCell>
			<TableListCell>
				<div>{title}</div>
			</TableListCell>
			<TableListCell>
				<div>{userIds?.map((user: User) => user.name).join(', ')}</div>
			</TableListCell>
			<TableListCell>
				<div>{paperCount}</div>
			</TableListCell>
			<TableListCell>
				<div>{sealStamp}</div>
			</TableListCell>
			<TableListCell>
				<div>{viewCode}</div>
			</TableListCell>
			<TableListCell>
				<div>{isActive ? t('cmmn_label_activation') : t('cmmn_label_disable')}</div>
			</TableListCell>
			<TableListCell>
				<div>{createTime}</div>
			</TableListCell>
		</TableListRow>
	);
}
