import { DashboardWorkStatus } from '../../../model/api/dashboard';
import ListItemDashboard from './ListItemDashboard';

type Props = {
	data: DashboardWorkStatus[];
};

export default function ListDashboard({ data }: Props) {
	return (
		<ul className='flex flex-col'>
			{data.map(workStatus => (
				<ListItemDashboard key={`${workStatus.history_type}${workStatus.related_id}`} item={workStatus} />
			))}
		</ul>
	);
}
