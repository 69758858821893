import { HardDelete } from '../model/hardDelete';
import { sendPost } from './common';

export async function getHardDeleteList() {
	const res = await sendPost<HardDelete[]>('/api/get-hard-delete-list/', {});
	return res.data;
}

export async function reqHardDelete(list: readonly string[]) {
	const res = await sendPost('/api/req-hard-delete/', { list });
	return res.data;
}
