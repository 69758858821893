import { Chip } from '@mui/material';
import ClearIcon from '../icons/ClearIcon';

type Props = {
	text: string;
	disabled?: boolean;
	link?: string;
	onClick?: () => void;
	onDelete?: () => void;
};

export default function ItemChip({ text, link = '', disabled, onClick = undefined, onDelete = () => {} }: Props) {
	return (
		<Chip
			label={text}
			disabled={disabled}
			classes={{
				root: 'h-auto px-3 bg-neutral-200',
				icon: 'ml-0',
				label: 'px-0',
				disabled: 'opacity-100',
			}}
			onClick={onClick} // Chip 컴포넌트에 onClick 이벤트 핸들러 추가
			deleteIcon={!disabled ? <ClearIcon className='m-0 -mt-0.5' /> : undefined}
			onDelete={!disabled ? onDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
		/>
	);
}
