import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import _ from 'lodash';
import { ChangeEvent, MouseEvent, useMemo, useState } from 'react';
import { FormikValues } from 'formik';
import { useTranslation } from 'react-i18next';
import { PaperManageDetailTableRow } from '../../../model/paperManage';
import { HeadCell, Order } from '../../../model/table';
import { useValidateForm } from '../../../hooks/useValidateForm';
import TableSearchBar from '../../ui/tables/TableSearchBar';
import InputSelect from '../../ui/inputs/InputSelect';
import InputText from '../../ui/inputs/InputText';
import SearchIcon from '../../ui/icons/SearchIcon';
import CardBox from '../../ui/containers/CardBox';
import TableFooter from '../../ui/tables/TableFooter';
import PaperManageDetailListHeader from './PaperManageDetailListHeader';

type Props = {
	id: string;
	listType?: 'checkbox' | 'radio';
	list: PaperManageDetailTableRow[];
	orderBy: keyof PaperManageDetailTableRow;
	headCells: readonly HeadCell<PaperManageDetailTableRow>[];
	formik: FormikValues;
	selectedFormik: FormikValues;
};

export default function PaperManageDetailTable({
	id: formikId,
	listType,
	list,
	orderBy: ob = 'id',
	headCells,
	formik,
	selectedFormik,
}: Props) {
	const { t } = useTranslation();
	const [order, setOrder] = useState<Order>('asc');
	const [orderBy, setOrderBy] = useState(ob);
	const [selected, setSelected] = useState<readonly string[]>(
		formik.values[formikId].map((item: PaperManageDetailTableRow) => item.id),
	);
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);

	const formikTableSearch = useValidateForm({
		validationSchema: {
			paper_manage_detail_table_search_input: { initialValue: '', type: 'string' },
			paper_manage_detail_table_search_select: { initialValue: orderBy, type: 'string' },
		},
	});

	const handleRequestSort = (e: MouseEvent<unknown>, property: keyof PaperManageDetailTableRow) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event: ChangeEvent<HTMLInputElement>) => {
		if (!listType) return;
		if (event.target.checked) {
			const newSelected = list.map(n => n.id);
			setSelected(newSelected);
			selectedFormik.setFieldValue(formikId, list);
			return;
		}
		setSelected([]);
		selectedFormik.setFieldValue(formikId, []);
	};

	const handleClick = (e: MouseEvent<unknown>, id: string) => {
		if (!listType) return;
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly string[] = [];

		if (selectedIndex === -1) newSelected = _.concat(selected, id);
		else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(e.target.value, 10));
		setPage(0);
	};

	// 검색하면 page, rowsPerPage 초기화
	const handleBeforeDebounceChange = () => {
		setRowsPerPage(10);
		setPage(0);
	};

	const isSelected = (id: string) => selected.indexOf(id) !== -1;

	// rows 양이 적을 경우 크기 조절
	const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	// 현재 page 와 filter 에 맞는 rows slice
	const visibleRows = useMemo(() => {
		const orderArr = order === 'asc' ? _.chain(list).sortBy(orderBy) : _.chain(list).sortBy(orderBy).reverse();
		const filterArr = orderArr.filter(item => {
			// 오브젝트 검색인 경우 아래 내용 필요
			// if (typeof item[formikTableSearch.values.paper_manage_detail_table_search_select as keyof PaperManageDetailTableRow] === 'object') {
			// 	if (
			// 		Array.isArray(
			// 			item[
			// 				formikTableSearch.values.paper_manage_detail_table_search_select as keyof Pick<
			// 					PaperManageDetailTableRow,
			// 					'creator'
			// 				>
			// 			],
			// 		)
			// 	) {
			// 		return item[
			// 			formikTableSearch.values.paper_manage_detail_table_search_select as keyof Pick<PaperManageDetailTableRow, 'creator'>
			// 		].some(user => user.name.includes(formikTableSearch.values.paper_manage_detail_table_search_input));
			// 	}
			// }
			return String(
				item[
					formikTableSearch.values.paper_manage_detail_table_search_select as keyof PaperManageDetailTableRow
				],
			).includes(formikTableSearch.values.paper_manage_detail_table_search_input);
		});
		return {
			count: filterArr.value().length,
			rows: filterArr.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).value(),
		};
	}, [
		order,
		orderBy,
		page,
		rowsPerPage,
		formikTableSearch.values.paper_manage_detail_table_search_input,
		formikTableSearch.values.paper_manage_detail_table_search_select,
	]);

	// useEffect(() => {
	// 	console.log('New list received:', list);
	// }, [list]);

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar numSelected={selected.length}>
				<div className='flex items-center gap-2 w-full'>
					<div className='min-w-[150px] rounded-sm bg-white'>
						<InputSelect
							id='paper_manage_detail_table_search_select'
							label={t('search_option')}
							placeholder={t('select_search_option')}
							options={headCells.map(({ id, label }) => ({ name: label, value: id }))}
							required
							formik={formikTableSearch}
						/>
					</div>
					<div className='flex-1 rounded-sm bg-white'>
						<InputText
							id='paper_manage_detail_table_search_input'
							icon={<SearchIcon />}
							labelText={`${
								headCells.filter(
									cell =>
										cell.id === formikTableSearch.values.paper_manage_detail_table_search_select,
								)[0]?.label ?? ''
							}`}
							// delay={500}
							formik={formikTableSearch}
							onBeforeDebounceChange={handleBeforeDebounceChange}
						/>
					</div>
				</div>
			</TableSearchBar>

			<CardBox className='pt-1' size='small'>
				<TableContainer className='overflow-visible'>
					<Table size='medium' stickyHeader>
						<PaperManageDetailListHeader
							headCells={headCells}
							numSelected={listType ? selected.length : 0}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={list.length}
						/>
						<TableBody>
							{visibleRows.rows.length === 0 && (
								<TableRow>
									<TableCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
										{t('label_no_search_results_exist')}
									</TableCell>
								</TableRow>
							)}
							{visibleRows.rows.length > 0 &&
								visibleRows.rows.map((row, index) => {
									const isItemSelected = isSelected(row.id);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={e => handleClick(e, row.id)}
											role='checkbox'
											aria-checked={isItemSelected}
											tabIndex={-1}
											key={row.id}
											selected={listType && isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											<TableCell align='left' component='th' id={labelId} scope='row'>
												{index + 1}
											</TableCell>
											<TableCell align='left'>{row.app_type}</TableCell>
											<TableCell align='left'>{row.title}</TableCell>
											<TableCell align='left'>{row.creator}</TableCell>
											<TableCell align='left'>{row.department}</TableCell>
											<TableCell align='left'>{row.req_paper_count}</TableCell>
											<TableCell align='left'>{row.completed_date}</TableCell>
										</TableRow>
									);
								})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: 53 * emptyRows,
									}}
								>
									<TableCell colSpan={5} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TableFooter
					rowsPerPageOptions={[10, 20, 30, 50]}
					showFirstButton
					showLastButton
					count={visibleRows.count}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</CardBox>
		</section>
	);
}
