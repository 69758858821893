import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getDashboardProgressCount } from '../../../api/dashboard';
import { contractSearchParams } from '../../../constants/adeleSearchParams';
import { contractListUrl } from '../../../constants/adeleUrl';
import { DASHBOARD_PROGRESS_GROUP_COLOR_MAP } from '../../../constants/common';
import { DASHBOARD_CONTRACT_PROGRESS_MAP, DASHBOARD_CONTRACT_TEXT_MAP } from '../../../constants/legalStatus';
import { queryKey } from '../../../constants/queryKey';
import { useUser } from '../../../context/UserContext';
import { DashboardContractProgress, DashboardContractProgressMap } from '../../../model/common';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';

export default function ContractCountDashboard() {
	const { permission } = useUser();
	const { t } = useTranslation();
	const { data: progressCountMap } = useQuery(queryKey.dashboardProgressCount, getDashboardProgressCount, {
		staleTime: 60000,
	});

	const entries = Object.entries(DASHBOARD_CONTRACT_PROGRESS_MAP) as [
		keyof DashboardContractProgressMap,
		DashboardContractProgress[],
	][];

	const handleNavigate = (progressGroup: keyof DashboardContractProgressMap, status: DashboardContractProgress) => {
		sessionStorage.setItem(
			'contract_search_param',
			JSON.stringify({
				...contractSearchParams,
				status_check: `PROGRESS_STS_${progressGroup}`,
				progress_status: status,
			}),
		);

		navigateToAdele(contractListUrl);
	};

	return (
		<CardBox className={`${permission.DISPLAY_COUNSEL ? 'basis-7/12' : 'w-full'} flex flex-row gap-5 p-6`}>
			<p className='text-lg font-bold pr-5 whitespace-nowrap text-ellipsis'>
				{t('contract_label_current_status')}
			</p>
			{entries.map(([progressGroup, statusList]) => (
				<div key={progressGroup} className='flex flex-col flex-grow gap-2'>
					<div
						className='h-2'
						style={{
							backgroundColor: DASHBOARD_PROGRESS_GROUP_COLOR_MAP[progressGroup],
						}}
					/>
					<div
						className='flex grid gap-2'
						style={{
							gridAutoFlow: 'column',
							gridTemplateRows: `repeat(4, minmax(0, 1fr))`,
						}}
					>
						{statusList.map((status: DashboardContractProgress) => (
							<ButtonText
								className='justify-start p-1 whitespace-nowrap text-ellipsis overflow-hidden'
								key={status}
								onClick={() => handleNavigate(progressGroup, status)}
							>
								<div className='flex flex-col text-start' title={DASHBOARD_CONTRACT_TEXT_MAP[status]}>
									<p className='text-base font-bold'>{DASHBOARD_CONTRACT_TEXT_MAP[status]}</p>
									<p className='text-sm'>
										{`${progressCountMap?.[status] ?? 0}${t('label_number_of_cases')}`}
									</p>
								</div>
							</ButtonText>
						))}
					</div>
				</div>
			))}
		</CardBox>
	);
}
