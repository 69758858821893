import { Tab } from '@mui/material';
import { SyntheticEvent, useMemo } from 'react';
import AllIcon from '../ui/icons/apps/custom/sk/AllIcon';
import BoardIcon from '../ui/icons/apps/custom/sk/BoardIcon';
import ContractIcon from '../ui/icons/apps/custom/sk/ContractIcon';
import CounselIcon from '../ui/icons/apps/custom/sk/CounselIcon';
import LawsuitIcon from '../ui/icons/apps/custom/sk/LawsuitIcon';
import ReviewIcon from '../ui/icons/apps/custom/sk/ReviewIcon';
import SignIcon from '../ui/icons/apps/custom/sk/SignIcon';
import TabsCommon from '../ui/tabs/TabsCommon';

interface IProps {
	value: number;
	onChange: (_: SyntheticEvent, v: number) => void;
}

export default function TabsSearch({ value, onChange }: IProps) {
	const tabs = useMemo(
		() => [
			{ id: 'ALL', count: 100, label: '전체보기', icon: <AllIcon /> },
			{ id: 'CONTRACT', count: 12, label: '계약', icon: <ContractIcon /> },
			{ id: 'COUNSEL', count: 15, label: '자문', icon: <CounselIcon /> },
			{ id: 'LAWSUIT', count: 12, label: '분쟁', icon: <LawsuitIcon /> },
			{ id: 'CONTRACT_ETC', count: 12, label: '계약외문서', icon: <ReviewIcon /> },
			{ id: 'BOARD', count: 12, label: '게시판', icon: <BoardIcon /> },
			{ id: 'CONTRACT_SIGN_SEAL_PROCESS', count: 12, label: '사인 및 서류', icon: <SignIcon /> },
		],
		[],
	);

	return (
		<TabsCommon
			orientation='vertical'
			classes={{
				root: 'px-2 py-3',
				indicator: 'hidden',
			}}
			value={value}
			onChange={onChange}
		>
			{tabs.map(({ id, count, label, icon }) => (
				<Tab
					key={id}
					classes={{
						root: 'flex-row items-center gap-2 min-w-0 min-h-0 px-4 py-3 text-sm font-bold rounded-lg',
						iconWrapper: 'mb-0',
						selected: 'text-gray-950 bg-zinc-100',
					}}
					id={id}
					label={
						<>
							<p>{label}</p>
							<p className='ml-auto'>{count}</p>
						</>
					}
					icon={icon}
				/>
			))}
		</TabsCommon>
	);
}
