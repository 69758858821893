import { t } from 'i18next';
import {
	DashboardContractProgress,
	DashboardContractProgressMap,
	DashboardCounselProgress,
	DashboardCounselProgressMap,
} from '../model/common';

export const DASHBOARD_CONTRACT_TEXT_MAP: Record<DashboardContractProgress, string> = {
	CONTRACT_DRAFT: t('label_draft'),
	CONTRACT_WAIT_DEPT_APPROVAL: t('label_waiting_for_approval_of_department_heads'),
	CONTRACT_DEPT_REJECTED: t('label_response_to_the_head_of_the_department_head'),
	CONTRACT_LEGAL_RE_REVIEW: t('label_re_crafted'),
	CONTRACT_NEW_CONTRACT_WRITING: t('contract_guide_writing_a_new_contract'),
	CONTRACT_WAIT_ASSIGN_REVIEWER: t('label_wait_for_the_reviewer'),
	CONTRACT_REVIEW: t('MSG_ID_28'),
	CONTRACT_WAIT_FINAL_APPROVAL: t('label_final_approval_waiting'),
	CONTRACT_NEGO: t('cmmn_guide_negotiating'),
	CONTRACT_RESERVED_SENDING: t('label_shipment_reservation'),
	CONTRACT_RESERVED_SENDING_RUNNING: t('cmmn_guide_sending_schedule_running'),
	CONTRACT_RESERVED_SENDING_FAILED: t('cmmn_error_shipment_reservation_failed'),
	CONTRACT_WAIT_APPROVAL: t('cmmn_label_waiting_for_payment'),
	CONT_STS_WF_SIGN: t('cmmn_label_waiting_for_payment'),
	CONT_STS_WF_SEAL_PROCESS: t('cmmn_label_waiting_for_payment'),
	CONTRACT_WAIT_SIGN_AND_SEAL: t('MSG_ID_1319'),
	CONTRACT_WAIT_REG_SCAN_DOC: t('label_scan_registration_waiting'),
	CONTRACT_WAIT_RECOVER: t('label_original_collection_waiting'),
	CONTRACT_WAIT_COMPLETE: t('label_complete_atmosphere'),
	CONTRACT_COMPLETE: t('MSG_ID_54'),
	CONTRACT_REJECT_AND_CANCEL: t('MSG_ID_47'),
};

export const DASHBOARD_CONTRACT_PROGRESS_MAP: DashboardContractProgressMap = {
	// == 초안 ==
	DRAFT: ['CONTRACT_DRAFT', 'CONTRACT_WAIT_DEPT_APPROVAL', 'CONTRACT_DEPT_REJECTED'],
	// == 검토 및 승인 ==
	REVIEW: [
		'CONTRACT_NEW_CONTRACT_WRITING',
		'CONTRACT_WAIT_ASSIGN_REVIEWER',
		'CONTRACT_REVIEW',
		'CONTRACT_WAIT_FINAL_APPROVAL',
		'CONTRACT_NEGO',
		'CONTRACT_RESERVED_SENDING',
		'CONTRACT_RESERVED_SENDING_RUNNING',
		'CONTRACT_RESERVED_SENDING_FAILED',
	],
	// == 서명 ==
	SIGN_AND_SEAL: [
		'CONTRACT_WAIT_SIGN_AND_SEAL',
		'CONTRACT_WAIT_REG_SCAN_DOC',
		'CONTRACT_WAIT_RECOVER',
		'CONTRACT_WAIT_COMPLETE',
	],
	// == 완료 ==
	COMPLETE: ['CONTRACT_COMPLETE', 'CONTRACT_REJECT_AND_CANCEL'],
};

export const DASHBOARD_COUNSEL_TEXT_MAP: Record<DashboardCounselProgress, string> = {
	COUNSEL_DRAFT: t('label_draft'),
	COUNSEL_WAIT_DEPT_CONFIRM: t('label_waiting_for_approval_of_department_heads'),
	COUNSEL_DEPT_DENY: t('label_response_to_the_head_of_the_department_head'),
	COUNSEL_WAIT_ASSIGN_REVIEWER: t('contract_guide_writing_a_new_contract'),
	COUNSEL_REVIEW: t('label_wait_for_the_reviewer'),
	COUNSEL_RE_REVIEW: t('contract_guide_under_review'),
	COUNSEL_CIRCULATION: t('label_in_circulation_ing'),
	COUNSEL_CIR_COMPLETE: t('label_completion_counsel_circulation'),
	COUNSEL_WAIT_FINAL_APPROVAL: t('label_final_approval_waiting'),
	COUNSEL_FINAL_APPROVAL_DENY: t('label_final_approval_reject'),
	COUNSEL_COMPLETE: t('MSG_ID_54'),
	COUNSEL_STOP: t('label_counsel_suspend'),
};

export const DASHBOARD_COUNSEL_PROGRESS_MAP: DashboardCounselProgressMap = {
	// == 초안 ==
	DRAFT: ['COUNSEL_DRAFT', 'COUNSEL_WAIT_DEPT_CONFIRM', 'COUNSEL_DEPT_DENY'],
	// == 검토 ==
	REVIEW: ['COUNSEL_WAIT_ASSIGN_REVIEWER', 'COUNSEL_REVIEW', 'COUNSEL_RE_REVIEW', 'COUNSEL_CIRCULATION'],
	// == 회람 및 승인 ==
	APPROVAL: ['COUNSEL_CIR_COMPLETE', 'COUNSEL_WAIT_FINAL_APPROVAL', 'COUNSEL_FINAL_APPROVAL_DENY'],
	// == 완료 ==
	COMPLETE: ['COUNSEL_COMPLETE', 'COUNSEL_STOP'],
};
