import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function LawsuitIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				opacity='0.991'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M2.9812 17.7597C2.9812 13.4723 2.9812 9.18473 2.9812 4.89724C3.29252 3.56092 4.11751 2.73593 5.4562 2.42224C5.4562 7.35975 5.4562 12.2972 5.4562 17.2347C4.56438 17.1225 3.73938 17.2975 2.9812 17.7597Z'
				className='fill-inherit'
			/>
			<path
				opacity='0.978'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M10.8562 5.34729C11.8562 5.34729 12.8562 5.34729 13.8562 5.34729C13.8562 7.0973 13.8562 8.84728 13.8562 10.5973C12.8562 10.5973 11.8562 10.5973 10.8562 10.5973C10.8562 8.84728 10.8562 7.0973 10.8562 5.34729Z'
				className='fill-inherit'
			/>
			<path
				opacity='0.978'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.1811 7.67228C22.1811 7.85978 22.1811 8.04728 22.1811 8.23478C22.047 8.56246 21.8096 8.78123 21.4686 8.89103C19.2936 8.90978 17.1186 8.91605 14.9436 8.90978C14.9436 8.27228 14.9436 7.63478 14.9436 6.99728C17.1062 6.99102 19.2686 6.99728 21.4311 7.01603C21.794 7.11035 22.0439 7.32912 22.1811 7.67228Z'
				className='fill-inherit'
			/>
			<path
				opacity='0.989'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M17.8312 21.5848C13.3062 21.5848 8.78121 21.5848 4.2562 21.5848C3.5937 21.3973 3.1687 20.9723 2.9812 20.3098C2.9812 20.0723 2.9812 19.8348 2.9812 19.5973C3.20243 18.895 3.67743 18.4763 4.4062 18.341C8.49366 18.3223 12.5812 18.316 16.6687 18.3223C16.6687 19.0348 16.6687 19.7473 16.6687 20.4598C17.0564 20.4536 17.4439 20.4598 17.8312 20.4785C18.1468 20.6416 18.253 20.8978 18.15 21.2473C18.0867 21.4042 17.9804 21.5167 17.8312 21.5848Z'
				className='fill-inherit'
			/>
			<path
				opacity='0.984'
				fillRule='evenodd'
				clipRule='evenodd'
				d='M18.1687 5.90977C18.1715 5.44139 18.1718 4.97303 18.1697 4.50469H18.1575C18.161 4.45935 18.1628 4.41353 18.1628 4.36728V4.12881C18.1628 3.1724 17.3875 2.39707 16.4311 2.39707C16.3139 2.39707 16.1993 2.40872 16.0886 2.43093V2.38477H6.5437V17.2348C9.7115 17.2401 12.8793 17.2364 16.047 17.2238V17.2183C16.0832 17.2211 16.1196 17.2228 16.1563 17.2234C16.2705 17.2229 16.3847 17.2225 16.4989 17.222C17.4201 17.1822 18.1546 16.4228 18.1546 15.4919C18.1546 15.3746 18.143 15.2601 18.1208 15.1494H18.1616C18.171 13.4321 18.1734 11.7147 18.1687 9.99727H14.9437V10.5973C15.1101 10.5864 15.2726 10.6051 15.4312 10.6535C15.7987 11.0124 15.7738 11.3373 15.3562 11.6285C14.3642 11.6716 13.3705 11.6904 12.375 11.6848C11.3794 11.6904 10.3857 11.6716 9.3937 11.6285C9.23744 11.5723 9.1312 11.466 9.07495 11.3098C9.00179 10.789 9.23305 10.5516 9.7687 10.5973V5.34727C9.5794 5.36125 9.40439 5.31751 9.2437 5.21602C8.93245 4.84797 8.98248 4.53547 9.3937 4.27852C11.3687 4.25351 13.3437 4.25351 15.3187 4.27852C15.4335 4.3183 15.5273 4.38704 15.6 4.48477C15.7834 5.06571 15.5647 5.35321 14.9437 5.34727V5.90977H18.1687ZM8.5312 12.9223C11.0937 12.916 13.6562 12.9223 16.2187 12.941C16.596 13.127 16.6897 13.4083 16.5 13.7848C16.4084 13.8774 16.3022 13.9461 16.1812 13.991C13.6437 14.016 11.1062 14.016 8.5687 13.991C8.13111 13.8088 8.0311 13.515 8.2687 13.1098C8.3521 13.0372 8.43963 12.9747 8.5312 12.9223ZM10.0312 14.5723C11.5938 14.566 13.1563 14.5723 14.7187 14.591C15.0211 14.7271 15.1399 14.9583 15.075 15.2848C15.0027 15.4696 14.8714 15.5883 14.6812 15.641C13.1437 15.666 11.6062 15.666 10.0687 15.641C9.63111 15.4588 9.5311 15.165 9.7687 14.7598C9.8521 14.6872 9.93963 14.6247 10.0312 14.5723Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
