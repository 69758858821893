import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getUserList } from '../api/common';
import { queryKey } from '../constants/queryKey';
import { useValidateForm } from '../hooks/useValidateForm';
import { HeadCell } from '../model/table';
import { User } from '../model/user';
import SysConfigStore from '../store/common/SysConfigStore';
import ButtonText from './ui/buttons/ButtonText';
import ScrollBox from './ui/containers/ScrollBox';
import InputPopupSelect from './ui/inputs/InputPopupSelect';
import { ModalSize } from './ui/popups/PopupDefault';
import PopupInternalState from './ui/popups/PopupInternalState';
import TableSkeleton from './ui/tables/TableSkeleton';
import TableUserList from './ui/tables/user/TableUserList';

/**
 * formik: 유저 팝업이 뜨기 전 유저 추가 밑에 있는 유저 아이콘 있는 유저들
 * selectedFormik: 유저 팝업에서 체크되어있는 유저들
 */

type Props = {
	id: string;
	label?: string;
	formik: FormikValues;
	popupSize?: ModalSize;
	inputSize?: 'small' | 'medium';
	required?: boolean;
	disabled?: boolean;
};
export default function PopupUsers({
	id,
	formik,
	label,
	popupSize = 'medium',
	inputSize = 'medium',
	required = false,
	disabled = false,
}: Props) {
	const { t } = useTranslation();
	const { isComponentEnabled } = SysConfigStore();
	const [closeSwitch, setCloseSwitch] = useState(false);
	const handleTogglePopup = () => setCloseSwitch(prev => !prev);
	const { data, isLoading } = useQuery(queryKey.userList, () => getUserList());

	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});

	const openTrigger = (
		<InputPopupSelect
			id={id}
			label={label}
			formik={formik}
			size={inputSize}
			required={required}
			disabled={disabled}
		/>
	);

	const handleClick = () => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	};

	const companyHeadCell: HeadCell<User>[] = isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF')
		? [
				{
					id: 'subsidiaryName',
					align: 'left',
					disablePadding: false,
					label: t('label_company_name'),
				},
		  ]
		: [];

	const headCells: readonly HeadCell<User>[] = [
		{
			id: 'name',
			align: 'left',
			disablePadding: true,
			label: '이름',
		},
		...companyHeadCell,
		{
			id: 'dept',
			align: 'left',
			disablePadding: false,
			label: '부서',
		},
		{
			id: 'rank',
			align: 'left',
			disablePadding: false,
			label: '직급',
		},
		{
			id: 'email',
			align: 'left',
			disablePadding: false,
			label: '이메일',
		},
	];

	return (
		<PopupInternalState openTrigger={openTrigger} closeTrigger={closeSwitch} size={popupSize}>
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold'>임직원 검색</h2>
				</div>

				<ScrollBox>
					{isLoading && !data && <TableSkeleton colSpan={headCells.length + 1} />}
					{!isLoading && data && (
						<TableUserList
							id={id}
							listType='checkbox'
							list={data}
							orderBy='name'
							headCells={headCells}
							formik={formik}
							selectedFormik={selectedFormik}
						/>
					)}
				</ScrollBox>

				<div className='flex justify-center gap-5'>
					<ButtonText text='취소' variant='contained' onClick={handleTogglePopup} />
					<ButtonText text='확인' variant='contained' color='primary' onClick={handleClick} />
				</div>
			</section>
		</PopupInternalState>
	);
}
