import { PaletteOptions } from '@mui/material';
import { colors } from '../../util/colors';

export const getPalette = (): PaletteOptions => {
	return {
		primary: {
			main: colors.orange[550],
		},
		secondary: {
			main: colors.neutral[500],
		},
		tertiary: {
			main: colors.orange[550],
		},
		error: {
			main: colors.red[500],
		},
		success: {
			main: colors.green[600],
		},
		info: {
			main: colors.sky[500],
		},
		warning: {
			main: colors.orange[400],
		},
		body: {
			main: colors.zinc[100],
		},
	};
};
