import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../api/dashboard';
import { queryKey } from '../../../constants/queryKey';
import { useSubsidiary } from '../../../context/SubsidiaryContext';
import { navigateToAdele } from '../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';

export default function StatusDigitalCertificationDashboardDefault() {
	const { id: subsidiaryId } = useSubsidiary();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();

	const reviewItems = [
		{
			count: statusCount?.digital_certificate?.uncompleted_count,
			label: '',
			onClick: () => navigateToAdele('/digital_certificate/digital_certificate_list_view'),
		},
	];

	const plusBtn = {
		display: true,
		classes: 'cursor-pointer',
		text: `+ ${t('plus_digital_certification')}`,
		onClick: () => navigateToAdele('/digital_certificate/digital_certificate_list_view?isAutoOpen=true'),
	};

	return <StatusCardDashboard title={t('digital_certification')} items={reviewItems} plusBtn={plusBtn} />;
}
