import Switch from '@mui/material/Switch';
import { FormikValues } from 'formik';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';

type Props = {
	id: string;
	label?: string;
	name?: string;
	size?: 'small' | 'medium';
	labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
	disabled?: boolean;
	required?: boolean;
	formik: FormikValues;
};

const getInputContainerClasses = (labelPlacement: string) => {
	let classes: string = '';
	switch (labelPlacement) {
		case 'end':
			classes = 'inline-flex items-center flex-row-reverse';
			break;
		case 'start':
			classes = 'inline-flex items-center';
			break;
		case 'top':
			classes = 'inline-flex flex-col items-center';
			break;
		case 'bottom':
			classes = 'inline-flex items-center flex-col-reverse';
			break;
		default:
	}
	return classes;
};

export default function InputSwitch({ id, name, label, size = 'medium', labelPlacement = 'end', required = false, disabled = false, formik }: Props) {
	const customClasses = {
		root: '',
		asterisk: 'text-red-600',
	};

	return (
		<div className='relative'>
			<div className={getInputContainerClasses(labelPlacement)}>
				{label && (
					<FormLabel htmlFor={id} required={required} error={formik.touched[id] && formik.errors[id] && true}>
						{label}
					</FormLabel>
				)}
				<Switch
					classes={customClasses}
					id={id}
					name={name || id}
					size={size}
					color='primary'
					disabled={disabled}
					onChange={formik.handleChange}
					value={formik.values[id]}
					checked={formik.values[id]}
				/>
			</div>
			{formik.touched[id] && formik.errors[id] && (
				<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full' }} error>
					{formik.errors[id]}
				</FormHelperText>
			)}
		</div>
	);
}
