import SysConfigStore from '../../store/common/SysConfigStore';
import HeaderDefault from './Header';
import HeaderSK from './custom/sk/Header';

function Component() {
	const { isComponentEnabled } = SysConfigStore();

	// SK
	if (isComponentEnabled('CMPNT179_THEME_SK')) return <HeaderSK />;

	// Default
	return <HeaderDefault />;
}

export default function RouteHeader() {
	return <Component />;
}
