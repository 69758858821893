/* eslint-disable no-else-return */
import { useTranslation } from 'react-i18next';
import Chart from '../../components/ui/Chart';
import CardBox from '../../components/ui/containers/CardBox';
import SysConfigStore from '../../store/common/SysConfigStore';
import { getAreaLineOption, getBarVerticalOption, getPieOption } from '../../util/chartOptions';

type ChartData = {
	type: string | '';
	count: number | 0;
	option1: { data: { value: number; name: string; color: string }[]; name: string };
	option2: { xAxisData: string[]; data: { name: string; data: number[]; color: string }[] };
	option3: { xAxisData: string[]; data: { name: string; data: number[]; color: string }[] };
};

export default function ChartComponent(props: ChartData) {
	const { isModuleEnabled, labelText  } = SysConfigStore();
	const { t } = useTranslation();

	const { type, count, option1, option2, option3 } = props;
	if (type === 'NEW-REVIEW') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<div className='relative'>
					<div className='absolute left-0 top-0'>
						<p className='text-5xl'>{count}</p>
					</div>
					<div>
						<Chart option={getPieOption(option1 !== undefined ? option1 : { data: [], name: '' })} />
					</div>
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEW-STATUS-RATE') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<div className='relative'>
					<div className='absolute left-0 top-0'>
						<p className='text-5xl'>{count}</p>
					</div>
					<div className='w-full'>
						<Chart option={getPieOption(option1 !== undefined ? option1 : { data: [], name: '' })} />
					</div>
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEWED-COUNT') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>
					{isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 검토 처리 건수 추이' : '계약 검토 처리 건수 추이'}
				</p>
				<div className='w-full'>
					<Chart option={getAreaLineOption(option2 !== undefined ? option2 : { xAxisData: [], data: [] })} />
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEW-PERIOD-COUNT') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>
					{isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 발생 건수 추이' : '계약 신규 발생 건수 추이'}
				</p>
				<div className='w-full'>
					<Chart option={getAreaLineOption(option2 !== undefined ? option2 : { xAxisData: [], data: [] })} />
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEW-MANAGERS') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>
					{isModuleEnabled('COUNSEL_MANAGE')
						? '신규 계약/자문 검토자 배정 현황'
						: '신규 계약 검토자 배정 현황'}
				</p>
				<div className='w-full'>
					<Chart
						option={getBarVerticalOption(option3 !== undefined ? option3 : { xAxisData: [], data: [] })}
					/>
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEW-CONTRACT-COUNT-BY-CATEGORY') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>계약 카테고리별 배정 현황</p>
				<div className='w-full'>
					<Chart
						option={getBarVerticalOption(option3 !== undefined ? option3 : { xAxisData: [], data: [] })}
					/>
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-REVIEW-COUNSEL-COUNT-BY-CATEGORY') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>자문 카테고리별 배정 현황</p>
				<div className='w-full'>
					<Chart
						option={getBarVerticalOption(option3 !== undefined ? option3 : { xAxisData: [], data: [] })}
					/>
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-LAWSUIT-MANAGE-COUNT') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>신규소송 담당자 배정 현황</p>
				<div className='w-full'>
					<Chart option={getPieOption(option1 !== undefined ? option1 : { data: [], name: '' })} />
				</div>
			</CardBox>
		);
	}

	if (type === 'NEW-LAWSUIT-MANAGE-WITH-IN-AND-OUT') {
		return (
			<CardBox className='grid grid-cols-1 gap-4 min-h-[390px] p-6'>
				<p className='font-bold text-neutral-600'>{`${labelText('cmmn_label_lawsuit_external_delegation')}`}</p>
				<div className='w-full'>
					<Chart
						option={getBarVerticalOption(option3 !== undefined ? option3 : { xAxisData: [], data: [] })}
					/>
				</div>
			</CardBox>
		);
	}

	return null;
}
