/* eslint-disable no-else-return */
import { Toolbar } from '@mui/material';
import { uniqueId } from 'lodash';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL, STATIC_DIR } from '../../constants/common';
import { useUser } from '../../context/UserContext';
import { MenuData } from '../../model/header';
import SysConfigStore from '../../store/common/SysConfigStore';
import SubsidiarySelector from '../common/SubsidiarySelector';
import LogoIcon from '../ui/icons/LogoIcon';
import AlarmList from './AlarmList';
import AuthorizationList from './AuthorizationList';
import DoaUserOption from './DoaUserOption';
import MenuItem1Depth from './MenuItem1Depth';

export default function Header() {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const [openMenu, setOpenMenu] = useState<string | null>(null);
	const { loginUser, isAdmin, isLawyer, isGroupAdmin, hasAnyRole, permission } = useUser();

	const menus: MenuData[] = [
		// 로고
		{
			link: '/',
			icon: <LogoIcon />,
			title: '',
			classes: { root: 'order-first', button: 'px-0' },
			display: true,
		},
		// 계약
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: labelText('contract'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: true,
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: 'CONTRACT' })),
		},
		// 전자 계약 분리 커스텀(그린랩스)
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('MSG_ID_423'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('CONTRACT_WEB_CONTENT'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_WEB_CONTENT' }),
				),
		},
		// 부서 계약 커스텀(FNF)
		{
			link: `${DOMAIN_URL}/contract/get_contract_dept_view`,
			title: t('contract_label_department_contract'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('CONTRACT_DEPT_LIST'),
			isExternal: true,
		},
		// 인감(한샘)
		{
			link: `${DOMAIN_URL}/contract/${
				hasAnyRole ? 'get_contract_stamp_all_view/' : 'get_contract_stamp_my_view/'
			}`,
			title: t('MSG_ID_16'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('CONTRACT_STAMP_MANAGE'),
			isExternal: true,
			preHandle: () => {
				if (hasAnyRole) {
					sessionStorage.setItem(
						'/contract/get_contract_stamp_all_view/',
						JSON.stringify({ migration_only: '0' }),
					);
				}
			},
		},
		// 자문
		{
			link: `${DOMAIN_URL}/counsel/get-list-view-v28/`,
			title: t('counsel'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: permission.DISPLAY_COUNSEL,
			isExternal: true,
		},
		// 소송
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('label_dashboard_type_kr_lawsuit'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: permission.DISPLAY_LAWSUIT,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'LAWSUIT' })),
		},
		// 분쟁
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('MSG_ID_1203'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: permission.DISPLAY_DISPUTE,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'DISPUTE' })),
		},
		// 표준계약서 DB(한샘)
		{
			link: `${DOMAIN_URL}/contract/contract-form-list-view/`,
			title: '표준계약서 DB',
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('STANDARD_CONTRACT_FORM'),
			isExternal: true,
		},
		// 수발신문서
		{
			link: `${DOMAIN_URL}/doc_received/get-list-view-v28/`,
			title: t('MSG_ID_46'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('DOC_RECEIVED'),
			isExternal: true,
		},
		// 프로젝트
		{
			link: `${DOMAIN_URL}/epic/get_epic_all_list/`,
			title: t('MSG_ID_1193'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('EPIC_MANAGE'),
			isExternal: true,
		},
		// HPL(한샘)
		{
			link: `${DOMAIN_URL}/contract/get_list_view_all/`,
			title: 'HPL(구 법무포탈 자료)',
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('EXTRA_MENU_FOR_MIGRATION_DATA'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get_list_view_all/', JSON.stringify({ migration_only: '1' })),
		},
		// 통계
		{
			link: '/statistics',
			title: t('MSG_ID_682'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: permission.DISPLAY_DASHBOARD_STATISTICS,
		},
		// 법률뉴스(그린랩스)
		{
			link: `${DOMAIN_URL}/manage/issue_list_view/`,
			title: t('cmmn_label_legal_news'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isModuleEnabled('LEGAL_ISSUE'),
			isExternal: true,
		},
		// UI 페이지
		{
			link: '/ui',
			title: 'UI',
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: process.env.NODE_ENV === 'development',
		},
		// SaaS 공지사항
		{
			link: `${DOMAIN_URL}/manage/notice_list_view/`,
			title: t('MSG_ID_1'),
			classes: { root: 'ml-auto', button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: true,
			isExternal: true,
		},
		// 문서비교
		{
			link: `${DOMAIN_URL}/document-tools/dashboard-view/`,
			title: t('cmmn_label_document_comparison'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isComponentEnabled('CMPNT150_MENU_DRAFTABLE_F'),
			isExternal: true,
		},
		// 전자인증서
		{
			link: `${DOMAIN_URL}/digital_certificate/digital_certificate_list_view/`,
			title: t('cmmn_label_digital_certificate'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF'),
			isExternal: true,
		},
		// 그룹관리자 메뉴
		{
			link: `${DOMAIN_URL}/manage/get_list_view_group_view/`,
			title: '그룹관리자',
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isGroupAdmin,
			isExternal: true,
		},
		// 관리자 드롭박스
		{
			title: t('MSG_ID_2'),
			classes: { button: 'pt-[17px] pb-[19px] px-[11px]' },
			display: isAdmin,
			children: [
				// 계약 관리
				{
					title: '계약',
					display: true,
					children: [
						// 워크플로우
						{
							link: `${DOMAIN_URL}/admin/admin_workflow_list_view/`,
							title: '워크플로우',
							display: true,
							isExternal: true,
						},
						// 템플릿
						{
							link: `${DOMAIN_URL}/admin/admin_template_list_view/`,
							title: '템플릿',
							display: true,
							isExternal: true,
						},
						// 디지털직인
						{
							link: `${DOMAIN_URL}/admin/admin_stamp_list_view/`,
							title: '디지털직인',
							display: true,
							isExternal: true,
						},
						// 검토자관리
						{
							link: `${DOMAIN_URL}/admin/admin_lawyer_list_view/`,
							title: '검토자관리',
							display: true,
							isExternal: true,
						},
						// 계약이관
						{
							link: `${DOMAIN_URL}/contract/switch_contractor_view_each_for_adele/`,
							title: '계약이관',
							display: true,
							isExternal: true,
						},
						// 인감
						{
							link: `${DOMAIN_URL}/admin/admin_seal_stamp_list_view/`,
							title: '인감',
							display: isModuleEnabled('SEAL_STAMP'),
							isExternal: true,
						},
						// 통계
						{
							link: `${DOMAIN_URL}/admin/admin_statistics_list_view/`,
							title: '통계',
							display: isModuleEnabled('STATISTICAL_STATUS'),
							isExternal: true,
						},
						// 날인 담당자
						{
							link: `${DOMAIN_URL}/admin/admin_seal_manager_edit_view/`,
							title: '날인 담당자',
							display: isModuleEnabled('ENABLED_SEAL_MANAGER_EDIT'),
							isExternal: true,
						},
						// 서류관리
						{
							link: '/paperManage/list',
							title: t('paper_manage'),
							display: isComponentEnabled('CMPNT166_PAPER_MANAGE_BF'),
							// isExternal: true,
						},
						// 인장통계
						{
							link: `${DOMAIN_URL}/admin/admin_stamp_statistic_view/`,
							title: t('cmmn_label_stamp_statistic').replace('{{인장}}', labelText('seal_stamp')),
							display: isComponentEnabled('CMPNT173_STAMP_STATISTIC_BF'),
							isExternal: true,
						},
					],
				},
				// 법률자문
				{
					link: `${DOMAIN_URL}/admin/admin_counsel_view/`,
					title: '법률자문',
					display: isModuleEnabled('COUNSEL_MANAGE'),
					isExternal: true,
				},
				// 소송관리
				{
					link: `${DOMAIN_URL}/admin/admin_lawsuit/`,
					title: '소송관리',
					display: isModuleEnabled('APP_LAWSUIT'),
					isExternal: true,
				},
				// 프로젝트관리
				{
					link: `${DOMAIN_URL}/admin/admin_epic_view/`,
					title: '프로젝트관리',
					display: isModuleEnabled('EPIC_MANAGE'),
					isExternal: true,
				},
				// 업체
				{
					title: '업체',
					display: true,
					isExternal: true,
					children: [
						// 외부업체
						{
							link: `${DOMAIN_URL}/admin/admin_external_company_list_view/`,
							title: '외부업체',
							display: true,
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
							title: t('label_external_lawfirm'),
							display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: t('label_external_lawyer'),
							display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 법무법인
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
							title: '법무법인',
							display:
								isModuleEnabled('APP_LAWSUIT') &&
								!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 사외변호사
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: '사외변호사',
							display:
								isModuleEnabled('APP_LAWSUIT') &&
								!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
							isExternal: true,
						},
						// 계열사 관리
						{
							link: `${DOMAIN_URL}/admin/admin_subsidiary_company_list_view/`,
							title: '계열사 관리',
							display: permission.ACTIVATE_SUBSIDIARY,
							isExternal: true,
						},
					],
				},
				// 라벨
				{
					link: `${DOMAIN_URL}/admin/admin_label_list/`,
					title: '라벨',
					display: true,
					isExternal: true,
				},
				// 시스템관리
				{
					title: '시스템관리',
					display: true,
					isExternal: true,
					children: [
						// 유저
						{
							link: `${DOMAIN_URL}/admin/admin_user_list_view/`,
							title: '유저',
							display: true,
							isExternal: true,
						},
						// 그룹
						{
							link: `${DOMAIN_URL}/admin/admin_group_list_view/`,
							title: '그룹',
							display: true,
							isExternal: true,
						},
						// 보안등급
						{
							link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
							title: '보안등급',
							display: isModuleEnabled('SECURITY_GRADE_ENABLED'),
							isExternal: true,
						},
						// 회사정보
						{
							link: `${DOMAIN_URL}/admin/admin_company_info_view/`,
							title: '회사정보',
							display: true,
							isExternal: true,
						},
						// 권한위임
						{
							link: `${DOMAIN_URL}/admin/admin_emporwerment_view/`,
							title: '권한위임',
							display: isModuleEnabled('DOA'),
							isExternal: true,
						},
						// 공지사항 관리부서
						{
							link: `${DOMAIN_URL}/admin/admin_notice_manage_dept_view/`,
							title: '공지사항 관리부서',
							display: isModuleEnabled('CUSTOM_NOTICE_MANAGE_BY_DEPT'),
							isExternal: true,
						},
						{
							link: `${DOMAIN_URL}/admin/admin_reviewer_satisfaction_ratings_view/`,
							title: t('cmmn_label_evaluate_legal_satisfaction'),
							display: isComponentEnabled('CMPNT141_REVIEWER_SATISFACTION_RATINGS_BF'),
							isExternal: true,
						},
						// 물리적 삭제
						{
							link: '/hardDelete',
							title: t('hard_delete'),
							display: isComponentEnabled('CMPNT172_HARD_DELETE_BF'),
							// isExternal: true,
						},
					],
				},
			],
		},
		// 유저 프로필
		{
			title: ` ${loginUser.name} `,
			display: true,
			icon: <img src={`${STATIC_DIR}/images/user-profile.png`} alt='user' className='w-[40px]' />,
			classes: {
				root: 'order-last',
			},
			children: [
				// 내 정보 보기
				{
					link: `${DOMAIN_URL}/setup/mypage_info/`,
					title: '내 정보 보기',
					display: true,
					isExternal: true,
				},
				// 로그아웃
				{
					link: '/logout',
					display: true,
					title: '로그아웃',
				},
			],
		},
	];

	const handleMenuToggle = (title: string) => {
		if (openMenu === title) {
			setOpenMenu(null);
		} else {
			setOpenMenu(title);
		}
	};

	const setDOAMenu = () => {
		if (!loginUser.isDOA) {
			if (loginUser?.DOAList?.length !== 0) {
				return <AuthorizationList userList={loginUser?.DOAList} />;
			} else {
				return null;
			}
		} else {
			return <DoaUserOption />;
		}
	};

	return (
		<header className='flex items-center w-full text-sm shadow-md shadow-gray-200 bg-white'>
			<Toolbar
				classes={{
					root: 'flex items-stretch gap-6 w-full min-h-full px-[22px] max-[1380px]:px-[11px]',
				}}
			>
				{menus
					.filter(({ display }) => display)
					.map(menu => (
						<MenuItem1Depth
							key={uniqueId(`${menu.title}-`)}
							itemData={menu}
							isOpen={openMenu === menu.title}
							onToggle={() => handleMenuToggle(menu.title)}
						/>
					))}

				{isModuleEnabled('DOA') && setDOAMenu()}
				{isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF') && (
					<div className='flex items-center'>
						<SubsidiarySelector size='small' />
					</div>
				)}
				<AlarmList />
			</Toolbar>
		</header>
	);
}
