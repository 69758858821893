type Size = 'small' | 'medium';

type Props = {
	children: React.ReactNode;
	className?: string;
	size?: Size;
	color?: string;
};

const getSizeStyle = (size: Size) => {
	let style = '';
	switch (size) {
		case 'small':
			style = 'rounded-md shadow-sm';
			break;
		case 'medium':
			style = 'rounded-2xl shadow-md';
			break;
		default:
	}
	return style;
};
export default function CardBox({ children, className = '', size = 'medium', color = 'bg-white' }: Props) {
	return <div className={`${getSizeStyle(size)} ${className} ${color}`}>{children}</div>;
}
