import { useTranslation } from 'react-i18next';
import { contractCreateUrl, counselCreateUrl } from '../../../constants/adeleUrl';
import { useUser } from '../../../context/UserContext';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import AddCounselIcon from '../../ui/icons/AddCounselIcon';
import AddContractIcon from '../../ui/icons/apps/AddContractIcon';
import PopoverInternalState from '../../ui/popovers/PopoverInternalState';
import AlarmButtonDashboard from './AlarmButtonDashboard';
import AlarmDisplayDashboard from './AlarmDisplayDashboard';
import NoticeListDashboard from './NoticeListDashboard';
import StatusDashboardDefault from './StatusDashboardDefault';
import StatusDashboardLawyer from './StatusDashboardLawyer';
import StatusDigitalCertificationDashboardDefault from './StatusDigitalCertificationDashboardDefault';
import StatusSignSealProcessDashboardDefault from './StatusSignSealProcessDashboardDefault';

export default function HeaderDashboard() {
	const { isLawyer, permission } = useUser();
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const handleNavigate = (type: 'CONTRACT' | 'COUNSEL' | 'CONTRACT_ETC' | 'CONTRACT_SIGN_SEAL_PROCESS') => {
		const urlMap = {
			CONTRACT: contractCreateUrl,
			COUNSEL: counselCreateUrl,
			CONTRACT_ETC: contractCreateUrl,
			CONTRACT_SIGN_SEAL_PROCESS: contractCreateUrl,
		};

		if (['CONTRACT', 'CONTRACT_ETC', 'CONTRACT_SIGN_SEAL_PROCESS'].includes(type)) {
			sessionStorage.setItem('/contract/get_list_view_template/', JSON.stringify({ template_type: type }));
		}

		if (['COUNSEL'].includes(type) && isComponentEnabled('CMPNT169_COUNSEL_CUSTOM_BF')) {
			urlMap.COUNSEL = '/counsel/get-list-view-v28/';
			sessionStorage.setItem('CMPNT169_COUNSEL_CUSTOM_BF_isAutoOpen', 'true');
		}

		navigateToAdele(urlMap[type]);
	};
	const { t } = useTranslation();

	return (
		<header className='flex flex-col gap-5 w-full lg:flex-row'>
			<div className='basis-4/5 grid grid-cols-1 gap-5 lg:grid-cols-4'>
				<PopoverInternalState openTrigger={<AlarmButtonDashboard />}>
					<AlarmDisplayDashboard />
				</PopoverInternalState>
				{isLawyer ? <StatusDashboardLawyer /> : <StatusDashboardDefault />}
				<NoticeListDashboard />
				{isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF') ? (
					<StatusSignSealProcessDashboardDefault />
				) : null}
				{isComponentEnabled('CMPNT163_DIGITAL_CERTIFICATE_BF') ? (
					<StatusDigitalCertificationDashboardDefault />
				) : null}
			</div>

			<div className='basis-1/5 flex flex-col gap-5'>
				<ButtonText
					classes={{ root: 'gap-4 h-full rounded-lg' }}
					color='primary'
					variant='contained'
					onClick={() => handleNavigate('CONTRACT')}
				>
					<AddContractIcon className='text-white' />
					<p className='text-xl text-white font-bold'>새 계약</p>
				</ButtonText>
				{permission.DISPLAY_COUNSEL && (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='secondary'
						variant='contained'
						onClick={() => handleNavigate('COUNSEL')}
					>
						<AddCounselIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('cmmn_label_new_counsel_create')}</p>
					</ButtonText>
				)}
				{isComponentEnabled('CMPNT165_CONTRACT_ETC_BF') ? (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='primary'
						variant='contained'
						onClick={() => handleNavigate('CONTRACT_ETC')}
					>
						<AddContractIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('new_contract_etc').replace('{{계약외문서}}', labelText('etc_contract'))}</p>
					</ButtonText>
				) : null}
				{isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF') ? (
					<ButtonText
						classes={{ root: 'gap-4 h-full rounded-lg' }}
						color='primary'
						variant='contained'
						onClick={() => handleNavigate('CONTRACT_SIGN_SEAL_PROCESS')}
					>
						<AddContractIcon className='text-white' />
						<p className='text-xl text-white font-bold'>{t('new_contract_sign_seal_process')}</p>
					</ButtonText>
				) : null}
			</div>
		</header>
	);
}
