import * as yup from 'yup';

/**
 * Yup validation type 정의
 */
export type TypeSchema = 'string' | 'number' | 'email' | 'boolean' | 'array';

/**
 * Yup 필수여부 정의
 * string: Custom Message 전달 받아서 출력
 * boolean: Default Message 출력
 */
export type RequiredSchema = string | boolean;
/**
 * Yup 최솟값 정의
 * [0]: 최솟값 number 형태로 기입
 * [1]: Custom Message 출력 없을 경우 Default Message 출력
 */
export type MinSchema = [number, string] | [number];
/**
 * Yup 최댓값 정의
 * [0]: 최댓값 number 형태로 기입
 * [1]: Custom Message 출력 없을 경우 Default Message 출력
 */
export type MaxSchema = [number, string] | [number];

type Validation = {
	type: TypeSchema;
	required: RequiredSchema;
	min?: MinSchema;
	max?: MaxSchema;
};

/**
 *
 * @param type Yup validation type
 * @returns Yup Object
 */
const getTypeSchema = (type: TypeSchema) => {
	let yupObject: yup.AnyObject = {};
	switch (type) {
		case 'string':
			yupObject = yup.string();
			break;
		case 'number':
			yupObject = yup.number();
			break;
		case 'email':
			yupObject = yup.string().email('Email 형식이 다릅니다.');
			break;
		case 'boolean':
			yupObject = yup.boolean();
			break;
		case 'array':
			yupObject = yup.array();
			break;
		default:
	}

	return yupObject;
};

/**
 *
 * @param yupObject Yup Object
 * @param required
 * @returns Yup Object
 */
const getRequiredSchema = (yupObject: yup.AnyObject, required: RequiredSchema = false) => {
	if (!required) return yupObject;
	const msg = required === true ? '필수 항목 입니다.' : required;
	return yupObject.type === 'boolean' ? yupObject.oneOf([true], msg) : yupObject.required(msg);
};

/**
 *
 * @param yupObject Yup Object
 * @param min
 * @returns Yup Object
 */
const getMinSchema = (yupObject: yup.AnyObject, min: MinSchema) => {
	if (min[0] === 0) return yupObject;
	const [length, msg = `최소 ${length} 이상 입니다.`] = min;

	return yupObject.min(length, msg);
};

/**
 *
 * @param yupObject Yup Object
 * @param max
 * @returns Yup Object
 */
const getMaxSchema = (yupObject: yup.AnyObject, max: MaxSchema) => {
	if (max[0] === 0) return yupObject;
	const [length, msg = `최대 ${length} 이하 입니다.`] = max;

	return yupObject.min(length, msg);
};

export const validationFormat = ({ type = 'string', required = false, max = [0, ''], min = [0, ''] }: Validation) => {
	const typeObj = getTypeSchema(type);
	const minObj = getMinSchema(typeObj, min);
	const maxObj = getMaxSchema(minObj, max);
	const requiredObj = getRequiredSchema(maxObj, required);

	return requiredObj;
};
