import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../model/icon';

export default function CounselIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M3 5.13581C3 3.95597 3.95623 3 5.13581 3H18.6626C19.8421 3 20.7984 3.95597 20.7984 5.13581V15.8148C20.7984 16.9947 19.8421 17.9506 18.6626 17.9506H15.0962L12.151 20.8957C12.0119 21.0348 11.7866 21.0348 11.6475 20.8957L8.70226 17.9506H5.13581C3.95623 17.9506 3 16.9947 3 15.8148V5.13581ZM6.95301 11.6072C7.73943 11.6072 8.37688 10.9696 8.37688 10.1833C8.37688 9.39699 7.73943 8.75944 6.95301 8.75944C6.1666 8.75944 5.52914 9.39699 5.52914 10.1833C5.52914 10.9696 6.1666 11.6072 6.95301 11.6072ZM11.8992 11.6072C12.6856 11.6072 13.3231 10.9696 13.3231 10.1833C13.3231 9.39699 12.6856 8.75944 11.8992 8.75944C11.1128 8.75944 10.4753 9.39699 10.4753 10.1833C10.4753 10.9696 11.1128 11.6072 11.8992 11.6072ZM18.2696 10.1833C18.2696 10.9696 17.6321 11.6072 16.8457 11.6072C16.0593 11.6072 15.4218 10.9696 15.4218 10.1833C15.4218 9.39699 16.0593 8.75944 16.8457 8.75944C17.6321 8.75944 18.2696 9.39699 18.2696 10.1833Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
