import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../model/icon';

export default function ContractIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M8.73287 11.7499L16.7765 3.70632C17.7182 2.76456 19.2451 2.76456 20.1868 3.70631C21.1286 4.64807 21.1286 6.17495 20.1868 7.11671L12.1433 15.1603L12.1397 15.1639C10.2104 17.0901 7.14199 17.733 6.68253 17.2735C6.22307 16.814 6.80311 13.6828 8.7293 11.7535L8.73287 11.7499ZM4.24021 7.58148C4.24021 6.78238 4.88801 6.13458 5.68711 6.13458H12.1035C12.4459 6.13458 12.7236 5.85695 12.7236 5.51447C12.7236 5.172 12.4459 4.89437 12.1035 4.89437H5.68711C4.20306 4.89437 3 6.09743 3 7.58148V18.3129C3 19.797 4.20307 21 5.68712 21H15.0089C16.4929 21 17.696 19.797 17.696 18.3129V13.2048C17.696 12.8623 17.4183 12.5847 17.0759 12.5847C16.7334 12.5847 16.4558 12.8623 16.4558 13.2048V18.3129C16.4558 19.112 15.808 19.7598 15.0089 19.7598H5.68712C4.88801 19.7598 4.24021 19.112 4.24021 18.3129V7.58148Z'
				fill='#0891B2'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
