import React from 'react';
import Popover, { PopoverOrigin, PopoverProps } from '@mui/material/Popover';

export type PopoverDefaultProps = Omit<PopoverProps, 'open' | 'anchorEl'> & {
	children: React.ReactNode;
	open: boolean;
	anchorEl: HTMLElement | null;
};

const defaultAnchorOrigin: PopoverOrigin = {
	vertical: 'bottom',
	horizontal: 'left',
};

const defaultTransformOrigin: PopoverOrigin = {
	vertical: 'top',
	horizontal: 'left',
};
export default function PopoverDefault({
	children,
	anchorEl,
	open,
	onClose,
	classes = { paper: 'rounded-2xl shadow-2xl scrollbar' },
	anchorOrigin = defaultAnchorOrigin,
	transformOrigin = defaultTransformOrigin,
	...props
}: PopoverDefaultProps) {
	return (
		<Popover
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={anchorOrigin}
			transformOrigin={transformOrigin}
			classes={classes}
			{...props}
		>
			{children}
		</Popover>
	);
}
