import { TableCell as Cell, TableCellProps } from '@mui/material';

export type TableCellPropsOverrides = TableCellProps;

export default function TableListCell({ ...props }: TableCellPropsOverrides) {
	const { align = 'left', children } = props;

	return (
		<Cell align={align} {...props}>
			{children}
		</Cell>
	);
}
