import SubsidiarySelector from '../../components/common/SubsidiarySelector';
import CalendarDashboard from '../../components/dashboard/calendar/CalendarDashboard';
import HeaderDashboard from '../../components/dashboard/header/HeaderDashboard';
import AccodionChartDashboard from '../../components/dashboard/main/AccodionChartDashboard';
import ContractCountDashboard from '../../components/dashboard/main/ContractCountDashboard';
import CounselCountDashboard from '../../components/dashboard/main/CounselCountDashboard';
import TabsDashboard from '../../components/dashboard/main/TabsDashboard';
import CardBox from '../../components/ui/containers/CardBox';
import { useUser } from '../../context/UserContext';

export default function DashboardPage() {
	const { permission } = useUser();

	return (
		<section className='flex flex-col gap-5 p-8 max-w-[1920px] mx-auto'>
			{permission.DISPLAY_DASHBOARD_SUBSIDIARY && (
				<div className='flex justify-between items-center space-x-5'>
					<div className='flex-1 text-right'>
						<p className='text-xl font-bold'>계열사 보기</p>
					</div>
					<div className='flex-1'>
						<SubsidiarySelector />
					</div>
				</div>
			)}
			<HeaderDashboard />
			<section className='flex flex-col gap-5 lg:flex-row'>
				<div className='basis-4/5 flex flex-col gap-5'>
					{permission.DISPLAY_DASHBOARD_STATUS_COUNT && (
						<div className='flex flex-col gap-5 xl:flex-row'>
							<ContractCountDashboard />
							<CounselCountDashboard />
						</div>
					)}
					{permission.DISPLAY_DASHBOARD_STATISTICS && <AccodionChartDashboard />}
					<CardBox color='bg-slate-100'>
						<TabsDashboard />
					</CardBox>
				</div>

				<CardBox className='basis-1/5 p-5'>
					<CalendarDashboard />
				</CardBox>
			</section>
		</section>
	);
}
