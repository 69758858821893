import React, { useState } from 'react';
import ButtonText from '../ui/buttons/ButtonText';
import { useValidateForm } from '../../hooks/useValidateForm';
import InputText from '../ui/inputs/InputText';
import ButtonLoading from '../ui/buttons/ButtonLoading';
import PopupDefault from '../ui/popups/PopupDefault';
import { loginSendEmail } from '../../api/login';

type Props = {
	text: string;
	type: string;
};
export default function LoginSendEmailPopup({ text, type }: Props) {
	const [open, setOpen] = useState(false);
	const [nestedOpen, setNestedOpen] = useState(false);
	const [result, setResult] = useState<React.ReactElement>(<div />);

	const resultContent = (message: string, onConfirm: () => void) => (
		<div className='flex flex-col p-3 gap-5 text-center'>
			<div>{message}</div>
			<ButtonText text='확인' variant='contained' color='primary' onClick={onConfirm} />
		</div>
	);

	const formik = useValidateForm({
		validationSchema: {
			email: { initialValue: '', type: 'email' },
		},
		onSubmit: values => {
			loginSendEmail({ email: values.email, type })
				.then(msg => {
					setResult(resultContent(msg, () => setOpen(false)));
				})
				.catch(error => {
					setResult(resultContent(error.response.data.msg, () => setNestedOpen(false)));
				})
				.finally(() => {
					setNestedOpen(true);
				});
		},
	});

	const guideText =
		type === 'send_register'
			? ['등록된 이메일 아이디를 입력하시면', '해당 메일로 신규 등록 링크가 전송됩니다.']
			: ['등록된 이메일 아이디를 입력하시면', '해당 메일로 비밀번호 변경 링크가 전송됩니다.'];

	return (
		<>
			<ButtonText text={text} size='small' color='primary' onClick={() => setOpen(true)} />
			<PopupDefault open={open} onClose={() => setOpen(false)} size='xsmall'>
				<>
					<div className='my-6'>
						<p className='text-m text-center text-neutral-600'>{guideText[0]}</p>
						<p className='text-m text-center text-neutral-600'>{guideText[1]}</p>
					</div>
					<form onSubmit={formik.handleSubmit} className='flex flex-col gap-5'>
						<InputText id='email' labelText='Email' placeholder='Email address' formik={formik} />
						<ButtonLoading
							type='submit'
							text='전송'
							variant='contained'
							color='primary'
							disabled={!formik.values.email || !!formik.errors.email}
						/>
						<PopupDefault size='xsmall' open={nestedOpen} onClose={() => setNestedOpen(false)}>
							{result}
						</PopupDefault>
					</form>
				</>
			</PopupDefault>
		</>
	);
}
