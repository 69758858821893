import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getMainWorkStatusCount } from '../../../api/dashboard';
import { contractSearchParams } from '../../../constants/adeleSearchParams';
import { contractListUrl } from '../../../constants/adeleUrl';
import { queryKey } from '../../../constants/queryKey';
import { useSubsidiary } from '../../../context/SubsidiaryContext';
import { navigateToAdele } from '../../../util/commonUtils';
import StatusCardDashboard from './StatusCardDashboard';

export default function StatusSignSealProcessDashboardDefault() {
	const { id: subsidiaryId } = useSubsidiary();
	const { data: statusCount } = useQuery([queryKey.dashboard, queryKey.workStatusCount, subsidiaryId], () =>
		getMainWorkStatusCount(subsidiaryId),
	);
	const { t } = useTranslation();

	const handleNavigate = (url: string, workType: 'CONTRACT_SIGN_SEAL_PROCESS') => {
		const paramsMap = {
			CONTRACT_SIGN_SEAL_PROCESS: contractSearchParams,
		};
		sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: workType }));

		sessionStorage.setItem(
			`${workType.toLowerCase()}_search_param`,
			JSON.stringify({
				...paramsMap[workType],
				status_check: ``,
			}),
		);

		navigateToAdele(url);
	};

	const reviewItems = [
		{
			count: statusCount?.contract_sign_seal_process?.uncompleted_count,
			label: '',
			onClick: () => handleNavigate(contractListUrl, 'CONTRACT_SIGN_SEAL_PROCESS'),
		},
	];

	return <StatusCardDashboard title={t('label_contract_sign_seal_process')} items={reviewItems} />;
}
