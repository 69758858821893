// import {useQuery} from "@tanstack/react-query";
import { uniqueId } from 'lodash';
import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUser } from '../../../../context/UserContext';
import { MenuData } from '../../../../model/header';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import MenuItem1Depth from './MenuItem1Depth';
// import {queryKey} from "../../../../constants/queryKey";



type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function HeaderLeftOrder({ openMenu, onToggle }: Props) {
	const { getSysConfigValue, isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const { permission } = useUser();

	const headerMenuOrder = getSysConfigValue('HEADER_MENU_BAR_SORT');

	// \r\n, \n 등 줄바꿈 문자를 제거하고 앞뒤 공백을 정리한 후
	const cleanedMenuOrder = headerMenuOrder.replace(/[\r\n]/g, '').trim();
	// 쉼표 또는 공백을 기준으로 문자열을 배열로 변환
	const menuOrderArray = cleanedMenuOrder.split(/[, ]+/)
		.filter((item: string) => item); // 빈 문자열 제거


	const menus: MenuData[] = [
		// 계약
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: labelText('contract'),
			menu_bar_value:'contract',
			classes: { button: 'px-7 py-4 text-white' },
			display: true,
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem('/contract/get-list-view-v28/', JSON.stringify({ contract_type: 'CONTRACT' })),
		},

		// 자문
		{
			link: `${DOMAIN_URL}/counsel/get-list-view-v28/`,
			title: t('counsel'),
			menu_bar_value:'counsel',
			classes: { button: 'px-7 py-4 text-white' },
			display: permission.DISPLAY_COUNSEL,
			isExternal: true,
		},
		// 소송
		{
			link: `${DOMAIN_URL}/lawsuit/get-list-view-v28/`,
			title: t('MSG_ID_1203'),
			menu_bar_value:'lawsuit',
			classes: { button: 'px-7 py-4 text-white' },
			display: permission.DISPLAY_LAWSUIT,
			isExternal: true,
			preHandle: () => sessionStorage.setItem('/lawsuit/get-list-view-v28/', JSON.stringify({ type: 'LAWSUIT' })),
		},
		// 프로젝트
		{
			link: `${DOMAIN_URL}/epic/get_epic_all_list/`,
			title: t('MSG_ID_1193'),
			menu_bar_value:'epic',
			classes: { button: 'px-7 py-4 text-white' },
			display: isModuleEnabled('EPIC_MANAGE'),
			isExternal: true,
		},
		// s사 계약 외 문서
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('label_etc_contract').replace('{{계약외문서}}', labelText('etc_contract')),
			menu_bar_value:'contract_etc',
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT165_CONTRACT_ETC_BF'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_ETC' }),
				),
		},
		// s사 사인 날인
		{
			link: `${DOMAIN_URL}/contract/get-list-view-v28`,
			title: t('label_contract_sign_seal_process').replace('사인/서류', labelText('sign_and_seal')),
			menu_bar_value:'sign_and_seal',
			classes: { button: 'px-7 py-4 text-white' },
			display: isComponentEnabled('CMPNT144_SEAL_APRV_PROC_FOR_MNG_TO_SEAL_BF'),
			isExternal: true,
			preHandle: () =>
				sessionStorage.setItem(
					'/contract/get-list-view-v28/',
					JSON.stringify({ contract_type: 'CONTRACT_SIGN_SEAL_PROCESS' }),
				),
		},
		// 수발신문서
		{
			link: `${DOMAIN_URL}/doc_received/get-list-view-v28/`,
			title: isComponentEnabled('CMPNT205_CUSTOMIZE_TEXT_SK_B')
				? t('cmmn_label_doc_recieved_box')
				: t('MSG_ID_46'),
			menu_bar_value:'doc_received',
			classes: { button: 'px-7 py-4 text-white' },
			display: isModuleEnabled('DOC_RECEIVED'),
			isExternal: true,
		},
		// 클레임
		{
			link: `${DOMAIN_URL}/claim/get_claim_all_list/`,
			title: t('MSG_ID_681'),
			menu_bar_value:'claim',
			classes: { button: 'px-7 py-4 text-white' },
			display: isModuleEnabled('MODULE_CLAIM'),
			isExternal: true,
		},
		// UI 페이지
		{
			link: '/ui',
			title: 'UI',
			menu_bar_value:'ui',
			classes: { button: 'px-7 py-4 text-white' },
			display: process.env.NODE_ENV === 'development',
		},
	];


	if (isModuleEnabled('HEADER_MENU_BAR_SORT')) {
		const sortedMenus = (() => {
			// display가 true인 메뉴만 필터링
			const visibleMenus = menus.filter(menu => menu.display);

			// menuOrder가 정의되지 않았을 경우, 빈 배열을 기본값으로 사용
			const menuOrderSafe = Array.isArray(menuOrderArray) ? menuOrderArray : [];
			// menuOrderSafe에 따라 visibleMenus 정렬, 설정에 없는 값들은 마지막으로 밀림
			const orderedMenus = menuOrderSafe
				.map(orderKey => visibleMenus.find(menu => menu?.menu_bar_value && menu.menu_bar_value === orderKey))
				.filter(Boolean) as MenuData[]; // menu_bar_value가 일치하는 메뉴만 남김

			// 설정에 없는 메뉴를 마지막에 추가
			const unorderedMenus = visibleMenus.filter(
				menu => !menuOrderSafe.includes(menu.menu_bar_value || '') // menu_bar_value가 undefined일 경우 빈 문자열로 처리
			);

			// 정렬된 메뉴 + 설정에 없는 메뉴를 마지막으로 배치
			return [...orderedMenus, ...unorderedMenus];
		})();

		return sortedMenus.map(menu => (
			<MenuItem1Depth
				key={uniqueId(`${menu?.title}-`)}
				itemData={menu}
				isOpen={openMenu === menu?.title}
				onToggle={() => onToggle(menu?.title)}
			/>
		));
	}

	return menus
		.filter(({ display }) => display)
		.map(menu => (
			<MenuItem1Depth
				key={uniqueId(`${menu.title}-`)}
				itemData={menu}
				isOpen={openMenu === menu.title}
				onToggle={() => onToggle(menu.title)}
			/>
		));
}
