import { useUser } from '../../../../context/UserContext';
import AuthorizationList from './AuthorizationList';
import DoaUserOption from './DoaUserOption';

export default function MenuDOA() {
	const { loginUser } = useUser();

	function getDOACompoent() {
		if (!loginUser.isDOA) {
			if (loginUser?.DOAList?.length !== 0) {
				return <AuthorizationList userList={loginUser?.DOAList} />;
			}
			return null;
		}
		return <DoaUserOption />;
	}
	return (
		<>
			{!loginUser.isDOA && loginUser?.DOAList && loginUser?.DOAList?.length > 0 && (
				<AuthorizationList userList={loginUser?.DOAList} />
			)}
			{loginUser.isDOA && loginUser?.DOAList?.length === 0 && <DoaUserOption />}
		</>
	);
}
