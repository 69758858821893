import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function CampaignIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M18 12C18 12.55 18.45 13 19 13H21C21.55 13 22 12.55 22 12C22 11.45 21.55 11 21 11H19C18.45 11 18 11.45 18 12ZM16.59 16.82C16.26 17.26 16.35 17.87 16.79 18.19C17.32 18.58 17.88 19 18.41 19.4C18.85 19.73 19.47 19.64 19.79 19.2C19.79 19.19 19.8 19.19 19.8 19.18C20.13 18.74 20.04 18.12 19.6 17.8C19.07 17.4 18.51 16.98 17.99 16.59C17.55 16.26 16.93 16.36 16.6 16.8C16.6 16.81 16.59 16.82 16.59 16.82ZM19.81 4.81004C19.81 4.80004 19.8 4.80004 19.8 4.79004C19.47 4.35004 18.85 4.26005 18.42 4.59005C17.89 4.99005 17.32 5.41004 16.8 5.81004C16.36 6.14004 16.28 6.76005 16.61 7.19005C16.61 7.20005 16.62 7.20005 16.62 7.21005C16.95 7.65005 17.56 7.74004 18 7.41004C18.53 7.02004 19.09 6.59004 19.62 6.19004C20.05 5.87004 20.13 5.25004 19.81 4.81004ZM8 9.00004H4C2.9 9.00004 2 9.90004 2 11V13C2 14.1 2.9 15 4 15H5V18C5 18.55 5.45 19 6 19C6.55 19 7 18.55 7 18V15H8L13 18V6.00004L8 9.00004ZM15.5 12C15.5 10.67 14.92 9.47004 14 8.65004V15.34C14.92 14.53 15.5 13.33 15.5 12Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
