import { useSnackbar, VariantType } from 'notistack';
import ClearIcon from '../components/ui/icons/ClearIcon';

export const useAlert = () => {
	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	return [
		(msg: string, variant: VariantType) =>
			enqueueSnackbar(msg, {
				variant,
				anchorOrigin: { horizontal: 'right', vertical: 'top' },
				autoHideDuration: 3500,
				action: key => (
					<ClearIcon
						fontSize='small'
						classes={{ root: 'cursor-pointer' }}
						onClick={() => closeSnackbar(key)}
					/>
				),
			}),
	];
};
