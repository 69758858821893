import { MouseEvent, useState } from 'react';
import { Menu, MenuItem } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { MenuData } from '../../../../model/header';
import ArrowRightIcon from '../../../ui/icons/ArrowRightIcon';
import MenuItem3Depth from './MenuItem3Depth';

export default function MenuItem2Depth({
	childItem,
	onClose,
}: {
	childItem: MenuData;
	onClose: (e: MouseEvent<HTMLElement>) => void;
}) {
	const [subMenuOpen, setSubMenuOpen] = useState(false);
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const navigate = useNavigate();

	const handleClose = (e: MouseEvent<HTMLElement>, { link, isExternal, preHandle }: MenuData) => {
		if (preHandle) preHandle();
		setSubMenuOpen(false);
		setAnchorEl(null);
		onClose(e); // 상위 메뉴도 닫기
		if (!link) return;
		if (!isExternal) navigate(link);
		else window.location.href = link;
	};

	const handleOpen = (e: MouseEvent<HTMLElement>, item: MenuData) => {
		setSubMenuOpen(true);
		setAnchorEl(e.currentTarget); // 하위 메뉴가 표시될 요소 설정
		if (!childItem.children) handleClose(e, item);
	};

	return (
		<div>
			<MenuItem classes={{ root: 'relative text-sm' }} onClick={e => handleOpen(e, childItem)}>
				{childItem.children && (
					<ArrowRightIcon
						classes={{
							root: 'absolute top-1/2 right-1 -translate-y-1/2 font-xs text-gray-300 hover:text-gray-500',
						}}
					/>
				)}
				{childItem.title}
				{subMenuOpen && childItem.children && (
					<Menu
						anchorEl={anchorEl}
						open={subMenuOpen}
						anchorOrigin={{
							vertical: 'top',
							horizontal: 'right',
						}}
						transformOrigin={{
							vertical: 'top',
							horizontal: 'left',
						}}
						onClose={(e: MouseEvent<HTMLElement>) => handleClose(e, childItem)}
					>
						{childItem.children
							.filter(({ display }: MenuData) => display)
							.map((item: MenuData) => (
								<MenuItem3Depth
									key={uniqueId(item.title)}
									childItem={item}
									onClose={(e: MouseEvent<HTMLElement>) => handleClose(e, item)}
								/>
							))}
					</Menu>
				)}
			</MenuItem>
		</div>
	);
}
