import {
	RealtimeReviews,
	NewReviews,
	ReviewTimes,
	CategoryCount,
	NewReviewsManager,
	LawsuitManager,
	ThreeWeeksNewReview,
	RealtimeReviewManage,
	RealtimeLawsuitManager,
	StatisticsTableSearchParams,
	StatisticsTableRow,
} from '../model/statistics';
import { sendPost, sendPostPageNavigation } from './common';

export async function getDashboardRealtimeStatistics({
	// 실시간 검토중 건수
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<RealtimeReviews>('/get_realtime_chart_data1/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getDashboardRealtimeStatistics3({
	subsidiary,
	period,
}: {
	subsidiary?: string | number;
	period: string;
}) {
	const params = {
		returnType: 'json',
		subsidiary,
		period,
	};

	const res = await sendPost<ThreeWeeksNewReview>('/get_realtime_chart_data3/', params);

	return res.data;
}

export async function getDashboardRealtimeStatistics4({
	subsidiary,
	period,
}: {
	subsidiary?: string | number;
	period: string;
}) {
	const params = {
		returnType: 'json',
		subsidiary,
		period,
	};

	const res = await sendPost<RealtimeReviewManage>('/get_realtime_chart_data4/', params);

	return res.data;
}

export async function getDashboardRealtimeStatistics5({
	subsidiary,
	period,
}: {
	subsidiary?: string | number;
	period: string;
}) {
	const params = {
		returnType: 'json',
		subsidiary,
		period,
	};

	const res = await sendPost<RealtimeLawsuitManager>('/get_realtime_chart_data5/', params);

	return res.data;
}

export async function getDashboardReviewTimeStatistics({
	// 기간중 검토 처리 시간/건수
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<ReviewTimes>('/get_review_time_statistics_data/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getDashboardNewReviewStatistics({
	// 기간중 신규검토건수 현황
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<NewReviews>('/get_new_review_count_statistics/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getDashboardNewReviewManagerStatistics({
	// 기간중 검토자(담당자)현황
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<NewReviewsManager>('/get_review_manager_statistics_data/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getDashboardCategoryStatistics({
	// 기간중 카테고리별 현황
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<CategoryCount>('/get_category_statistics_data/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getDashboardLawsuitStatistics({
	// 기간중 소송담당자별 현황
	subsidiary,
	period,
	startDate,
	endDate,
}: {
	subsidiary?: string | number;
	period: string;
	startDate?: string;
	endDate?: string;
}) {
	const params = {
		formname: 'statistics-form',
		returnType: 'json',
		subsidiary,
		period,
		startDate,
		endDate,
	};

	const res = await sendPost<LawsuitManager>('/get_lawsuit_manager_statistics_data/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

export async function getChartDataList({
	contractIds,
	counselIds,
	lawsuitIds,
	searchParams,
}: {
	contractIds?: number[];
	counselIds?: number[];
	lawsuitIds?: number[];
	searchParams: StatisticsTableSearchParams;
}) {
	const { searchType, searchText, currentPage, rowsPerPage, orderColumn, order } = searchParams;

	const params = {
		returnType: 'json',
		contractIds,
		counselIds,
		lawsuitIds,
		curr_page: currentPage + 1,
		search_type: searchType,
		search_text: searchText,
		number_per_page: rowsPerPage,
		order_col: orderColumn,
		order,
	};

	const res = await sendPostPageNavigation<{ legalList: StatisticsTableRow[] }>(
		'/get_legal_list_from_statistics/',
		params,
	);

	return res;
	// return res.data as { [key: string]: any } as AppList[];

	// return data.legalList as AppList[];
	// return data.legalList;
}
