import _ from 'lodash';
import { Divider, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import PopoverDefault from '../ui/popovers/PopoverDefault';
import ButtonText from '../ui/buttons/ButtonText';
import { queryKey } from '../../constants/queryKey';
import { getNotificationCount, getNotificationList } from '../../api/notification';
import { getTodayFormatToHour } from '../../util/dateFormat';

type Props = {
	open: boolean;
	anchorEl: HTMLElement | null;
	onClose: () => void;
};

const actionColorMap = {
	REQUEST: 'text-blue-600',
	REJECTED: 'text-red-600',
	DEFAULT: '',
};

export default function AlarmPopover({ open, anchorEl, onClose }: Props) {
	useQuery(queryKey.notificationCount, getNotificationCount);

	const { data = [], isLoading } = useQuery(queryKey.notification, getNotificationList);

	const handleNavigate = (link: string) => {
		window.location.href = `http://${link}`;
	};

	return (
		<PopoverDefault
			classes={{ paper: '' }}
			open={open}
			anchorEl={anchorEl}
			onClose={onClose}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'right',
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right',
			}}
		>
			<ul className='flex flex-col min-w-[15rem]'>
				{isLoading &&
					_.chain([])
						.range(3)
						.map(item => (
							<li key={item} className='p-2'>
								<Skeleton height={25} variant='rounded' animation='wave' />
							</li>
						))
						.value()}
				{data.map(
					(
						{
							id,
							status,
							link,
							title,
							app_code_name: workType,
							create_time: createTime,
							action,
							action_user: actionUser,
							type,
						},
						index,
					) => (
						<li key={id}>
							<ButtonText
								onClick={() => handleNavigate(link)}
								className={`flex-col items-stretch gap-1 w-full rounded-none p-3 hover:bg-gray-200 ${
									status !== 'PN' ? 'opacity-60' : ''
								}`}
							>
								<div className='flex items-center justify-between gap-2'>
									<p className='text-s font-bold'>{title}</p>
									<div className='flex items-center'>
										<p className='text-xs'>{getTodayFormatToHour(createTime, 'DAY')}</p>
									</div>
								</div>
								<div className='flex items-center gap-2'>
									<div className='flex items-center gap-2'>
										<span
											className={`inline-flex w-1 h-1 rounded-full ${
												status !== 'PN' ? 'bg-gray-500' : 'bg-red-500'
											}`}
										/>
										<p className='text-s font-bold'>{workType}</p>
									</div>
									<p className='text-s'>|</p>
									<p
										className={`text-s ${status === 'PN' ? actionColorMap[type] : ''}`}
									>{`${actionUser}님 ${action}`}</p>
								</div>
							</ButtonText>
							{index < data.length - 1 && <Divider />}
						</li>
					),
				)}
			</ul>
		</PopoverDefault>
	);
}
