import { FormikValues } from 'formik';
import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';
import { SavedFile } from '../../../model/file';
import FileChip from '../chips/FileChip';
import CardBox from '../containers/CardBox';

type Props = {
	id: string;
	formik: FormikValues;
	button?: React.ReactElement;
	classes?: { root?: string; list?: string };
	multiple?: boolean;
	dragDrop?: boolean;
	disableFileChip?: boolean;
	downloadFile?: (fileId: number, fileName: string) => void;
};

export default function InputFile({
	id: formikId,
	formik,
	classes = { root: '', list: '' },
	button,
	multiple = false,
	dragDrop = false,
	disableFileChip = false,
	downloadFile,
}: Props) {
	const { root = 'flex items-center gap-2', list = 'flex flex-wrap items-center gap-2' } = classes;
	const { t } = useTranslation();
	const fileInputRef = React.useRef<HTMLInputElement>(null);
	const filterNewFile = (prev: File[], addedFileList: File[]) => {
		// 이미 있는 파일을 필터링
		const newFiles = addedFileList.filter(file => !prev.some(prevFile => prevFile.name === file.name));
		// 기존 파일과 새로운 파일 합치기
		return [...prev, ...newFiles];
	};

	const onDrop = useCallback(
		(selectedFiles: File[]) => {
			formik.setFieldValue(formikId, filterNewFile(formik.values[formikId], selectedFiles));
		},
		[formik],
	);
	const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

	const handleButtonClick = () => {
		if (fileInputRef.current) {
			fileInputRef.current.click();
		}
	};

	const enhancedButton =
		button &&
		React.cloneElement(button, {
			onClick: handleButtonClick,
		});

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const { files } = e.target;
		if (files) {
			const selectedFiles = Array.from(files);
			formik.setFieldValue(formikId, filterNewFile(formik.values[formikId], selectedFiles));
		}
	};

	const handleFileDelete = (fileToDelete: File) => {
		formik.setFieldValue(
			formikId,
			formik.values[formikId].filter((file: File) => file !== fileToDelete),
		);
	};

	return (
		<div className={root}>
			{dragDrop && (
				<CardBox className='p-6 border border-dashed border-gray-300 transition-colors hover:border-blue-500 hover:text-blue-500 cursor-pointer'>
					<div className='' {...getRootProps()}>
						<input {...getInputProps()} />
						{isDragActive ? (
							<p className='text-center'>{t('drop_the_files_here')}</p>
						) : (
							<p className='text-center'>{t('cmmn_guide_drag_the_file_to_upload_here')}</p>
						)}
					</div>
				</CardBox>
			)}

			<input
				type='file'
				ref={fileInputRef}
				multiple={multiple}
				style={{ display: 'none' }}
				onChange={handleChange}
			/>

			<div className={list}>
				{enhancedButton}
				{formik.values[formikId].map((file: SavedFile) => (
					<FileChip
						key={file.name}
						text={file.name}
						onDelete={() => handleFileDelete(file)}
						disabled={disableFileChip}
						onClick={downloadFile && (() => downloadFile(file.id ? Number(file.id) : -1, file.name))}
					/>
				))}
			</div>
		</div>
	);
}
