import { Tab, Tabs, TabsOwnProps, TabOwnProps, TabClasses } from '@mui/material';

const getDepthStyle = (depth: number) => {
	let classes: Partial<TabClasses> = {};
	switch (depth) {
		case 1:
			classes = {
				root: 'flex-row justify-start gap-2 max-w-full pl-8 text-neutral-600 font-bold text-left',
				selected: '!text-gray-950 bg-blue-200',
				iconWrapper: 'mb-0',
			};
			break;
		case 2:
			classes = {
				root: 'flex-row justify-start gap-2 max-w-full min-h-0 pl-16 py-3 text-neutral-600 text-left',
				selected: '!text-gray-950 font-bold bg-blue-200',
				iconWrapper: 'mb-0',
			};
			break;
		default:
	}

	return classes;
};

type TabOwnPropsOverrides = TabOwnProps & { id: string | number; depth?: number; query?: string; isVisible?: boolean };
type TabsOwnPropsOverrides = TabsOwnProps & {
	tabs: TabOwnPropsOverrides[];
};

export default function TabSnb({ ...props }: TabsOwnPropsOverrides) {
	const { classes, tabs, orientation = 'vertical', variant = 'scrollable', onChange, value } = props;

	return (
		<Tabs
			classes={{
				...classes,
				indicator: `${classes?.indicator} right-auto left-0 w-2 bg-blue-300`,
			}}
			orientation={orientation}
			variant={variant}
			value={value}
			onChange={onChange}
			{...props}
		>
			{tabs.map(
				({ id, icon, label, depth = 1, disabled = false, isVisible = true, ...other }) =>
					isVisible && (
						<Tab
							key={`tab_${id}`}
							id={`vertical-tab-${id}`}
							aria-controls={`vertical-tabpanel-${id}`}
							classes={getDepthStyle(depth)}
							label={label}
							icon={icon ?? ''}
							disabled={disabled}
							{...other}
						/>
					),
			)}
		</Tabs>
	);
}
