import { useQuery } from '@tanstack/react-query';
import { getCompanyInfoSummary } from '../../api/login';
import { queryKey } from '../../constants/queryKey';
import sysConfigStore from '../../store/common/SysConfigStore';

export default function LoginCompanyTitle() {
	const { isModuleEnabled } = sysConfigStore();
	const { data: company, isLoading } = useQuery(queryKey.companyInfoSummary, getCompanyInfoSummary, {
		staleTime: 60000,
	});

	if (isModuleEnabled('NO_DISPLAY_COMPANYNAME_ON_LOGIN')) {
		return null;
	}

	return <h2 className='text-3xl text-center pt-4 pb-20'>{company?.name}</h2>;
}
