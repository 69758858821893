import { Tab } from '@mui/material';
import { MouseEvent, SyntheticEvent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import { DOMAIN_URL } from '../../constants/common';
import useEseal from '../../hooks/useEseal';
import { SignList } from '../../model/list';
import { Sign } from '../../model/sign';
import { HeadCell } from '../../model/table';
import SysConfigStore from '../../store/common/SysConfigStore';
import CardBox from '../ui/containers/CardBox';
import TableSkeleton from '../ui/tables/TableSkeleton';
import TabPanel from '../ui/tabs/TabPanel';
import TabsCommon from '../ui/tabs/TabsCommon';
import TableSelectedSignList from './TableSelectedSignList';
import TableSignList from './TableSignList';

type Props = {
	contractId: string;
	signs: Sign[];
	value: number;
	onChange: (_: SyntheticEvent, v: number) => void;
	onDrop: (item: AnyObject, dropResult: AnyObject) => void;
	onDeleteSign: (e: MouseEvent<HTMLButtonElement>, sign: Sign) => void;
	onGoToPage: (e: AnyObject, pageIndex: number) => void;
};
export default function TabsSign({ contractId, signs, value, onChange, onDrop, onDeleteSign, onGoToPage }: Props) {
	const { labelText } = SysConfigStore();
	const { t } = useTranslation();

	const { getEsealList } = useEseal();

	const { data: signData, isLoading: signDataLoading } = getEsealList(contractId);

	const signDataStatus: { [key: string]: string } = {
		IS_USED: t('MSG_ID_54'),
		APPROVED: t('MSG_ID_442'),
		PENDING: t('label_waiting_for_approval'),
		REJECTED: t('MSG_ID_35'),
		UNNECESSARY: t('cmmn_label_unnecessary_approve'),
	};

	const normalSealData = useMemo(() => {
		if (signData) {
			return signData
				.filter(sign => sign.type === 'normal')
				.map(data => {
					return {
						...data,
						imageUrl: `${DOMAIN_URL}${data.imageUrl}`,
						status: signDataStatus[data.status],
					};
				});
		}

		return undefined;
	}, [signData]);

	const originCopySealData = useMemo(() => {
		if (signData) {
			return signData
				.filter(sign => sign.type === 'origin_copy')
				.map(data => {
					return {
						...data,
						imageUrl: `${DOMAIN_URL}${data.imageUrl}`,
						status: signDataStatus[data.status],
					};
				});
		}

		return undefined;
	}, [signData]);

	const headCells: HeadCell<SignList>[] = [
		{
			id: 'imageUrl',
			width: 'w-[130px]',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: t('label_image'),
		},
		{
			id: 'title',
			align: 'left',
			disablePadding: false,
			sort: true,
			label: t('cmmn_label_seal_stamp_name').replace('{{인장}}', labelText('seal_stamp')),
		},
		{
			id: 'status',
			align: 'left',
			disablePadding: false,
			sort: true,
			label: t('label_situation'),
		},
	];

	const originCopyHeadCells: HeadCell<SignList>[] = [
		{
			id: 'imageUrl',
			width: 'w-[130px]',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: t('label_image'),
		},
		{
			id: 'title',
			align: 'left',
			disablePadding: false,
			sort: true,
			label: t('cmmn_label_seal_stamp_name').replace('{{인장}}', labelText('seal_stamp')),
		},
		{
			id: 'id',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: '',
		},
	];

	const headCellsSelectedSign: HeadCell<Sign>[] = [
		{
			id: 'imageUrl',
			width: 'w-[100px]',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: t('label_image'),
		},
		{
			id: 'width',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: t('label_size'),
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			sort: true,
			label: t('cmmn_label_seal_stamp_name').replace('{{인장}}', labelText('seal_stamp')),
		},
		{
			id: 'pageIndex',
			align: 'left',
			disablePadding: false,
			sort: true,
			label: t('label_page'),
		},
		{
			id: 'id',
			width: 'w-[50px]',
			align: 'left',
			disablePadding: false,
			sort: false,
			label: '',
		},
	];

	return (
		<CardBox>
			<TabsCommon
				classes={{
					indicator: 'h-1 bg-blue-300',
				}}
				value={value}
				onChange={onChange}
			>
				<Tab
					classes={{
						root: 'min-w-0 min-h-0 px-10 py-5 text-gray-950',
						selected: 'font-bold',
					}}
					id='sign-tab-1'
					label={t('label_list_seal').replace('{{인장}}', labelText('seal_stamp'))}
				/>
				<Tab
					classes={{
						root: 'min-w-0 min-h-0 px-10 py-5 text-gray-950',
						selected: 'font-bold',
					}}
					id='sign-tab-2'
					label={t('label_original_copy_completed_list')}
				/>
				<Tab
					classes={{
						root: 'min-w-0 min-h-0 px-10 py-5 text-gray-950',
						selected: 'font-bold',
					}}
					id='sign-tab-3'
					label={t('label_list_seal_stamp')}
					disabled={signs.length === 0}
				/>
			</TabsCommon>

			<TabPanel value={value} index={0}>
				<header className='px-4 pt-4'>
					<p className='text-sm'>
						{t('contract_guide_select_seal_and_drag')
							.replaceAll('{{날인}}', labelText('seal'))
							.replaceAll('{{인장}}', labelText('seal_stamp'))}
						<br />
						{t('contract_guide_adjust_size_and_save')}
					</p>
					<p className='mt-4 text-m font-bold'>{`${t('MSG_ID_1255')}: ${
						!signDataLoading && signData ? signData.filter(sign => sign.type === 'normal').length : 0
					}`}</p>
				</header>
				<div>
					{signDataLoading && <TableSkeleton isHeader={false} colSpan={headCells.length + 1} />}
					{!signDataLoading && normalSealData && (
						<TableSignList
							list={normalSealData}
							headCells={headCells}
							contractId={contractId}
							onDrop={onDrop}
							sealType='normal'
						/>
					)}
				</div>
			</TabPanel>
			<TabPanel value={value} index={1}>
				<header className='px-4 pt-4'>
					<p className='text-sm'>
						{t('contract_guide_select_seal_and_drag')
							.replaceAll('{{날인}}', labelText('seal'))
							.replaceAll('{{인장}}', labelText('seal_stamp'))}
						<br />
						{t('contract_guide_adjust_size_and_save')}
					</p>
					<p className='mt-4 text-m font-bold'>{`${t('MSG_ID_1255')}: ${
						!signDataLoading && signData ? signData.filter(sign => sign.type === 'origin_copy').length : 0
					}`}</p>
				</header>
				<div>
					{signDataLoading && <TableSkeleton isHeader={false} colSpan={originCopyHeadCells.length + 1} />}
					{!signDataLoading && originCopySealData && (
						<TableSignList
							list={originCopySealData}
							headCells={originCopyHeadCells}
							contractId={contractId}
							onDrop={onDrop}
							sealType='origin_copy'
						/>
					)}
				</div>
			</TabPanel>
			<TabPanel value={value} index={2}>
				<header className='px-4 pt-4'>
					<p className='text-sm'>
						{t('contract_guide_check_details_seal_by_page').replaceAll('{{날인}}', labelText('seal'))}
						<br />
						{t('contract_guide_click_on_seal_detail_page_and_delete').replaceAll(
							'{{날인}}',
							labelText('seal'),
						)}
					</p>
					<p className='mt-4 text-m font-bold'>{`${labelText('stamping')}: ${signs.length}`}</p>
				</header>

				<TableSelectedSignList
					list={signs}
					headCells={headCellsSelectedSign}
					onDeleteSign={onDeleteSign}
					onClick={onGoToPage}
				/>
			</TabPanel>
		</CardBox>
	);
}
