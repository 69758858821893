/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AnyObject } from 'yup';
import {
	getDashboardCategoryStatistics,
	getDashboardLawsuitStatistics,
	getDashboardNewReviewManagerStatistics,
	getDashboardNewReviewStatistics,
	getDashboardRealtimeStatistics,
	getDashboardReviewTimeStatistics,
} from '../../api/statistics';
import SubsidiarySelector from '../../components/common/SubsidiarySelector';
import Chart from '../../components/ui/Chart';
import ButtonText from '../../components/ui/buttons/ButtonText';
import CardBox from '../../components/ui/containers/CardBox';
import InputDate from '../../components/ui/inputs/InputDate';
import InputSelect from '../../components/ui/inputs/InputSelect';
import { STATISTICS_CHART_COLOR } from '../../constants/common';
import { useStatistics } from '../../context/StatisticsContext';
import { useValidateForm } from '../../hooks/useValidateForm';
import {
	CategoryCount,
	LawsuitManager,
	NewReviews,
	NewReviewsManager,
	PeriodOptionValue,
	RealtimeReviews,
	ReviewTimes,
} from '../../model/statistics';
import SysConfigStore from '../../store/common/SysConfigStore';
import { getAreaLineOption, getBarVerticalOption, getPieOption } from '../../util/chartOptions';
import { dateFormat, getStartDateOfWeek } from '../../util/dateFormat';

export default function DashboardPage() {
	const { isModuleEnabled,  labelText} = SysConfigStore();
	const { t } = useTranslation();
	const [resRealtimeStatisticsData, setResRealtimeStatisticsData] = useState<RealtimeReviews>();
	const [resReviewTimesStatisticsData, setResReviewTimesStatisticsData] = useState<ReviewTimes>();
	const [resNewReviewStatisticsData, setResNewReviewStatisticsData] = useState<NewReviews>();
	const [resNewReviewsManager, setResNewReviewsManager] = useState<NewReviewsManager>();
	const [resCategoryCount, setResCategoryCount] = useState<CategoryCount>();
	const [resLawsuitManager, setResLawsuitManager] = useState<LawsuitManager>();

	const { period, setPeriod, startDate, setStartDate, endDate, setEndDate } = useStatistics();

	const [subsidiary, setSubsidiary] = useState<number>(-1);

	// const isSubsidiaryManage = isModuleEnabled('ENABLED_SUBSIDIARY_MODULE_V28');

	const data: { value: number; name: string; color: string }[] = [];

	const data2: { value: number; name: string; color: string }[] = [];

	const data3: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data3NewRivew: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data4: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data5: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data5Counsel: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const data6: { value: number; name: string; color: string }[] = [];

	const data7: {
		xAxisData: string[];
		data: { name: string; data: number[]; color: string }[];
	} = { xAxisData: [], data: [] };

	const getStatistics = (params: {
		subsidiary: string | number;
		period: string;
		startDate: string;
		endDate: string;
	}) => {
		getDashboardRealtimeStatistics({
			...params,
		}).then(res => {
			console.log('실시간 검토건');
			console.log(res);
			setResRealtimeStatisticsData(res);
			console.log(resRealtimeStatisticsData);
		});

		getDashboardReviewTimeStatistics({
			...params,
		}).then(res => {
			console.log('검토건수 + 처리시간');
			console.log(res);
			setResReviewTimesStatisticsData(res);
			console.log(resReviewTimesStatisticsData);
		});

		getDashboardNewReviewStatistics({
			...params,
		}).then(res => {
			console.log('신규검토');
			console.log(res);
			setResNewReviewStatisticsData(res);
			console.log(resNewReviewStatisticsData);
		});

		getDashboardNewReviewManagerStatistics({
			...params,
		}).then(res => {
			console.log('신규검토담당자별');
			console.log(res);
			setResNewReviewsManager(res);
			console.log(resNewReviewsManager);
		});

		getDashboardCategoryStatistics({
			...params,
		}).then(res => {
			console.log('카테고리별');
			console.log(res);
			setResCategoryCount(res);
			console.log(resCategoryCount);
		});

		getDashboardLawsuitStatistics({
			...params,
		}).then(res => {
			console.log('소송 담당자별');
			console.log(res);
			setResLawsuitManager(res);
			console.log(resLawsuitManager);
		});
	};

	const formik = useValidateForm({
		validationSchema: {
			// search_subsidiary_select: { initialValue: 'all', type: 'string' },
			search_date_select: { initialValue: 'month', type: 'string' },
			search_date_start: { initialValue: dateFormat().format('YYYY.MM.DD'), type: 'string' },
			search_date_end: { initialValue: dateFormat().format('YYYY.MM.DD'), type: 'string' },
		},
		onSubmit: values => {
			getStatistics({
				// subsidiary: values.search_subsidiary_select,
				subsidiary,
				period: values.search_date_select,
				startDate: values.search_date_start,
				endDate: values.search_date_end,
			});
		},
	});

	const calculateReviewVariance = () => {
		let totalCount =
			(resNewReviewStatisticsData?.contract_count ?? 0) + (resNewReviewStatisticsData?.counsel_count ?? 0);
		let comparisonCount =
			(resNewReviewStatisticsData?.comp_contract_count ?? 0) +
			(resNewReviewStatisticsData?.comp_counsel_count ?? 0);

		if (comparisonCount === 0) return '-';

		let rate = ((totalCount - comparisonCount) / comparisonCount) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const calculateCompleteVariance = () => {
		let totalCount =
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) +
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);
		let comparisonCount =
			(resReviewTimesStatisticsData?.comparison_reviewed_contract_ids.length ?? 0) +
			(resReviewTimesStatisticsData?.reviewed_comparison_counsel_ids.length ?? 0);

		if (comparisonCount === 0) return '-';

		let rate = ((totalCount - comparisonCount) / comparisonCount) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const calculateContractReviewTime = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) === 0) return 0.0;

		return (avgReviewTime / 24).toFixed(1);
	};

	const calculateCounselReviewTime = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0) === 0) return 0.0;

		return (avgReviewTime / 24).toFixed(1);
	};

	const calculateContractReviewTimeVariance = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) === 0) {
			avgReviewTime = 0;
		}

		let comparisonReviewTime =
			(resReviewTimesStatisticsData?.comparison_total_reviewed_contract_time ?? 0) /
			(resReviewTimesStatisticsData?.comparison_reviewed_contract_ids.length ?? 0);

		if (
			comparisonReviewTime === 0 ||
			(resReviewTimesStatisticsData?.comparison_reviewed_contract_ids.length ?? 0) === 0
		) {
			return '-';
		}

		// 여기 다시 계산 필요
		let rate = ((avgReviewTime - comparisonReviewTime) / 24 / (comparisonReviewTime / 24)) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const calculateCounselReviewTimeVariance = () => {
		let avgReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);

		if ((resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0) === 0) {
			avgReviewTime = 0;
		}

		let comparisonReviewTime =
			(resReviewTimesStatisticsData?.total_reviewed_comparison_counsel_time ?? 0) /
			(resReviewTimesStatisticsData?.reviewed_comparison_counsel_ids.length ?? 0);

		if (
			comparisonReviewTime === 0 ||
			(resReviewTimesStatisticsData?.reviewed_comparison_counsel_ids.length ?? 0) === 0
		) {
			return '-';
		}

		// 여기 다시 계산 필요
		let rate = ((avgReviewTime - comparisonReviewTime) / 24 / (comparisonReviewTime / 24)) * 100;

		return rate > 0 ? `+${rate.toFixed(1) as string}` : rate.toFixed(1);
	};

	const getNewReviewTotal = () => {
		let conCnt = resNewReviewStatisticsData?.contract_count ?? 0;
		let counselCnt = resNewReviewStatisticsData?.counsel_count ?? 0;

		return conCnt + counselCnt;
	};

	const newReviewChartData = () => {
		let conCnt = resNewReviewStatisticsData?.contract_count ?? 0;
		let counselCnt = resNewReviewStatisticsData?.counsel_count ?? 0;

		data.push({
			value: conCnt,
			name: '계약',
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data.push({
				value: counselCnt,
				name: '자문',
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		return getPieOption({ data, name: '신규 검토 법무별 건수' });
	};

	const getStatusRateTotal = () => {
		let newReviewCnt = getNewReviewTotal();
		let reviewCompleteCnt =
			(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) +
			(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0);

		return newReviewCnt + reviewCompleteCnt;
	};

	const reviewStatusRateChartData = () => {
		data2.push({
			value: getNewReviewTotal(),
			name: '검토',
			color: STATISTICS_CHART_COLOR.LEGAL_REVIEW_IN_PROGRESS,
		});
		data2.push({
			value:
				(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) +
				(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0),
			name: '처리',
			color: STATISTICS_CHART_COLOR.LEGAL_REVIEW_COMPLETE,
		});

		return getPieOption({
			data: data2,
			name: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 법무 상태별 건수' : '계약 법무 상태별 건수',
		});
	};

	const reviewedCountLineChart = () => {
		let conDate = resReviewTimesStatisticsData?.reviewed_contract_datetime.map(item => item.split('|')[0]);
		conDate = conDate?.sort();
		let counselDate = resReviewTimesStatisticsData?.reviewed_counsel_datetime.map(item => item.split('|')[0]);
		counselDate = counselDate?.sort();

		let conDateCnt = conDate?.map(item => conDate?.filter(el => item === el).length);

		let counselDateObj = counselDate?.map(item => counselDate?.filter(el => item === el).length);

		let concatContractObj: AnyObject = _.zipObjectDeep(conDate, conDateCnt);
		let concatCounselObj: AnyObject = _.zipObjectDeep(counselDate, counselDateObj);

		let allDates = _.union(_.keys(concatContractObj), _.keys(concatCounselObj));

		_.forEach(allDates, key => {
			if (concatContractObj[key] === undefined) {
				concatContractObj[key] = 0;
			}
			if (concatCounselObj[key] === undefined) {
				concatCounselObj[key] = 0;
			}
		});

		data3.xAxisData = _.keys(concatContractObj).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => concatContractObj[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});

		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data3.data.push({
				name: '자문',
				data: data3.xAxisData.map(item => concatCounselObj[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		return getAreaLineOption(data3);
	};

	const newReviewCountLineChart = () => {
		let conDate = resNewReviewStatisticsData?.contract_create_time;
		conDate = conDate?.sort();
		let counselDate = resNewReviewStatisticsData?.counsel_create_time;
		counselDate = counselDate?.sort();

		let conDateCnt = conDate?.map(item => conDate?.filter(el => item === el).length);

		let counselDateObj = counselDate?.map(item => counselDate?.filter(el => item === el).length);

		let concatContractObj: AnyObject = _.zipObjectDeep(conDate, conDateCnt);
		let concatCounselObj: AnyObject = _.zipObjectDeep(counselDate, counselDateObj);

		let allDates = _.union(_.keys(concatContractObj), _.keys(concatCounselObj));

		_.forEach(allDates, key => {
			if (concatContractObj[key] === undefined) {
				concatContractObj[key] = 0;
			}
			if (concatCounselObj[key] === undefined) {
				concatCounselObj[key] = 0;
			}
		});

		data3NewRivew.xAxisData = _.keys(concatContractObj).sort();

		data3NewRivew.data.push({
			name: '계약',
			data: data3NewRivew.xAxisData.map(item => {
				return concatContractObj[item];
			}),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data3NewRivew.data.push({
				name: '자문',
				data: data3NewRivew.xAxisData.map(item => concatCounselObj[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		return getAreaLineOption(data3NewRivew);
	};

	const reviewManagerChartData = () => {
		let conManager = resNewReviewsManager?.contract_review_manager ?? {};
		let counselManager = resNewReviewsManager?.counsel_review_manager ?? {};

		let allManagers = _.union(_.keys(conManager), _.keys(counselManager));

		_.forEach(allManagers, key => {
			if (conManager[key] === undefined) {
				conManager[key] = 0;
			}
			if (counselManager[key] === undefined) {
				counselManager[key] = 0;
			}
		});

		data4.xAxisData = _.keys(conManager).sort();

		data4.data.push({
			name: '계약',
			data: data4.xAxisData.map(item => conManager[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data4.data.push({
				name: '자문',
				data: data4.xAxisData.map(item => counselManager[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		return getBarVerticalOption(data4);
	};

	const categoryChartData = () => {
		let conCategory = resCategoryCount?.contract_category_count ?? {};

		data5.xAxisData = _.keys(conCategory).sort();

		data5.data.push({
			name: '계약',
			data: data5.xAxisData.map(item => conCategory[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});

		return getBarVerticalOption(data5);
	};

	const categoryCounselChartData = () => {
		let counselCategory = resCategoryCount?.counsel_category_count ?? {};

		data5Counsel.xAxisData = _.keys(counselCategory).sort();

		data5Counsel.data.push({
			name: '자문',
			data: data5Counsel.xAxisData.map(item => counselCategory[item]),
			color: STATISTICS_CHART_COLOR.COUNSEL,
		});

		return getBarVerticalOption(data5Counsel);
	};

	const lawsuitManager = () => {
		let managers: string[] = _.keys(resLawsuitManager?.manager).map(item => item.split('|')[0]);

		managers = managers.filter((item, idx) => managers.indexOf(item) === idx);

		managers.map((item, idx) => {
			let manageInner = `${item}|INNER_LAWYER`;
			let manageOuter = `${item}|OUTER_LAWYER`;
			let manageEtc = `${item}|ETC`;
			const colorKey =
				resLawsuitManager && !_.isEmpty(resLawsuitManager?.manager)
					? (Object.keys(resLawsuitManager.manager)[0].split('|')[1] as
							| 'INNER_LAWYER'
							| 'OUTER_LAWYER'
							| 'ETC')
					: 'INNER_LAWYER';

			return data6.push({
				value:
					(resLawsuitManager !== undefined && resLawsuitManager.manager[manageInner] !== undefined
						? resLawsuitManager.manager[manageInner]
						: 0) +
					(resLawsuitManager !== undefined && resLawsuitManager.manager[manageOuter] !== undefined
						? resLawsuitManager.manager[manageOuter]
						: 0) +
					(resLawsuitManager !== undefined && resLawsuitManager.manager[manageEtc] !== undefined
						? resLawsuitManager.manager[manageEtc]
						: 0),
				name: item,
				color: STATISTICS_CHART_COLOR[`LAWSUIT_${colorKey}`],
			});
		});

		return getPieOption({ data: data6, name: '소송 담당자 배정현황(진행중)' });
	};

	// const data7 = {
	// 	xAxisData: ['김검토', '최검토', '박검토', '장검토', '리검토', '창검토'],
	// 	data: [
	// 		{
	// 			name: '내부',
	// 			data: [75, 51, 33, 12, 28, 5],
	// 			color: tailwindColors.yellow[400],
	// 		},
	// 		{
	// 			name: '외부',
	// 			data: [88, 26, 56, 22, 29, 47],
	// 			color: tailwindColors.yellow[200],
	// 		},
	// 	],
	// };
	const lawsuitManagerInnerOrOuter = () => {
		let managers: string[] = _.keys(resLawsuitManager?.manager)
			.map(item => item.split('|')[0])
			.sort();
		let types: string[] = _.keys(resLawsuitManager?.manager)
			.map(item => item.split('|')[1])
			.sort();
		let innerCnt: number[] = [];
		let outerCnt: number[] = [];
		let etcCnt: number[] = [];

		managers = managers.filter((item, idx) => managers.indexOf(item) === idx);

		data7.xAxisData = managers;

		innerCnt = managers.map(item => {
			return resLawsuitManager !== undefined && resLawsuitManager.manager[`${item}|INNER_LAWYER`] !== undefined
				? resLawsuitManager.manager[`${item}|INNER_LAWYER`]
				: 0;
		});

		outerCnt = managers.map(item => {
			return resLawsuitManager !== undefined && resLawsuitManager.manager[`${item}|OUTER_LAWYER`] !== undefined
				? resLawsuitManager.manager[`${item}|OUTER_LAWYER`]
				: 0;
		});

		etcCnt = managers.map(item => {
			return resLawsuitManager !== undefined && resLawsuitManager.manager[`${item}|ETC`] !== undefined
				? resLawsuitManager.manager[`${item}|ETC`]
				: 0;
		});

		data7.data.push({
			name: '내부',
			data: innerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_INNER_LAWYER,
		});

		data7.data.push({
			name: '외부',
			data: outerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_OUTER_LAWYER,
		});

		if (types.indexOf('ETC') >= 0) {
			data7.data.push({
				name: '기타',
				data: etcCnt,
				color: STATISTICS_CHART_COLOR.LAWSUIT_ETC,
			});
		}

		return getBarVerticalOption(data7);
	};

	useEffect(() => {
		getStatistics({
			subsidiary: -1,
			period: 'month',
			startDate: '',
			endDate: '',
		});
	}, []);

	useEffect(() => {
		const periodMap: Record<PeriodOptionValue, { startDate: Dayjs; endDate: Dayjs }> = {
			week: { startDate: getStartDateOfWeek(1), endDate: getStartDateOfWeek(1).add(6, 'day') },
			lastweek: {
				startDate: getStartDateOfWeek(1).subtract(1, 'week'),
				endDate: getStartDateOfWeek(1).subtract(1, 'week').add(6, 'day'),
			},
			month: {
				startDate: dayjs().startOf('month'),
				endDate: dayjs().endOf('month'),
			},
			lastmonth: {
				startDate: dayjs().subtract(1, 'month').startOf('month'),
				endDate: dayjs().subtract(1, 'month').endOf('month'),
			},
			first_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 0, 1)),
				endDate: dayjs(new Date(dayjs().year(), 2, 31)),
			},
			second_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 3, 1)),
				endDate: dayjs(new Date(dayjs().year(), 5, 30)),
			},
			third_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 6, 1)),
				endDate: dayjs(new Date(dayjs().year(), 8, 30)),
			},
			fourth_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 9, 1)),
				endDate: dayjs(new Date(dayjs().year(), 11, 31)),
			},
			year: {
				startDate: dayjs().startOf('year'),
				endDate: dayjs().endOf('year'),
			},
			lastyear: {
				startDate: dayjs().subtract(1, 'year').startOf('year'),
				endDate: dayjs().subtract(1, 'year').endOf('year'),
			},
			custom: {
				startDate: dayjs(),
				endDate: dayjs(),
			},
		};

		const selectOption = formik.values.search_date_select as PeriodOptionValue;

		if (selectOption !== 'custom') {
			formik.setValues({
				...formik.values,
				search_date_start: periodMap[selectOption].startDate.format('YYYY.MM.DD'),
				search_date_end: periodMap[selectOption].endDate.format('YYYY.MM.DD'),
			});
		}
		setPeriod(formik.values.search_date_select);
	}, [formik.values.search_date_select]);

	useEffect(() => {}, [formik.values.search_date_start, formik.values.search_date_start]);

	const isSelectCustomDate = formik.values.search_date_select === 'custom';

	useEffect(() => {
		setPeriod(formik.values.search_date_select);
		setStartDate(formik.values.search_date_start);
		setEndDate(formik.values.search_date_end);
	}, [formik.values.search_date_start, formik.values.search_date_start]);

	// useEffect(() => {
	// 	getStatistics({ subsidiary, period: 'month', startDate: '', endDate: '' });
	// }, [subsidiary]);

	return (
		<section className='flex flex-col gap-6 px-6 py-14'>
			<header className='flex flex-col gap-6'>
				<div className='flex flex-col items-center justify-between gap-6 lg:flex-row'>
					<h2 className='text-2xl'>대시보드</h2>
					<div className='flex items-center gap-5'>
						{/* {isSubsidiaryManage && (
							<div>
								<InputSelect
									id='search_subsidiary_select'
									label='계열사'
									options={[{ name: '전체', value: 'all' }]}
									required
									formik={formik}
								/>
							</div>
						)} */}
						<SubsidiarySelector individualId={subsidiary} setIndividualId={setSubsidiary} />
						<div>
							<InputSelect
								id='search_date_select'
								label='조회'
								options={[
									{ name: '주간', value: 'week' },
									{ name: '지난주', value: 'lastweek' },
									{ name: '월간', value: 'month' },
									{ name: '지난달', value: 'lastmonth' },
									{ name: '1분기', value: 'first_quarter' },
									{ name: '2분기', value: 'second_quarter' },
									{ name: '3분기', value: 'third_quarter' },
									{ name: '4분기', value: 'fourth_quarter' },
									{ name: '연도별', value: 'year' },
									{ name: '작년', value: 'lastyear' },
									// { name: '지난 7일', value: '5' },
									// { name: '지난 2주', value: '6' },
									// { name: '지난 1개월', value: '7' },
									// { name: '지난 3개월', value: '8' },
									// { name: '지난 6개월', value: '9' },
									// { name: '지난 12개월', value: '10' },
									{ name: '직접지정', value: 'custom' },
								]}
								required
								formik={formik}
							/>
						</div>
						<InputDate
							id='search_date_start'
							label='조회 시작일'
							format='YYYY.MM.DD (ddd)'
							formik={formik}
							disabled={!isSelectCustomDate}
							shouldDisableDate={date => date > dayjs(formik.values.search_date_end)}
						/>
						<span>~</span>
						<InputDate
							id='search_date_end'
							label='조회 종료일'
							format='YYYY.MM.DD (ddd)'
							formik={formik}
							disabled={!isSelectCustomDate}
							shouldDisableDate={date => date < dayjs(formik.values.search_date_start)}
						/>
						<ButtonText text='조회' onClick={() => formik.handleSubmit()} />
					</div>
				</div>
				<CardBox className='p-2 border-2 border-blue-800'>
					<h3 className='text-2xl text-blue-800 text-center'>
						{isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 현황' : '계약 현황'}
					</h3>
				</CardBox>
			</header>
			<article className='grid grid-cols-1 lg:grid-cols-2 2xl:grid-cols-4 gap-6'>
				<CardBox className='flex flex-col gap-6 p-6'>
					<p className='flex items-center gap-2 text-lg font-bold text-neutral-500'>
						<span className='inline-block w-3 h-3 rounded-full bg-blue-600' />
						실시간 검토중 건수
					</p>
					<div className='flex items-center gap-6'>
						<span className='text-4xl'>
							{(resRealtimeStatisticsData?.contract_count ?? 0) +
								(resRealtimeStatisticsData?.counsel_count ?? 0)}
						</span>
						{/* <span className='text-m text-neutral-500'>+ 0.5%</span> */}
					</div>
				</CardBox>
				<CardBox className='flex flex-col gap-6 p-6'>
					<p className='flex items-center gap-2 text-lg font-bold text-neutral-500'>
						<span className='inline-block w-3 h-3 rounded-full bg-blue-600' />
						신규 검토 건수
					</p>
					<div className='flex items-center gap-6'>
						<span className='text-4xl'>
							{(resNewReviewStatisticsData?.contract_count ?? 0) +
								(resNewReviewStatisticsData?.counsel_count ?? 0)}
						</span>
						<span className='text-m text-neutral-500'>{calculateReviewVariance()}%</span>
					</div>
				</CardBox>
				<CardBox className='flex flex-col gap-6 p-6'>
					<p className='flex items-center gap-2 text-lg font-bold text-neutral-500'>
						<span className='inline-block w-3 h-3 rounded-full bg-blue-600' />
						검토 처리 건수
					</p>
					<div className='flex items-center gap-6'>
						<span className='text-4xl'>
							{(resReviewTimesStatisticsData?.reviewed_contract_ids.length ?? 0) +
								(resReviewTimesStatisticsData?.reviewed_counsel_ids.length ?? 0)}
						</span>
						<span className='text-m text-neutral-500'>{calculateCompleteVariance()}%</span>
					</div>
				</CardBox>
				<CardBox className='flex flex-col gap-6 p-6'>
					<p className='flex items-center gap-2 text-lg font-bold text-neutral-500'>
						<span className='inline-block w-3 h-3 rounded-full bg-blue-600' />
						검토 처리 평균 기간
					</p>
					<div className='flex items-center justify-between gap-4'>
						<div className='flex items-center gap-4'>
							<div className='flex flex-col'>
								<span className='text-sm text-neutral-500'>계약</span>
								<p className='flex items-center gap-1 text-3xl'>
									{calculateContractReviewTime()}
									<span className='text-xl font-bold'>day</span>
								</p>
							</div>

							<span className='text-m text-neutral-500'>{calculateContractReviewTimeVariance()}%</span>
						</div>
						{isModuleEnabled('COUNSEL_MANAGE') && (
							<div className='flex items-center gap-4'>
								<div className='flex flex-col'>
									<span className='text-sm text-neutral-500'>자문</span>
									<p className='flex items-center gap-1 text-3xl'>
										{calculateCounselReviewTime()}
										<span className='text-xl font-bold'>day</span>
									</p>
								</div>

								<span className='text-m text-neutral-500'>{calculateCounselReviewTimeVariance()}%</span>
							</div>
						)}
					</div>
				</CardBox>
			</article>
			<article className='grid grid-cols-1 lg:grid-cols-2 gap-6'>
				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>
						{isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 검토 건수' : '계약 신규 검토 건수'}
					</p>
					<div className='flex relative'>
						<div className='absolute left-0 top-0'>
							<p className='text-5xl'>{getNewReviewTotal()}</p>
						</div>
						<div className='w-full'>
							{/* <Chart option={getPieOption({ data, name: '신규 검토 법무별 비중' })} /> */}
							<Chart option={newReviewChartData()} />
						</div>
					</div>
				</CardBox>

				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>
						{isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 상태별 건수' : '계약 상태별 건수'}
					</p>
					<div className='flex relative'>
						<div className='absolute left-0 top-0'>
							<p className='text-5xl'>{getStatusRateTotal()}</p>
						</div>
						<div className='w-full'>
							<Chart option={reviewStatusRateChartData()} />
						</div>
					</div>
				</CardBox>
			</article>
			<article className='grid grid-cols-1 gap-6'>
				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>
						{isModuleEnabled('COUNSEL_MANAGE')
							? '계약/자문 검토 처리 건수 추이'
							: '계약 검토 처리 건수 추이'}
					</p>
					<div className='w-full'>
						<Chart option={reviewedCountLineChart()} />
					</div>
				</CardBox>
			</article>
			<article className='grid grid-cols-1 gap-6'>
				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>
						{isModuleEnabled('COUNSEL_MANAGE')
							? '계약/자문 신규 발생 건수 추이'
							: '계약 신규 발생 건수 추이'}
					</p>
					<div className='w-full'>
						<Chart option={newReviewCountLineChart()} />
					</div>
				</CardBox>
			</article>
			<article className='grid grid-cols-1 gap-6'>
				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>
						{isModuleEnabled('COUNSEL_MANAGE')
							? '신규 계약/자문 검토자 배정 현황'
							: '신규 계약 검토자 배정 현황'}
					</p>
					<div className='w-full'>
						<Chart option={reviewManagerChartData()} />
					</div>
				</CardBox>
			</article>
			<article className='grid grid-cols-1 gap-6'>
				<CardBox className='flex flex-col gap-4 p-6'>
					<p className='font-bold text-neutral-600'>계약 카테고리별 신규 현황</p>
					<div className='w-full'>
						<Chart option={categoryChartData()} />
					</div>
				</CardBox>
			</article>
			{isModuleEnabled('COUNSEL_MANAGE') && (
				<article className='grid grid-cols-1 gap-6'>
					<CardBox className='flex flex-col gap-4 p-6'>
						<p className='font-bold text-neutral-600'>자문 카테고리별 신규 현황</p>
						<div className='w-full'>
							<Chart option={categoryCounselChartData()} />
						</div>
					</CardBox>
				</article>
			)}

			{isModuleEnabled('APP_LAWSUIT') && (
				<header>
					<CardBox className='p-2 border-2 border-blue-800'>
						<h3 className='text-2xl text-blue-800 text-center'>소송 현황</h3>
					</CardBox>
				</header>
			)}
			{isModuleEnabled('APP_LAWSUIT') && (
				<article className='grid grid-cols-1 lg:grid-cols-3 gap-6'>
					<CardBox className='col-span-1 flex flex-col gap-4 p-6'>
						<p className='font-bold text-neutral-600'>신규소송 담당자 배정현황</p>
						<div className='w-full'>
							<Chart option={lawsuitManager()} />
						</div>
					</CardBox>

					<CardBox className='col-span-1 flex flex-col gap-4 p-6 lg:col-span-2'>
						<p className='font-bold text-neutral-600'>{`${t('cmmn_label_lawsuit_external_delegation')}`}</p>
						<div className='flex'>
							<div className='w-full'>
								<Chart option={lawsuitManagerInnerOrOuter()} />
							</div>
						</div>
					</CardBox>
				</article>
			)}
		</section>
	);
}
