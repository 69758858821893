import { MouseEvent } from 'react';
import { MenuItem } from '@mui/material';
import { MenuData } from '../../../../model/header';

export default function MenuItem3Depth({
	childItem,
	onClose,
}: {
	childItem: MenuData;
	onClose: (e: MouseEvent<HTMLElement>) => void;
}) {
	return (
		<MenuItem classes={{ root: 'text-sm' }} onClick={onClose}>
			{childItem.title}
		</MenuItem>
	);
}
