import { t } from 'i18next';

export type LabelTextKeys =
	| 'creator'
	| 'dept_mgr'
	| 'contract_deny'
	| 'contract_reviewer'
	| 'contract_related'
	| 'manage_group'
	| 'report_submit'
	| 'confirm_cancel'
	| 'dept_cancel'
	| 'ori_grecov_proc'
	| 'seal_process'
	| 'seal_process_btn'
	| 'seal_process_alarm_msg'
	| 'contract_recall'
	| 'orig_recov'
	| 'contract_orig'
	| 'contract_copy'
	| 'contract_review_ing'
	| 'contract_status'
	| 'dept_confirm'
	| 'user_rank'
	| 'approval_request'
	| 'dept_mgr_appr_btn'
	| 'dept_mgr_deny_btn'
	| 'outer_user_tab'
	| 'confirm_cancel_content'
	| 'dept_cancel_content'
	| 'dept_mgr_be'
	| 'dept_mgr_obj'
	| 'paper_scan_contract'
	| 'contract_recall_icon_syntax'
	| 'business_conference'
	| 'sign_enable'
	| 'user_add_name_lable'
	| 'auto_update_valid_content'
	| 'auto_update_valid_content_short'
	| 'auto_update_option_docstring'
	| 'add_alarm_content'
	| 'create_add_file_docstring'
	| 'create_add_file_content'
	| 'receive_check_btn'
	| 'class_name_bt_red'
	| 'subsidiary_contract_content'
	| 'subsidiary_contract'
	| 'contract_info'
	| 'letter_of_approval'
	| 'contract_title'
	| 'contract_main_contractor'
	| 'contract_paper_file'
	| 'change_sign_type'
	| 'recovery_sign_type'
	| 'pass_contract_to_next_flow_btn'
	| 'pass_to_creator'
	| 'pass_to_creator_msg'
	| 'close_contract'
	| 'paper_scan_contract_btn'
	| 'counsel_dept_approval_btn'
	| 'cooperative_companies'
	| 'counsel_sts_editable'
	| 'contract_paper_file_notice'
	| 'contract_paper_download_notice'
	| 'lawsuit_opposite_company'
	| 'contract_sts_wf_draft'
	| 'change_sign_type_popup_msg'
	| 'dispute'
	| 'lawsuit_address'
	| 'lawsuit_system_alarm'
	| 'sign_on_paper'
	| 'migration_stamp'
	| 'migration_contract'
	| 'migration_counsel'
	| 'migration_lawsuit'
	| 'final'
	| 'signatory_option'
	| 'paper_signatory_option'
	| 'signatory_does_not_exist_alarm'
	| 'review_signatory_does_not_exist_alarm'
	| 'btn_select_dept'
	| 'btn_select_related'
	| 'btn_create'
	| 'request_dept_popup_title'
	| 'request_dept_popup_content'
	| 'btn_request_approval'
	| 'btn_register'
	| 'approval_popup_title'
	| 'approval_popup_content'
	| 'deny_popup_title'
	| 'deny_popup_content'
	| 'btn_approval'
	| 'btn_deny'
	| 'alert_approval_popup_content'
	| 'alert_deny_popup_content'
	| 'request_creator_comment'
	| 'request_dept_approval_comment'
	| 'request_dept_deny_comment'
	| 'add_file_content'
	| 'counsel_header_dochtml'
	| 'counsel_private_snb'
	| 'counsel_private_sbu_title'
	| 'default_send_message'
	| 'system_manual_name'
	| 'system_manual_download'
	| 'system_manual_name_contract_management'
	| 'multi_receiver'
	| 'notice'
	| 'lawsuit'
	| 'create'
	| 'doc_received'
	| 'send_contract'
	| 'is_new_contract_false'
	| 'is_contract_change'
	| 'auto_update_valid_info_message'
	| 'sign_user'
	| 'add_pop_sign_user'
	| 'related_user'
	| 'counsel_related_user'
	| 'subsidiary_name'
	| 'counsel_circulation'
	| 'field_user'
	| 'pdf_down_btn_comment'
	| 'change_sign_type_comment'
	| 'recovery_sign_type_comment'
	| 'do_seal_process_comment'
	| 'id_for_search'
	| 'contract_scanfile_name'
	| 'contract_scanfile_upload'
	| 'contract_scanfile_reupload'
	| 'contract_complate'
	| 'counsel_representative'
	| 'contract_process_stopped'
	| 'counsel_process_stopped'
	| 'contract'
	| 'LAWSUIT_PROGRESS_TYPE'
	| 'INNER_LAWYER'
	| 'OUTER_LAWYER'
	| 'LAWSUIT_PROGRESS_TYPE_ETC'
	| 'contract_review_done_msg'
	| 'contract_content'
	| 'search_text'
	| 'contract_from_startdate_notice'
	| 'contract_from_enddate_notice'
	| 'contract_until_startdate_notice'
	| 'contract_until_enddate_notice'
	| 'contract_sts_wf_legal_re_review'
	| 'cancellation_of_the_department'
	| 'add_related_person'
	| 'add_related_person_msg'
	| 'view_code_contract'
	| 'view_code_counsel'
	| 'counsel_guide_advisory_related_person'
	| 'add_related_completed'
	| 'department_head_approval'
	| 'need_the_approval_of_the_department'
	| 'cancel_dept_req'
	| 'reject_dept_req'
	| 'del_related_person'
	| 'contract_del_relation_msg'
	| 'counsel_del_relation_msg'
	| 'cancel_dept_req_tooltip'
	| 'req_dept_tooltip'
	| 'approval_dept_tooltip'
	| 'reject_dept_tooltip'
	| 'counsel_re_req_dept_tooltip'
	| 'dept_reject_his'
	| 'waiting_for_approval'
	| 'send_support_email'
	| 'guide_send_support_email'
	| 'support_email_request_content'
	| 'cmmn_label_lawsuit_external_delegation'
	| 'cmmn_label_number_no'
	| 'seal_stamp'
	| 'stamping'
	| 'seal'
	| 'final_approval_waiting'
	| 'etc_contract'
	| 'sign_and_seal';

export const LABEL_TEXT_MAP: Record<LabelTextKeys, string> = {
	creator: t('MSG_ID_61'),
	dept_mgr: t('label_department_manager'),
	contract_deny: t('label_rejection'),
	contract_reviewer: t('MSG_ID_62'),
	contract_related: t('MSG_ID_1035'),
	manage_group: t('MSG_ID_18'),
	report_submit: t('label_reverence'),
	confirm_cancel: t('label_reverence_cancellation'),
	dept_cancel: t('label_reverence_cancellation'),
	ori_grecov_proc: t('contract_label_request_for_return_of_original'),
	seal_process: t('label_wedding_and_recovery'),
	seal_process_btn: t('label_styles'),
	seal_process_alarm_msg: t('contract_guide_ask_the_person_in_charge_of_the_seal_to_have_it_stamped'),
	contract_recall: t('label_collect'),
	orig_recov: t('MSG_ID_496'),
	contract_orig: t('contract_guide_return_of_the_original_contract'),
	contract_copy: t('contract_guide_recreate_a_contract_based_on_this_contract'),
	contract_review_ing: t('MSG_ID_28'),
	contract_status: t('MSG_ID_181'),
	dept_confirm: t('contract_guide_report_to_department_head'),
	user_rank: t('MSG_ID_103'),
	approval_request: t('MSG_ID_442'),
	dept_mgr_appr_btn: t('MSG_ID_440'),
	dept_mgr_deny_btn: t('label_determination_of_department_heads'),
	outer_user_tab: t('cmmn_label_outside_related_person'),
	confirm_cancel_content: t('cmmn_label_cancel_the_appeal_request'),
	dept_cancel_content: t('cmmn_label_cancel_the_appeal_request'),
	dept_mgr_be: t('contract_guide_department_head'),
	dept_mgr_obj: t('contract_guide_head_of_department'),
	paper_scan_contract: t('contract_warn_please_scan_a_contract_document_and_register'),
	contract_recall_icon_syntax: t('MSG_ID_33'),
	business_conference: t('MSG_ID_438'),
	sign_enable: t('MSG_ID_32'),
	user_add_name_lable: t('MSG_ID_99'),
	auto_update_valid_content: t('cmmn_label_autorenew_option'),
	auto_update_valid_content_short: t('cmmn_label_autorenew_option'),
	auto_update_option_docstring: '',
	add_alarm_content: t(
		'contract_guide_an_alarm_email_is_automatically_sent_to_the_drafter_14_days_prior_to_the_contract_completion_date',
	),
	create_add_file_docstring: t('cmmn_warn_this_is_a_contract_that_requires_file_attachments'),
	create_add_file_content: t('MSG_ID_131'),
	receive_check_btn: t('label_confirmation_of_the_reception_contract'),
	class_name_bt_red: '',
	subsidiary_contract_content: t('MSG_ID_708'),
	subsidiary_contract: t('label_line'),
	contract_info: t('MSG_ID_472'),
	letter_of_approval: t('cmmn_label_approval'),
	contract_title: t('label_contract_title'),
	contract_main_contractor: t('MSG_ID_60'),
	contract_paper_file: t('contract_label_draft_contract'),
	change_sign_type: t('contract_label_digital_signature_conversion'),
	recovery_sign_type: t('contract_guide_document_contract_conversion'),
	pass_contract_to_next_flow_btn: t('cmmn_guide_proceed_to_the_next_stage'),
	pass_to_creator: t('label_review_draftsman_delivery'),
	pass_to_creator_msg: t('contract_guide_complete_contract_review'),
	close_contract: t('label_final_companion'),
	paper_scan_contract_btn: '',
	counsel_dept_approval_btn: '',
	cooperative_companies: t('label_partner+/'),
	counsel_sts_editable: t('label_ask_for_review'),
	contract_paper_file_notice: '',
	contract_paper_download_notice: '',
	lawsuit_opposite_company: t('MSG_ID_791'),
	contract_sts_wf_draft: t('label_drawing'),
	change_sign_type_popup_msg: '',
	dispute: t('MSG_ID_1203'),
	lawsuit_address: t('MSG_ID_139'),
	lawsuit_system_alarm: t('MSG_ID_764'),
	sign_on_paper: t('MSG_ID_428'),
	migration_stamp: t('label_phosphorus'),
	migration_contract: t('label_old_contract'),
	migration_counsel: t('label_old_advisor'),
	migration_lawsuit: t('label_litigation'),
	final: t('label_final_confirm_user'),
	signatory_option: t('cmmn_guide_need_signature_related_person'),
	paper_signatory_option: t('cmmn_warn_contract_opponent'),
	signatory_does_not_exist_alarm: t('contract_guide_there_is_no_signer_please_specify_a_signer'),
	review_signatory_does_not_exist_alarm: t('contract_guide_there_are_no_signers_ask_the_drafter_to_appoint_a_signer'),
	btn_select_dept: t('label_select_department_manager'),
	btn_select_related: t('cmmn_label_add_stakeholder'),
	btn_create: t('MSG_ID_1207'),
	request_dept_popup_title: t('cmmn_label_request_for_approval_of_department_heads'),
	request_dept_popup_content: t('cmmn_label_approval_request_comments'),
	btn_request_approval: t('label_approval_request'),
	btn_register: t('cmmn_label_temporary_storage'),
	approval_popup_title: t('cmmn_guide_approval_opinion'),
	approval_popup_content: t('cmmn_guide_approval_opinion'),
	deny_popup_title: t('label_rebellion'),
	deny_popup_content: t('label_rebellion'),
	btn_approval: t('MSG_ID_442'),
	btn_deny: t('MSG_ID_35'),
	alert_approval_popup_content: t('label_approval_please_put_the_approval_opinion'),
	alert_deny_popup_content: t('cmmn_warn_please_put_your_opinion'),
	request_creator_comment: t('label_dressed_request_comment'),
	request_dept_approval_comment: t('contract_guide_department_head_approval_comments'),
	request_dept_deny_comment: t('contract_guide_comments_from_the_head_of_department'),
	add_file_content: t('MSG_ID_131'),
	counsel_header_dochtml: t('cmmn_guide_exact_answer_comes_from_the_correct_question'),
	counsel_private_snb: t('counsel_label_personal_legal_advice'),
	counsel_private_sbu_title: t('counsel_label_personal_legal_advice'),
	default_send_message: '',
	system_manual_name: t('MSG_ID_863'),
	system_manual_download: t('MSG_ID_403'),
	system_manual_name_contract_management: t('contract_label_contract_management_system'),
	multi_receiver: t('label_recipient'),
	notice: t('MSG_ID_1'),
	lawsuit: t('MSG_ID_7'),
	create: t('MSG_ID_1207'),
	doc_received: t('MSG_ID_46'),
	send_contract: t('label_forwarding'),
	is_new_contract_false: t('MSG_ID_705'),
	is_contract_change: t('MSG_ID_415'),
	auto_update_valid_info_message: t('MSG_ID_81'),
	sign_user: t('MSG_ID_174'),
	add_pop_sign_user: t('MSG_ID_174'),
	related_user: t('MSG_ID_163'),
	counsel_related_user: t('MSG_ID_1035'),
	subsidiary_name: t('label_affiliate_information'),
	counsel_circulation: t('MSG_ID_39'),
	field_user: t('MSG_ID_822'),
	pdf_down_btn_comment: t('contract_guide_download_the_contract_for_a_stamping_contract'),
	change_sign_type_comment: t('cmmn_guide_convert_it_to_an_electronic_signature'),
	recovery_sign_type_comment: t('contract_guide_back_to_the_document_contract'),
	do_seal_process_comment: '',
	id_for_search: 'ID',
	contract_scanfile_name: t('label_scan'),
	contract_scanfile_upload: t('MSG_ID_492'),
	contract_scanfile_reupload: t('label_re_registration_scanned_copy'),
	contract_complate: t('MSG_ID_54'),
	counsel_representative: t('MSG_ID_734'),
	contract_process_stopped: t('contract_label_contract_termination'),
	counsel_process_stopped: t('label_counsel_stop'),
	contract: t('MSG_ID_5'),
	LAWSUIT_PROGRESS_TYPE: t('MSG_ID_144'),
	INNER_LAWYER: t('MSG_ID_147'),
	OUTER_LAWYER: t('cmmn_label_outside_lawyer_practice'),
	LAWSUIT_PROGRESS_TYPE_ETC: t('MSG_ID_149'),
	contract_review_done_msg: t('cmmn_guide_review_completed_normally'),
	contract_content: t('label_contract'),
	search_text: t('cmmn_label_search_name'),
	contract_from_startdate_notice: t('start_date_enter_the_search_start_date'),
	contract_from_enddate_notice: t('cmmn_guide_enter_the_start_date_and_end_date_of_the_search'),
	contract_until_startdate_notice: t('cmmn_guide_end_date_enter_the_start_date_of_the_search'),
	contract_until_enddate_notice: t('cmmn_guide_end_date_enter_the_search_end_date'),
	contract_sts_wf_legal_re_review: t('label_re_crafted'),
	cancellation_of_the_department: t('contract_guide_cancellation_of_the_department_heads_appeal'),
	add_related_person: t('MSG_ID_462'),
	add_related_person_msg: t('cmmn_guide_username_added_targetuser_as_an_associate'),
	view_code_contract: t('MSG_ID_107'),
	view_code_counsel: t('MSG_ID_128'),
	counsel_guide_advisory_related_person: t('counsel_guide_advisory_related_person'),
	add_related_completed: t('cmmn_guide_additional_user_name_related_person_has_been_added'),
	department_head_approval: t('cmmn_department_head_approval'),
	need_the_approval_of_the_department: t('cmmn_guide_i_need_the_approval_of_the_department_head'),
	cancel_dept_req: t('label_cancellation_of_approval_of_department_head'),
	reject_dept_req: t('label_response_to_the_head_of_the_department_head'),
	del_related_person: t('label_delete_related_person'),
	contract_del_relation_msg: t('cmmn_guide_username_has_removed_targetuser_from_related_people'),
	counsel_del_relation_msg: t('counsel_user_name_deleted_target_user_from_the_relevant_person'),
	cancel_dept_req_tooltip: t('contract_guide_cancel_the_request_for_approval_from_the_head_of_department'),
	req_dept_tooltip: t('contract_guide_request_approval_from_department_head'),
	approval_dept_tooltip: t('contract_guide_department_head_approves'),
	reject_dept_tooltip: t('cmmn_guide_reject_the_department_manager_approval'),
	counsel_re_req_dept_tooltip: t('counsel_guide_request_for_reapproval_of_the_head_of_the_advisory_department'),
	dept_reject_his: t('cmmn_guide_this_is_the_rejection_of_department_heads'),
	waiting_for_approval: t('label_waiting_for_approval'),
	send_support_email: t('cmmn_label_send_support_email'),
	guide_send_support_email: t(
		'cmmn_guide_it_will_be_sent_to_the_legal_support_mail_and_we_will_help_you_with_a_quick_response',
	),
	support_email_request_content: t('MSG_ID_407'),
	cmmn_label_lawsuit_external_delegation: t('cmmn_label_lawsuit_external_delegation'),
	cmmn_label_number_no: t('cmmn_label_number_no'),
	seal_stamp: t('seal_stamp'),
	seal: t('seal'),
	stamping: t('contract_label_seal_proc'),
	final_approval_waiting: t('label_final_approval_waiting'),
	etc_contract: t('etc_contract'),
	sign_and_seal: t('sign_and_seal'),
};
