export const contractSearchParams = {
	contract_title: '',
	contract_auth: '',
	contract_id: '',
	contract_reviewer: '',
	contract_content: '',
	contract_contractor_comp_name: '',
	progress_status: '',
	valid_from_startdate: '',
	valid_from_enddate: '',
	valid_until_startdate: '',
	valid_until_enddate: '',
	group_name: '',
	all_search_text: '',
	attach_name_contents: '',
	search_mode: '0',
	my_contract: 'False',
	search_department_mode: 'False',
	tag_search_allCon: [],
	click_ctg_id: '',
	parent_ctg_id: '',
	ctg_con_list: [],
	status_check: '',
	adele_yn: 'Y',
	banner_clicked: '-1',
	srch_type: '',
	contract_type: 'CONTRACT',
	G_CURR_CLICKED_CTG_ID: -1,
	subsidiary_id: '',
	is_dept_contract: 'False',
	curr_page: 1,
	number_per_page: 10,
	htmlResponse: false,
	order_col: '-1',
	order: 'desc',
};

export const counselSearchParams = {
	counsel_title: '',
	counsel_creater: '',
	counsel_reviewer: '',
	counsel_code: '',
	counsel_content: '',
	counsel_file_content: '',
	all_search_text: '',
	attach_name_contents: '',
	progress_status: '',
	search_mode: '0',
	my_counsel: 'False',
	tag_search_allCon: [],
	counsel_search_tags: [],
	ctg_counsel_id: null,
	status_check: '',
	adele_yn: 'Y',
	banner_clicked: '-1',
	srch_type: '',
	G_CURR_CLICKED_CTG_ID: -1,
	subsidiary_id: '',
	curr_page: 1,
	number_per_page: 10,
	htmlResponse: false,
	order_col: '-1',
	order: 'desc',
};

export const lawsuitSearchParams = {
	lawsuit_title: '',
	lawsuit_content: '',
	main_issue_contents: '',
	lawsuit_idcode: '',
	lawsuit_lawyer: '',
	all_search_text: '',
	search_mode: '0',
	my_lawsuit: 'False',
	lawsuit_search_tags: [],
	valid_from_startdate: '',
	valid_from_enddate: '',
	epic_name: '',
	type: 'LAWSUIT',
	related_person_search: '',
	dispute_step_search: '',
	parties_name_search: '',
	parties_name_status_search: '',
	case_type_search: '',
	compotent_court_search: '',
	case_number_search: '',
	progress_status_search: '',
	type_search: '',
	status_check: '',
	adele_yn: 'Y',
	banner_clicked: '-1',
	srch_type: '',
	G_CURR_CLICKED_CTG_ID: -1,
	subsidiary_id: '',
	curr_page: 1,
	number_per_page: 10,
	htmlResponse: false,
	order_col: '-1',
	order: 'desc',
};
