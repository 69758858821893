import MenuItem1Depth from './MenuItem1Depth';
import { MenuData } from '../../../../model/header';
import LogoIcon from '../../../ui/icons/LogoIcon';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function MenuLogo({ openMenu, onToggle }: Props) {
	const menu: MenuData = {
		link: '/',
		icon: <LogoIcon />,
		title: '',
		classes: { button: 'px-0' },
		display: true,
	};

	return (
		<MenuItem1Depth
			key={menu.title}
			itemData={menu}
			isOpen={openMenu === menu.title}
			onToggle={() => onToggle(menu.title)}
		/>
	);
}
