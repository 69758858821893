import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function ReviewIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M16.8996 20.6169C16.8996 21.1139 16.4967 21.5169 15.9996 21.5169H6.90117C4.58157 21.5169 2.70117 19.6365 2.70117 17.3169V4.68174C2.70117 4.18468 3.10412 3.78174 3.60117 3.78174V3.78174C4.09823 3.78174 4.50117 4.18468 4.50117 4.68174V17.3169C4.50117 18.6424 5.57569 19.7169 6.90117 19.7169H15.9996C16.4967 19.7169 16.8996 20.1198 16.8996 20.6169V20.6169Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M5.70117 5.91675C5.70117 4.25989 7.04432 2.91675 8.70117 2.91675H15.2982C16.1777 2.91675 16.9688 3.29521 17.5176 3.89815C15.7086 4.01706 14.2781 5.52202 14.2781 7.36108C14.2781 8.45172 14.7812 9.42486 15.568 10.0611H8.1C7.76863 10.0611 7.5 10.3297 7.5 10.6611C7.5 10.9925 7.76863 11.2611 8.1 11.2611H15.9C16.2314 11.2611 16.5 10.9925 16.5 10.6611C16.5 10.6401 16.4989 10.6194 16.4968 10.599C16.8852 10.7492 17.3073 10.8316 17.7486 10.8316C17.9357 10.8316 18.1192 10.8168 18.2982 10.7883V15.5167C18.2982 17.1736 16.9551 18.5167 15.2982 18.5167H8.70117C7.04432 18.5167 5.70117 17.1736 5.70117 15.5167V5.91675ZM8.1 7.36108C7.76863 7.36108 7.5 7.62971 7.5 7.96108C7.5 8.29245 7.76863 8.56108 8.1 8.56108H11.6244C11.9558 8.56108 12.2244 8.29245 12.2244 7.96108C12.2244 7.62971 11.9558 7.36108 11.6244 7.36108H8.1ZM8.1 12.7611C7.76863 12.7611 7.5 13.0297 7.5 13.3611C7.5 13.6925 7.76863 13.9611 8.1 13.9611H15.9C16.2314 13.9611 16.5 13.6925 16.5 13.3611C16.5 13.0297 16.2314 12.7611 15.9 12.7611H8.1Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.4191 7.36118C22.4191 9.94063 20.3281 12.0317 17.7486 12.0317C15.1692 12.0317 13.0781 9.94063 13.0781 7.36118C13.0781 4.78173 15.1692 2.69067 17.7486 2.69067C20.3281 2.69067 22.4191 4.78173 22.4191 7.36118ZM17.7486 10.8317C19.6653 10.8317 21.2191 9.27789 21.2191 7.36118C21.2191 5.44447 19.6653 3.89067 17.7486 3.89067C15.8319 3.89067 14.2781 5.44447 14.2781 7.36118C14.2781 9.27789 15.8319 10.8317 17.7486 10.8317Z'
				className='fill-inherit'
			/>
			<path
				d='M18.8186 6.04596C18.7725 6.07112 18.4203 6.40657 18.0345 6.78815L17.3342 7.48422L17.0449 7.20747C16.7011 6.87621 16.504 6.79654 16.2314 6.8804C16.0134 6.9433 15.7912 7.16973 15.7534 7.36262C15.6947 7.68969 15.745 7.78613 16.3572 8.40672C17.0701 9.12795 17.2462 9.21181 17.6152 9.02312C17.6991 8.97699 18.198 8.51155 18.7222 7.98321C19.7411 6.95588 19.8082 6.85944 19.7453 6.52817C19.7118 6.35625 19.5524 6.15079 19.3805 6.06273C19.2421 5.99145 18.9402 5.98306 18.8186 6.04596Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
