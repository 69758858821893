import { TypographyVariantsOptions } from '@mui/material';

// 다크 모드 구현을 위해 테마 모드 정의
export type ThemeMode = 'light' | 'dark';
// 사용할 모든 폰트들
export type FontFamily = 'helvetica, AppleSDGothicNeo, Sans-serif';
export const defaultFontFamily = 'helvetica, AppleSDGothicNeo, Sans-serif';

/**
 * MUI 커스텀 테마
 * @param mode // dark or light
 * @param fontFamily // Font family 정의
 * @returns TypographyVariantsOptions
 */
export const getTypography = (fontFamily: FontFamily = defaultFontFamily): TypographyVariantsOptions => ({
	fontFamily,
	button: {
		fontSize: '1rem',
		textTransform: 'none',
	},
});

// overline: {
// 	lineHeight: 1.66,
// 	color: mode === 'light' ? palette.grey?.[900] : palette.grey?.[50],
// },
