import { ButtonOwnPropsOverride } from '../../../model/button';
import ButtonText from './ButtonText';

const arrow = `inline-block w-3 h-3 -rotate-135`;
const before = `before:block before:content-[''] before:origin-top-right before:transition-all before:w-full before:h-full before:border-solid before:border-[currentColor] before:border-t before:border-r`;
const after = `after:block after:content-[''] after:origin-top-right after:transition-all after:w-full after:h-full after:border-solid after:border-[currentColor] after:float-left after:relative after:-top-[90%] after:border-r`;

const afterHover = `group-hover/arrow:after:h-full group-hover/arrow:after:rotate-45`;
const beforeHover = `group-hover/arrow:before:scale-90`;

export default function ButtonBack(props: ButtonOwnPropsOverride) {
	return (
		<ButtonText size='small' className='group/arrow min-w-0 gap-1' {...props}>
			<span className={`${arrow} ${before} ${after} ${afterHover} ${beforeHover}`} />
		</ButtonText>
	);
}
