import Badge from '@mui/material/Badge';
import { PickersDay, PickersDayProps } from '@mui/x-date-pickers/PickersDay';
import { Dayjs } from 'dayjs';

export default function CalendarHighlightDate(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
	const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

	const isSelected = !outsideCurrentMonth && highlightedDays.indexOf(day.date()) >= 0;

	return (
		<Badge
			key={day.toString()}
			classes={{ badge: 'top-3/4 right-1/2 min-w-0 w-1.5 h-1.5 p-0 bg-red-600' }}
			overlap='circular'
			badgeContent={isSelected ? '' : undefined}
		>
			<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
		</Badge>
	);
}
