import { Editor, IAllProps } from '@tinymce/tinymce-react';
import './style.css';

type TPropsOverrides = IAllProps & {
	onContentChange?: (content: string) => void;
};

function TinyMCE({ onContentChange, ...props }: TPropsOverrides) {
	const { init } = props;

	return (
		<Editor
			apiKey=''
			init={{
				...init,
				height: init?.height || 350,
				menubar: init?.menubar || false,
				toolbar:
					init?.toolbar ||
					'undo redo | formatselect | bold italic backcolor | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | removeformat | help',
				branding: init?.branding || false,
				statusbar: init?.statusbar || false,
				block_unsupported_drop: init?.block_unsupported_drop || false,
				placeholder: init?.placeholder || '내용을 입력해 주세요',
			}}
			onEditorChange={(content, editor) => {
				if (onContentChange) onContentChange(content);
			}}
			onDragOver={e => e.preventDefault()}
			{...props}
		/>
	);
}

export default TinyMCE;
