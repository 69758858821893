import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { queryKey } from '../../constants/queryKey';
import { SubsidiaryProvider } from '../../context/SubsidiaryContext';
import { getSubsidiaryList } from '../../api/dashboard';
import { useUser } from '../../context/UserContext';

type Props = {
	children: React.ReactNode;
};

export default function SubsidiarySetter({ children }: Props) {
	const { permission } = useUser();

	const isActive = permission.ACTIVATE_SUBSIDIARY;
	const fetchSubsidiary = isActive ? getSubsidiaryList : async () => [];
	const { data: subsidiaries } = useQuery(queryKey.subsidiary, fetchSubsidiary);

	return (
		<SubsidiaryProvider subsidiaries={subsidiaries || []} isActive={isActive}>
			{children}
		</SubsidiaryProvider>
	);
}
