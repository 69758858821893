import { Chip, ChipOwnProps } from '@mui/material';

type ChipOwnPropsOverrides = ChipOwnProps & { text: string };
export default function StateChip({
	text,
	classes = {
		root: 'text-m font-normal text-neutral-600 px-1',
	},
}: ChipOwnPropsOverrides) {
	return <Chip label={text} size='small' variant='outlined' classes={classes} />;
}
