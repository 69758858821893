import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function CounselIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M22.7097 2.40459C22.7097 1.74185 22.1724 1.20459 21.5097 1.20459H9.62979C8.96704 1.20459 8.42979 1.74185 8.42979 2.40459V7.20166H14.3694C15.6949 7.20166 16.7694 8.27618 16.7694 9.60166V13.8368H17.102L20.6631 15.5679C20.8623 15.6647 21.0942 15.5196 21.0942 15.298V13.8368H21.5097C22.1724 13.8368 22.7097 13.2996 22.7097 12.6368V2.40459ZM2.48955 8.40166C1.82681 8.40166 1.28955 8.93892 1.28955 9.60166V19.8339C1.28955 20.4966 1.82681 21.0339 2.48955 21.0339H2.90498V22.4951C2.90498 22.7167 3.13688 22.8618 3.33614 22.7649L6.89724 21.0339H14.3694C15.0322 21.0339 15.5694 20.4966 15.5694 19.8339V9.60166C15.5694 8.93892 15.0322 8.40166 14.3694 8.40166H2.48955ZM3.94912 11.9308C3.94912 11.5994 4.21775 11.3308 4.54912 11.3308H8.07354C8.40491 11.3308 8.67353 11.5994 8.67353 11.9308C8.67353 12.2621 8.40491 12.5308 8.07354 12.5308H4.54912C4.21775 12.5308 3.94912 12.2621 3.94912 11.9308ZM3.94912 14.6308C3.94912 14.2994 4.21775 14.0308 4.54912 14.0308H12.3491C12.6805 14.0308 12.9491 14.2994 12.9491 14.6308C12.9491 14.9621 12.6805 15.2308 12.3491 15.2308H4.54912C4.21775 15.2308 3.94912 14.9621 3.94912 14.6308ZM3.94912 17.3308C3.94912 16.9994 4.21775 16.7308 4.54912 16.7308H12.3491C12.6805 16.7308 12.9491 16.9994 12.9491 17.3308C12.9491 17.6621 12.6805 17.9308 12.3491 17.9308H4.54912C4.21775 17.9308 3.94912 17.6621 3.94912 17.3308Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
