import { MouseEvent } from 'react';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';
import StatusItemDashboard from './StatusItemDashboard';

type Item = {
	count?: number;
	label: string;
	onClick: (e?: MouseEvent<HTMLButtonElement> | undefined) => void;
	display?: boolean;
};

type PlusBtn = {
	display?: boolean;
	classes?: string;
	text?: string;
	onClick?: (e?: MouseEvent<HTMLButtonElement> | undefined) => void;
};

type Props = {
	className?: string;
	title: string;
	items: Item[];
	plusBtn?: PlusBtn;
};

export default function StatusCardDashboard({ title, items, className, plusBtn }: Props) {
	return (
		<CardBox className={`flex flex-col gap-2 p-6 ${className}`}>
			<h3 className='text-xl font-bold'>{title}</h3>
			<ul className='flex flex-wrap justify-end'>
				{items
					.filter(({ display = true }) => display)
					.map(({ label, count, onClick }) => (
						<StatusItemDashboard key={label} count={count} label={label} onClick={onClick} />
					))}
			</ul>
			{plusBtn?.display ? (
				<ButtonText onClick={plusBtn.onClick} classes={{ root: plusBtn?.classes }}>
					{plusBtn.text}
				</ButtonText>
			) : null}
		</CardBox>
	);
}
