import { Chip, ChipOwnProps } from '@mui/material';
import { FormikValues } from 'formik';
import PersonIcon from '../icons/PersonIcon';
import ClearIcon from '../icons/ClearIcon';
import { ExternalUser } from '../../../model/user';

type ChipOwnPropsOverrides = ChipOwnProps & { id: string; user: ExternalUser; formik?: FormikValues };
export default function ExternalUserChip({ id, user, formik, disabled, ...props }: ChipOwnPropsOverrides) {
	const { email, name } = user;
	const handleDelete = () => {
		formik?.setFieldValue(
			id,
			formik.values[id].filter(({ email: e }: ExternalUser) => e !== email),
		);
	};

	return (
		<Chip
			id={id}
			icon={<PersonIcon className='-mt-0.5' />}
			label={name}
			classes={{
				root: 'px-3 bg-neutral-100',
				icon: 'ml-0',
				label: 'pr-0 pl-3',
				disabled: 'opacity-100',
			}}
			deleteIcon={<ClearIcon className='m-0 -mt-0.5' />}
			onDelete={!disabled ? handleDelete : undefined}
			onMouseDown={e => e.stopPropagation()}
			onFocus={e => e.stopPropagation()}
			{...props}
		/>
	);
}
