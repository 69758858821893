import axios from 'axios';
import _ from 'lodash';
import { PDFDocument } from 'pdf-lib';
import { pdfData } from '../model/api/contract';
import { Sign } from '../model/sign';

export async function addImageToPdf(pdfObject: pdfData, signs: Sign[]) {
	const pdfDoc = await PDFDocument.load(pdfObject.file);

	await Promise.all(
		_.chain(signs)
			.map(async sign => {
				const { pageIndex, imageUrl, width, height, x, y } = sign;

				const imageRes = await axios.get(imageUrl, { responseType: 'arraybuffer' });
				const imageBytes = imageRes.data;
				const imageExtension = imageUrl.split('.').pop()!.toUpperCase();

				const image =
					imageExtension === 'PNG' ? await pdfDoc.embedPng(imageBytes) : await pdfDoc.embedJpg(imageBytes);

				const page = pdfDoc.getPage(pageIndex);
				const { width: pageWidth, height: pageHeight } = page.getSize();

				page.drawImage(image, {
					x,
					y: pageHeight - height - y,
					width,
					height,
				});
			})
			.value(),
	);

	const pdfBytes = await pdfDoc.save();

	return pdfBytes;

	// download(pdfBytes, pdfObject.fileName, 'application/pdf');
}
