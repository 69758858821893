import LogoutIcon from '@mui/icons-material/Logout';
import { Menu, MenuItem } from '@mui/material';
import { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUser } from '../../../../context/UserContext';
import { useTheme } from '../../../../hooks/useTheme';
import ButtonText from '../../../ui/buttons/ButtonText';
import ExpandMoreIcon from '../../../ui/icons/ExpandMoreIcon';
import SettingsIcon from '../../../ui/icons/SettingsIcon';
import { useConcurrentJob } from '../../../../hooks/useConcurrentJob';
import { doaBackdo, doaCheck } from '../../../../api/login';
import PopupCommonInternal from '../../../ui/popups/PopupCommonInternal';

interface IProps {
	openMenu: string | null;
	onToggle: (title: string) => void;
}
export default function MenuUserOptions({ openMenu, onToggle }: IProps) {
	const { loginUser } = useUser();
	const theme = useTheme();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { setLoginUser } = useUser();

	const { refetchConcurrentJobList, getConcurrentJobList } = useConcurrentJob();
	const { data: concurrentJobList, isLoading, error } = getConcurrentJobList;

	const concurrentList = concurrentJobList?.concurrent_job_list;

	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleNavigate = (link: string) => {
		window.location.href = link;
	};

	return (
		<div>
			<ButtonText
				className='flex-col gap-5 min-w-0 min-h-0 h-full px-7 py-4 text-base font-bold rounded-none'
				onClick={handleClick}
			>
				<div className='flex items-center'>
					{loginUser.name}
					<ExpandMoreIcon
						fontSize='small'
						className={`text-neutral-300 transition-transform ${open ? 'rotate-180' : ''}`}
					/>
				</div>
			</ButtonText>
			<Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
				<li className='px-4 pb-1 font-bold border-b'>{t('label_setting')}</li>
				<li>
					<ul>
						<MenuItem
							classes={{ root: 'justify-between gap-4' }}
							onClick={() => handleNavigate(`${DOMAIN_URL}/setup/mypage_info/`)}
						>
							<p className='text-sm'>{t('MSG_ID_3')}</p>
							<SettingsIcon className='text-gray-500' fontSize='small' />
						</MenuItem>
						<MenuItem classes={{ root: 'justify-between gap-4' }} onClick={() => handleNavigate('/logout')}>
							<p className='text-sm'>{t('MSG_ID_4')}</p>
							<LogoutIcon className='text-gray-500' fontSize='small' />
						</MenuItem>
					</ul>
				</li>
				<li className='px-4 pb-1 mt-5 font-bold border-b'>{t('label_concurrent_account_conversion')}</li>
				{
					loginUser.isDOA ? <PopupCommonInternal
						openTrigger={
							<ButtonText className='px-4 py-4'>{t('cmmn_label_switch_to_my_account')}</ButtonText>
						}
						msg={t('would_you_like_to_switch_to_my_account_1')}
						confirmClose={false}
						onConfirm={() => {
							doaBackdo({ navigate });
						}}
						onCancel={() => false}
					/> :
					concurrentList && concurrentList.length > 0 && (
						<li>
							<ul>
							{concurrentList.map((job, index) => (
								<MenuItem key={String(job.doa_id)} classes={{ root: 'items-center gap-2' }} onClick={() => {doaCheck({ index: Number(job.doa_id), navigate, setLoginUser, doaProcLoginId: String(job.doa_id) });}}>
									<p className='text-sm'>{job.name} {job.subsidiary_name}{job.dept_name ? `/${job.dept_name}` : ''}{job.rank ? `/${job.rank}` : ''}</p>
									<p className='flex items-center justify-center w-4 h-4 text-white text-xs rounded-full bg-red-600'>{String(job.cnt)}</p>
									<p className='px-2 ml-auto text-xs rounded-full border-2' style={{ borderColor: theme.palette.primary.main }}>{t('cmmn_label_transform')}</p>
								</MenuItem>
							))}
							</ul>
						</li>
					)
				}
			</Menu>
		</div>
	);
}