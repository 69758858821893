import { useLocation } from 'react-router-dom';
import DetailInfoPaperManage from '../../../components/main/paperManage/DetailInfoPaperManage';
import PaperManageDetailList from '../../../components/main/paperManage/PaperManageDetailList';

export default function PaperManageDetail() {
	const { state } = useLocation();
	return (
		<>
			<DetailInfoPaperManage objId={state.id} />
			<PaperManageDetailList objId={state.id} />
		</>
	);
}
