import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../model/icon';

export default function AddContractIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M16.2675 11.5862C13.6693 11.5862 11.5606 13.6949 11.5606 16.2931C11.5606 18.8913 13.6693 21 16.2675 21C18.8657 21 20.9744 18.8913 20.9744 16.2931C20.9744 13.6949 18.8657 11.5862 16.2675 11.5862ZM18.621 16.7638H16.7382V18.6466H15.7968V16.7638H13.9141V15.8224H15.7968V13.9397H16.7382V15.8224H18.621V16.7638Z'
				fill='white'
				className='fill-inherit'
			/>
			<path
				d='M15.4576 3.6387L8.18083 10.9155C6.43904 12.6601 5.91452 15.4916 6.33 15.9071C6.74548 16.3226 9.52018 15.7413 11.2647 13.9994L18.5415 6.72261C19.3931 5.87101 19.3931 4.4903 18.5415 3.6387C17.6899 2.7871 16.3092 2.7871 15.4576 3.6387Z'
				fill='white'
				className='fill-inherit'
			/>
			<path
				d='M5.42987 5.83446C4.70727 5.83446 4.12148 6.42025 4.12148 7.14286V16.847C4.12148 17.5696 4.70727 18.1554 5.42988 18.1554H11.439C11.6048 18.5605 11.8256 18.9374 12.0919 19.2769H5.42988C4.0879 19.2769 3 18.189 3 16.847V7.14286C3 5.80087 4.08789 4.71298 5.42987 4.71298H11.232C11.5417 4.71298 11.7927 4.96403 11.7927 5.27372C11.7927 5.58341 11.5417 5.83446 11.232 5.83446H5.42987Z'
				fill='white'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
