import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { addOriginCopySeal, deleteOriginCopySeal, getContractSealList } from '../api/contract';
import { queryKey } from '../constants/queryKey';
import { SealStamp } from '../model/sealStamp';
import { useAlert } from './useAlert';

export default function useEseal() {
	const { t } = useTranslation();
	const [snackbar] = useAlert();

	const queryClient = useQueryClient();

	const getEsealListFn = (contractId: number | string) => getContractSealList(contractId);

	const refetch = () => queryClient.invalidateQueries(queryKey.signList);

	const deleteSeal = async (contractId: number | string, sealId: number | string) => {
		const res = await deleteOriginCopySeal({ contractId, sealId });

		if (res.success) {
			snackbar(t('cmmn_guide_sucecss_save'), 'success');
			refetch();
		}
	};

	const addSeal = async (contractId: number | string, selectedSealStamp: SealStamp[]) => {
		const res = await addOriginCopySeal({
			contract_id: contractId,
			seal_stamp_ids: selectedSealStamp.map(stamp => stamp.seal_stamp_id),
		});

		if (res.success) {
			snackbar(t('cmmn_guide_sucecss_save'), 'success');
			refetch();
		}
	};

	const getEsealList = (contractId: number | string) =>
		useQuery(queryKey.signList, () => getEsealListFn(contractId), {
			onError: error => {
				snackbar(
					t('contract_guide_it_is_deleted_or_wrong_approach_please_contact_the_system_administrator'),
					'error',
				);
				window.location.href = '/';
			},
		});

	return { getEsealList, refetch, deleteSeal, addSeal };
}
