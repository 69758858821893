/* eslint-disable camelcase */
import { RelatedCaseParams, RelatedCaseResponse } from '../model/api/relatedCase';
import { sendPostPageNavigation, sendUploadPost } from './common';

export async function getRelatedCaseList(params: RelatedCaseParams) {
	// 연관법무 검색
	const res = await sendPostPageNavigation<RelatedCaseResponse>('/get_related_case_list/', params);

	return res.data;

	// console.log(JSON.stringify(realtimeReviewRes));
}

// 서포트이메일 발송
export async function sendSupportEmailFunction({
	email,
	support_title,
	support_content,
	files,
}: {
	email: string;
	support_title: string;
	support_content: string;
	files?: FileList;
}) {
	const params = {
		returnType: 'json',
		email,
		support_title,
		support_content,
		files,
	};

	const formData = new FormData();
	if (files) Array.from(files).map(file => formData.append('files', file));
	formData.append('email', email);
	formData.append('support_title', support_title);
	formData.append('support_content', support_content);

	const res = await sendUploadPost('/setup/send_support_email/', undefined, formData);

	return res.data;
}
