import { TablePagination, TablePaginationOwnProps } from '@mui/material';

export default function TableFooter({ ...props }: TablePaginationOwnProps) {
	return (
		<TablePagination
			labelDisplayedRows={({ from, to, count, page: p }) =>
				`현재 ${from} ~ ${to} / 전체 ${count} (${p + 1} 페이지)`
			}
			component='div'
			{...props}
		/>
	);
}
