/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { MouseEvent } from 'react';
import { HeadCell } from '../../model/table';
import TableSkeleton from '../ui/tables/TableSkeleton';
import { LegalList, StatisticsTableRow } from '../../model/statistics';
import { queryKey } from '../../constants/queryKey';
import { getChartDataList } from '../../api/statistics';
import { getLegalLinkMap, navigateToAdele } from '../../util/commonUtils';
import StatisticsTable from './StatisticsTable';
import { useStatistics } from '../../context/StatisticsContext';
import { useAlert } from '../../hooks/useAlert';

const headCellsContract: HeadCell<StatisticsTableRow>[] = [
	{
		id: 'type',
		width: 'w-[100px]',
		align: 'left',
		disablePadding: false,
		label: '구분',
	},
	{
		id: 'title',
		width: 'w-4/12',
		align: 'left',
		disablePadding: false,
		label: '법무명',
	},
	{
		id: 'status',
		width: 'w-[150px]',
		align: 'left',
		disablePadding: false,
		label: '상태(진행단계)',
	},
	{
		id: 'counterparty',
		align: 'left',
		disablePadding: false,
		label: '상대방',
	},
	{
		id: 'drafter',
		align: 'left',
		disablePadding: false,
		label: '등록인',
	},
	{
		id: 'id',
		width: 'w-[100px]',
		align: 'left',
		disablePadding: false,
		label: 'ID',
	},
];

type Props = {
	legalListIds: LegalList;
};

export default function StatisticsList({ legalListIds }: Props) {
	const { searchParams } = useStatistics();
	const [snackbar] = useAlert();

	const { data: paginationData, isLoading } = useQuery(
		[queryKey.statisticsList, legalListIds, searchParams],
		() => getChartDataList({ ...legalListIds, searchParams }),
		{ staleTime: 60000 },
	);

	const getListAppType = (type: string) => {
		if (type === '계약') return 'CONTRACT';
		if (type === '자문') return 'COUNSEL';
		if (['소송', '분쟁'].includes(type)) return 'LAWSUIT';

		return 'DOC_RECEIVED';
	};

	const handleClick = async (e: MouseEvent<HTMLTableRowElement>, row: StatisticsTableRow) => {
		const id = Number(row.id);
		const type = getListAppType(row.type);

		const { success, url, msg } = await getLegalLinkMap(type, id);
		if (success) {
			navigateToAdele(url);
		} else {
			snackbar(msg, 'error');
		}
	};

	return (
		<div>
			{isLoading && <TableSkeleton colSpan={headCellsContract.length + 1} />}
			{!isLoading && paginationData && (
				<StatisticsTable
					list={paginationData.data.legalList}
					headCells={headCellsContract}
					totalListCount={paginationData.recordsTotal}
					onClick={handleClick}
				/>
			)}
		</div>
	);
}
