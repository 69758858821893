import { Pagination } from '@mui/material';
import { useState } from 'react';
import usePaginationHelper from '../../hooks/usePaginationHelper';

const numbers = Array.from({ length: 123 }, (_, i) => i + 1);
const dummyData = numbers.map(number => {
	return { number, name: `${number}번 데이터` };
});

export default function PaginationPage() {
	const [myData, setMyData] = useState(dummyData);
	const fetchData = async (pageNumber: number) => {
		const newData = dummyData.slice((pageNumber - 1) * 10, pageNumber * 10);
		setMyData(newData);
	};

	const { currentData, lastPage, handlePageChange } = usePaginationHelper({
		data: myData,
		itemsPerPage: 10,
		totalCount: dummyData.length,
		onPageChange: fetchData,
	});

	return (
		<section className='flex flex-col gap-5 p-10'>
			{currentData.map(item => (
				<p key={item.number}>
					{item.number} | {item.name}
				</p>
			))}
			<Pagination count={lastPage} onChange={handlePageChange} />
		</section>
	);
}
