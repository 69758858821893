import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDeptList } from '../../../api/common';
import { queryKey } from '../../../constants/queryKey';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { HeadCell } from '../../../model/table';
import { Department } from '../../../model/user';
import SysConfigStore from '../../../store/common/SysConfigStore';
import ButtonText from '../../ui/buttons/ButtonText';
import ScrollBox from '../../ui/containers/ScrollBox';
import InputDeptPopupSelect from '../../ui/inputs/InputDeptPopupSelect';
import { ModalSize } from '../../ui/popups/PopupDefault';
import PopupInternalState from '../../ui/popups/PopupInternalState';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import TableDeptList from './TableDeptList';

type Props = {
	id: string;
	formik: FormikValues;
	popupSize?: ModalSize;
	inputSize?: 'small' | 'medium';
	disabled?: boolean;
};
export default function PopupDepts({
	id,
	formik,
	popupSize = 'medium',
	inputSize = 'medium',
	disabled = false,
}: Props) {
	const { t } = useTranslation();
	const { isComponentEnabled } = SysConfigStore();
	const [closeSwitch, setCloseSwitch] = useState(false);
	const handleTogglePopup = () => setCloseSwitch(prev => !prev);
	const { data, isLoading } = useQuery([queryKey.dept, queryKey.list], () => getDeptList());

	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});

	const openTrigger = (
		<InputDeptPopupSelect
			id={id}
			label={t('select_department')}
			formik={formik}
			size={inputSize}
			disabled={disabled}
		/>
	);

	const handleClick = () => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	};

	const companyHeadCell: HeadCell<Department>[] = isComponentEnabled('CMPNT147_ADD_COMPANY_OPTION_TO_POPUP_BF')
		? [
				{
					id: 'subsidiaryName',
					align: 'left',
					disablePadding: false,
					label: t('label_company_name'),
				},
		  ]
		: [];

	const headCells: readonly HeadCell<Department>[] = [
		{
			id: 'name',
			align: 'left',
			disablePadding: true,
			label: t('label_department'),
		},
		...companyHeadCell,
		{
			id: 'department_no',
			align: 'left',
			disablePadding: true,
			label: t('cmmn_label_department_number'),
		},
		{
			id: 'group_id',
			align: 'left',
			disablePadding: true,
			label: t('label_group_id'),
		},
		{
			id: 'group_name',
			align: 'left',
			disablePadding: true,
			label: t('MSG_ID_111'),
		},
	];

	return (
		<PopupInternalState openTrigger={openTrigger} closeTrigger={closeSwitch} size={popupSize}>
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold'>{t('select_department')}</h2>
				</div>

				<ScrollBox className='max-h-[70vh] xl:max-h-[77vh]'>
					{isLoading && !data && <TableSkeleton colSpan={headCells.length + 1} />}
					{!isLoading && data && (
						<TableDeptList
							id={id}
							listType='checkbox'
							list={data}
							orderBy='name'
							headCells={headCells}
							formik={formik}
							selectedFormik={selectedFormik}
						/>
					)}
				</ScrollBox>

				<div className='flex justify-center gap-5'>
					<ButtonText text={t('MSG_ID_105')} variant='contained' onClick={handleTogglePopup} />
					<ButtonText text={t('MSG_ID_104')} variant='contained' color='primary' onClick={handleClick} />
				</div>
			</section>
		</PopupInternalState>
	);
}
