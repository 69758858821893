type ExecutePostLoginParams = {
	navigate: (path: string) => void;
	next: string | undefined;
	subsidiary_search_id?: string | undefined;
};

export function executePostLogin({ navigate, next, subsidiary_search_id: subsidiarySearchId }: ExecutePostLoginParams) {
	// subsidiarySearchId가 있다는건 컴포넌트가 있다는 것과 마찬가지
	if (subsidiarySearchId) {
		sessionStorage.setItem('subsidiary_search_id', subsidiarySearchId || '-1');
	}

	if (next) {
		window.location.href = next;
	} else {
		navigate('/');
	}
}
