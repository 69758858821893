import { useTranslation } from 'react-i18next';
import { noticeDetailUrl } from '../../../constants/adeleUrl';
import { useNotice } from '../../../hooks/useNotice';
import SysConfigStore from '../../../store/common/SysConfigStore';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';

export default function NoticeListDashboard() {
	const { isComponentEnabled } = SysConfigStore();
	const { t } = useTranslation();
	const {
		getData: { data: noticeList },
	} = useNotice();

	const detailUri = isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF')
		? '/manage/notice-board-detail-view/'
		: noticeDetailUrl;

	const handleNavigate = (noticeId: number) => {
		sessionStorage.setItem(
			detailUri,
			JSON.stringify({
				notice_id: noticeId,
			}),
		);

		navigateToAdele(detailUri);
	};

	return (
		<CardBox className='flex flex-col gap-2 p-6'>
			<h3 className='text-xl font-bold'>
				{isComponentEnabled('CMPNT171_CUSTOM_NOTICE_BOARD_BF') ? t('label_notice_board') : t('MSG_ID_1')}
			</h3>
			<ul className='flex flex-col gap-2'>
				{noticeList?.slice(0, 2).map(({ id, notice_title: title, notice_updated: updatedAt }) => (
					<ButtonText
						key={id}
						className='flex items-center justify-between p-0 gap-1'
						onClick={() => handleNavigate(id)}
					>
						<p className='flex-1 font-bold text-neutral-600 truncate text-left'>{title}</p>
						<p className='text-sm text-neutral-600 truncate'>{updatedAt}</p>
					</ButtonText>
				))}
			</ul>
		</CardBox>
	);
}
