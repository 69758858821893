import { useQuery } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { getDashboardProgressCount } from '../../../api/dashboard';
import { counselSearchParams } from '../../../constants/adeleSearchParams';
import { counselListUrl } from '../../../constants/adeleUrl';
import { DASHBOARD_PROGRESS_GROUP_COLOR_MAP } from '../../../constants/common';
import { DASHBOARD_COUNSEL_PROGRESS_MAP, DASHBOARD_COUNSEL_TEXT_MAP } from '../../../constants/legalStatus';
import { queryKey } from '../../../constants/queryKey';
import { useUser } from '../../../context/UserContext';
import { DashboardCounselProgress, DashboardCounselProgressMap } from '../../../model/common';
import { navigateToAdele } from '../../../util/commonUtils';
import ButtonText from '../../ui/buttons/ButtonText';
import CardBox from '../../ui/containers/CardBox';

export default function CounselCountDashboard() {
	const { permission } = useUser();

	if (!permission.DISPLAY_COUNSEL) {
		return null;
	}

	const { t } = useTranslation();
	const { data: progressCountMap } = useQuery(queryKey.dashboardProgressCount, getDashboardProgressCount, {
		staleTime: 60000,
	});

	const entries = Object.entries(DASHBOARD_COUNSEL_PROGRESS_MAP) as [
		keyof DashboardCounselProgressMap,
		DashboardCounselProgress[],
	][];

	const handleNavigate = (progressGroup: keyof DashboardCounselProgressMap, status: DashboardCounselProgress) => {
		const group = progressGroup === 'APPROVAL' ? 'REVIEW' : progressGroup;
		sessionStorage.setItem(
			'counsel_search_param',
			JSON.stringify({
				...counselSearchParams,
				status_check: `PROGRESS_STS_${group}`,
				progress_status: status,
			}),
		);

		navigateToAdele(counselListUrl);
	};

	return (
		<CardBox className='basis-5/12 flex flex-row gap-5 p-6'>
			<p className='text-lg font-bold pr-5 whitespace-nowrap text-ellipsis'>
				{t('counsel_label_current_status')}
			</p>
			{entries.map(([progressGroup, statusList]) => (
				<div key={progressGroup} className='flex flex-col flex-grow gap-2'>
					<div
						className='h-2'
						style={{
							backgroundColor: DASHBOARD_PROGRESS_GROUP_COLOR_MAP[progressGroup],
						}}
					/>
					<div
						className='flex grid gap-2'
						style={{
							gridAutoFlow: 'column',
							gridTemplateRows: `repeat(4, minmax(0, 1fr))`,
						}}
					>
						{statusList.map((status: DashboardCounselProgress) => (
							<ButtonText
								className='justify-start p-1 whitespace-nowrap text-ellipsis overflow-hidden'
								key={status}
								onClick={() => handleNavigate(progressGroup, status)}
							>
								<div className='flex flex-col text-start' title={DASHBOARD_COUNSEL_TEXT_MAP[status]}>
									<p className='text-base font-bold'>{DASHBOARD_COUNSEL_TEXT_MAP[status]}</p>
									<p className='text-sm'>
										{`${progressCountMap?.[status] ?? 0}${t('label_number_of_cases')}`}
									</p>
								</div>
							</ButtonText>
						))}
					</div>
				</div>
			))}
		</CardBox>
	);
}
