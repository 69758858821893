import { useTranslation } from 'react-i18next';
import { ChangeEvent } from 'react';
import { useValidateForm } from '../../../hooks/useValidateForm';
import InputText from '../../ui/inputs/InputText';
import SearchIcon from '../../ui/icons/SearchIcon';
import { HeadCell } from '../../../model/table';
import TableSearchBar from '../../ui/tables/TableSearchBar';
import { PaperManageTableRow } from '../../../model/paperManage';
import { usePaperManageList } from '../../../context/PaperManageContext';
import ListItemPaperManage from './ListItemPaperManage';
import TableList from '../../ui/tables/TableList';
import TableListRow from '../../ui/tables/TableListRow';
import TableListCell from '../../ui/tables/TableListCell';
import ButtonText from '../../ui/buttons/ButtonText';
import { downloadExcelPaperManageList } from '../../../api/paperManage';
import { ExportExcelFileDownloadWithTask } from '../../../api/common';

type Props = {
	list: PaperManageTableRow[];
	headCells: HeadCell<PaperManageTableRow>[];
	totalListCount?: number;
};

export default function PaperManageTable({ list = [], headCells, totalListCount = 0 }: Props) {
	const { t } = useTranslation();
	const { searchParams, setSearchParams } = usePaperManageList();
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;

	const formik = useValidateForm({
		validationSchema: {
			// search_select_paper_manage_table: { initialValue: searchType, type: 'string' },
			search_input_paper_manage_table: { initialValue: searchText, type: 'string' },
		},
		onSubmit: values => {
			setSearchParams(prev => ({
				...prev,
				// searchType: values.search_select_paper_manage_table,
				searchText: values.search_input_paper_manage_table,
			}));
		},
	});

	const searchOptions = [
		{
			value: 'title',
			name: t('paper_manage_title'),
		},
	];

	// const handleHeaderClick = (id: string, o: string) => {
	// 	console.log(id);
	// 	console.log(o);
	// };

	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	const handleExportExcelPaperManageList = () => {
		downloadExcelPaperManageList(searchParams).then(res => {
			const taskId = res.task_id;
			ExportExcelFileDownloadWithTask(taskId);
		});
	};

	// rows 양이 적을 경우 크기 조절
	const emptyRows = currentPage > 0 ? Math.max(0, rowsPerPage - list.length) : 0;

	return (
		<div>
			<section className='flex flex-col gap-10'>
				<section className='flex flex-col gap-3'>
					<TableSearchBar>
						<div className='flex items-center gap-2 w-full'>
							{/* <div className='min-w-[150px] rounded-sm bg-white'>
									<InputSelect
										id='search_select_paper_manage_table'
										label={t('search_type')}
										// placeholder='서류명'
										options={searchOptions}
										required
										formik={formik}
									/>
								</div> */}
							<div className='flex-1 rounded-sm bg-white'>
								<form onSubmit={formik.handleSubmit}>
									<InputText
										id='search_input_paper_manage_table'
										icon={
											<SearchIcon
												classes={{ root: 'cursor-pointer' }}
												onClick={formik.handleSubmit}
											/>
										}
										labelText={`${
											headCells.filter(cell => cell.id === formik.values.table_search_select)[0]
												?.label ?? ''
										} ${t('write_paper_manage_title')}`}
										formik={formik}
									/>
								</form>
							</div>
						</div>
					</TableSearchBar>

					<h2 className='text-xl'>
						{t('all_count')} {totalListCount}
					</h2>

					<TableList
						headCells={headCells}
						// order='asc'
						// orderBy='title'
						// onHeaderClick={handleHeaderClick}
						count={totalListCount}
						rowsPerPage={rowsPerPage}
						page={currentPage}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					>
						{totalListCount > 0 ? (
							list.map((row, idx) => <ListItemPaperManage key={row.id} item={row} index={idx} />)
						) : (
							<TableListRow>
								<TableListCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
									{t('label_no_search_results_exist')}
								</TableListCell>
							</TableListRow>
						)}
					</TableList>
				</section>
			</section>
			<div className='flex justify-end gap-5 mt-5'>
				<ButtonText
					text={t('MSG_ID_398')}
					size='medium'
					variant='outlined'
					onClick={handleExportExcelPaperManageList}
				/>
			</div>
		</div>
	);
}
