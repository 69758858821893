import { useTranslation } from 'react-i18next';
import { DOMAIN_URL } from '../../../../constants/common';
import { useUser } from '../../../../context/UserContext';
import { MenuData } from '../../../../model/header';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import MenuItem1Depth from './MenuItem1Depth';

type Props = {
	openMenu: string | null;
	onToggle: (title: string) => void;
};
export default function MenuAdmin({ openMenu, onToggle }: Props) {
	const { isModuleEnabled, isComponentEnabled, labelText } = SysConfigStore();
	const { t } = useTranslation();
	const { isAdmin, permission } = useUser();

	const menu: MenuData = {
		title: `${t('MSG_ID_2')} `,
		classes: { button: 'px-7 py-4' },
		display: isAdmin,
		children: [
			// 계약 관리
			{
				title: '계약',
				display: true,
				children: [
					// 워크플로우
					{
						link: `${DOMAIN_URL}/admin/admin_workflow_list_view/`,
						title: '워크플로우',
						display: true,
						isExternal: true,
					},
					// 템플릿
					{
						link: `${DOMAIN_URL}/admin/admin_template_list_view/`,
						title: '템플릿',
						display: true,
						isExternal: true,
					},
					// 디지털직인
					{
						link: `${DOMAIN_URL}/admin/admin_stamp_list_view/`,
						title: '디지털직인',
						display: true,
						isExternal: true,
					},
					// 검토자관리
					{
						link: `${DOMAIN_URL}/admin/admin_lawyer_list_view/`,
						title: '검토자관리',
						display: true,
						isExternal: true,
					},
					// 계약이관
					{
						link: `${DOMAIN_URL}/contract/switch_contractor_view_each_for_adele/`,
						title: '계약이관',
						display: true,
						isExternal: true,
					},
					// 인감
					{
						link: `${DOMAIN_URL}/admin/admin_seal_stamp_list_view/`,
						title: labelText('seal_stamp'),
						display: isModuleEnabled('SEAL_STAMP'),
						isExternal: true,
					},
					// 통계
					{
						link: `${DOMAIN_URL}/admin/admin_statistics_list_view/`,
						title: '통계',
						display: isModuleEnabled('STATISTICAL_STATUS'),
						isExternal: true,
					},
					// 날인 담당자
					{
						link: `${DOMAIN_URL}/admin/admin_seal_manager_edit_view/`,
						title: '날인 담당자',
						display: isModuleEnabled('ENABLED_SEAL_MANAGER_EDIT'),
						isExternal: true,
					},
					// 서류관리
					{
						link: '/paperManage/list',
						title: t('paper_manage'),
						display: isComponentEnabled('CMPNT166_PAPER_MANAGE_BF'),
					},
					// 인장통계
					{
						link: `${DOMAIN_URL}/admin/admin_stamp_statistic_view/`,
						title: t('cmmn_label_stamp_statistic').replace('{{인장}}', labelText('seal_stamp')),
						display: isComponentEnabled('CMPNT173_STAMP_STATISTIC_BF'),
						isExternal: true,
					},
					// 법무 리스크
					{
						link: `${DOMAIN_URL}/admin/legal-risk/list-view/`,
						title: t('label_legal_lisk'),
						display: isComponentEnabled('CMPNT217_LEGAL_LISK'),
						isExternal: true,
					},
				],
			},
			// 법률자문
			{
				link: `${DOMAIN_URL}/admin/admin_counsel_view/`,
				title: '법률자문',
				display: isModuleEnabled('COUNSEL_MANAGE'),
				isExternal: true,
			},
			// 소송관리
			{
				link: `${DOMAIN_URL}/admin/admin_lawsuit/`,
				title: '소송관리',
				display: isModuleEnabled('APP_LAWSUIT') && !isComponentEnabled('CMPNT184_DISPUTE_BF'),
				isExternal: true,
			},
			// 프로젝트관리
			{
				link: `${DOMAIN_URL}/admin/admin_epic_view/`,
				title: '프로젝트관리',
				display: isModuleEnabled('EPIC_MANAGE'),
				isExternal: true,
			},
			// 업체
			{
				title: '업체',
				display: true,
				isExternal: true,
				children: [
					// 외부업체
					{
						link: `${DOMAIN_URL}/admin/admin_external_company_list_view/`,
						title: '외부업체',
						display: true,
						isExternal: true,
					},
					{
						link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
						title: t('label_external_lawfirm'),
						display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
						isExternal: true,
					},
					{
						link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
						title: t('label_external_lawyer'),
						display: isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
						isExternal: true,
					},
					// 법무법인
					{
						link: `${DOMAIN_URL}/admin/admin_external_lawfirm_list_view/`,
						title: '법무법인',
						display:
							isModuleEnabled('APP_LAWSUIT') &&
							!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
						isExternal: true,
					},
					// 사외변호사
					{
						link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
						title: '사외변호사',
						display:
							isModuleEnabled('APP_LAWSUIT') &&
							!isComponentEnabled('CMPNT152_EXTERNAL_LAWYER_EXTENDED_01_BF'),
						isExternal: true,
					},
					// 계열사 관리
					{
						link: `${DOMAIN_URL}/admin/admin_subsidiary_company_list_view/`,
						title: '계열사 관리',
						display: permission.ACTIVATE_SUBSIDIARY,
						isExternal: true,
					},
				],
			},
			// 라벨
			{
				link: `${DOMAIN_URL}/admin/admin_label_list/`,
				title: '라벨',
				display: true,
				isExternal: true,
			},
			// 시스템관리
			{
				title: '시스템관리',
				display: true,
				isExternal: true,
				children: [
					// 유저
					{
						link: `${DOMAIN_URL}/admin/admin_user_list_view/`,
						title: '유저',
						display: true,
						isExternal: true,
					},
					// 그룹
					{
						link: `${DOMAIN_URL}/admin/admin_group_list_view/`,
						title: '그룹',
						display: true,
						isExternal: true,
					},
					// 보안등급
					{
						link: `${DOMAIN_URL}/admin/admin_external_lawyer_list_view/`,
						title: '보안등급',
						display: isModuleEnabled('SECURITY_GRADE_ENABLED'),
						isExternal: true,
					},
					// 회사정보
					{
						link: `${DOMAIN_URL}/admin/admin_company_info_view/`,
						title: '회사정보',
						display: true,
						isExternal: true,
					},
					// 권한위임
					{
						link: `${DOMAIN_URL}/admin/admin_emporwerment_view/`,
						title: '권한위임',
						display: isModuleEnabled('DOA'),
						isExternal: true,
					},
					// 공지사항 관리부서
					{
						link: `${DOMAIN_URL}/admin/admin_notice_manage_dept_view/`,
						title: '공지사항 관리부서',
						display: isModuleEnabled('CUSTOM_NOTICE_MANAGE_BY_DEPT'),
						isExternal: true,
					},
					{
						link: `${DOMAIN_URL}/admin/admin_reviewer_satisfaction_ratings_view/`,
						title: t('cmmn_label_evaluate_legal_satisfaction'),
						display: isComponentEnabled('CMPNT141_REVIEWER_SATISFACTION_RATINGS_BF'),
						isExternal: true,
					},
					// 물리적 삭제
					{
						link: '/hardDelete',
						title: t('hard_delete'),
						display: isComponentEnabled('CMPNT172_HARD_DELETE_BF'),
					},
				],
			},
		],
	};

	return <MenuItem1Depth itemData={menu} isOpen={openMenu === menu.title} onToggle={() => onToggle(menu.title)} />;
}
