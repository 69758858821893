export const contractListUrl = '/contract/get-list-view-v28/';
export const counselListUrl = '/counsel/get-list-view-v28/';
export const lawsuitListUrl = '/lawsuit/get-list-view-v28/';
export const docReceivedListUrl = '/doc_received/get-list-view-v28/';
export const contractDetailUrl = '/contract/get_contract_edit_view/';
export const counselDetailUrl = '/counsel/counsel_detail_view/';
export const claimDetailUrl = '/claim/claim_detail_view/';
export const lawsuitDetailUrl = '/lawsuit/lawsuit_detail_view/';
export const noticeDetailUrl = '/manage/notice_detail_view/';
export const contractCreateUrl = '/contract/get_list_view_template/';
export const counselCreateUrl = '/counsel/counsel_create/';
