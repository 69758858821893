/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/function-component-definition */
import React, { ReactNode, createContext, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { EpicTableSearchParams } from '../model/epic';

interface EpicContext {
	searchParams: EpicTableSearchParams;
	setSearchParams: React.Dispatch<React.SetStateAction<EpicTableSearchParams>>;
}

const EpicContext = createContext<EpicContext | undefined>(undefined);

export const EpicProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
	// const { isAdmin, isLawyer, isViewer } = useUser();
	const { state } = useLocation();

	// if (!(isAdmin || isLawyer || isViewer)) {
	// 	return <Navigate to='/' replace />;
	// }

	const [searchParams, setSearchParams] = useState<EpicTableSearchParams>({
		currentPage: 0,
		searchType: 'name',
		searchText: '',
		rowsPerPage: 10,
		// orderColumn: -1,
		// order: 'desc',
	});

	const { searchType, searchText, rowsPerPage } = searchParams;

	useEffect(() => {
		setSearchParams(prev => ({ ...prev, currentPage: 0 }));
	}, [searchType, searchText, rowsPerPage, state]);

	return <EpicContext.Provider value={{ searchParams, setSearchParams }}>{children}</EpicContext.Provider>;
};

export function useEpicList() {
	const context = useContext(EpicContext);
	if (context === undefined) {
		throw new Error('Epic must be used within a EpicProvider');
	}

	return context;
}
