import { Outlet } from 'react-router-dom';
import React from 'react';
import { ThemeProvider } from '@mui/material';
import Footer from './components/footer/Footer';
import { sendPost } from './api/common';
import SubsidiarySetter from './pages/common/SubsidiarySetter';
import ChannelIO from './components/common/ChannelIO';
import { EpicProvider } from './context/EpicContext';
import RouteHeader from './components/header/RouteHeader';
import { useTheme } from './hooks/useTheme';

interface configDict {
	status: unknown;
	headers: any;
	data: any;
}

export async function initSysCompanyConfigs() {
	const res = await sendPost<configDict>('/get_sysconfig_list_by_company/');
	return res;
}

export default function App() {
	const theme = useTheme();

	return (
		<ThemeProvider theme={theme}>
			<section className='flex flex-col min-h-screen' style={{ backgroundColor: theme.palette.body.main }}>
				<SubsidiarySetter>
					<RouteHeader />
					<main className='flex-1 w-full'>
						<EpicProvider>
							<Outlet />
							<ChannelIO />
						</EpicProvider>
					</main>
				</SubsidiarySetter>
				<Footer />
			</section>
		</ThemeProvider>
	);
}
