import { EChartsCoreOption, graphic } from 'echarts';
import colors from 'tailwindcss/colors';
import { AnyObject } from 'yup';
import { defaultFontFamily } from '../themes/typography';

type PieData = {
	name: string;
	value: number;
	color?: string;
	itemStyle?: {
		color: string;
	};
};

type BarData = {
	data: number[];
	name: string;
	color: string;
};

type AreaLineData = {
	data: number[];
	name: string;
	color: string;
};

// Chart 글로벌 옵션
const globalOption = (): EChartsCoreOption => ({
	textStyle: {
		fontFamily: defaultFontFamily,
	},
	tooltip: {
		trigger: 'item',
	},
	legend: {
		orient: 'horizontal',
		left: 'left',
		bottom: 0,
		icon: 'circle',
	},
	grid: {
		left: '5%',
		right: '5%',
		top: '7%',
		bottom: '7%',
		containLabel: true,
	},
});

// Chart 글로벌 X, Y 축 그리드 라인 스타일
const globalSplitLine = () => ({
	splitLine: {
		show: true,
		lineStyle: {
			color: colors.neutral[200],
			width: 2,
		},
	},
});

// Bar Chart 글로벌 Inside Top Label
const globalLabel = () => ({
	label: {
		show: true,
		position: 'top',
		formatter: (params: AnyObject) => params?.value || '',
	},
});

// 데이터의 총합 계산
export const getTotal = (data: AnyObject[]) => data.reduce((sum, item) => sum + item.value, 0);

type PieProps = {
	data: PieData[];
	name: string;
};

// 원형 차트
export const getPieOption = ({ data, name }: PieProps): EChartsCoreOption => {
	return {
		...globalOption(),
		title: {
			text: 'Total Value',
			subtext: `${getTotal(data)}`,
			left: 'center',
			top: '40%',
			textStyle: {
				color: colors.neutral[500],
				fontSize: 14,
			},
			subtextStyle: {
				color: colors.gray[950],
				fontWeight: 'bold',
				fontSize: 28,
			},
		},
		series: [
			{
				type: 'pie',
				data: data.map(({ name: n, value, color }) => ({ name: n, value, itemStyle: { color } })),
				name,
				top: 0,
				radius: ['45%', '60%'], // 내부, 외부 반지름 정의
				avoidLabelOverlap: false,
				labelLine: {
					show: true,
				},
				label: {
					show: true,
					formatter: '{c}', // '{c}' 는 데이터의 value 를 표시
				},
			},
		],
	};
};

type AreaLineProps = {
	data: AreaLineData[];
	xAxisData: string[];
};

// 꺾은 선 차트
export const getAreaLineOption = ({ xAxisData, data: d }: AreaLineProps): EChartsCoreOption => {
	return {
		...globalOption(),
		xAxis: {
			type: 'category',
			boundaryGap: false,
			...globalSplitLine(),
			data: xAxisData,
			axisLabel: {
				rotate: xAxisData.length > 8 ? 30 : 0,
				formatter: (value: string) => {
					const maxLength = 10;
					return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
				},
				fontSize: 10,
			},
		},
		yAxis: {
			type: 'value',
			...globalSplitLine(),
		},
		series: d.map(({ data, name, color }) => ({
			type: 'line',
			data,
			name,
			symbolSize: 8,
			symbol: 'circle',
			color,
			lineStyle: {
				width: 3,
			},
			areaStyle: {
				color: new graphic.LinearGradient(0, 0, 0, 1, [
					{
						offset: 0,
						color,
					},
					{
						offset: 1,
						color: colors.white,
					},
				]),
			},
		})),
	};
};

type BarProps = {
	data: BarData[];
	xAxisData: string[];
};

// 세로 막대 차트
export const getBarVerticalOption = ({ xAxisData, data: d }: BarProps): EChartsCoreOption => {
	return {
		...globalOption(),
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow',
			},
		},
		xAxis: [
			{
				...globalSplitLine(),
				type: 'category',
				data: xAxisData,
				axisLabel: {
					interval: 0,
					rotate: xAxisData.length > 8 ? 30 : 0,
					formatter: (value: string) => {
						const maxLength = 10;
						return value.length > maxLength ? `${value.slice(0, maxLength)}...` : value;
					},
					fontSize: 10,
				},
			},
		],
		yAxis: [
			{
				...globalSplitLine(),
				type: 'value',
			},
		],
		series: d.map(({ data, name, color }) => ({
			type: 'bar',
			data,
			name,
			barWidth: '25%',
			itemStyle: {
				color,
				borderRadius: [4, 4, 0, 0],
			},
			...globalLabel(),
		})),
	};
};

// 가로 막대 차트
export const getBarHorizontalOption = ({ xAxisData, data: d }: BarProps): EChartsCoreOption => {
	return {
		...globalOption(),
		tooltip: {
			trigger: 'axis',
			axisPointer: {
				type: 'shadow',
			},
		},
		xAxis: [
			{
				...globalSplitLine(),
				type: 'value',
			},
		],
		yAxis: [
			{
				...globalSplitLine(),
				type: 'category',
				data: xAxisData,
			},
		],
		series: d.map(({ data, name, color }) => ({
			type: 'bar',
			data,
			name,
			barWidth: '40%',
			itemStyle: {
				color,
				borderRadius: [0, 4, 4, 0],
			},
			...globalLabel(),
		})),
	};
};
