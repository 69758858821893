import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function ClaimIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path 
				className='fill-inherit'
				d="M16.3767 11.7383C16.3767 10.0917 15.0418 8.75683 13.3951 8.75683C12.7512 8.75683 12.1549 8.96096 11.6676 9.30804L15.8254 13.4659C16.1725 12.9785 16.3767 12.3823 16.3767 11.7383Z" 
				fill="#030712"/>
			<path 
				className='fill-inherit'
				d="M10.9648 10.0108L15.1227 14.1687C14.6353 14.5157 14.039 14.7199 13.3951 14.7199C11.7485 14.7199 10.4136 13.385 10.4136 11.7383C10.4136 11.0944 10.6178 10.4982 10.9648 10.0108Z" 
				fill="#030712"/>
			<path 
				className='fill-inherit'
				fillRule="evenodd" 
				clipRule="evenodd" 
				d="M15.3685 2.3999V5.36638C15.3685 6.41732 16.2421 7.26816 17.2976 7.26816H20.0093V16.6484C20.0093 17.5537 19.2814 18.2999 18.3759 18.2999H8.40286C7.49751 18.2999 6.76943 17.5539 6.76943 16.6484V4.05145C6.76943 3.14609 7.49734 2.3999 8.40286 2.3999H15.3685ZM13.3951 7.76299C15.5907 7.76299 17.3705 9.54281 17.3705 11.7383C17.3705 13.9339 15.5907 15.7137 13.3951 15.7137C11.1996 15.7137 9.41978 13.9339 9.41978 11.7383C9.41978 9.54281 11.1996 7.76299 13.3951 7.76299Z" 
				fill="#030712"/>
			<path 
				className='fill-inherit'
				d="M17.3301 6.54025H19.564L16.1291 2.95958V5.36638C16.1291 6.01697 16.675 6.54025 17.3301 6.54025Z" 
				fill="#030712"/>
			<path 
				className='fill-inherit'
				d="M16.8985 21.3351C17.3956 21.3351 17.7985 20.9321 17.7985 20.4351C17.7985 19.938 17.3956 19.5351 16.8985 19.5351H7.8001C6.47461 19.5351 5.4001 18.4605 5.4001 17.1351V4.4999C5.4001 4.00285 4.99715 3.5999 4.5001 3.5999C4.00304 3.5999 3.6001 4.00285 3.6001 4.4999V17.1351C3.6001 19.4547 5.4805 21.3351 7.8001 21.3351H16.8985Z" 
				fill="#030712"/>
				
		</SvgIcon>
	);
}
