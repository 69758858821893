import Tab, { TabOwnProps } from '@mui/material/Tab';

export default function TabButtonDashboard({ ...props }: TabOwnProps) {
	return (
		<Tab
			classes={{
				root: 'flex-row justify-start gap-2 pl-8 text-neutral-500 font-bold',
				selected: 'text-gray-950 bg-blue-200',
				iconWrapper: 'mb-0',
			}}
			{...props}
		/>
	);
}
