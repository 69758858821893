import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../../../../model/icon';

export default function AllIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M9.9562 2.99998C8.10625 2.98123 6.25623 2.97497 4.4062 2.98123C3.66928 3.18692 3.20053 3.66192 2.99995 4.40623C2.97495 6.25621 2.97495 8.10624 2.99995 9.95623C3.1937 10.7 3.66245 11.1687 4.4062 11.3625C6.25619 11.3875 8.10621 11.3875 9.9562 11.3625C10.6999 11.1687 11.1687 10.7 11.3625 9.95623C11.3875 8.10624 11.3875 6.25621 11.3625 4.40623C11.1687 3.66248 10.6999 3.19373 9.9562 2.99998Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.5562 2.99998C17.7063 2.98123 15.8562 2.97497 14.0062 2.98123C13.2693 3.18692 12.8005 3.66192 12.6 4.40623C12.5749 6.25621 12.5749 8.10624 12.6 9.95623C12.7937 10.7 13.2625 11.1687 14.0062 11.3625C15.8562 11.3875 17.7062 11.3875 19.5562 11.3625C20.2999 11.1687 20.7687 10.7 20.9625 9.95623C20.9875 8.10624 20.9875 6.25621 20.9625 4.40623C20.7687 3.66248 20.2999 3.19373 19.5562 2.99998ZM19.1812 4.19998C17.5813 4.18123 15.9813 4.17497 14.3812 4.18123C14.0442 4.23707 13.8504 4.43707 13.8 4.78123C13.7749 6.38124 13.7749 7.98121 13.8 9.58123C13.8437 9.92499 14.0374 10.1187 14.3812 10.1625C15.9812 10.1875 17.5812 10.1875 19.1812 10.1625C19.525 10.1187 19.7187 9.92499 19.7625 9.58123C19.7875 7.98121 19.7875 6.38124 19.7625 4.78123C19.7187 4.43747 19.525 4.24373 19.1812 4.19998Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M4.4062 12.5812C6.25623 12.575 8.10625 12.5812 9.9562 12.6C10.6999 12.7937 11.1687 13.2625 11.3625 14.0062C11.3875 15.8562 11.3875 17.7062 11.3625 19.5562C11.1687 20.3 10.6999 20.7687 9.9562 20.9625C8.10621 20.9875 6.25619 20.9875 4.4062 20.9625C3.66245 20.7687 3.1937 20.3 2.99995 19.5562C2.97495 17.7062 2.97495 15.8562 2.99995 14.0062C3.20053 13.2619 3.66928 12.7869 4.4062 12.5812ZM4.7812 13.7812C6.38125 13.775 7.98126 13.7812 9.5812 13.8C9.92496 13.8437 10.1187 14.0375 10.1625 14.3812C10.1875 15.9812 10.1875 17.5812 10.1625 19.1812C10.1187 19.525 9.92496 19.7187 9.5812 19.7625C7.98119 19.7875 6.38121 19.7875 4.7812 19.7625C4.43745 19.7187 4.2437 19.525 4.19995 19.1812C4.17495 17.5812 4.17495 15.9812 4.19995 14.3812C4.25042 14.0371 4.44417 13.8371 4.7812 13.7812Z'
				className='fill-inherit'
			/>
			<path
				fillRule='evenodd'
				clipRule='evenodd'
				d='M19.5562 12.6C17.7063 12.5812 15.8562 12.575 14.0062 12.5812C13.2693 12.7869 12.8005 13.2619 12.6 14.0062C12.5749 15.8562 12.5749 17.7062 12.6 19.5562C12.7937 20.3 13.2625 20.7687 14.0062 20.9625C15.8562 20.9875 17.7062 20.9875 19.5562 20.9625C20.2999 20.7687 20.7687 20.3 20.9625 19.5562C20.9875 17.7062 20.9875 15.8562 20.9625 14.0062C20.7687 13.2625 20.2999 12.7937 19.5562 12.6ZM19.1812 13.8C17.5813 13.7812 15.9813 13.775 14.3812 13.7812C14.0442 13.8371 13.8504 14.0371 13.8 14.3812C13.7749 15.9812 13.7749 17.5812 13.8 19.1812C13.8437 19.525 14.0374 19.7187 14.3812 19.7625C15.9812 19.7875 17.5812 19.7875 19.1812 19.7625C19.525 19.7187 19.7187 19.525 19.7625 19.1812C19.7875 17.5812 19.7875 15.9812 19.7625 14.3812C19.7187 14.0375 19.525 13.8437 19.1812 13.8Z'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
