import { SvgIcon } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

export default function DisputeIcon({ className = '', ...props }: SvgIconPropsOverrides) {
	return (
		<SvgIcon className={`${className}`} {...props}>
			<path
				d='M16.3232 13.9707C16.4508 13.649 16.4464 13.2749 16.4576 11.0047L16.4681 8.4442L18.2042 8.45479C19.9151 8.4653 19.9293 8.46539 20.1296 8.5462C20.3652 8.64117 20.6747 8.90847 20.8049 9.13006C20.8287 9.17074 20.8497 9.2047 20.8681 9.23999C21.0073 9.5067 21.0019 9.84896 21.0019 13.7411C21.0019 17.6333 21.0073 17.9756 20.8681 18.2423C20.8497 18.2776 20.8287 18.3116 20.8049 18.3522C20.6747 18.5738 20.3652 18.8411 20.1296 18.9361C19.9326 19.0135 19.8939 19.017 18.4448 19.0346L16.9606 19.0522L15.8843 20.4872C14.7553 21.9926 14.7271 22.0242 14.4668 22.0242C14.4106 22.0242 14.3121 21.989 14.2453 21.9433C14.1749 21.8976 13.6895 21.2856 13.06 20.4485L11.9977 19.0346H10.8969C9.68341 19.0346 9.56383 19.0205 9.24728 18.8165C9.01866 18.6723 8.84279 18.4683 8.71266 18.194L8.60362 17.9618L8.58252 15.6264L11.4772 15.6158L11.4927 15.6158C14.3474 15.6018 14.3759 15.6016 14.6286 15.5244C15.3461 15.3063 15.8913 14.8596 16.1973 14.2371C16.2495 14.1313 16.2906 14.0527 16.3232 13.9707Z'
				fill='#0891B2'
				className='fill-inherit'
			/>
			<path
				d='M4.01728 4.0723C3.55301 4.19892 3.16611 4.58933 3.04653 5.05712C2.99729 5.24705 2.99377 5.75705 3.0008 9.38331L3.01135 13.495L3.12391 13.7341C3.33494 14.1808 3.7359 14.4692 4.23183 14.5325C4.37252 14.5501 5.17093 14.5677 6.00803 14.5677H7.52747L7.54154 11.9896C7.54203 11.8559 7.54247 11.7288 7.54289 11.608C7.54945 9.69729 7.55067 9.34221 7.67157 9.03409C7.69917 8.96377 7.733 8.89589 7.77455 8.81251C7.78756 8.78641 7.80132 8.75879 7.81589 8.72911C8.01637 8.32463 8.44195 7.89201 8.84995 7.68449C8.8858 7.66613 8.91835 7.649 8.94872 7.63303C9.02057 7.59524 9.08015 7.5639 9.14197 7.53785C9.44246 7.41128 9.79569 7.40992 11.8659 7.40198C12.0516 7.40127 12.2511 7.40051 12.4657 7.3996L15.4131 7.38904L15.392 5.08878L15.2794 4.85312C15.1247 4.52602 14.9277 4.32202 14.6077 4.16375L14.3403 4.03361L9.27554 4.02657C4.91769 4.01954 4.17907 4.02657 4.01728 4.0723Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
			<path
				d='M9.6662 8.48655C9.14917 8.60966 8.72359 9.05986 8.62159 9.59096C8.59697 9.73869 8.58641 10.516 8.59345 12.1761L8.604 14.5503L11.2243 14.5608C12.6664 14.5643 13.9643 14.5538 14.1155 14.5362C14.731 14.4658 15.2094 14.0614 15.3571 13.4916C15.4028 13.3192 15.4098 12.9112 15.4098 10.8677V8.44786L12.6066 8.45138C11.0625 8.4549 9.74006 8.46897 9.6662 8.48655Z'
				fill='#2563EB'
				className='fill-inherit'
			/>
		</SvgIcon>
	);
}
