import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { ChangeEvent, MouseEvent, useState } from 'react';
import { FormikValues } from 'formik';
import { Checkbox } from '@mui/material';
import { Epic } from '../../model/epic';
import { HeadCell } from '../../model/table';
import { useEpicList } from '../../context/EpicContext';
import { useValidateForm } from '../../hooks/useValidateForm';
import TableSearchBar from '../ui/tables/TableSearchBar';
import InputText from '../ui/inputs/InputText';
import SearchIcon from '../ui/icons/SearchIcon';
import TableList from '../ui/tables/TableList';
import TableListRow from '../ui/tables/TableListRow';
import TableListCell from '../ui/tables/TableListCell';
import InputSelect from '../ui/inputs/InputSelect';

type Props = {
	id: string;
	listType?: 'radio';
	list: Epic[];
	// orderBy: keyof Epic;
	headCells: HeadCell<Epic>[];
	totalListCount?: number;
	formik: FormikValues;
	maxSelected?: number;
	selectedFormik: FormikValues;
};

export default function TableEpicPopupList({
	id: formikId,
	listType = 'radio',
	list,
	// orderBy: ob = 'title',
	headCells,
	totalListCount = 0,
	formik,
	maxSelected = 0,
	selectedFormik,
}: Props) {
	const { t } = useTranslation();
	// const [order, setOrder] = useState<Epic>('asc');
	// const [orderBy, setOrderBy] = useState(ob);Epic
	const [selected, setSelected] = useState<readonly number[]>(formik.values[formikId].map((item: Epic) => item.id));
	// const [page, setPage] = useState(0);
	// const [rowsPerPage, setRowsPerPage] = useState(10);
	const { searchParams, setSearchParams } = useEpicList();
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;

	const formikTableSearch = useValidateForm({
		validationSchema: {
			search_select_epic_table: { initialValue: searchType, type: 'string' },
			search_input_epic_table: { initialValue: searchText, type: 'string' },
		},
		onSubmit: values => {
			setSearchParams(prev => ({
				...prev,
				searchType: values.search_select_epic_table,
				searchText: values.search_input_epic_table,
			}));
		},
	});

	const searchOptions = [
		{
			value: 'name',
			name: t('MSG_ID_701'),
		},
		{
			value: 'code',
			name: t('epic_code'),
		},
	];

	const handleClick = (e: MouseEvent<unknown>, id: number) => {
		const selectedIndex = _.indexOf(selected, id);
		let newSelected: readonly number[] = [];

		if (selectedIndex === -1) {
			newSelected = _.concat(selected, id);

			if (maxSelected && maxSelected < newSelected.length) {
				newSelected = _.slice(newSelected, 1);
			}
		} else newSelected = _.concat(_.slice(selected, 0, selectedIndex), _.slice(selected, selectedIndex + 1));

		setSelected(newSelected);

		selectedFormik.setFieldValue(
			formikId,
			_.filter(list, obj => _.includes(newSelected, obj.id)),
		);
	};

	const handleChangePage = (e: unknown, newPage: number) => {
		setSearchParams(prev => ({ ...prev, currentPage: newPage }));
	};

	const handleChangeRowsPerPage = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchParams(prev => ({ ...prev, rowsPerPage: parseInt(e.target.value, 10), currentPage: 0 }));
	};

	// // 검색하면 page, rowsPerPage 초기화
	// const handleBeforeDebounceChange = () => {
	// 	setRowsPerPage(10);
	// 	setPage(0);
	// };

	const isSelected = (id: number) => selected.indexOf(id) !== -1;

	// // rows 양이 적을 경우 크기 조절
	// const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;

	return (
		<section className='flex flex-col gap-3'>
			<TableSearchBar>
				<div className='flex items-center gap-2 w-full'>
					<div className='min-w-[150px] rounded-sm bg-white'>
						{searchOptions && (
							<InputSelect
								id='search_select_epic_table'
								label={t('search_type')}
								options={searchOptions}
								required
								formik={formikTableSearch}
							/>
						)}
					</div>
					<div className='flex-1 rounded-sm bg-white'>
						<form onSubmit={formikTableSearch.handleSubmit}>
							<InputText
								id='search_input_epic_table'
								icon={
									<SearchIcon
										classes={{ root: 'cursor-pointer' }}
										onClick={formikTableSearch.handleSubmit}
									/>
								}
								labelText={`${
									headCells.filter(cell => cell.id === formik.values.search_select_epic_table)[0]
										?.label ?? ''
								} ${t('search_action')}`}
								formik={formikTableSearch}
							/>
						</form>
					</div>
				</div>
			</TableSearchBar>

			<TableList
				headCells={headCells}
				// order='asc'
				// orderBy='title'
				// onHeaderClick={handleHeaderClick}
				count={totalListCount}
				rowsPerPage={rowsPerPage}
				page={currentPage}
				onPageChange={handleChangePage}
				onRowsPerPageChange={handleChangeRowsPerPage}
			>
				{totalListCount > 0 ? (
					list.map((row, idx) => {
						const isItemSelected = isSelected(row.id);
						const labelId = `checkbox_epic_popup_${row.id}`;
						return (
							<TableListRow
								sx={{ cursor: 'pointer' }}
								onClick={event => handleClick(event, row.id)}
								key={row.id}
							>
								<TableListCell padding='checkbox'>
									<Checkbox
										id={labelId}
										color='primary'
										checked={isItemSelected}
										inputProps={{
											'aria-labelledby': labelId,
										}}
									/>
								</TableListCell>
								<TableListCell>
									<div>{row.code}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.name}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.manage_department_nmae}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.create_time}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.owner}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.contract_count}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.counsel_count}</div>
								</TableListCell>
								<TableListCell>
									<div>{row.lawsuit_count}</div>
								</TableListCell>
							</TableListRow>
						);
					})
				) : (
					<TableListRow>
						<TableListCell colSpan={headCells.length + 1} classes={{ root: 'text-center' }}>
							{t('label_no_search_results_exist')}
						</TableListCell>
					</TableListRow>
				)}
			</TableList>
		</section>
	);
}
