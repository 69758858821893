import * as ChannelService from '@channel.io/channel-web-sdk-loader';
import SysConfigStore from '../../store/common/SysConfigStore';

export default function ChannelIO() {
	const { isModuleEnabled } = SysConfigStore();

	if (!isModuleEnabled('GET_CHATBOT')) return;

	ChannelService.loadScript();
	ChannelService.boot({
		pluginKey: process.env.REACT_APP_CHANNELIO_PLUGIN_KEY_FNF!,
	});

	// eslint-disable-next-line consistent-return
	return null;
}
