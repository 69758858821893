import { DocumentLoadEvent, PageChangeEvent, RenderPage, RenderPageProps, ZoomEvent } from '@react-pdf-viewer/core';
import { useQuery } from '@tanstack/react-query';
import { uniqueId } from 'lodash';
import { ChangeEvent, MouseEvent, SyntheticEvent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RndDragCallback, RndResizeCallback } from 'react-rnd';
import { AnyObject } from 'yup';
import { getContractSealPdf } from '../../api/contract';
import TabsSign from '../../components/sign/TabsSign';
import HeaderSign from '../../components/sign/header/HeaderSign';
import HeaderSignAction from '../../components/sign/header/HeaderSignAction';
import CardBox from '../../components/ui/containers/CardBox';
import StickyBox from '../../components/ui/containers/StickyBox';
import SignProvider from '../../components/ui/signs/SignProvider';
import SignRenderPage from '../../components/ui/viewer/SignRenderPage';
import Viewer from '../../components/ui/viewer/Viewer';
import { queryKey } from '../../constants/queryKey';
import { useAlert } from '../../hooks/useAlert';
import { pdfData } from '../../model/api/contract';
import { Sign } from '../../model/sign';
import SysConfigStore from '../../store/common/SysConfigStore';

type Props = {
	contractId: string;
};

/**
 * 인감 날인 페이지
 * 주의 : Axios로 가져온 pdfAxiosRes의 ArrayBuffer 메모리가 Viewer로 빠지면서,
 * Worker만 접근이 가능해, pdfObject로 따로 상태관리 해야 함
 */
export default function DetailPage({ contractId }: Props) {
	const { labelText } = SysConfigStore();
	const { t } = useTranslation();
	const [snackbar] = useAlert();
	const [signs, setSigns] = useState<Sign[]>([]);
	const [zoom, setZoom] = useState(1);
	const [pageInfo, setPageInfo] = useState({
		width: 0,
		height: 0,
	});
	const [pdfObject, setPdfObject] = useState<pdfData | null>(null);
	const [goToPageIndex, setGoToPageIndex] = useState<number | null>(null);
	const [saveSize, setSaveSize] = useState(0);
	const [tabValue, setTabValue] = useState(0);

	const { data: pdfAxiosRes, isLoading: pdfAxiosLoading } = useQuery(
		queryKey.sealPdfData,
		() => getContractSealPdf(contractId),
		{
			onError: error => {
				snackbar(t('contract_warn_no_seal_stamp').replace('{{날인}}', labelText('seal')), 'error');
				window.location.href = '/';
			},
		},
	);

	const handleDragStop: RndDragCallback = useCallback(
		(e, data) => {
			if (!data) return;
			const {
				x: prevX,
				y: prevY,
				node: {
					dataset: { id },
				},
			} = data;

			setSigns(prev =>
				prev.map(sign => {
					const { id: signId } = sign;

					const x = prevX / zoom;
					const y = prevY / zoom;
					return signId !== id ? sign : { ...sign, x, y };
				}),
			);
		},
		[signs, zoom],
	);

	// 날인 이미지 사이즈 조정
	const handleResizeStop: RndResizeCallback = useCallback(
		(e, dir, elementRef, delta, position) => {
			if (!elementRef || !position) return;
			const {
				offsetWidth: width,
				offsetHeight: height,
				dataset: { id },
			} = elementRef;
			const { x, y } = position;

			setSigns(prev =>
				prev.map(sign => {
					const { id: signId } = sign;
					return signId !== id
						? sign
						: { ...sign, width: width / zoom, height: height / zoom, x: x / zoom, y: y / zoom };
				}),
			);
		},
		[signs, zoom],
	);

	// 날인 리스트에서 계약서로 이미지 추가
	const handleDrop = useCallback(
		(item: AnyObject, dropResult: AnyObject) => {
			const { id, imageUrl, name, width, height, x: cursorX, y: cursorY } = item;
			const { id: pageIndex, viewerZoom, x: pageCursorX, y: pageCursorY, pageWidth, pageHeight } = dropResult;
			const x = pageCursorX - cursorX;
			const y = pageCursorY - cursorY;

			setZoom(viewerZoom);

			setPageInfo({
				width: pageWidth / viewerZoom,
				height: pageHeight / viewerZoom,
			});

			setSigns(prev => [
				...prev,
				{
					id: uniqueId(`${id}-`),
					name,
					pageIndex: Number(pageIndex),
					imageUrl,
					x: x / viewerZoom,
					y: y / viewerZoom,
					width: width / viewerZoom,
					height: height / viewerZoom,
				},
			]);
		},
		[signs, zoom],
	);

	// PDF 확대 축소
	const handleZoom = useCallback(
		(e: ZoomEvent) => {
			const { scale = 1 } = e;
			setZoom(scale);
		},
		[signs, zoom],
	);

	// 날인 이미지 입력으로 사이즈 조정
	const handleChange = useCallback(
		(e: ChangeEvent<HTMLInputElement>, sign: Sign) => {
			const {
				target: { value: v },
			} = e;
			const value = Number(v);
			const { id, width: imageWidth, height: imageHeight } = sign;
			const { width: pageWidth } = pageInfo;

			const minValue = value < 40 ? 40 : value;
			const width = minValue > pageWidth ? pageWidth : minValue;
			const height = (imageHeight * width) / imageWidth;

			setSigns(prev => {
				return [
					...prev.map(item =>
						item.id === id
							? ({
									...item,
									id: uniqueId(`${id.toString().split('-')[0]}-`),
									width,
									height,
							  } as Sign)
							: item,
					),
				];
			});
		},
		[signs, zoom],
	);

	// 날인 이미지 삭제
	const handleDeleteSign = useCallback(
		(e: MouseEvent<HTMLButtonElement>, sign: Sign) => {
			const { id } = sign;
			setSigns(prev => prev.filter(({ id: signId }) => signId !== id));
		},
		[signs, zoom],
	);

	// 날인 이미지 사이즈 저장
	const handleSaveSize = useCallback(
		(e: MouseEvent<HTMLButtonElement>, sign: Sign) => {
			const { width } = sign;

			setSaveSize(width);
		},
		[signs, zoom],
	);

	// 날인 저장된 이미지 사이즈 불러오기
	const handleLoadSize = useCallback(
		(e: MouseEvent<HTMLButtonElement>, sign: Sign) => {
			const { id, width: imageWidth, height: imageHeight } = sign;
			const { width: pageWidth } = pageInfo;

			const width = Number(saveSize) > pageWidth ? pageWidth : Number(saveSize);
			const height = (imageHeight * width) / imageWidth;

			setSigns(prev => {
				return [
					...prev.map(item =>
						item.id === id
							? ({
									...item,
									id: uniqueId(`${id.toString().split('-')[0]}-`),
									width,
									height,
							  } as Sign)
							: item,
					),
				];
			});
		},
		[signs, zoom],
	);

	// PDF 페이지 렌더링
	const renderPage: RenderPage = useCallback(
		(props: RenderPageProps) => (
			<SignRenderPage
				signs={signs}
				onDragStop={handleDragStop}
				onResizeStop={handleResizeStop}
				onDeleteSign={handleDeleteSign}
				onChange={handleChange}
				onSaveSize={handleSaveSize}
				onLoadSize={handleLoadSize}
				{...props}
			/>
		),
		[signs, zoom],
	);

	// PDF 페이지 load 후
	const handleDocumentLoad = async (e: DocumentLoadEvent) => {
		const fileArray = await e.doc.getData();
		const fileName = pdfAxiosRes?.fileName;

		if (fileName) setPdfObject({ file: fileArray, fileName });
	};

	// PDF 페이지 사용자가 변경한 경우
	const handlePageChange = useCallback((e: PageChangeEvent) => setGoToPageIndex(null), []);

	// PDF 페이지 강제 이동
	const handleGoToPage = useCallback((e: AnyObject, pageIndex: number) => setGoToPageIndex(pageIndex), [signs, zoom]);

	// 날인 탭 이동
	const handleChangeTab = useCallback((_: SyntheticEvent, v: number) => setTabValue(v), [signs, zoom]);

	return (
		<section className='flex flex-col gap-5 p-8 max-w-[1920px] mx-auto'>
			<HeaderSign title={pdfObject?.fileName || ''} contractId={contractId} />

			<div>
				<SignProvider>
					<div className='flex gap-5'>
						<CardBox className='basis-8/12 p-5'>
							{!pdfAxiosLoading && pdfAxiosRes && (
								<Viewer
									fileUrl={pdfAxiosRes.file}
									goToPageIndex={goToPageIndex}
									onPageChange={handlePageChange}
									renderPage={renderPage}
									onZoom={handleZoom}
									onDocumentLoad={handleDocumentLoad}
								/>
							)}
						</CardBox>
						<div className='basis-4/12'>
							<StickyBox className='grid gap-4'>
								{pdfObject && (
									<HeaderSignAction signs={signs} pdfObject={pdfObject} contractId={contractId} />
								)}

								<TabsSign
									contractId={contractId}
									signs={signs}
									value={tabValue}
									onChange={handleChangeTab}
									onDrop={handleDrop}
									onDeleteSign={handleDeleteSign}
									onGoToPage={handleGoToPage}
								/>
							</StickyBox>
						</div>
					</div>
				</SignProvider>
			</div>
		</section>
	);
}
