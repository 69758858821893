import { useNavigate } from 'react-router-dom';
import ButtonText from '../ui/buttons/ButtonText';
import StateChip from '../ui/chips/StateChip';
import PopoverInternalState from '../ui/popovers/PopoverInternalState';
import { DOA } from '../../model/user';
import PopupCommonInternal from '../ui/popups/PopupCommonInternal';
import { doaCheck } from '../../api/login';
import { useUser } from '../../context/UserContext';

type Props = {
	userList?: DOA[];
};

export default function AuthorizationList({ userList = [] }: Props) {
	const { setLoginUser } = useUser();
	const navigate = useNavigate();

	const makeMsg = (name: string, dept: string, doaStartDate: string, doaEndDate: string) => {
		return `위임 계정: ${name} ${dept} 기한: ${doaStartDate} ~ ${doaEndDate} 권한위임 계정으로 전환하시겠습니까?`;
	};

	return (
		<PopoverInternalState
			classes={{ paper: '' }}
			openTrigger={<ButtonText className='px-3 text-sm'>권한위임</ButtonText>}
		>
			<ul className='flex flex-col gap-1 min-w-[15rem] py-2'>
				{userList &&
					userList.map(({ id, name, dept, rank, doaStartDate, doaEndDate }, index) => (
						<li key={id}>
							<PopupCommonInternal
								openTrigger={
									<ButtonText className='w-full rounded-none'>
										<div className='flex items-center gap-1 w-full'>
											<p className='text-sm whitespace-nowrap'>{name}</p>
											<p className='text-sm whitespace-nowrap'>{dept}</p>
											{rank && <p className='text-sm whitespace-nowrap'>{`/ ${rank}`}</p>}
											<StateChip
												text='전환'
												classes={{
													root: 'text-xs px-1 py-1 border-2 border-yellow-400 ml-auto',
												}}
											/>
										</div>
									</ButtonText>
								}
								msg={makeMsg(name, dept ?? '', doaStartDate, doaEndDate)}
								confirmClose={false}
								onConfirm={() => {
									doaCheck({ index, navigate, setLoginUser, doaProcLoginId: '-1' });
								}}
								onCancel={() => false}
							/>
						</li>
					))}
			</ul>
		</PopoverInternalState>
	);
}
