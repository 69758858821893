import { useMutation, useQueryClient } from '@tanstack/react-query';
import { getCompanyInfoSummary } from '../api/login';
import { queryKey } from '../constants/queryKey';

export default function useCompanyInfo() {
	const queryClient = useQueryClient();

	const getCompanyInfoFn = () => getCompanyInfoSummary();

	const getCompanyInfo = useMutation(getCompanyInfoFn, {
		onSuccess: () => queryClient.invalidateQueries(queryKey.companyInfoSummary),
	});

	const refetchCompanyInfo = () => {
		queryClient.invalidateQueries(queryKey.companyInfoSummary);
	};

	return { getCompanyInfo, refetchCompanyInfo };
}
