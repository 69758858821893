import { InputLabel } from '@mui/material';
import { FormikValues } from 'formik';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createPaperManage } from '../../../api/paperManage';
import { useUser } from '../../../context/UserContext';
import usePaperManage from '../../../hooks/usePaperManage';
import { useValidateForm } from '../../../hooks/useValidateForm';
import PopupUsers from '../../PopupUsers';
import SubsidiarySelector from '../../common/SubsidiarySelector';
import ButtonLoading from '../../ui/buttons/ButtonLoading';
import ButtonText from '../../ui/buttons/ButtonText';
import ScrollBox from '../../ui/containers/ScrollBox';
import InputFile from '../../ui/inputs/InputFile';
import InputText from '../../ui/inputs/InputText';
import PopupInternalState from '../../ui/popups/PopupInternalState';
import InputSealStamp from '../sealStamp/InputSealStamp';
import PaperTypeSelector from './PaperTypeSelector';

export default function PopupCreatePaperManage() {
	const { t } = useTranslation();
	const [closeSwitch, setCloseSwitch] = useState(false);
	const [btnLoading, setBtnLoading] = useState(false);
	const { loginUser } = useUser();
	const { resetList } = usePaperManage();
	const closeTogglePopup = (formikParam: FormikValues) => {
		setCloseSwitch(prev => !prev);
		formikParam.resetForm();
	};
	const formik = useValidateForm({
		validationSchema: {
			title_create_paper_manage: { initialValue: '', type: 'string', required: true },
			type_create_paper_manage: { initialValue: '', type: 'string', required: true },
			subsidiary_create_paper_manage: { initialValue: '', type: 'number', required: true },
			description_create_paper_manage: { initialValue: '', type: 'string' },
			users_list_create_paper_manage: { initialValue: [], type: 'array' },
			file_list_create_paper_manage: { initialValue: [], type: 'array' },
			seal_stamp_create_paper_manage: { initialValue: [], type: 'array' },
		},
		onSubmit: values => {
			createPaperManage({
				title: values.title_create_paper_manage,
				paperType: values.type_create_paper_manage,
				subsidiary: values.subsidiary_create_paper_manage,
				description: values.description_create_paper_manage,
				userList: values.users_list_create_paper_manage,
				fileList: values.file_list_create_paper_manage,
				sealStamp: values.seal_stamp_create_paper_manage,
			}).then(res => {
				closeTogglePopup(formik);
				resetList();
			});
		},
	});
	useEffect(() => {
		if (loginUser.subsidiaryId) {
			formik.setFieldValue('subsidiary_create_paper_manage', loginUser.subsidiaryId);
		}
	}, [closeSwitch]);

	const openTrigger = (
		<ButtonLoading loading={btnLoading} text={`+ ${t('new_paper_manage')}`} color='primary' variant='contained' />
	);
	const fileButton = (
		<ButtonText
			classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
			text='+'
			color='inherit'
			variant='outlined'
		/>
	);

	return (
		<PopupInternalState openTrigger={openTrigger} closeTrigger={closeSwitch} size='medium'>
			<section className='flex flex-col gap-5'>
				<div className=' h-16'>
					<h2 className='text-2xl font-bold'>{t('create_paper_manage')}</h2>
				</div>
				<ScrollBox className='flex flex-col gap-5'>
					<div className='pb-5 border-b border-b-zinc-200'>
						<SubsidiarySelector
							label={t('label_company_name')}
							formikId='subsidiary_create_paper_manage'
							required
							formik={formik}
						/>
					</div>

					<div className='pb-5 border-b border-b-zinc-200'>
						<InputText
							id='title_create_paper_manage'
							labelText={t('paper_manage_title')}
							placeholder={t('write_paper_manage_title')}
							formik={formik}
						/>
					</div>

					<div className='pb-5 border-b border-b-zinc-200'>
						<PaperTypeSelector formikId='type_create_paper_manage' formik={formik} />
					</div>

					<div className='pb-5 border-b border-b-zinc-200'>
						<InputText
							multiline
							id='description_create_paper_manage'
							labelText={t('MSG_ID_152')}
							placeholder={t('MSG_ID_152')}
							formik={formik}
						/>
					</div>

					<div className='flex items-center gap-5 pb-5 border-b border-b-zinc-200'>
						<InputSealStamp formikId='seal_stamp_create_paper_manage' formik={formik} />
					</div>

					<div className='pb-5 border-b border-b-zinc-200'>
						<PopupUsers
							id='users_list_create_paper_manage'
							label={t('paper_manage_manager')}
							required
							formik={formik}
						/>
					</div>
					<div className='flex items-center gap-2 w-full pb-2 border-b border-b-zinc-200'>
						<InputLabel id='label_file_list_create_paper_manage'>{t('MSG_ID_131')}</InputLabel>
						<InputFile
							id='file_list_create_paper_manage'
							formik={formik}
							button={fileButton}
							multiple
							dragDrop
						/>
					</div>
				</ScrollBox>
				<div className='flex justify-center gap-5'>
					<ButtonText text={t('MSG_ID_105')} variant='contained' onClick={() => closeTogglePopup(formik)} />
					<ButtonText
						text={t('MSG_ID_104')}
						variant='contained'
						color='primary'
						onClick={() => formik.handleSubmit()}
					/>
				</div>
			</section>
		</PopupInternalState>
	);
}
