import { Children, Fragment, ReactElement, cloneElement } from 'react';
import { useTranslation } from 'react-i18next';
import PopupDefault, { ModalSize } from './PopupDefault';
import ButtonLoading from '../buttons/ButtonLoading';

type Props = {
	openTrigger: ReactElement;
	isOpen: boolean;
	isLoading: boolean;
	msg: string;
	icon?: string;
	size?: ModalSize;
	externalClickClose?: boolean;
	onClick: () => void;
	onClose: () => void;
	onConfirm: () => void;
	isShowCloseBtn?: boolean;
	popupCloseEvent?: () => void;
};
export default function PopupHandleBtns({
	openTrigger,
	isLoading,
	isOpen,
	msg,
	icon,
	size = 'medium',
	externalClickClose = true,
	onClick,
	onClose,
	onConfirm,
	isShowCloseBtn = false,
	popupCloseEvent,
}: Props) {
	const { t } = useTranslation();
	const openComponent = cloneElement(Children.only(openTrigger), {
		onClick,
	});

	return (
		<>
			{openComponent}
			<PopupDefault open={isOpen} onClose={onClose} size={size} externalClickClose={externalClickClose}>
				<div className='flex flex-col gap-5'>
					{icon && <div className='text-center'>{icon}</div>}
					<div className='text-center'>
						{msg.split('\n').map((line, index) => (
							// eslint-disable-next-line react/no-array-index-key
							<Fragment key={index}>
								{line}
								<br />
							</Fragment>
						))}
					</div>
					<div className='flex justify-center space-x-6'>
						{!isShowCloseBtn && (
							<ButtonLoading
								loading={isLoading}
								text={t('MSG_ID_105')}
								onClick={onClose}
								color='inherit'
								variant='contained'
							/>
						)}
						{!isShowCloseBtn && (
							<ButtonLoading
								loading={isLoading}
								text={t('MSG_ID_104')}
								onClick={onConfirm}
								color='primary'
								variant='contained'
							/>
						)}
						{isShowCloseBtn && (
							<ButtonLoading
								loading={isLoading}
								text={t('MSG_ID_856')}
								onClick={popupCloseEvent || onClose}
								color='inherit'
								variant='contained'
							/>
						)}
					</div>
				</div>
			</PopupDefault>
		</>
	);
}
