import React, { JSXElementConstructor, ReactElement, useEffect, useState } from 'react';
import ModalDefault, { ModalSize } from './PopupDefault';

type Props = {
	children: ReactElement<unknown, string | JSXElementConstructor<unknown>>;
	openTrigger: React.ReactElement;
	closeTrigger?: boolean;
	size?: ModalSize;
	autoClose?: number;
	externalClickClose?: boolean;
	onClick?: () => void;
};

export default function PopupInternalState({
	children,
	onClick,
	openTrigger,
	closeTrigger,
	size,
	autoClose,
	externalClickClose = true,
}: Props) {
	const [open, setOpen] = useState(false);
	const handleOpen = () => {
		setOpen(true);

		if (autoClose) {
			setTimeout(() => {
				setOpen(false);
			}, autoClose);
		}
		if (onClick) onClick();
	};

	const handleClose = () => {
		setOpen(false);
	};

	const handleExternalClick = () => {
		if (externalClickClose !== false) {
			handleClose();
		}
	};

	useEffect(() => {
		handleClose();
	}, [closeTrigger]);

	const openComponent = React.cloneElement(React.Children.only(openTrigger), {
		onClick: handleOpen,
	});

	return (
		<>
			{openComponent}
			<ModalDefault open={open} onClose={handleExternalClick} size={size} externalClickClose={externalClickClose}>
				{children}
			</ModalDefault>
		</>
	);
}
