import { TableRow as Row, TableRowOwnProps } from '@mui/material';

type TableRowOwnPropsOverrides = TableRowOwnProps & {
	onClick?: (event: React.MouseEvent<HTMLElement>) => void;
};

export default function TableListRow({ onClick, ...props }: TableRowOwnPropsOverrides) {
	const { hover = true, children } = props;
	const handleRowClick = (event: React.MouseEvent<HTMLElement>) => {
		if (onClick) {
			onClick(event);
		}
	};
	return (
		<Row hover={hover} onClick={handleRowClick} {...props}>
			{children}
		</Row>
	);
}
