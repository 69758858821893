import { SealStamp, SealStampTableSearchParams } from '../model/sealStamp';
import { sendPostPageNavigation } from './common';

export async function getSealStampList(searchParams: SealStampTableSearchParams) {
	const { searchType, searchText, searchStatus, contractId, currentPage, rowsPerPage } = searchParams;
	const params = {
		returnType: 'json',
		curr_page: currentPage + 1,
		search_type: searchType,
		search_name: searchText,
		search_status: searchStatus,
		number_per_page: rowsPerPage,
		contract_id: contractId,
		// order_col: orderColumn,
		// order,
	};
	const res = await sendPostPageNavigation<SealStamp[]>('/admin/admin_seal_stamp_process_list_data/', params);
	return res;
}
