/**
 * 공통 유틸리티 함수 모듈입니다.
 *
 * 이 파일에는 다음과 같은 기능들이 포함되어 있습니다:
 * - 전역적으로 사용되는 데이터 조회, 포매팅 등
 *
 * @file axios 유틸리티 함수 정의 파일
 * @author il jung kim
 */

import { getContractLinkParam } from '../api/contract';
import { getCounselLinkParam } from '../api/counsel';
import { getLawsuitLinkParam } from '../api/lawsuit';
import { claimDetailUrl, contractDetailUrl, counselDetailUrl, docReceivedListUrl, lawsuitDetailUrl } from '../constants/adeleUrl';
import { ContractRole, CounselRole, LegalType } from '../model/common';
import sysConfigStore from '../store/common/SysConfigStore';

/**
 * 주어진 이름을 기반으로 쿠키값을 반환합니다.
 *
 * @param {string} name - 쿠키의 이름
 * @returns {string | null} 쿠키의 값. 해당 이름의 쿠키가 존재하지 않는 경우 null 반환
 *
 * @example
 * const userToken = getCookie('token');
 */
export function getCookie(name: string): string | null {
	const value = `; ${document.cookie}`;
	const parts = value.split(`; ${name}=`);
	if (parts.length === 2) {
		return parts.pop()?.split(';').shift() || null;
	}
	return null;
}

/**
 * 현재 페이지의 도메인 URL을 반환합니다.
 *
 * @returns {string} 현재 도메인의 URL
 *
 * @example
 * const currentURL = getDomainURL();
 */
export function getDomainURL() {
	const { protocol } = window.location;
	const { hostname } = window.location;
	const { port } = window.location;

	// 개발 환경인 경우
	if (process.env.NODE_ENV === 'development') {
		return `${protocol}//${hostname}`;
	}

	// 프로덕션 환경인 경우
	return port ? `${protocol}//${hostname}:${port}` : `${protocol}//${hostname}`;
}

/**
 * 바이트 단위 파일 크기 포매팅 함수입니다.
 *
 * @param {number} size - 파일 크기 (바이트 단위)
 * @returns {string} KB 또는 MB 단위로 포맷된 파일 크기 문자열
 *
 * @example
 * const formattedSize = formatFileSize(1234567); // "1.18 MB"
 */
export function formatFileSize(size: number) {
	if (size < 1024) return `${size} Bytes`;
	if (size < 1048576) return `${(size / 1024).toFixed(2)} KB`;
	return `${(size / 1048576).toFixed(2)} MB`;
}

export function navigateToAdele(url: string) {
	window.location.href = `${getDomainURL()}${url}`;
}

export function openToAdele(url: string) {
	window.open(`${getDomainURL()}${url}`, '_blank');
}

export async function getLegalLinkMap(legalType: LegalType, id: number) {
	const linkParamMap = {
		CONTRACT: async () => {
			const res = await getContractLinkParam({
				conid: id,
				viewmode: 'direct',
			});
			return {
				res,
				baseUrl: contractDetailUrl,
			};
		},
		COUNSEL: async () => {
			const res = await getCounselLinkParam({ counsel_id: id });
			return {
				res,
				baseUrl: counselDetailUrl,
			};
		},
		LAWSUIT: async () => {
			const res = await getLawsuitLinkParam({ lawsuit_id: id });
			return {
				res,
				baseUrl: lawsuitDetailUrl,
			};
		},
		DOC_RECEIVED: async () => {
			return {
				res: {
					data: `${btoa(`${id - 1 * 2}|${id}|${id + 1 * 2}`)}`,
					msg: '',
				},
				baseUrl: docReceivedListUrl,
			};
		},
		STAMP: async () => {
			const res = await getContractLinkParam({
				conid: id,
				viewmode: 'direct',
				contract_type: 'CONTRACT_STAMP',
			});
			return {
				res,
				baseUrl: contractDetailUrl,
			};
		},
		DISPUTE: async () => {
			const res = await getLawsuitLinkParam({ lawsuit_id: id });
			return {
				res,
				baseUrl: lawsuitDetailUrl,
			};
		},
		CONTRACT_ETC: async () => {
			const res = await getContractLinkParam({
				conid: id,
				viewmode: 'direct',
			});
			return {
				res,
				baseUrl: contractDetailUrl,
			};
		},
		CONTRACT_SIGN_SEAL_PROCESS: async () => {
			const res = await getContractLinkParam({
				conid: id,
				viewmode: 'direct',
			});
			return {
				res,
				baseUrl: contractDetailUrl,
			};
		},
		CLAIM: async () => {
			return {
				res: {
					data: `${btoa(`${id - 1 * 2}|${id}|${id + 1 * 2}`)}`,
					msg: '',
				},
				baseUrl: claimDetailUrl,
			};
		},
	};

	if (['LAWSUIT', 'DISPUTE'].includes(legalType)) {
		localStorage.setItem('lawsuit_id', id.toString());
	}

	const { res, baseUrl } = await linkParamMap[legalType]();
	const { data, msg } = res;

	return {
		success: !!data,
		url: `${baseUrl}?param=${data}`,
		msg,
	};
}

export function getContractRoleString(key: ContractRole) {
	const { labelText } = sysConfigStore();

	const roleMap = {
		0: '기안자',
		1: '상대방',
		2: '관련인',
		3: '검토자',
		4: '최종승인자',
		5: '관련인',
		6: labelText('dept_mgr'),
		7: '날인 담당자',
		8: '원본회수 담당자',
		9: '전임 담당자',
		10: '전임 검토자',
		11: '전임 최종승인자',
		12: '날인 담당자',
		13: '참조자',
	};

	return roleMap[key] || '';
}

export function getCounselRoleString(key: CounselRole) {
	const { labelText } = sysConfigStore();

	const roleMap = {
		CREATOR: '기안자',
		DEPT: labelText('dept_mgr'),
		REVIEW: '검토자',
		FINAL: '최종승인자',
		RELATED: '관련인',
		CIRCULATION: '회람인',
	};

	return roleMap[key] || '';
}

export function clearSearchParam() {
	sessionStorage.removeItem('contract_search_param');
	sessionStorage.removeItem('counsel_search_param');
	sessionStorage.removeItem('lawsuit_search_param');
	sessionStorage.removeItem('doc_received_search_param');
}
