import { t } from 'i18next';
import { ResScheduleData } from '../model/calendar';

export const calendarSubjectMap: Record<string, ResScheduleData['cons_message']> = {
	CON_START_DATE: t('cmmn_label_contract_start_date'),
	CON_END_DATE: t('cmmn_label_contract_end'),
	LAWSUIT_MEDIATION_DATE: t('cmmn_label_adjustment_date'),
	LAWSUIT_SENTENCE_DATE: t('cmmn_label_judgment_date'),
	LAWSUIT_ARGUMENT_DATE: t('cmmn_label_negotiation_date'),
	CON_AUTO_RENEWAL_DATE: t('cmmn_automatic_renewal_decision_date'),
};
