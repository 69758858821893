import { Components } from '@mui/material';
import tailwindColors from 'tailwindcss/colors';
import theme from 'tailwindcss/defaultTheme';
import { AnyObject } from 'yup';
import { colors } from '../../util/colors';

export const getComponents = (): Components & {
	MuiDateCalendar: AnyObject;
} => ({
	MuiTableCell: {
		styleOverrides: {
			root: {
				wordBreak: 'break-all',
			},
		},
	},
	MuiChip: {
		styleOverrides: {
			root: {
				'&.MuiChip-sizeMedium': {
					fontSize: theme.fontSize.base[0],
					lineHeight: theme.fontSize.base[1].lineHeight,
				},
				'&.MuiChip-colorPrimary': {
					color: tailwindColors.blue[600],
					textDecoration: 'underline',
				},
				'.MuiChip-deleteIconColorPrimary': {
					color: 'rgba(0, 0, 0, 0.26)',
				},
				'.MuiChip-deleteIconColorPrimary:hover': {
					color: 'rgba(0, 0, 0, 0.4)',
				},
			},
		},
	},
	MuiFormControl: {
		styleOverrides: {
			root: {
				'&.border-0 .MuiOutlinedInput-notchedOutline': {
					borderWidth: '0 !important',
				},
			},
		},
	},
	MuiInputBase: {
		styleOverrides: {
			root: {
				'&': {
					borderRadius: 'inherit',
					borderColor: 'inherit',
				},
				'&.Mui-disabled': {
					color: tailwindColors.gray[950],
				},
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: 'inherit',
				},
			},
			input: {
				'&:-webkit-autofill': {
					transitionDelay: '9999s',
					transitionProperty: 'background-color, color',
				},
				'&.Mui-disabled': {
					textFillColor: 'inherit',
				},
			},
		},
	},
	MuiAccordion: {
		styleOverrides: {
			root: {
				backgroundColor: 'inherit',
				boxShadow: 'inherit',
				'&:first-of-type': {
					borderTopLeftRadius: 0,
					borderTopRightRadius: 0,
				},
				'&:before': {
					display: 'none',
				},
				'&.Mui-expanded': {
					margin: 0,
				},
			},
		},
	},
	MuiAccordionSummary: {
		styleOverrides: {
			root: {
				display: 'inline-flex',
				padding: 0,
				minHeight: 0,
				'&.Mui-expanded': {
					minHeight: 0,
				},
			},
			content: {
				margin: 0,
				'&.Mui-expanded': {
					margin: 0,
				},
			},
		},
	},
	MuiAccordionDetails: {
		styleOverrides: {
			root: {
				padding: 0,
			},
		},
	},
	MuiDateCalendar: {
		styleOverrides: {
			root: {
				'.MuiPickersDay-root.Mui-selected': {
					color: '#FFF',
				},
				'.MuiPickersDay-root.Mui-selected:focus': {
					backgroundColor: colors.orange[550],
				},
			},
		},
	},
});
