import { sendPost } from './common';
import { ConcurrentJobList, LoginParams, LoginResponse, LoginSendEmailParams } from '../model/api/login';
import { User } from '../model/user';
import { executePostLogin } from '../service/executePostLogin';
import { Company } from '../model/api/company';

export function login({ email, password, options }: LoginParams) {
	const params = {
		formname: 'login-process-form',
		returnType: 'json',
		csrfmiddlewaretoken: '',
		email,
		password,
		...options,
	};

	return sendPost<LoginResponse>('/login/', params);
}

export function logout() {
	sessionStorage.removeItem('subsidiary_search_id');
	return sendPost('/logout/');
}

export async function checkUserAuth() {
	const res = await sendPost<User>('/check_user_auth/');
	return res.data;
}

export function delegateOfAuth(userProfileId: number, isEntry: boolean) {
	const value = isEntry ? 1 : 2;
	const options = {
		doa_proc_login_user_index: userProfileId,
		doa_proc_login_request: value,
	};
	return login({ options });
}

export function loginForce() {
	const options = {
		dup_login_flag_check: 1,
	};
	return login({ options });
}

export async function getCompanyInfoSummary() {
	const res = await sendPost<Company>('/get_company_info_summary/', {
		subsidiary_id: sessionStorage.getItem('subsidiary_search_id') || -1,
	});
	return res.data;
}

export async function doaCheck({
	index,
	navigate,
	doaProcLoginId,
}: {
	index: number;
	navigate: any;
	setLoginUser: React.Dispatch<React.SetStateAction<User>>;
	doaProcLoginId: string;
}) {
	const params = {
		index,
		doa_proc_login_id: doaProcLoginId,
	};

	await sendPost<any>('/manage/is_valid_doa_status/', params).then(async res => {
		const isValid = res.data.is_valid;
		if (isValid) {
			const doaLoginParams = {
				formname: 'login-process-form',
				returnType: 'json',
				csrfmiddlewaretoken: '',
				doa_proc_login_request: 1,
				doa_proc_login_user_index: index,
				doa_proc_login_id: doaProcLoginId,
			};
			await sendPost<LoginResponse>('/login/', doaLoginParams)
				.then(doaRes => {
					const {
						next,
						dup_login_flag: dupLoginFlag,
						subsidiary_search_id: subsidiarySearchId,
					} = doaRes.data;

					if (!dupLoginFlag) {
						executePostLogin({ navigate, next, subsidiary_search_id: subsidiarySearchId });
						window.location.reload();
					}
				})
				.catch(error => {
					console.log(error);
					const isDoa = error.response.data.data.isDOA;
					const isDup = error.response.data.data.dup_login_flag;

					if (isDoa && isDup) {
						alert('현재 로그인중인 유저입니다.');
						executePostLogin({ navigate, next: '/login' });
					}
				});
		}
	});
}

export async function doaBackdo({ navigate }: { navigate: any }) {
	const doaBackdoParams = {
		formname: 'login-process-form',
		returnType: 'json',
		csrfmiddlewaretoken: '',
		doa_proc_login_request: 2,
		doa_proc_login_user_index: -1,
	};

	await sendPost<LoginResponse>('/login/', doaBackdoParams).then(backdoRes => {
		const { next, subsidiary_search_id: subSidiarySearchId } = backdoRes.data;

		executePostLogin({ navigate, next, subsidiary_search_id: subSidiarySearchId });
		window.location.reload();
	});
}

export async function loginSendEmail(params: LoginSendEmailParams) {
	const res = await sendPost('/login_sendemail/', params);
	return res.msg;
}

export async function getConcurrentJob() {
	const res = await sendPost<ConcurrentJobList>('/manage/get-concurrent-job-list/');
	return res.data;
}