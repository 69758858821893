import { MouseEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doaCheck } from '../../../../api/login';
import { useUser } from '../../../../context/UserContext';
import { DOA } from '../../../../model/user';
import ButtonText from '../../../ui/buttons/ButtonText';
import StateChip from '../../../ui/chips/StateChip';
import ExpandMoreIcon from '../../../ui/icons/ExpandMoreIcon';
import PopoverDefault from '../../../ui/popovers/PopoverDefault';
import PopupCommonInternal from '../../../ui/popups/PopupCommonInternal';

type Props = {
	userList?: DOA[];
};

export default function AuthorizationList({ userList = [] }: Props) {
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const open = Boolean(anchorEl);

	const handleOpen = (e: MouseEvent<HTMLElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const { setLoginUser } = useUser();
	const navigate = useNavigate();

	const makeMsg = (name: string, dept: string, doaStartDate: string, doaEndDate: string) => {
		return `위임 계정: ${name} ${dept} 기한: ${doaStartDate} ~ ${doaEndDate} 권한위임 계정으로 전환하시겠습니까?`;
	};

	return (
		<>
			<ButtonText className='px-7 py-4 text-base font-bold' onClick={handleOpen}>
				권한위임
				<ExpandMoreIcon
					fontSize='small'
					className={`text-neutral-300 transition-transform ${open && 'rotate-180'}`}
				/>
			</ButtonText>
			<PopoverDefault
				open={open}
				anchorEl={anchorEl}
				classes={{
					paper: '',
				}}
				onClose={handleClose}
			>
				<ul className='flex flex-col gap-1 min-w-[15rem] py-2'>
					{userList &&
						userList.map(({ id, name, dept, rank, doaStartDate, doaEndDate }, index) => (
							<li key={id}>
								<PopupCommonInternal
									openTrigger={
										<ButtonText className='w-64 rounded-none px-[16px] py-[6px]'>
											<div className='flex items-center justify-between gap-[10px] w-full'>
												<p className='text-sm'>
													{name} {dept}
													{rank && `/${rank}`}
												</p>
												<StateChip
													text='전환'
													classes={{
														root: 'text-xs px-1 py-1 border-2 border-yellow-400 ml-auto',
													}}
												/>
											</div>
										</ButtonText>
									}
									msg={makeMsg(name, dept ?? '', doaStartDate, doaEndDate)}
									confirmClose={false}
									onConfirm={() => {
										doaCheck({ index, navigate, setLoginUser, doaProcLoginId: '-1' });
									}}
									onCancel={() => false}
								/>
							</li>
						))}
				</ul>
			</PopoverDefault>
		</>
	);
}
