import { useNavigate } from 'react-router-dom';
import React from 'react';
import { loginForce } from '../../api/login';
import { executePostLogin } from '../../service/executePostLogin';
import PopupWarning from '../ui/popups/PopupWarning';

type Props = {
	open: boolean;
	setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export default function DupLoginPopup({ open, setOpen }: Props) {
	const navigate = useNavigate();
	const handleLoginForce = async () => {
		const res = await loginForce();
		const { next, subsidiary_search_id: SubsidiarySearchId } = res.data;
		executePostLogin({ navigate, next, subsidiary_search_id: SubsidiarySearchId });
	};

	const handleClose = () => setOpen(false);

	return (
		<PopupWarning
			size='xsmall'
			open={open}
			msg={
				<>
					<p>동일 아이디가 중복 사용중 입니다.</p>
					<p>기존 접속을 해제하고 로그인합니다.</p>
				</>
			}
			onConfirm={handleLoginForce}
			onCancel={handleClose}
			onClose={handleClose}
		/>
	);
}
