import { createTheme } from '@mui/material';
import SysConfigStore from '../store/common/SysConfigStore';
import { getComponents } from '../themes/components';
import { getPalette } from '../themes/palette';
import { getComponents as getSKComponents } from '../themes/sk/components';
import { getPalette as getSKPalette } from '../themes/sk/palette';
import { getTypography as getSKTypography } from '../themes/sk/typography';
import { getTypography } from '../themes/typography';

export const useTheme = () => {
	const { isComponentEnabled } = SysConfigStore();

	if (isComponentEnabled('CMPNT179_THEME_SK'))
		return createTheme({
			typography: getSKTypography(),
			palette: getSKPalette(),
			components: getSKComponents(),
		});

	return createTheme({
		typography: getTypography(),
		palette: getPalette(),
		components: getComponents(),
	});
};
