import { useCallback, useMemo, useState } from 'react';
import TinyMCE from '../common/editor/TinyMCE';
import ButtonText from '../ui/buttons/ButtonText';
import PopupCommonExternal from '../ui/popups/PopupCommonExternal';

export default function PopupPreviewComment() {
	const [open, setOpen] = useState(false);
	const handleOpen = useCallback(() => setOpen(true), []);
	const handleClose = useCallback(() => setOpen(false), []);
	const render = useMemo(() => {
		return (
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold text-left'>미리보기</h2>
				</div>
				<div className='flex items-center gap-4'>
					<p>
						<b>김기안</b>님 코멘트 추가
					</p>
					<p>2024/12/12 15:12</p>
				</div>
				<div className='p-3 border border-neutral-500 rounded-2xl bg-zinc-100'>
					<TinyMCE
						initialValue='A거래처에 확인 부탁드립니다'
						init={{ height: 200, menubar: false, toolbar: false, statusbar: false, readonly: true }}
						disabled
					/>
				</div>
			</section>
		);
	}, []);
	return (
		<>
			<ButtonText text='미리보기' size='small' variant='outlined' onClick={handleOpen} />
			<PopupCommonExternal open={open} msg={render} onClose={handleClose} onCancel={handleClose} />
		</>
	);
}
