import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import SysConfigStore from '../../../../store/common/SysConfigStore';
import SubsidiarySelector from '../../../common/SubsidiarySelector';
import AlarmList from '../../AlarmList';
import HeaderLeft from './HeaderLeft';
import HeaderRight from './HeaderRight';
import MenuAdmin from './MenuAdmin';
import MenuDOA from './MenuDOA';
import MenuLogo from './MenuLogo';
import MenuNoticeList from './MenuNoticeList';
import MenuSearch from './MenuSearch';
import MenuUserOptions from './MenuUserOptions';
import HeaderLeftOrder from "./HeaderLeftOrder";

export default function Header() {
	const { isComponentEnabled, isModuleEnabled } = SysConfigStore();
	const [openMenu, setOpenMenu] = useState<string | null>(null);
	const theme = useTheme();
	const handleMenuToggle = (title: string) => {
		if (openMenu === title) {
			setOpenMenu(null);
		} else {
			setOpenMenu(title);
		}
	};

	return (
		<header className='grid shadow-md shadow-gray-200 bg-white'>
			<nav className='flex justify-between gap-x-3 px-12 2xl:gap-x-6'>
				<MenuLogo openMenu={openMenu} onToggle={handleMenuToggle} />

				<div className='basis-2/6 flex items-center'>
					<MenuSearch />
				</div>

				<div className='flex gap-x-3 2xl:gap-x-6'>
					{isComponentEnabled('CMPNT145_SUBSIDIARY_MODULE_CUSTOM_01_BF') && (
						<div className='flex items-center min-w-[100px] 2xl:min-w-[150px]'>
							<SubsidiarySelector size='small' classes={{ root: 'border-0' }} />
						</div>
					)}

					{isModuleEnabled('DOA') && <MenuDOA />}

					<MenuAdmin openMenu={openMenu} onToggle={handleMenuToggle} />
					<AlarmList />

					{/* <MenuUser openMenu={openMenu} onToggle={handleMenuToggle} /> */}
					<MenuUserOptions openMenu={openMenu} onToggle={handleMenuToggle} />
				</div>
			</nav>

			<nav
				className='flex flex-wrap justify-between px-10 rounded-3xl 2xl:mx-4'
				style={{ backgroundColor: theme.palette.secondary.main }}
			>
				<div className='flex'>
					{isModuleEnabled('HEADER_MENU_BAR_SORT') ? (
                    <HeaderLeftOrder openMenu={openMenu} onToggle={handleMenuToggle} />
                ) : (
                    <HeaderLeft openMenu={openMenu} onToggle={handleMenuToggle} />
                )}
				</div>
				<div className='hidden items-center 2xl:flex'>
					<MenuNoticeList />
				</div>
				<div className='flex'>
					<HeaderRight openMenu={openMenu} onToggle={handleMenuToggle} />
				</div>
			</nav>
		</header>
	);
}
