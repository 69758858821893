import { Menu, useTheme } from '@mui/material';
import { uniqueId } from 'lodash';
import { MouseEvent, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { MenuData } from '../../../../../model/header';
import MenuItem2Depth from '../../../../header/custom/sk/MenuItem2Depth';
import ButtonText from '../../../../ui/buttons/ButtonText';
import ExpandMoreIcon from '../../../../ui/icons/ExpandMoreIcon';

type Props = {
	itemData: MenuData;
	isOpen: boolean;
	setIsopen: (itme: boolean) => void;
};

export default function AdminSettingMenuDepth({ itemData, isOpen, setIsopen }: Props) {
	const theme = useTheme();
	const { icon, title, link = '', classes = { root: '', button: '' } } = itemData;
	const { root = '', button = '' } = classes;
	const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
	const { pathname } = useLocation();
	const navigate = useNavigate();

	const handleOpen = (e: MouseEvent<HTMLButtonElement>, { link: href, isExternal, preHandle }: MenuData) => {
		setIsopen(true);
		if (preHandle) preHandle();
		setAnchorEl(e.currentTarget); // 메뉴를 연결할 요소 설정
		// onToggle(); // 상위 컴포넌트에서 상태 변경
		if (!href) return;
		if (!isExternal) navigate(href);
		else window.location.href = href;
	};

	const handleClose = () => {
		setIsopen(false);
		setAnchorEl(null); // anchorEl 상태 초기화
		// onToggle(); // 상위 컴포넌트에서 상태 변경
	};

	return (
		itemData.children?.length > 0 && (
			<div className={`${root}`}>
				<ButtonText
					className={`flex-col gap-5 min-w-0 min-h-0 h-full text-base font-bold rounded-none ${
						!icon && link && pathname.includes(link) ? 'bg-neutral-600' : ''
					} ${button}`}
					onClick={e => handleOpen(e, itemData)}
				>
					<div className='flex items-center'>
						{title ?? title}
						{icon ?? icon}
						{itemData.children && (
							<ExpandMoreIcon
								fontSize='small'
								className={`text-neutral-300 transition-transform ${isOpen && 'rotate-180'}`}
							/>
						)}
					</div>
					{!icon && pathname.includes(link) && link && (
						<span
							className='absolute left-0 bottom-0 w-full border-b-4'
							style={{
								borderColor:
									pathname.includes(link) && link ? theme.palette.primary.main : 'transparent',
							}}
						/>
					)}
				</ButtonText>
				{isOpen && itemData.children && (
					<Menu anchorEl={anchorEl} open={isOpen} onClose={handleClose}>
						{itemData.children
							.filter(({ display: dis }: MenuData) => dis)
							.map((childItem: MenuData) => (
								<MenuItem2Depth
									key={uniqueId(`${childItem.title}`)}
									childItem={childItem}
									onClose={handleClose}
								/>
							))}
					</Menu>
				)}
			</div>
		)
	);
}
