import { FormHelperText, InputLabel } from '@mui/material';
import Select, { SelectProps } from '@mui/material/Select';
import { FormikValues } from 'formik';
import FormControl from '@mui/material/FormControl';
import { useState } from 'react';
import UserChip from '../chips/UserChip';
import { User } from '../../../model/user';

type SelectPropsOverrides = SelectProps & {
	id: string;
	size?: 'small' | 'medium';
	formik?: FormikValues;
	onClick?: () => void;
};

export default function InputPopupSelect({
	id,
	label,
	size = 'medium',
	disabled = false,
	required = false,
	onClick,
	formik,
	...props
}: SelectPropsOverrides) {
	const [open, setOpen] = useState(false);
	const handleRenderValue = (selected: User[]): React.ReactNode => {
		return (
			<div className='flex flex-wrap gap-2'>
				{selected.map(item => (
					<UserChip key={item.email} id={id} formik={formik} user={item} disabled={disabled} />
				))}
			</div>
		);
	};

	return (
		<div>
			<FormControl
				className='w-full relative text-m'
				size={size}
				error={formik?.touched[id] && formik?.errors[id] && true}
			>
				<InputLabel id={`${id}_label`} required={required}>
					{label}
				</InputLabel>
				<Select
					labelId={`${id}_label`}
					label={label}
					id={id}
					multiple
					value={formik?.values[id]}
					open={open}
					autoFocus={false}
					renderValue={handleRenderValue}
					onOpen={() => {
						if (onClick) onClick();
						setOpen(false);
					}}
					{...props}
				/>
				{formik?.touched[id] && formik?.errors[id] && (
					<FormHelperText classes={{ root: 'absolute bottom-0 translate-y-full mx-0' }} error>
						{formik?.errors[id]}
					</FormHelperText>
				)}
			</FormControl>
		</div>
	);
}
