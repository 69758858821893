import {
	PaperManage,
	PaperManageDetailTableRow,
	PaperManageTableRow,
	PaperManageTableSearchParams,
} from '../model/paperManage';
import { SealStamp } from '../model/sealStamp';
import { User } from '../model/user';
import { sendGetWithFileDownload, sendPost, sendPostPageNavigation, sendUploadPost } from './common';
import { ExcelExportTaskIdResponse } from '../model/tasks';

export async function createPaperManage({
	title,
	paperType,
	subsidiary,
	description,
	userList,
	fileList,
	sealStamp,
}: {
	title: string;
	paperType: string;
	subsidiary: number;
	description?: string;
	userList: User[];
	fileList?: FileList;
	sealStamp?: SealStamp[];
}) {
	const formData = new FormData();
	formData.append('title', title);
	formData.append('paperType', paperType);
	formData.append('subsidiary', String(subsidiary));
	if (description) formData.append('description', description);
	if (fileList) Array.from(fileList).map(file => formData.append('fileList', file));
	Array.from(userList).map(user => formData.append('userList', String(user.id)));
	if (sealStamp && sealStamp.length > 0) formData.append('sealStampId', String(sealStamp[0].seal_stamp_id));

	const res = await sendUploadPost<PaperManage>('/admin/create-paper-manage/', undefined, formData);

	return res.data;
}

export async function updatePaperManageDetail(obj: PaperManage) {
	const formData = new FormData();
	Object.entries(obj).map(([key, value]) => {
		formData.append(key, String(value));
		return [key, value];
	});
	if (obj.fileList) Array.from(obj.fileList).map(file => formData.append('fileList', file));
	const res = await sendUploadPost<PaperManage>('/admin/update-paper-manage-detail/', undefined, formData);

	return res.data;
}

export async function getPaperManageList(searchParams: PaperManageTableSearchParams) {
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;
	const params = {
		returnType: 'json',
		curr_page: currentPage + 1,
		search_type: searchType,
		search_text: searchText,
		number_per_page: rowsPerPage,
		// order_col: orderColumn,
		// order,
	};
	const res = await sendPostPageNavigation<PaperManageTableRow[]>('/admin/get-paper-manage-list/', params);

	return res;
}

export async function getPaperManageDetail(id: number) {
	const res = await sendPost<PaperManage>('/admin/get-paper-manage-detail/', { id });
	return res.data;
}

export async function getPaperManageDetailList(id: number) {
	const res = await sendPost<PaperManageDetailTableRow[]>('/admin/get-paper-manage-detail-list/', { id });
	return res.data;
}

export async function downloadPaperManageFile(id: number, fileName: string) {
	await sendGetWithFileDownload(`/admin/download-paper-manage-file`, id, fileName);
}

export async function deletePapaerManage(id: number) {
	await sendPost<PaperManage>('/admin/delete-paper-manage/', { id });
}

export async function downloadExcelPaperManageList(searchParams: PaperManageTableSearchParams) {
	const { searchType, searchText, currentPage, rowsPerPage } = searchParams;
	const params = {
		search_type: searchType,
		search_text: searchText,
	};
	const res = await sendPost<ExcelExportTaskIdResponse>('/admin/download-excel-paper-manage-list/', params);
	return res.data;
}
