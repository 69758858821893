import { useCallback } from 'react';
import { useValidateForm } from '../../../../hooks/useValidateForm';
import SearchIcon from '../../../ui/icons/SearchIcon';
import InputText from '../../../ui/inputs/InputText';

export default function MenuSearch() {
	const formik = useValidateForm({
		validationSchema: {
			search: { initialValue: '', type: 'string' },
		},
		onSubmit: ({ search }) => {
			// 여기서 작업 필요
			console.log(search);
		},
	});

	const handleChange = useCallback(() => {
		formik.handleSubmit();
	}, [formik.values.search]);

	return (
		<InputText
			id='search'
			classes={{ root: 'border-transparent rounded-3xl bg-zinc-100' }}
			icon={<SearchIcon />}
			labelText='통합검색'
			placeholder='검색 내용을 입력해주세요.'
			size='small'
			delay={1000}
			onBeforeDebounceChange={handleChange}
			formik={formik}
		/>
	);
}
