import { Dispatch, SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import PopupWarning from '../../ui/popups/PopupWarning';
import ButtonBack from '../../ui/buttons/ButtonBack';
import { PopupCommonExternalProps } from '../../ui/popups/PopupCommonExternal';

type Props = PopupCommonExternalProps & {
	setOpen: Dispatch<SetStateAction<boolean>>;
};

export default function PopupBack({ open, setOpen, onConfirm, ...props }: Props) {
	const { t } = useTranslation();
	const handleClose = () => setOpen(false);
	const handleConfirm = () => {
		if (typeof onConfirm === 'function') onConfirm();
	};

	return (
		<>
			<ButtonBack text={t('label_go_back')} onClick={() => setOpen(true)} />
			<PopupWarning
				size='xsmall'
				open={open}
				onConfirm={handleConfirm}
				onCancel={handleClose}
				onClose={handleClose}
				{...props}
			/>
		</>
	);
}
