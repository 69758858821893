/**
 * i18next 라이브러리를 사용하여 다국어 기능을 지원하는 모듈입니다.
 *
 * @file 다국어 설정 정의 파일
 * @author il jung kim
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import ko from './resource/ko.json';
import en from './resource/en.json';
import ja from './resource/ja.json';

// 각 언어에 해당하는 번역 리소스 파일 매핑
const resources = {
	ko: {
		translation: ko,
	},
	en: {
		translation: en,
	},
	ja: {
		translation: ja,
	},
};

i18n.use(initReactI18next).init({
	resources, // 사용할 번역 리소스
	lng: 'ko', // 언어 초기값 설정
	fallbackLng: 'ko', // 설정 된 언어 리소스가 없을 시 사용할 기본 언어(예: 언어 코드를 잘못 입력했을 경우)
	interpolation: {
		escapeValue: false, // XSS 방지용 설정(HTML 등의 특수 문자를 해석하지 않고 문자열 그대로 번역)
	},
});
