import { Props, Rnd } from 'react-rnd';

type PropsOverrides = Props;

export default function RndArea({ ...props }: PropsOverrides) {
	const { bounds = 'parent', children, className = '', id, x, y, width, height } = props;

	return (
		<Rnd
			className={className}
			data-id={id}
			bounds={bounds}
			default={{
				x,
				y,
				width,
				height,
			}}
			lockAspectRatio
			{...props}
		>
			{children}
		</Rnd>
	);
}
