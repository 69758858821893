import { sendGet, sendPost } from './common';
import { DashboardNotice, DashboardWorkStatus, DashboardWorkStatusCountData } from '../model/api/dashboard';
import { Subsidiary } from '../model/api/company';
import { DashboardContractProgress, DashboardCounselProgress } from '../model/common';

export async function getMainWorkStatus(workType: DashboardWorkStatus['history_type'] | 'ALL', subsidiaryId: number) {
	const params = {
		workType,
		...(subsidiaryId > 0 && { subsidiaryId }),
	};
	const res = await sendPost<DashboardWorkStatus[]>('/api/get_main_work_status_list/', params);
	return res.data;
}

export async function getMainWorkStatusCount(subsidiaryId: number) {
	const params = {
		...(subsidiaryId > 0 && { subsidiaryId }),
	};
	const res = await sendPost<DashboardWorkStatusCountData>('/api/get_dashboard_work_status_count/', params);
	return res.data;
}

export async function getNoticeList() {
	const res = await sendGet<DashboardNotice[]>('/init_notice_alarm_view/');
	return res.data;
}

export async function getSubsidiaryList() {
	const res = await sendGet<Subsidiary[]>('/api/get_all_subsidiary_list/');
	return res.data;
}

export async function getDashboardProgressCount() {
	const res =
		await sendGet<Record<DashboardContractProgress | DashboardCounselProgress, number>>(
			'/get-status-count-list-v28/',
		);
	return res.data;
}
