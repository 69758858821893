import { Skeleton } from '@mui/material';
import dayjs, { Dayjs } from 'dayjs';
import { useTranslation } from 'react-i18next';
import { getCalendarScheduleDetail } from '../../../../../api/calendar';
import { calendarSubjectMap } from '../../../../../constants/calendar';
import { useTheme } from '../../../../../hooks/useTheme';
import { ResScheduleData } from '../../../../../model/calendar';
import { dateFormat } from '../../../../../util/dateFormat';
import ButtonText from '../../../../ui/buttons/ButtonText';

type Props = {
	selectedDate: Dayjs;
	isLoading: boolean;
	schedules?: ResScheduleData[];
};

export default function CalendarListSchedule({ selectedDate, isLoading, schedules = [] }: Props) {
	const { t } = useTranslation();
	const theme = useTheme();
	const scheduleList = schedules.map(schedule => ({
		scheduleId: schedule.schedule_id,
		consMessage: schedule.cons_message,
		createTime: schedule.create_time,
		dDay: schedule.d_day,
		dDayTime: schedule.d_day_time,
		startTime: schedule.start_time,
		title: schedule.title,
		appType: schedule.app_type,
		appTitle: schedule.app_title,
	}));

	const handleNavigate = (id: number) => {
		getCalendarScheduleDetail(id).then(({ app_link: link }) => {
			if (link) window.location.href = link;
		});
	};

	return (
		<div className='flex flex-col gap-2'>
			<ul>
				{isLoading && (
					<li className='flex flex-col gap-1 py-3 border-y border-gray-300'>
						<Skeleton animation='wave' height={15} width='50%' />
						<div className='flex justify-between'>
							<Skeleton animation='wave' height={15} width='30%' />
							<Skeleton animation='wave' height={15} width='10%' />
						</div>
						<Skeleton animation='wave' height={15} width='80%' />
					</li>
				)}
				{!isLoading && scheduleList.length === 0 && (
					<li className='py-3'>
						<div className='flex flex-col items-center'>
							<p className='text-m font-bold text-gray-700'>{t('cmmn_label_no_scheduled_events')}</p>
						</div>
					</li>
				)}
				{!isLoading &&
					scheduleList.length > 0 &&
					scheduleList.map(({ scheduleId, startTime, appTitle, consMessage }) => {
						const diffDays = dayjs(startTime)
							.startOf('day')
							.diff(dayjs(selectedDate).startOf('day'), 'day');
						return (
							<li key={scheduleId} className={`pb-3 ${diffDays ? 'border-b border-gray-300' : ''}`}>
								<ButtonText
									className='w-full px-0 text-left'
									onClick={() => handleNavigate(scheduleId)}
								>
									<div className='grid w-full'>
										<div className='flex items-center justify-between'>
											<span>{calendarSubjectMap[consMessage]}</span>
											{diffDays ? (
												<div className='flex items-center gap-3'>
													<span className='text-sm font-bold text-neutral-500'>
														{dateFormat(startTime).format('MM월 DD일 (ddd)')}
													</span>
													<span
														className='font-bold text-sm'
														style={{ color: theme.palette.primary.main }}
													>{`D-${diffDays}`}</span>
												</div>
											) : (
												''
											)}
										</div>
										<p className='text-sm text-neutral-400 font-bold truncate'>{appTitle}</p>
									</div>
								</ButtonText>
							</li>
						);
					})}
			</ul>
		</div>
	);
}
