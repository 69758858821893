/* eslint-disable no-else-return */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable prefer-const */
/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs, { Dayjs } from 'dayjs';
import _ from 'lodash';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { AnyObject } from 'yup';
// import Chart from '../../components/ui/Chart';
// import ButtonText from '../../components/ui/buttons/ButtonText';
// import CardBox from '../../components/ui/containers/CardBox';
import InputDate from '../../components/ui/inputs/InputDate';
import InputSelect from '../../components/ui/inputs/InputSelect';
import { useValidateForm } from '../../hooks/useValidateForm';
// import { getPieOption, getAreaLineOption, getBarVerticalOption } from '../../util/chartOptions';
import {
	getDashboardCategoryStatistics,
	getDashboardLawsuitStatistics,
	getDashboardNewReviewManagerStatistics,
	getDashboardNewReviewStatistics,
	getDashboardReviewTimeStatistics,
} from '../../api/statistics';
import SubsidiarySelector from '../../components/common/SubsidiarySelector';
import StatisticsList from '../../components/statistics/StatisticsList';
import ButtonLoading from '../../components/ui/buttons/ButtonLoading';
import CardBox from '../../components/ui/containers/CardBox';
import TableSkeleton from '../../components/ui/tables/TableSkeleton';
import { STATISTICS_CHART_COLOR } from '../../constants/common';
import { useStatistics } from '../../context/StatisticsContext';
import {
	CategoryCount,
	LawsuitManager,
	LegalList,
	NewReviews,
	NewReviewsManager,
	PeriodOptionValue,
	RealtimeReviews,
	ReviewTimes,
} from '../../model/statistics';
import SysConfigStore from '../../store/common/SysConfigStore';
import { dateFormat, getStartDateOfWeek } from '../../util/dateFormat';
import ChartComponent from './DetailStatisticsChart';

type ChartData = {
	type: string | '';
	count: number | 0;
	option1: { data: { value: number; name: string; color: string }[]; name: string };
	option2: { xAxisData: string[]; data: { name: string; data: number[]; color: string }[] };
	option3: { xAxisData: string[]; data: { name: string; data: number[]; color: string }[] };
};

export default function DetailPage() {
	const { isModuleEnabled, labelText } = SysConfigStore();
	const { period, setPeriod, startDate, setStartDate, endDate, setEndDate } = useStatistics();
	const { state } = useLocation();

	const [resRealtimeStatisticsData, setResRealtimeStatisticsData] = useState<RealtimeReviews>();
	const [resReviewTimesStatisticsData, setResReviewTimesStatisticsData] = useState<ReviewTimes>();
	const [resNewReviewStatisticsData, setResNewReviewStatisticsData] = useState<NewReviews>();
	const [resNewReviewsManager, setResNewReviewsManager] = useState<NewReviewsManager>();
	const [resCategoryCount, setResCategoryCount] = useState<CategoryCount>();
	const [resLawsuitManager, setResLawsuitManager] = useState<LawsuitManager>();
	const [chartInfo, setChartInfo] = useState<ChartData>({
		type: state.query,
		count: 0,
		option1: { data: [], name: '' },
		option2: { xAxisData: [], data: [] },
		option3: { xAxisData: [], data: [] },
	});
	const [legalListIds, setLegalListIds] = useState<LegalList>();
	const [isLoading, setIsLoading] = useState(true);
	const [btnLoading, setBtnLoading] = useState(false);
	const [subsidiary, setsubsidiary] = useState<number>(-1);

	let count: number = 0;
	let chartOption1: { data: { value: number; name: string; color: string }[]; name: string } = {
		data: [],
		name: '',
	};
	let chartOption2: { xAxisData: string[]; data: { name: string; data: number[]; color: string }[] } = {
		xAxisData: [],
		data: [],
	};

	const newReviewChartData = (resData: NewReviews) => {
		const conCnt = resData?.contract_count ?? 0;
		const counselCnt = resData?.counsel_count ?? 0;

		const data1: { value: number; name: string; color: string }[] = [];

		data1.push({
			value: conCnt,
			name: '계약',
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data1.push({
				value: counselCnt,
				name: '자문',
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		const cd: ChartData = {
			type: state.query,
			count: conCnt + counselCnt,
			option1: { data: data1, name: '신규 검토 법무별 건수' },
			option2: { xAxisData: [], data: [] },
			option3: { xAxisData: [], data: [] },
		};

		setChartInfo(cd);
	};

	const newReviewCountData = (resData: ReviewTimes, res2Data: NewReviews) => {
		const conCompleteCnt = resData?.reviewed_contract_ids.length ?? 0;
		const counselCompleteCnt = resData?.reviewed_counsel_ids.length ?? 0;
		const conCnt = res2Data?.contract_count ?? 0;
		const counselCnt = res2Data?.counsel_count ?? 0;

		const data1: { value: number; name: string; color: string }[] = [];

		data1.push({
			value: conCnt + counselCnt,
			name: '검토',
			color: STATISTICS_CHART_COLOR.LEGAL_REVIEW_IN_PROGRESS,
		});
		data1.push({
			value: conCompleteCnt + counselCompleteCnt,
			name: '처리',
			color: STATISTICS_CHART_COLOR.LEGAL_REVIEW_COMPLETE,
		});

		const cd: ChartData = {
			type: state.query,
			count: conCnt + counselCnt + conCompleteCnt + counselCompleteCnt,
			option1: {
				data: data1,
				name: isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 발생 상태별 건수' : '계약 발생 상태별 건수',
			},
			option2: { xAxisData: [], data: [] },
			option3: { xAxisData: [], data: [] },
		};

		setChartInfo(cd);
	};

	const reviewedCountLineChart = (resData: ReviewTimes) => {
		let conDate = resData?.reviewed_contract_datetime.map(item => item.split('|')[0]);
		conDate = conDate?.sort();
		let counselDate = resData?.reviewed_counsel_datetime.map(item => item.split('|')[0]);
		counselDate = counselDate?.sort();

		let conDateCnt = conDate?.map(item => conDate?.filter(el => item === el).length);

		let counselDateObj = counselDate?.map(item => counselDate?.filter(el => item === el).length);

		let concatContractObj: AnyObject = _.zipObjectDeep(conDate, conDateCnt);
		let concatCounselObj: AnyObject = _.zipObjectDeep(counselDate, counselDateObj);

		let allDates = _.union(_.keys(concatContractObj), _.keys(concatCounselObj));

		_.forEach(allDates, key => {
			if (concatContractObj[key] === undefined) {
				concatContractObj[key] = 0;
			}
			if (concatCounselObj[key] === undefined) {
				concatCounselObj[key] = 0;
			}
		});

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		data3.xAxisData = _.keys(concatContractObj).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => concatContractObj[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data3.data.push({
				name: '자문',
				data: data3.xAxisData.map(item => concatCounselObj[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: data3,
			option3: { xAxisData: [], data: [] },
		};

		setChartInfo(cd);
	};

	const newReviewCountLineChart = (resData: NewReviews) => {
		let conDate = resData?.contract_create_time;
		conDate = conDate?.sort();
		let counselDate = resData?.counsel_create_time;
		counselDate = counselDate?.sort();

		let conDateCnt = conDate?.map(item => conDate?.filter(el => item === el).length);

		let counselDateObj = counselDate?.map(item => counselDate?.filter(el => item === el).length);

		let concatContractObj: AnyObject = _.zipObjectDeep(conDate, conDateCnt);
		let concatCounselObj: AnyObject = _.zipObjectDeep(counselDate, counselDateObj);

		let allDates = _.union(_.keys(concatContractObj), _.keys(concatCounselObj));

		_.forEach(allDates, key => {
			if (concatContractObj[key] === undefined) {
				concatContractObj[key] = 0;
			}
			if (concatCounselObj[key] === undefined) {
				concatCounselObj[key] = 0;
			}
		});

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		data3.xAxisData = _.keys(concatContractObj).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => concatContractObj[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data3.data.push({
				name: '자문',
				data: data3.xAxisData.map(item => concatCounselObj[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: data3,
			option3: { xAxisData: [], data: [] },
		};

		setChartInfo(cd);
	};

	const reviewManagerChartData = (resData: NewReviewsManager) => {
		let conManager = resData?.contract_review_manager ?? {};
		let counselManager = resData?.counsel_review_manager ?? {};

		let allManagers = _.union(_.keys(conManager), _.keys(counselManager));

		_.forEach(allManagers, key => {
			if (conManager[key] === undefined) {
				conManager[key] = 0;
			}
			if (counselManager[key] === undefined) {
				counselManager[key] = 0;
			}
		});

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		data3.xAxisData = _.keys(conManager).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => conManager[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});
		if (isModuleEnabled('COUNSEL_MANAGE')) {
			data3.data.push({
				name: '자문',
				data: data3.xAxisData.map(item => counselManager[item]),
				color: STATISTICS_CHART_COLOR.COUNSEL,
			});
		}

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: { xAxisData: [], data: [] },
			option3: data3,
		};

		setChartInfo(cd);
	};

	const categoryChartData = (resData: CategoryCount) => {
		let conCategory = resData?.contract_category_count ?? {};

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		data3.xAxisData = _.keys(conCategory).sort();

		data3.data.push({
			name: '계약',
			data: data3.xAxisData.map(item => conCategory[item]),
			color: STATISTICS_CHART_COLOR.CONTRACT,
		});

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: { xAxisData: [], data: [] },
			option3: data3,
		};

		setChartInfo(cd);
	};

	const categoryCounselChartData = (resData: CategoryCount) => {
		let counselCategory = resData?.counsel_category_count ?? {};

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		data3.xAxisData = _.keys(counselCategory).sort();

		data3.data.push({
			name: '자문',
			data: data3.xAxisData.map(item => counselCategory[item]),
			color: STATISTICS_CHART_COLOR.COUNSEL,
		});

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: { xAxisData: [], data: [] },
			option3: data3,
		};

		setChartInfo(cd);
	};

	const lawsuitManager = (resData: LawsuitManager) => {
		let managers: string[] = _.keys(resData.manager).map(item => item.split('|')[0]);

		managers = managers.filter((item, idx) => managers.indexOf(item) === idx);

		const data1: { value: number; name: string; color: string }[] = [];
		let totalCnt = 0;

		managers.map((item, idx) => {
			let manageInner = `${item}|INNER_LAWYER`;
			let manageOuter = `${item}|OUTER_LAWYER`;
			let manageEtc = `${item}|ETC`;
			const colorKey =
				resLawsuitManager && !_.isEmpty(resLawsuitManager?.manager)
					? (Object.keys(resLawsuitManager.manager)[0].split('|')[1] as
							| 'INNER_LAWYER'
							| 'OUTER_LAWYER'
							| 'ETC')
					: 'INNER_LAWYER';

			data1.push({
				value:
					(resData !== undefined && resData.manager[manageInner] !== undefined
						? resData.manager[manageInner]
						: 0) +
					(resData !== undefined && resData.manager[manageOuter] !== undefined
						? resData.manager[manageOuter]
						: 0) +
					(resData !== undefined && resData.manager[manageEtc] !== undefined
						? resData.manager[manageEtc]
						: 0),
				name: item,
				color: STATISTICS_CHART_COLOR[`LAWSUIT_${colorKey}`],
			});

			totalCnt +=
				(resData !== undefined && resData.manager[manageInner] !== undefined
					? resData.manager[manageInner]
					: 0) +
				(resData !== undefined && resData.manager[manageOuter] !== undefined
					? resData.manager[manageOuter]
					: 0) +
				(resData !== undefined && resData.manager[manageEtc] !== undefined ? resData.manager[manageEtc] : 0);

			return data1;
		});

		const cd: ChartData = {
			type: state.query,
			count: totalCnt,
			option1: { data: data1, name: '소송 담당자 배정현황(진행중)' },
			option2: { xAxisData: [], data: [] },
			option3: { xAxisData: [], data: [] },
		};

		setChartInfo(cd);
	};

	const lawsuitManagerInnerOrOuter = (resData: LawsuitManager) => {
		let managers: string[] = _.keys(resData.manager)
			.map(item => item.split('|')[0])
			.sort();
		let types: string[] = _.keys(resLawsuitManager?.manager)
			.map(item => item.split('|')[1])
			.sort();
		let innerCnt: number[];
		let outerCnt: number[];
		let etcCnt: number[] = [];

		const data3: {
			xAxisData: string[];
			data: { name: string; data: number[]; color: string }[];
		} = { xAxisData: [], data: [] };

		managers = managers.filter((item, idx) => managers.indexOf(item) === idx);

		data3.xAxisData = managers;

		innerCnt = managers.map(item => {
			return resData !== undefined && resData.manager[`${item}|INNER_LAWYER`] !== undefined
				? resData.manager[`${item}|INNER_LAWYER`]
				: 0;
		});

		outerCnt = managers.map(item => {
			return resData !== undefined && resData.manager[`${item}|OUTER_LAWYER`] !== undefined
				? resData.manager[`${item}|OUTER_LAWYER`]
				: 0;
		});

		etcCnt = managers.map(item => {
			return resData !== undefined && resData.manager[`${item}|ETC`] !== undefined
				? resData.manager[`${item}|ETC`]
				: 0;
		});

		data3.data.push({
			name: '내부',
			data: innerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_INNER_LAWYER,
		});

		data3.data.push({
			name: '외부',
			data: outerCnt,
			color: STATISTICS_CHART_COLOR.LAWSUIT_OUTER_LAWYER,
		});

		if (types.indexOf('ETC') >= 0) {
			data3.data.push({
				name: '기타',
				data: etcCnt,
				color: STATISTICS_CHART_COLOR.LAWSUIT_ETC,
			});
		}

		const cd: ChartData = {
			type: state.query,
			count: 0,
			option1: { data: [], name: '' },
			option2: { xAxisData: [], data: [] },
			option3: data3,
		};

		setChartInfo(cd);
	};

	// const get

	const getStatistics = (params: {
		subsidiary?: number | string;
		period: string;
		startDate: string;
		endDate: string;
	}) => {
		setIsLoading(true);
		setLegalListIds({ contractIds: [], counselIds: [], lawsuitIds: [] });
		if (state.query === 'NEW-REVIEW') {
			getDashboardNewReviewStatistics({
				...params,
			}).then(res => {
				setResNewReviewStatisticsData(res);
				setLegalListIds({ contractIds: res.contract_ids, counselIds: res.counsel_ids, lawsuitIds: [] });
				setIsLoading(false);
				newReviewChartData(res);
			});
		} else if (state.query === 'NEW-REVIEW-STATUS-RATE') {
			getDashboardReviewTimeStatistics({
				...params,
			}).then(res => {
				getDashboardNewReviewStatistics({
					...params,
				}).then(res2 => {
					setResNewReviewStatisticsData(res2);
					setResReviewTimesStatisticsData(res);
					const concatcontractIds = res.reviewed_contract_ids.concat(res2.contract_ids);
					const concatCounselIds = res.reviewed_counsel_ids.concat(res2.counsel_ids);
					setLegalListIds({
						contractIds: concatcontractIds.filter((item, pos) => concatcontractIds.indexOf(item) === pos),
						counselIds: concatCounselIds.filter((item, pos) => concatCounselIds.indexOf(item) === pos),
						lawsuitIds: [],
					});
					setIsLoading(false);
					newReviewCountData(res, res2);
				});
			});
		} else if (state.query === 'NEW-REVIEWED-COUNT') {
			getDashboardReviewTimeStatistics({
				...params,
			}).then(res => {
				setResReviewTimesStatisticsData(res);
				setLegalListIds({
					contractIds: res.reviewed_contract_ids,
					counselIds: res.reviewed_counsel_ids,
					lawsuitIds: [],
				});
				setIsLoading(false);
				reviewedCountLineChart(res);
			});
		} else if (state.query === 'NEW-REVIEW-PERIOD-COUNT') {
			getDashboardNewReviewStatistics({
				...params,
			}).then(res => {
				setResNewReviewStatisticsData(res);
				setLegalListIds({ contractIds: res.contract_ids, counselIds: res.counsel_ids, lawsuitIds: [] });
				setIsLoading(false);
				newReviewCountLineChart(res);
			});
		} else if (state.query === 'NEW-REVIEW-MANAGERS') {
			getDashboardNewReviewManagerStatistics({
				...params,
			}).then(res => {
				setResNewReviewsManager(res);
				setLegalListIds({ contractIds: res.contract_ids, counselIds: res.counsel_ids, lawsuitIds: [] });
				setIsLoading(false);
				reviewManagerChartData(res);
			});
		} else if (state.query === 'NEW-REVIEW-CONTRACT-COUNT-BY-CATEGORY') {
			getDashboardCategoryStatistics({
				...params,
			}).then(res => {
				setResCategoryCount(res);
				setLegalListIds({ contractIds: res.contract_ids, counselIds: [], lawsuitIds: [] });
				setIsLoading(false);
				categoryChartData(res);
			});
		} else if (state.query === 'NEW-REVIEW-COUNSEL-COUNT-BY-CATEGORY') {
			getDashboardCategoryStatistics({
				...params,
			}).then(res => {
				setResCategoryCount(res);
				setLegalListIds({ contractIds: [], counselIds: res.counsel_ids, lawsuitIds: [] });
				setIsLoading(false);
				categoryCounselChartData(res);
			});
		} else if (state.query === 'NEW-LAWSUIT-MANAGE-COUNT') {
			getDashboardLawsuitStatistics({
				...params,
			}).then(res => {
				setResLawsuitManager(res);
				setLegalListIds({ contractIds: [], counselIds: [], lawsuitIds: res.lawsuit_ids });
				setIsLoading(false);
				lawsuitManager(res);
			});
		} else if (state.query === 'NEW-LAWSUIT-MANAGE-WITH-IN-AND-OUT') {
			getDashboardLawsuitStatistics({
				...params,
			}).then(res => {
				setResLawsuitManager(res);
				setLegalListIds({ contractIds: [], counselIds: [], lawsuitIds: res.lawsuit_ids });
				setIsLoading(false);
				lawsuitManagerInnerOrOuter(res);
			});
		}
	};

	const formik = useValidateForm({
		validationSchema: {
			search_date_start: { initialValue: startDate ?? dateFormat().format('YYYY.MM.DD'), type: 'string' },
			search_date_end: { initialValue: endDate ?? dateFormat().format('YYYY.MM.DD'), type: 'string' },
			search_date_select: { initialValue: 'month', type: 'string' },
		},
		onSubmit: values => {
			setPeriod(formik.values.search_date_select);
			setStartDate(formik.values.search_date_start);
			setEndDate(formik.values.search_date_end);
			getStatistics({
				subsidiary,
				period: formik.values.search_date_select,
				startDate: formik.values.search_date_start,
				endDate: formik.values.search_date_end,
			});
		},
	});

	const chartDescription = () => {
		if (state.query === 'NEW-REVIEW')
			return isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 검토 건수' : '계약 신규 검토 건수';
		if (state.query === 'NEW-REVIEW-STATUS-RATE')
			return isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 상태별 건수' : '계약 상태별 건수';
		if (state.query === 'NEW-REVIEWED-COUNT')
			return isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 검토 처리 건수 추이' : '계약 검토 처리 건수 추이';
		if (state.query === 'NEW-REVIEW-PERIOD-COUNT')
			return isModuleEnabled('COUNSEL_MANAGE') ? '계약/자문 신규 발생 건수 추이' : '계약 신규 발생 건수 추이';
		if (state.query === 'NEW-REVIEW-MANAGERS')
			return isModuleEnabled('COUNSEL_MANAGE') ? '신규 계약/자문 검토자 배정 현황' : '신규 계약 검토자 배정 현황';
		if (state.query === 'NEW-REVIEW-CONTRACT-COUNT-BY-CATEGORY') return '계약 카테고리별 신규 현황';
		if (state.query === 'NEW-REVIEW-COUNSEL-COUNT-BY-CATEGORY') return '자문 카테고리별 신규 현황';
		if (state.query === 'NEW-LAWSUIT-MANAGE-COUNT') return '신규소송 담당자 배정 현황';
		if (state.query === 'NEW-LAWSUIT-MANAGE-WITH-IN-AND-OUT')
			return `${labelText('cmmn_label_lawsuit_external_delegation')}`;

		return '';
	};

	const isSelectCustomDate = formik.values.search_date_select === 'custom';

	useEffect(() => {
		// getStatistics();
		getStatistics({
			subsidiary,
			period: formik.values.search_date_select,
			startDate: formik.values.search_date_start,
			endDate: formik.values.search_date_end,
		});
	}, []);

	useEffect(() => {
		getStatistics({
			subsidiary,
			period: formik.values.search_date_select,
			startDate: formik.values.search_date_start,
			endDate: formik.values.search_date_end,
		});
	}, [state.query]);

	useEffect(() => {
		const periodMap: Record<PeriodOptionValue, { startDate: Dayjs; endDate: Dayjs }> = {
			week: { startDate: getStartDateOfWeek(1), endDate: getStartDateOfWeek(1).add(6, 'day') },
			lastweek: {
				startDate: getStartDateOfWeek(1).subtract(1, 'week'),
				endDate: getStartDateOfWeek(1).subtract(1, 'week').add(6, 'day'),
			},
			month: {
				startDate: dayjs().startOf('month'),
				endDate: dayjs().endOf('month'),
			},
			lastmonth: {
				startDate: dayjs().subtract(1, 'month').startOf('month'),
				endDate: dayjs().subtract(1, 'month').endOf('month'),
			},
			first_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 0, 1)),
				endDate: dayjs(new Date(dayjs().year(), 2, 31)),
			},
			second_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 3, 1)),
				endDate: dayjs(new Date(dayjs().year(), 5, 30)),
			},
			third_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 6, 1)),
				endDate: dayjs(new Date(dayjs().year(), 8, 30)),
			},
			fourth_quarter: {
				startDate: dayjs(new Date(dayjs().year(), 9, 1)),
				endDate: dayjs(new Date(dayjs().year(), 11, 31)),
			},
			year: {
				startDate: dayjs().startOf('year'),
				endDate: dayjs().endOf('year'),
			},
			lastyear: {
				startDate: dayjs().subtract(1, 'year').startOf('year'),
				endDate: dayjs().subtract(1, 'year').endOf('year'),
			},
			custom: {
				startDate: dayjs(),
				endDate: dayjs(),
			},
		};

		const selectOption = formik.values.search_date_select as PeriodOptionValue;

		if (selectOption !== 'custom') {
			formik.setValues({
				...formik.values,
				search_date_start: periodMap[selectOption].startDate.format('YYYY.MM.DD'),
				search_date_end: periodMap[selectOption].endDate.format('YYYY.MM.DD'),
			});
		}
		setPeriod(formik.values.search_date_select);
	}, [formik.values.search_date_select]);

	return (
		<section className='flex flex-col gap-6 px-6 py-14'>
			<header className='flex flex-col gap-6'>
				<div className='flex flex-col items-center justify-between gap-6 lg:flex-row'>
					<h2 className='text-2xl'>{chartDescription()}</h2>
					<div className='flex items-center gap-5'>
						<SubsidiarySelector individualId={subsidiary} setIndividualId={setsubsidiary} />
						<div>
							<InputSelect
								id='search_date_select'
								label='조회'
								options={[
									{ name: '주간', value: 'week' },
									{ name: '지난주', value: 'lastweek' },
									{ name: '월간', value: 'month' },
									{ name: '지난달', value: 'lastmonth' },
									{ name: '1분기', value: 'first_quarter' },
									{ name: '2분기', value: 'second_quarter' },
									{ name: '3분기', value: 'third_quarter' },
									{ name: '4분기', value: 'fourth_quarter' },
									{ name: '연도별', value: 'year' },
									{ name: '작년', value: 'lastyear' },
									{ name: '직접지정', value: 'custom' },
								]}
								required
								formik={formik}
							/>
						</div>
						<InputDate
							id='search_date_start'
							label='조회 시작일'
							format='YYYY.MM.DD (ddd)'
							formik={formik}
							disabled={!isSelectCustomDate}
							shouldDisableDate={date => date > dayjs(formik.values.search_date_end)}
						/>
						<span>~</span>
						<InputDate
							id='search_date_end'
							label='조회 종료일'
							format='YYYY.MM.DD (ddd)'
							formik={formik}
							disabled={!isSelectCustomDate}
							shouldDisableDate={date => date < dayjs(formik.values.search_date_start)}
						/>
						{/* <ButtonText text='조회' onClick={() => { setIsLoading() formik.handleSubmit(); }} disabled={isLoading} /> */}
						<ButtonLoading
							loading={btnLoading}
							text='조회'
							onClick={() => {
								setBtnLoading(true);
								setTimeout(() => setBtnLoading(false), 1000);
								formik.handleSubmit();
							}}
							disabled={isLoading}
						/>
					</div>
				</div>
			</header>

			{isLoading && (
				<CardBox className='flex'>
					<div className='w-full h-[388px]' />
				</CardBox>
			)}
			{isLoading && <TableSkeleton colSpan={6} />}

			{!isLoading && (
				<ChartComponent
					type={chartInfo.type}
					count={chartInfo.count}
					option1={chartInfo.option1}
					option2={chartInfo.option2}
					option3={chartInfo.option3}
				/>
			)}
			{!isLoading && legalListIds && <StatisticsList legalListIds={legalListIds} />}
		</section>
	);
}
