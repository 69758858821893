import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getContractLinkParam } from '../../../api/contract';
import { contractDetailUrl } from '../../../constants/adeleUrl';
import { navigateToAdele } from '../../../util/commonUtils';
import PopupBack from './PopupBack';

type Props = {
	title: string;
	contractId: string;
};
export default function HeaderSign({ title, contractId }: Props) {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	
	const handleClick = async () => {
		setOpen(false);
		const param = await getContractLinkParam({
			conid: Number(contractId),
			viewmode: 'direct',
		});

		navigateToAdele(`${contractDetailUrl}?param=${param.data}`);
	};

	return (
		<header>
			<PopupBack
				open={open}
				setOpen={setOpen}
				msg={
					<p>{t('contract_guide_confirm_seal_sign_cancel')}</p>
				}
				onConfirm={handleClick}
			/>
			<h2 className='text-xl font-bold mt-2'>{title}</h2>
		</header>
	);
}
