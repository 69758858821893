import { SvgIcon, SvgIconClasses } from '@mui/material';
import { SvgIconPropsOverrides } from '../../../model/icon';

type SvgIconClassesOverrides = SvgIconClasses & { path1: string; path2: string };
type SvgIconAlarmPropsOverrides = SvgIconPropsOverrides & {
	classes?: Partial<SvgIconClassesOverrides>;
};

export default function AlarmIcon({ classes, ...props }: SvgIconAlarmPropsOverrides) {
	const { root = '', path1 = '', path2 = '' } = { ...classes };

	return (
		<SvgIcon className={`${root}`} {...props}>
			<path
				d='M21.1734 16.4666V9.99993C21.1734 6.35196 18.2091 3.41124 14.1156 2.59078V1.76296C14.1156 0.821932 13.1292 0 12 0C10.869 0 9.88443 0.821932 9.88443 1.76296V2.59078C5.78915 3.41124 2.82664 6.35196 2.82664 9.99993V16.4666L0 18.8221V19.9999H24V18.8221L21.1734 16.4666Z'
				className={`fill-inherit ${path1}`}
			/>
			<path
				d='M12.0019 23.9997C14.1773 23.9997 15.9543 22.5169 15.9543 20.6958H8.03711C8.03711 22.5169 9.82645 23.9997 12.0019 23.9997Z'
				className={`fill-inherit ${path2}`}
			/>
		</SvgIcon>
	);
}
