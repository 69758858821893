import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { getTagList } from '../../api/common';
import { queryKey } from '../../constants/queryKey';
import InputSelect from '../ui/inputs/InputSelect';

type Props = {
	id: string;
	label: string;
	formik: FormikValues;
	extendedType?: 'DEFAULT' | 'LEGAL_FIELD';
};

export default function InputTags({ id, label, formik, extendedType = 'DEFAULT' }: Props) {
	// const { t } = useTranslation();
	const { data: options } = useQuery([queryKey.tag, queryKey.list], () => getTagList());

	return (
		options && (
			<InputSelect
				multiple
				type='tag'
				id={id}
				label={label}
				options={options
					.filter(option => option?.extended_type === extendedType)
					.map(option => ({ ...option, value: option.id }))}
				formik={formik}
			/>
		)
	);
}
