import { useQuery } from '@tanstack/react-query';
import { FormikValues } from 'formik';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getSealStampList } from '../../../api/sealStamp';
import { queryKey } from '../../../constants/queryKey';
import { useSealStampList } from '../../../context/SealStampContext';
import { useValidateForm } from '../../../hooks/useValidateForm';
import { SealStamp } from '../../../model/sealStamp';
import { HeadCell } from '../../../model/table';
import SysConfigStore from '../../../store/common/SysConfigStore';
import ButtonText from '../../ui/buttons/ButtonText';
import ScrollBox from '../../ui/containers/ScrollBox';
import PopupInternalState from '../../ui/popups/PopupInternalState';
import TableSkeleton from '../../ui/tables/TableSkeleton';
import TableSealStampList from './TableSealStampList';

type Props = {
	id: string;
	formik: FormikValues;
	disabled?: boolean;
};
export default function PopupSealStamp({ id, formik, disabled = false }: Props) {
	const { labelText } = SysConfigStore();
	const { t } = useTranslation();
	const [isOpen, setIsOpen] = useState(false);
	const [closeSwitch, setCloseSwitch] = useState(false);
	const handleTogglePopup = () => setCloseSwitch(prev => !prev);
	const { searchParams } = useSealStampList();
	const { data: paginationData } = useQuery(
		[queryKey.sealStamp, queryKey.list, searchParams],
		() => getSealStampList(searchParams),
		{ enabled: isOpen },
	);
	const selectedFormik = useValidateForm({
		validationSchema: {
			[id]: { initialValue: [], type: 'array' },
		},
	});

	const openTrigger = (
		<ButtonText
			classes={{ root: 'min-w-0 py-0 font-bold border-2 border-gray-300 rounded-2xl' }}
			text='+'
			color='inherit'
			variant='outlined'
		/>
	);

	const handleClick = () => {
		formik.setFieldValue(id, selectedFormik.values[id]);
		handleTogglePopup();
	};

	const headCells: HeadCell<SealStamp>[] = [
		{
			id: 'checkboxSection',
			align: 'left',
			disablePadding: true,
			label: '',
		},
		{
			id: 'img_url',
			align: 'left',
			disablePadding: true,
			label: '',
		},
		{
			id: 'name',
			align: 'left',
			disablePadding: false,
			label: t('contract_label_stamp_names').replace('{{인장}}', labelText('seal_stamp')),
		},
		{
			id: 'seal_type',
			align: 'left',
			disablePadding: false,
			label: t('seal_type').replace('{{인장}}', labelText('seal_stamp')),
		},
		{
			id: 'approval_user_names',
			align: 'left',
			disablePadding: false,
			label: t('contract_label_approval_user_name'),
		},
		{
			id: 'view_code',
			align: 'left',
			disablePadding: false,
			label: t('seal_stamp_viewcode').replace('{{인장}}', labelText('seal_stamp')),
		},
	];

	return (
		<PopupInternalState
			onClick={() => setIsOpen(prev => !prev)}
			openTrigger={openTrigger}
			closeTrigger={closeSwitch}
			size='large'
		>
			<section className='flex flex-col gap-5'>
				<div>
					<h2 className='text-2xl font-bold'>
						{t('search_seal_stamp').replace('{{인장}}', labelText('seal_stamp'))}
					</h2>
				</div>

				<ScrollBox className='max-h-[70vh] xl:max-h-[77vh]'>
					{paginationData ? (
						<TableSealStampList
							id={id}
							list={paginationData.data}
							// orderBy='name'
							headCells={headCells}
							totalListCount={paginationData.recordsTotal}
							formik={formik}
							maxSelected={1}
							selectedFormik={selectedFormik}
						/>
					) : (
						<TableSkeleton colSpan={headCells.length + 1} />
					)}
				</ScrollBox>

				<div className='flex justify-center gap-5'>
					<ButtonText text={t('MSG_ID_105')} variant='contained' onClick={handleTogglePopup} />
					<ButtonText text={t('MSG_ID_104')} variant='contained' color='primary' onClick={handleClick} />
				</div>
			</section>
		</PopupInternalState>
	);
}
